import { CHF_VISIT_SECTIONS } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/CNWizardForm/sections/chfVisit';
import type { MedReviewFormFields } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/CNWizardForm/sections/shared/MedReview/formConfig';
import type { SectionStepsState } from '@/shared/common/Wizard/state';
import { getWizardStepValues } from '@/shared/common/Wizard/state';
import type { PatientMedications } from '@/shared/generated/grpc/go/pms/pkg/patient/medication/medication.pb';

import { cnMedicationReview } from '../../shared/cnMedicationReview';

export function chfVisitMedicationReview(
  data: SectionStepsState,
  noteId: number,
  meds: PatientMedications,
) {
  const medReviewData = getWizardStepValues(
    data,
    CHF_VISIT_SECTIONS,
    '/med-review',
    '/index',
  )<MedReviewFormFields>();

  return cnMedicationReview({ noteId, meds, medReviewData });
}
