import { CareProviderProviderSpecialty } from '@/shared/generated/grpc/cadence/models/models.pb';
import {
  type PatientExternalProvider,
  PatientExternalProviderType,
} from '@/shared/generated/grpc/go/pms/pkg/patient/pms.pb';

export function getReferringCareProvider(
  externalProviders: PatientExternalProvider[] = [],
) {
  return externalProviders.find(
    (excp) => excp.careProviderType === PatientExternalProviderType.REFERRING,
  );
}

export function getPrimaryCareProvider(
  externalProviders: PatientExternalProvider[] = [],
) {
  return externalProviders.find(
    (excp) =>
      excp.careProviderType === PatientExternalProviderType.USUAL &&
      excp.careProvider?.providerSpecialty !==
        CareProviderProviderSpecialty.CARDIOLOGY,
  );
}

export function getCardiologistProvider(
  externalProviders: PatientExternalProvider[] = [],
) {
  return externalProviders.find(
    (excp) =>
      excp.careProviderType ===
        PatientExternalProviderType.IDENTIFIED_CARDIOLOGIST ||
      excp.careProvider?.providerSpecialty ===
        CareProviderProviderSpecialty.CARDIOLOGY,
  );
}
