import { ProgramType } from '@/shared/generated/grpc/go/pms/pkg/patient/pms.pb';
import { makeI18nHookAndGetter } from '@/shared/hooks/makeI18nHook';

export const { hook: useProgramTypeI18n, getter: getProgramTypeI18n } =
  makeI18nHookAndGetter<Record<ProgramType, string>>((intl) => ({
    [ProgramType.APCM]: intl.formatMessage({
      defaultMessage: 'APCM',
      description: 'Abbreviation for "Advanced Primary Care Management"',
    }),
    [ProgramType.CCM]: intl.formatMessage({
      defaultMessage: 'CCM',
      description: 'Abbreviation for "Chronic Care Management"',
    }),
    [ProgramType.RPM]: intl.formatMessage({
      defaultMessage: 'RPM',
      description: 'Abbreviation for "Remote Patient Monitoring"',
    }),
    [ProgramType.PROGRAM_TYPE_UNSPECIFIED]: intl.formatMessage({
      defaultMessage: 'Unspecified',
    }),
  }));
