import { FormattedMessage } from 'react-intl';

import { Modal } from '@/shared/common/Modal';
import { ReportIssueRequestCategory } from '@/shared/generated/grpc/go/pms/pkg/issue_reporter/issue_reporter.pb';
import { Tabs } from '@/shared/tempo/@labs/atom/Tabs';

import { useSupportModalContext } from '../../SupportModalContext';
import { TicketCta } from '../../TicketCta';
import { tabs } from '../page.css';
import { tabPadding } from './Communications.css';
import { CommunicationsForm } from './CommunicationsForm';
import { CommunicationsResources } from './CommunicationsResources';
import { CommunicationsTable } from './CommunicationsTable';
import { WeeklySummary } from './WeeklySummary';

export function Communications() {
  const { patientId } = useSupportModalContext();
  if (!patientId) {
    return (
      <Modal.Body>
        <CommunicationsResources />
      </Modal.Body>
    );
  }

  return (
    <Modal.Body>
      <Tabs className={tabs}>
        <Tabs.Tab
          key="outbound"
          className={tabPadding}
          title={<FormattedMessage defaultMessage="Outbound" />}
        >
          <CommunicationsTable patientId={patientId} />
        </Tabs.Tab>
        <Tabs.Tab
          key="weekly_summary"
          className={tabPadding}
          title={<FormattedMessage defaultMessage="Weekly Summary" />}
        >
          <WeeklySummary patientId={patientId} />
        </Tabs.Tab>
        <Tabs.Tab
          key="preferences"
          title={<FormattedMessage defaultMessage="Preferences" />}
        >
          <CommunicationsForm patientId={patientId} />
        </Tabs.Tab>
        <Tabs.Tab
          key="resources"
          className={tabPadding}
          title={<FormattedMessage defaultMessage="Resources" />}
        >
          <CommunicationsResources />
        </Tabs.Tab>
      </Tabs>
      <TicketCta category={ReportIssueRequestCategory.CATEGORY_COMMUNICATION} />
    </Modal.Body>
  );
}
