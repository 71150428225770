import type {
  InfiniteData,
  UseInfiniteQueryOptions,
  UseQueryOptions,
} from 'react-query';
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import type { UseInfiniteQueryResult } from 'react-query/types/react/types';
import { useLocation } from 'react-router-dom';

import type {
  ListAllPatientsResponse,
  Patient,
} from '@/shared/generated/grpc/go/pms/pkg/patient/pms.pb';
import { PatientService } from '@/shared/generated/grpc/go/pms/pkg/patient/pms.pb';
import { useFlags } from '@/shared/hooks/useFlags';
import type { Condition } from '@/shared/types/clinicalprofile.types';
import type { PaginatedData } from '@/shared/types/pagination.types';
import type {
  EplProspectivePatient,
  PatientStatus,
  ProspectivePatient,
  StatusChangeReasonUppercase,
} from '@/shared/types/patient.types';
import type { Provider } from '@/shared/types/provider.types';
import type {
  CompositeSortDirection,
  CompositeSortKey,
  SortDirection,
} from '@/shared/types/sorting.types';
import Session from '@/shared/utils/session';
import { isValidUuid } from '@/shared/utils/uuid.utils';

import {
  defaultGetNextPageParam,
  defaultQueryFunction,
  grpcGetNextPageParam,
  grpcQueryFunction,
} from '../../../reactQuery';
import {
  PATIENTS_QUERY_PARAMS_FILTER_LOOKUP,
  PATIENTS_QUERY_PARAMS_ORDER_BY_LOOKUP,
  buildPatientsRPCRequest,
} from './patients-grpc';
import { convertPbPatientToPatient } from './patients-grpc/converters';

export const PROSPECTIVE_PATIENTS_BASE = [
  'pms',
  'api',
  'v1',
  'prospective-patients',
] as const;

export const GRPC_PROSPECTIVE_PATIENTS_BASE = [
  'rpm',
  'v1',
  'patients',
] as const;

const PROSPECTIVE_PATIENTS_QUERY_KEYS = {
  infinite: (params: ProspectivePatientsParams) =>
    [...PROSPECTIVE_PATIENTS_BASE, params, 'infinite'] as const,
  grpcInfiniteAllPatients: (params: ProspectivePatientsParams) =>
    [...GRPC_PROSPECTIVE_PATIENTS_BASE, 'all', params, 'infinite'] as const,
  grpcInfiniteProspectivePatients: (params: ProspectivePatientsParams) =>
    [
      ...GRPC_PROSPECTIVE_PATIENTS_BASE,
      'prospective',
      params,
      'infinite',
    ] as const,
  lastClinicApptProviders: (params: LastClinicProviderParams) =>
    [
      ...PROSPECTIVE_PATIENTS_BASE,
      'last-clinic-appointment-providers',
      params,
    ] as const,
  usualProvidersInfinite: (params: UsualProviderParams) =>
    [
      ...PROSPECTIVE_PATIENTS_BASE,
      'usual-providers',
      params,
      'infinite',
    ] as const,
  identifiedProviders: (params: IdentifiedProviderParams) =>
    [...PROSPECTIVE_PATIENTS_BASE, 'identified-providers', params] as const,
  useSchedulingCareProviders: (params: SchedulingCareProviderParams) =>
    [
      ...PROSPECTIVE_PATIENTS_BASE,
      'scheduling-care-providers',
      params,
    ] as const,
  eligibilityInfo: (patientId: string) => [
    ...PROSPECTIVE_PATIENTS_BASE,
    'eligibility',
    patientId,
  ],
  synchronizePatient: (patientId: string) => [
    ...PROSPECTIVE_PATIENTS_BASE,
    'synchronization',
    patientId,
  ],
};

export type ProspectivePatientsSortField =
  | 'first_name'
  | 'last_name'
  | 'dob'
  | 'status'
  | 'created_at'
  | 'upcoming_appointment_date'
  | 'ejection_fraction_upper'
  | 'ejection_fraction_lower'
  | 'a1c'
  | 'last_blood_pressure_average_diastolic'
  | 'last_blood_pressure_average_systolic'
  | 'last_blood_pressure_max_diastolic'
  | 'last_blood_pressure_systolic'
  | 'last_blood_pressure_diastolic'
  | 'last_blood_pressure_max_systolic'
  | 'visited_emergency_department'
  | 'primary_insurance_name'
  | 'secondary_insurance_name'
  | 'status_date'
  | 'bmi'
  | 'order_date'
  | 'enrollment_score'
  | 'patient_tier'
  // TODO: Separate EplProspectivePatientsSortField from ProspectivePatientsSortField
  | EplProspectivePatientsSortField;

type EplProspectivePatientsSortField = 'latest_nab_list_generated_at';

export type ProspectivePatientsParams = {
  id?: string;
  fnormrn?: string; // Full name or MRN to search for
  full_name?: string;
  dob_from?: string;
  dob_to?: string;
  hospital_id?: string;
  status?: PatientStatus | '';
  not_status_reason?: StatusChangeReasonUppercase;
  appt_from?: string;
  appt_to?: string;
  nab_list_id?: string;
  sort_by?: CompositeSortKey<ProspectivePatientsSortField>;
  order_by?: CompositeSortDirection;
  condition?: Condition;
  include_ai_identified?: boolean;
  usual_provider_name?: string[];
  npi_id?: string[];
  care_provider_id?: string[];
  identified_provider_npi_id?: number[];
};

export function useInvalidateProspectivePatients() {
  const queryClient = useQueryClient();
  const flags = useFlags();
  return async () =>
    queryClient.invalidateQueries(
      flags.petWithGrpc
        ? GRPC_PROSPECTIVE_PATIENTS_BASE
        : PROSPECTIVE_PATIENTS_BASE,
    );
}

function useProspectivePatientsInfiniteQueryParams({
  fnormrn: fnormrnOrId,
  ...rest
}: ProspectivePatientsParams) {
  const fnormrn = fnormrnOrId?.trim();
  return {
    ...rest,
    ...(fnormrn && (isValidUuid(fnormrn) ? { id: fnormrn } : { fnormrn })),
  };
}

export function useProspectivePatients(
  prospectivePatientsParams: ProspectivePatientsParams,
  config?: UseInfiniteQueryOptions<PaginatedData<ProspectivePatient>>,
) {
  const params = useProspectivePatientsInfiniteQueryParams(
    prospectivePatientsParams,
  );

  const flags = useFlags();
  const location = useLocation();

  let queryKey;
  if (flags.petWithGrpc) {
    if (
      location.pathname.includes('/all-patients') ||
      location.pathname.includes('/patient-lists')
    ) {
      queryKey =
        PROSPECTIVE_PATIENTS_QUERY_KEYS.grpcInfiniteAllPatients(params);
    } else {
      queryKey =
        PROSPECTIVE_PATIENTS_QUERY_KEYS.grpcInfiniteProspectivePatients(params);
    }
  } else {
    queryKey = PROSPECTIVE_PATIENTS_QUERY_KEYS.infinite(params);
  }

  return useInfiniteQuery<PaginatedData<ProspectivePatient>>(
    queryKey,
    flags.petWithGrpc
      ? (ctx) =>
          grpcQueryFunction<ListAllPatientsResponse>(
            ctx,
            location.pathname.includes('/all-patients') ||
              location.pathname.includes('/patient-lists')
              ? PatientService.ListAllPatients
              : PatientService.ListProspectivePatients,
            buildPatientsRPCRequest(
              ctx,
              PATIENTS_QUERY_PARAMS_FILTER_LOOKUP,
              PATIENTS_QUERY_PARAMS_ORDER_BY_LOOKUP,
            ),
          )
      : defaultQueryFunction,
    {
      ...config,
      getNextPageParam: flags.petWithGrpc
        ? grpcGetNextPageParam
        : defaultGetNextPageParam,
      select: (data) => {
        if (flags.petWithGrpc) {
          return {
            ...data,
            pages: (data?.pages ?? []).map((page) => ({
              ...page,
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              data: ((page as any)?.patients ?? []).map(
                (pbPatient: Patient) => ({
                  ...convertPbPatientToPatient(pbPatient),
                  nab_lists: null,
                  has_potential_duplicate_patients: null,
                }),
              ),
              metadata: {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                total: (page as any).totalSize,
              },
            })),
          } as InfiniteData<PaginatedData<ProspectivePatient>>;
        }
        return data as InfiniteData<PaginatedData<ProspectivePatient>>;
      },
    },
  ) as UseInfiniteQueryResult<PaginatedData<ProspectivePatient>>;
}

export function useEplProspectivePatients(
  eplProspectivePatientsParams: ProspectivePatientsParams,
  config?: UseInfiniteQueryOptions<PaginatedData<EplProspectivePatient>>,
) {
  const flags = useFlags();
  const location = useLocation();

  const params = useProspectivePatientsInfiniteQueryParams(
    eplProspectivePatientsParams,
  );

  let queryKey;
  if (
    location.pathname.includes('/all-patients') ||
    location.pathname.includes('/patient-lists') ||
    location.pathname.includes('/toolbox/patient')
  ) {
    queryKey = PROSPECTIVE_PATIENTS_QUERY_KEYS.grpcInfiniteAllPatients(params);
  } else {
    queryKey =
      PROSPECTIVE_PATIENTS_QUERY_KEYS.grpcInfiniteProspectivePatients(params);
  }

  return useInfiniteQuery<PaginatedData<EplProspectivePatient>>(
    queryKey,
    flags.petWithGrpc
      ? (ctx) =>
          grpcQueryFunction<ListAllPatientsResponse>(
            ctx,
            location.pathname.includes('/all-patients') ||
              location.pathname.includes('/patient-lists') ||
              location.pathname.includes('/toolbox/patient')
              ? PatientService.ListAllPatients
              : PatientService.ListProspectivePatients,
            buildPatientsRPCRequest(
              ctx,
              PATIENTS_QUERY_PARAMS_FILTER_LOOKUP,
              PATIENTS_QUERY_PARAMS_ORDER_BY_LOOKUP,
            ),
          )
      : defaultQueryFunction,
    {
      ...config,
      getNextPageParam: flags.petWithGrpc
        ? grpcGetNextPageParam
        : defaultGetNextPageParam,
      select: (data) => {
        if (flags.petWithGrpc) {
          return {
            ...data,
            pages: (data?.pages ?? []).map((page) => ({
              ...page,
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              data: ((page as any)?.patients ?? []).map(
                (pbPatient: Patient) => ({
                  ...convertPbPatientToPatient(pbPatient),
                }),
              ),
              metadata: {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                total: (page as any)?.totalSize,
              },
            })),
          } as InfiniteData<PaginatedData<EplProspectivePatient>>;
        }
        return data as InfiniteData<PaginatedData<EplProspectivePatient>>;
      },
    },
  ) as UseInfiniteQueryResult<PaginatedData<EplProspectivePatient>>;
}

type LastClinicProviderParams = {
  sort_by?: 'last_clinic_appt_provider_name';
  order_by?: SortDirection;
  last_clinic_appt_provider_name_fuzzy?: string;
};

export type LastClinicApptProvider = {
  last_clinic_appt_provider_name: string;
};

export function useLastClinicApptCareProviders(
  params: LastClinicProviderParams,
  config: UseQueryOptions<PaginatedData<LastClinicApptProvider>> = {},
) {
  return useQuery<PaginatedData<LastClinicApptProvider>>(
    PROSPECTIVE_PATIENTS_QUERY_KEYS.lastClinicApptProviders({
      order_by: 'asc',
      sort_by: 'last_clinic_appt_provider_name',
      ...params,
    }),
    config,
  );
}

type UsualProviderParams = {
  order_by?: SortDirection;
  sort_by?: 'usual_provider_name';
  usual_provider_name_fuzzy?: string;
};

export type UsualProvider = {
  usual_provider_name: string;
  usual_provider_npi: string;
  hospitals: Array<UsualProviderHospital>;
};

export type UsualProviderHospital = {
  hospital_name: string;
  hospital_locality: string;
  hospital_region: string;
  health_system_name: string;
};

export function useUsualProvidersInfinite(
  params: UsualProviderParams,
  config: UseInfiniteQueryOptions<PaginatedData<UsualProvider>> = {},
) {
  return useInfiniteQuery<PaginatedData<UsualProvider>>(
    PROSPECTIVE_PATIENTS_QUERY_KEYS.usualProvidersInfinite({
      order_by: 'asc',
      sort_by: 'usual_provider_name',
      ...params,
    }),
    config,
  );
}

type SchedulingCareProviderParams = {
  order_by?: SortDirection;
  scheduling_care_provider_name?: string;
};

export type SchedulingCareProvider = {
  scheduling_care_provider_name: string;
};

export function useSchedulingCareProviders(
  params: SchedulingCareProviderParams,
  config: UseQueryOptions<PaginatedData<SchedulingCareProvider>> = {},
) {
  return useQuery<PaginatedData<SchedulingCareProvider>>(
    PROSPECTIVE_PATIENTS_QUERY_KEYS.useSchedulingCareProviders({
      order_by: 'asc',
      ...params,
    }),
    config,
  );
}

export function useSynchronizePatient(patientId: string) {
  const client = useQueryClient();

  return useMutation(
    () =>
      Session.Api.post(
        `/pms/api/v1/prospective-patients/synchronization/${patientId}`,
      ),
    {
      onSuccess: () => {
        client.invalidateQueries([...PROSPECTIVE_PATIENTS_BASE, patientId]);
      },
    },
  );
}

export function useIdentifiedProviders(
  params: IdentifiedProviderParams,
  config: UseQueryOptions<PaginatedData<Provider>> = {},
) {
  return useQuery<PaginatedData<Provider>>(
    PROSPECTIVE_PATIENTS_QUERY_KEYS.identifiedProviders({
      order_by: 'asc',
      sort_by: 'identified_provider_name',
      ...params,
    }),
    config,
  );
}

type IdentifiedProviderParams = {
  order_by?: SortDirection;
  sort_by?: 'identified_provider_name';
  care_provider_id?: string;
  identified_provider_name_fuzzy?: string;
  npi_id?: number;
};
