import { useHistory, useRouteMatch } from 'react-router-dom';

import type { CCMCarePlan } from '@/shared/generated/grpc/go/pms/pkg/ccm_care_plan/ccm_care_plan.pb';
import { grpcNameToId } from '@/shared/utils/grpc';
import { parentPath } from '@/shared/utils/route-helpers';

import { DeleteModal } from '../../DeleteModal';
import { CreateGoalModal } from '../../Goals/CreateGoalModal';
import { EditGoalModal } from '../../Goals/EditGoalModal';
import { GoalModalType } from '../../Goals/goalModal.types';
import { type ModalState, ModalType } from './modal.types';

type Props = {
  modalState: Nullable<ModalState>;
  setModalState: (s: Nullable<ModalState>) => void;
  existingDraft: CCMCarePlan | undefined;
};

export function CarePlanFormModals({
  modalState,
  setModalState,
  existingDraft,
}: Props) {
  const history = useHistory();
  const { url, params } = useRouteMatch<{ patientId: string }>();
  return (
    <>
      {modalState?.type === GoalModalType.CreateGoal && (
        <CreateGoalModal
          patientId={params.patientId}
          carePlanId={
            existingDraft?.name ? grpcNameToId(existingDraft?.name) : undefined
          }
          defaults={modalState.defaults}
          onClose={() => setModalState(null)}
        />
      )}
      {modalState?.type === GoalModalType.EditGoal && (
        <EditGoalModal
          goal={modalState.goal}
          onClose={() => setModalState(null)}
        />
      )}
      {modalState?.type === ModalType.Delete && (
        <DeleteModal
          carePlanName={modalState.carePlanName}
          onClose={() => setModalState(null)}
          onDeleteSuccess={() => {
            setModalState(null);
            history.push(parentPath(url));
          }}
        />
      )}
    </>
  );
}
