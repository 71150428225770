import { useQuery } from 'react-query';

import { PatientService } from '@/shared/generated/grpc/go/pms/pkg/patient/pms.pb';

export const programConditionQuerykey = {
  list: (patientId: string) => [
    'rpm',
    'v1',
    'patients',
    patientId,
    'programConditions',
  ],
};

export function usePatientPrograms(patientID: string, enabled = true) {
  return useQuery(
    programConditionQuerykey.list(patientID),
    () => PatientService.GetPatientConditionsPerProgram({ name: patientID }),
    {
      enabled,
    },
  );
}
