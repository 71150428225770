import { FormattedMessage } from 'react-intl';

export const PREVENTATIVE_CARE_OPTIONS = [
  {
    name: 'pneumovax',
    label: <FormattedMessage defaultMessage="Pneumovax" />,
    help: <FormattedMessage defaultMessage="At or after age of 65" />,
    alwaysApplicable: true,
  },
  {
    name: 'influenza',
    label: <FormattedMessage defaultMessage="Influenza" />,
    help: <FormattedMessage defaultMessage="During yearly flu season" />,
    alwaysApplicable: true,
  },
  {
    name: 'tdap',
    label: <FormattedMessage defaultMessage="Tdap" />,
    help: <FormattedMessage defaultMessage="Every 10 years OR after injury" />,
    alwaysApplicable: true,
  },
  {
    name: 'shingrix',
    label: <FormattedMessage defaultMessage="Shingrix" />,
    help: <FormattedMessage defaultMessage="Adults age 50+" />,
    alwaysApplicable: true,
  },
  {
    name: 'colonoscopy',
    label: <FormattedMessage defaultMessage="Colonoscopy" />,
    help: <FormattedMessage defaultMessage="Adults age 45-75" />,
    alwaysApplicable: true,
  },
  {
    name: 'breastCancerScreening',
    label: <FormattedMessage defaultMessage="Breast Cancer Screening" />,
    help: <FormattedMessage defaultMessage="Females age 40+" />,
    alwaysApplicable: false,
  },
  {
    name: 'diabeticEyeExam',
    label: <FormattedMessage defaultMessage="Diabetic Eye Exam" />,
    help: (
      <FormattedMessage defaultMessage="Annually for patients with Diabetes diagnosis" />
    ),
    alwaysApplicable: false,
  },
] as const;
