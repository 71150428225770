import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { LoadingPlaceholder } from '@/shared/common/LoadingPlaceholder';
import { ProgramType } from '@/shared/generated/grpc/go/pms/pkg/patient/pms.pb';
import { usePatientDetails } from '@/shared/hooks/queries';
import { conjugateList } from '@/shared/i18n/list.utils';
import { isParticipatingInProgramType } from '@/shared/patient/programs.utils';

import { gridContainer } from '../CarePlanForm/CarePlanForm.css';
import { CreateGoalModal } from '../Goals/CreateGoalModal';
import { EditGoalModal } from '../Goals/EditGoalModal';
import { GoalsGrid } from '../Goals/GoalsGrid';
import { type GoalModalState, GoalModalType } from '../Goals/goalModal.types';
import { emptyState } from './CarePlanEmptyState.css';

type Props = {
  patientId: string;
  hasLegacyApprovedCarePlan: boolean;
};

export function CarePlanEmptyState({
  patientId,
  hasLegacyApprovedCarePlan,
}: Props) {
  const intl = useIntl();
  const [openedModal, setOpenedModal] =
    useState<Nullable<GoalModalState>>(null);

  const { data: patient, isLoading: isLoadingPatient } = usePatientDetails(
    patientId,
    true,
  );
  const { isParticipating: isCcmParticipating } = isParticipatingInProgramType(
    patient,
    ProgramType.CCM,
    { checkConsent: true },
  );
  const { isParticipating: isApcmParticipating } = isParticipatingInProgramType(
    patient,
    ProgramType.APCM,
    { checkConsent: true },
  );

  const programNames = [
    isCcmParticipating && intl.formatMessage({ defaultMessage: 'CCM' }),
    isApcmParticipating && intl.formatMessage({ defaultMessage: 'APCM' }),
  ].filter(Boolean) as string[];

  return (
    <>
      {hasLegacyApprovedCarePlan && (
        <div className={gridContainer}>
          <GoalsGrid
            patientId={patientId}
            onEditGoal={(goal) => {
              setOpenedModal({ type: GoalModalType.EditGoal, goal });
            }}
            onCreateGoal={() => {
              setOpenedModal({ type: GoalModalType.CreateGoal });
            }}
          />
        </div>
      )}
      <div
        className={
          hasLegacyApprovedCarePlan
            ? emptyState.container.legacyPatient
            : emptyState.container.default
        }
      >
        <div className={emptyState.content}>
          <LoadingPlaceholder isLoading={isLoadingPatient}>
            {hasLegacyApprovedCarePlan ? (
              <>
                <p className={emptyState.text}>
                  <FormattedMessage
                    defaultMessage="This patient is enrolled in {programs} and may have an existing Care Plan in Google Drive."
                    values={{
                      programs: conjugateList(programNames, 'disjunction'),
                    }}
                  />
                </p>
                <p className={emptyState.text}>
                  <FormattedMessage defaultMessage="To create a new Care Plan in Cadence, start a CCM Care Plan encounter." />
                </p>
              </>
            ) : (
              <>
                <p className={emptyState.text}>
                  <FormattedMessage
                    defaultMessage="This patient is eligible for {programs} but does not yet have a care plan."
                    values={{
                      programs: conjugateList(programNames, 'disjunction'),
                    }}
                  />
                </p>
                <p className={emptyState.text}>
                  <FormattedMessage defaultMessage="Start a Care Plan encounter to start building a care plan." />
                </p>
              </>
            )}
          </LoadingPlaceholder>
        </div>
      </div>
      {openedModal?.type === GoalModalType.CreateGoal && (
        <CreateGoalModal
          patientId={patientId}
          carePlanId={undefined}
          defaults={openedModal.defaults}
          onClose={() => setOpenedModal(null)}
        />
      )}
      {openedModal?.type === GoalModalType.EditGoal && (
        <EditGoalModal
          goal={openedModal.goal}
          onClose={() => setOpenedModal(null)}
        />
      )}
    </>
  );
}
