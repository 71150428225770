import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { getCurrentEnvHostname } from '@/config';
import { SchedulingService } from '@/shared/generated/grpc/go/pms/pkg/scheduling/scheduling.pb';
import { useFlags } from '@/shared/hooks/useFlags';
import type { RootState } from '@/store/redux.types';

import { useCurrentUser } from './useCurrentUser';

// This is not a secret and we use the same value for all environments
const GOOGLE_OAUTH_CLIENT_ID =
  '335853418591-53viv2u7gc28j5ngfj1op15916t66f9a.apps.googleusercontent.com';

export function useGoogleCalendarOAuthCheck() {
  const { googleCalendarOauthCheck: isGoogleOAuthEnabled } = useFlags();
  const auth = useSelector((state: RootState) => state.auth);
  const isAuthenticated = auth?.isAuthenticated && auth?.token?.id_token;
  const { currentUser, currentUserId } = useCurrentUser();

  const { data, isLoading } = useQuery({
    queryKey: ['google-calendar-oauth-check'],
    queryFn: () =>
      SchedulingService.CheckGoogleOAuthTokenExists({
        careProviderId: currentUserId,
      }),
    enabled: Boolean(isGoogleOAuthEnabled && isAuthenticated && currentUserId),
    // Only check once per session
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  useEffect(() => {
    if (
      isGoogleOAuthEnabled &&
      isAuthenticated &&
      !isLoading &&
      data?.hasValidToken === false
    ) {
      window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${GOOGLE_OAUTH_CLIENT_ID}&redirect_uri=https://api2.${getCurrentEnvHostname()}/rpm/webhooks/google/callback&response_type=code&scope=https://www.googleapis.com/auth/calendar&access_type=offline&prompt=consent&state=${currentUserId}|${
        currentUser.email
      }`;
    }
  }, [
    isGoogleOAuthEnabled,
    isAuthenticated,
    currentUser,
    currentUserId,
    currentUser?.email,
    data?.hasValidToken,
    isLoading,
  ]);

  return {};
}
