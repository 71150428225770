import last from 'lodash/last';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import ChevronDown from '@/shared/assets/svgs/chevron.svg?react';
import SendIcon from '@/shared/assets/svgs/send.svg?react';
import { MODAL_OPEN_ATTR } from '@/shared/common/Modal';
import type { ExpandState } from '@/shared/common/TextArea';
import { UserAvatar } from '@/shared/common/UserAvatar';
import type { CareProvider as GrpcCareProvider } from '@/shared/generated/grpc/go/pms/pkg/task/task.pb';
import { useOnClickOutside } from '@/shared/hooks';
import {
  GRPC_RESOURCE_NAME,
  useCreateComment,
  useUpdateTask,
} from '@/shared/hooks/queries/tasks.queries';
import { useCurrentUser } from '@/shared/hooks/useCurrentUser';
import { ReassignmentModal } from '@/shared/tasking/ReassignmentModal';
import { useTaskListContext } from '@/shared/tasking/TaskList/taskListContext';
import type { Task } from '@/shared/tasking/types';
import { Menu } from '@/shared/tempo/@labs/molecule/Menu';
import { Button } from '@/shared/tempo/atom/Button';
import {
  HotkeyTooltip,
  SpecialChar,
} from '@/shared/tempo/molecule/HotkeyTooltip';
import { useToaster } from '@/shared/tempo/molecule/Toast';
import { color } from '@/shared/tempo/theme';

import { taskParticipants, toTaskProvider } from '../utils';
import { CommentInput, MAX_COMMENT_LENGTH } from './CommentInput';
import {
  actions,
  container,
  menuItem,
  sendButton,
} from './CommentInputControls.css';
import { useFormatProviderNameOrMyself } from './useFormatProviderNameOrMyself';
import { ChooseUser, CurrentUser, useMenuItems } from './useMenuItems';

type Props = {
  task: Task;
  isDisabled?: boolean;
};

export function CommentInputControls({ task, isDisabled }: Props) {
  const intl = useIntl();
  const [reassignmentModalOpen, setReassignmentModalOpen] = useState(false);
  const { currentUser, currentUserId } = useCurrentUser();
  const defaultAssignTo = defaultAssignee(task, {
    uid: currentUserId,
    givenName: currentUser.given_name,
    familyName: currentUser.family_name,
  });
  const formatProviderNameOrMyself = useFormatProviderNameOrMyself();

  const onClickOutsideNode = useOnClickOutside<HTMLDivElement>(
    () => setInputExpanded(false),
    {
      // Skip if any modals are open
      skipIf: () =>
        Boolean(document.querySelector(`[${MODAL_OPEN_ATTR}="true"]`)),
    },
  );

  const { loggedInProvider } = useTaskListContext();
  const [assignTo, setAssignTo] = useState(defaultAssignTo);
  const [inputFocused, setInputFocused] = useState(false);
  const [inputExpanded, setBaseInputExpanded] = useState(false);
  const setInputExpanded = (val: boolean) => {
    setBaseInputExpanded(val);
    // Reset the assignee dropdown to default assignee on hide
    if (!val) {
      setAssignTo(defaultAssignTo);
    }
  };
  const [commentBody, setCommentBody] = useState('');
  const hasContent = commentBody.length > 0;

  const participants = taskParticipants(
    task.comments,
    task.creator,
    task.assignee ?? undefined,
  );
  const menuItems = useMenuItems(participants);
  const { toaster } = useToaster();

  const updateTask = useUpdateTask(task, {
    onError: () =>
      toaster.error(
        intl.formatMessage({ defaultMessage: 'Failed to reassign task' }),
      ),
    onSuccess: () =>
      toaster.success(
        intl.formatMessage({ defaultMessage: 'Task has been reassigned' }),
      ),
  });

  const { mutate: postComment, isLoading: isPosting } = useCreateComment(
    task.name,
    {
      onSuccess: (comment) => {
        setCommentBody('');
        setInputExpanded(false);
        if (task.assignee?.uid !== assignTo.uid) {
          updateTask.mutate({
            task: {
              assignee: {
                name: GRPC_RESOURCE_NAME.careProvider(assignTo.name),
              },
            },
          });
        }
        if (comment?.author) {
          setAssignTo(comment.author);
        }
      },
      onError: () =>
        toaster.error(
          intl.formatMessage({ defaultMessage: 'Failed to add comment' }),
        ),
    },
  );

  const canPost =
    Boolean(commentBody) &&
    commentBody.length <= MAX_COMMENT_LENGTH &&
    !isPosting &&
    !isDisabled;

  return (
    <>
      <div className={container} ref={onClickOutsideNode}>
        <CommentInput
          onSubmit={() => postComment({ body: commentBody })}
          canSubmit={canPost && inputFocused}
          disabled={isPosting || isDisabled}
          expand={getTextExpandState(isDisabled, hasContent, inputExpanded)}
          value={commentBody}
          onFocus={() => {
            setInputExpanded(true);
            setInputFocused(true);
          }}
          onBlur={() => setInputFocused(false)}
          onChange={(val) => setCommentBody(val)}
          placeholder={intl.formatMessage({
            defaultMessage: 'Type a response',
          })}
        />
        {(inputExpanded || hasContent) && !isDisabled && (
          <div className={actions.container}>
            <div className={actions.footer}>
              <div>
                <div className={actions.sendAndAssignText}>
                  <FormattedMessage defaultMessage="Send and assign to" />
                </div>
                <div className={actions.assigneeInfo}>
                  <Menu.Trigger
                    closeOnSelect
                    placement="bottom end"
                    menu={
                      <Menu
                        onAction={(key) => {
                          const careProvider = participants.find(
                            (p) => p.uid === key,
                          );
                          switch (key) {
                            case CurrentUser:
                              setAssignTo(toTaskProvider(loggedInProvider));
                              break;
                            case ChooseUser:
                              setReassignmentModalOpen(true);
                              break;
                            default:
                              if (careProvider) {
                                setAssignTo(careProvider);
                              }
                          }
                        }}
                      >
                        <Menu.Section
                          title={
                            <FormattedMessage defaultMessage="Send and assign to" />
                          }
                          items={menuItems}
                        >
                          {(item) => (
                            <Menu.Item>
                              <div className={menuItem.container}>
                                {item.icon}
                                <div className={menuItem.text}>
                                  {item.label}
                                </div>
                              </div>
                            </Menu.Item>
                          )}
                        </Menu.Section>
                      </Menu>
                    }
                  >
                    <Button variant="tertiary" size="small">
                      <UserAvatar
                        size="small"
                        firstName={assignTo.givenName}
                        lastName={assignTo.familyName}
                      />
                      <span className={actions.assigneeName}>
                        {formatProviderNameOrMyself(assignTo, currentUserId)}
                      </span>
                      <Button.Icon>
                        <ChevronDown fill={color.Palette.Neutral[900]} />
                      </Button.Icon>
                    </Button>
                  </Menu.Trigger>
                </div>
              </div>
              <div>
                <HotkeyTooltip
                  hotkey={[SpecialChar.Meta, SpecialChar.Return]}
                  disabled={!canPost}
                  title={intl.formatMessage({ defaultMessage: 'Send' })}
                >
                  <Button
                    isDisabled={!canPost}
                    className={sendButton}
                    onPress={() => postComment({ body: commentBody })}
                  >
                    <Button.Icon>
                      <SendIcon />
                    </Button.Icon>
                  </Button>
                </HotkeyTooltip>
              </div>
            </div>
          </div>
        )}
      </div>
      <ReassignmentModal
        task={task}
        open={reassignmentModalOpen}
        onSubmit={(provider) => setAssignTo(toTaskProvider(provider))}
        onClose={() => setReassignmentModalOpen(false)}
      />
    </>
  );
}

function defaultAssignee(task: Task, currentUser: GrpcCareProvider) {
  // Default assignment to author of last comment or task creator
  const lastComment = last(task.comments);
  return lastComment?.author || task.creator || currentUser;
}

function getTextExpandState(
  isDisabled: Maybe<boolean>,
  hasContent: boolean,
  inputExpanded: boolean,
): ExpandState {
  if (isDisabled) {
    return 'collapsed';
  }
  if (hasContent || inputExpanded) {
    return 'expanded';
  }
  return 'collapsed';
}
