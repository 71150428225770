import type { ReactNode } from 'react';
import { renderToString } from 'react-dom/server';
import { FormattedMessage, IntlProvider } from 'react-intl';

export function formattedMessageToString(formattedMessage: ReactNode) {
  return renderToString(
    <IntlProvider locale="en">{formattedMessage}</IntlProvider>,
  );
}

export function spellOutDigit(digit: number) {
  return (
    <FormattedMessage
      defaultMessage="{digit, select, 0 {zero} 1 {one} 2 {two} 3 {three} 4 {four} 5 {five} 6 {six} 7 {seven} 8 {eight} 9 {nine} other {{digit}}}"
      values={{ digit }}
    />
  );
}
