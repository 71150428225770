/* eslint-disable */
// @ts-nocheck
/*
* This file is a generated Typescript file for GRPC Gateway, DO NOT MODIFY
*/

import * as fm from "@/shared/generated/grpc/fetch.pb"

export enum ReportIssueRequestCategory {
  CATEGORY_UNSPECIFIED = "CATEGORY_UNSPECIFIED",
  CATEGORY_VITALS = "CATEGORY_VITALS",
  CATEGORY_COMMUNICATION = "CATEGORY_COMMUNICATION",
  CATEGORY_ORDERS = "CATEGORY_ORDERS",
  CATEGORY_PATIENT_INFO = "CATEGORY_PATIENT_INFO",
}

export type ReportIssueRequest = {
  userDisplayName?: string
  currentPageUri?: string
  patientId?: string
  description?: string
  rumSessionId?: string
  environment?: string
  version?: string
  category?: ReportIssueRequestCategory
}

export type ReportIssueResponse = {
}

export class IssueReporterService {
  static ReportIssue(req: ReportIssueRequest, initReq?: fm.InitReq): Promise<ReportIssueResponse> {
    return fm.fetchReq<ReportIssueRequest, ReportIssueResponse>(`/rpm/v1/issues:reportIssue`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
}