export enum FoodSecurityChoice {
  No = 'NO',
  Yes = 'YES',
}

export enum FoodFrequencyChoice {
  Never = 'NEVER',
  Often = 'OFTEN',
  Sometimes = 'SOMETIMES',
}

export type FoodSecurityFields = {
  foodSecurityChoice?: FoodSecurityChoice;
  foodWorryFrequency?: FoodFrequencyChoice;
  foodRunOutFrequency?: FoodFrequencyChoice;
};
