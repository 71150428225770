import { format, isValid, parseISO } from 'date-fns';

import type { PatientDetails } from '@/shared/generated/grpc/go/pms/pkg/patient/pms.pb';
import type { EhrSyncTaskRequestScope } from '@/shared/generated/grpc/go/pms/pkg/patient/synchronization/synchronization.pb';

export function scopeLastSyncTime(
  patient: Maybe<PatientDetails>,
  scope: EhrSyncTaskRequestScope,
): Maybe<Date> {
  const syncScope = patient?.synchronizationData?.find(
    (task) => task.scope === scope,
  );

  const date = parseISO(
    syncScope?.syncTime || patient?.patient?.lastEhrSyncDate || '',
  );

  return isValid(date) ? date : null;
}

export function formatSyncTime(syncTime: string) {
  return format(parseISO(syncTime), 'MM/dd/yyyy h:mm a');
}

export function isValidSyncTime(syncTime: string) {
  return isValid(parseISO(syncTime));
}
