import { usePatientMedications } from '@/pages/patients/PatientMedications/patientMedications.queries';
import type { AsyncTitration } from '@/shared/generated/grpc/go/pms/pkg/patient/medication/medication.pb';

type AssociatedTitrationUpdates = {
  isLoading: boolean;
  titrationUpdates: AsyncTitration[];
};

export function useNoteAsyncTitrationUpdates(
  noteId: Maybe<number>,
  patientId?: string,
): AssociatedTitrationUpdates {
  const { data, isLoading } = usePatientMedications(patientId ?? '', {
    enabled: Boolean(patientId && noteId),
  });

  if (!noteId) {
    return {
      isLoading: false,
      titrationUpdates: [],
    };
  }

  const titrationUpdates = (data?.referencedMedications ?? [])
    .flatMap((meds) => meds.medChanges)
    .map((medChange) => medChange?.asyncTitration)
    .filter((titration): titration is AsyncTitration =>
      [
        titration?.initialReviewNoteId,
        titration?.consentRequestNoteId,
      ].includes(noteId),
    );

  return {
    isLoading,
    titrationUpdates,
  };
}
