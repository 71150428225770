import { FormattedMessage } from 'react-intl';

import { CommonInfoField, PLACEHOLDER } from '@/shared/common/CommonInfoField';
import type { Hospital } from '@/shared/generated/grpc/go/pms/pkg/hospital/hospital.pb';

import { formatList } from '../utils/formatting.utils';

const MAX_DISPLAYABLE_HOSPITALS = 3;

type Props = {
  hospitals?: Hospital[];
};

export function LocationsInfoField({ hospitals }: Props) {
  const hospitalNames = (hospitals || []).map(
    (hospital) => hospital.name || '',
  );

  return (
    <CommonInfoField
      label={<FormattedMessage defaultMessage="Loc" />}
      text={
        hospitalNames?.length
          ? formatList(hospitalNames, MAX_DISPLAYABLE_HOSPITALS)
          : PLACEHOLDER
      }
      toolTipMsg={
        hospitalNames?.length > MAX_DISPLAYABLE_HOSPITALS
          ? hospitalNames?.join(', ')
          : undefined
      }
    />
  );
}
