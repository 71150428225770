import { useMutation, useQuery } from 'react-query';

import { grpcQueryFunction } from '@/reactQuery';
import { CallService } from '@/shared/generated/grpc/go/pms/pkg/call/call.pb';
import type { ListConferencesResponse } from '@/shared/generated/grpc/go/pms/pkg/call/call.pb';
import { idToGrpcName } from '@/shared/utils/grpc';

import {
  CONFERENCE_QUERY_PARAMS_FILTER_LOOKUP,
  buildConferenceRPCRequest,
} from './conference-grpc';

const POLLING_INTERVAL = 10000;

export const CONFERENCE_QUERY_KEY_BASE = ['rpm', 'v1', 'conferences'] as const;

export type ConferenceParams = {
  patientId?: string;
  noteInfo?: {
    noteId?: number;
  };
  careProviderInfo?: {
    careProviderId?: string;
  };
};

export const CONFERENCE_QUERY_KEYS = {
  list: (params?: ConferenceParams) =>
    [...CONFERENCE_QUERY_KEY_BASE, params, 'infinite'] as const,
};

export function useListConferences(
  params?: ConferenceParams,
  enabled: boolean = true,
  refetch: boolean = true,
) {
  return useQuery(
    CONFERENCE_QUERY_KEYS.list(params),
    (ctx) =>
      grpcQueryFunction<ListConferencesResponse>(
        ctx,
        CallService.ListConferences,
        buildConferenceRPCRequest(ctx, CONFERENCE_QUERY_PARAMS_FILTER_LOOKUP),
      ),
    {
      enabled,
      refetchInterval: refetch ? POLLING_INTERVAL : false,
    },
  );
}

export function useRemoveConferenceNote(
  noteId: number,
  conferenceSid: string,
  onSuccess: () => void,
) {
  return useMutation(
    () =>
      CallService.RemoveConferenceNote({
        name: idToGrpcName('conference', conferenceSid),
        noteId,
      }),
    {
      onSuccess: () => {
        onSuccess();
      },
    },
  );
}

export function useAddConferenceNote(
  noteId: number,
  careProviderId: string,
  onSuccess: () => void,
) {
  return useMutation(
    (conferenceSid: string) =>
      CallService.AddConferenceNote({
        name: idToGrpcName('conference', conferenceSid),
        noteId,
        careProviderId,
      }),
    {
      onSuccess: () => {
        onSuccess();
      },
    },
  );
}
