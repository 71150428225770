import { parseISO } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { FormattedMessage } from 'react-intl';

import type { CCMGoal } from '@/shared/generated/grpc/go/pms/pkg/ccm_goal/ccm_goal.pb';
import { getUserTimezone } from '@/shared/utils/time-helpers';

import { container } from './GoalTimestampsInfo.css';

type Props = {
  goal: CCMGoal;
};

export function GoalTimestampsInfo({ goal }: Props) {
  const userTz = getUserTimezone();
  return (
    <div className={container}>
      {goal.createTime && (
        <div>
          <FormattedMessage
            defaultMessage="Goal created: {createdAt}"
            values={{
              createdAt: formatInTimeZone(
                parseISO(goal.createTime),
                userTz,
                'MMM do, yyyy',
              ),
            }}
          />
        </div>
      )}
      {goal.updateTime && (
        <div>
          <FormattedMessage
            defaultMessage="Last update: {updatedAt}"
            values={{
              updatedAt: formatInTimeZone(
                parseISO(goal.updateTime),
                userTz,
                'MMM do, yyyy',
              ),
            }}
          />
        </div>
      )}
    </div>
  );
}
