/* eslint-disable */
// @ts-nocheck
/*
* This file is a generated Typescript file for GRPC Gateway, DO NOT MODIFY
*/

import * as GoogleProtobufField_mask from "../../../../google/protobuf/field_mask.pb"
import * as GoogleProtobufTimestamp from "../../../../google/protobuf/timestamp.pb"
import * as fm from "@/shared/generated/grpc/fetch.pb"

export enum ProgramType {
  PROGRAM_TYPE_UNSPECIFIED = "PROGRAM_TYPE_UNSPECIFIED",
  PROGRAM_TYPE_RPM = "PROGRAM_TYPE_RPM",
  PROGRAM_TYPE_CCM = "PROGRAM_TYPE_CCM",
  PROGRAM_TYPE_APCM = "PROGRAM_TYPE_APCM",
}

export type UpdatePatientProgramRequest = {
  patientProgram?: PatientProgram
  updateMask?: GoogleProtobufField_mask.FieldMask
}

export type PatientProgram = {
  patientId?: string
  programType?: ProgramType
}

export type GetPatientSMSHistoryRequest = {
  patientId?: string
}

export type GetPatientSMSHistoryResponse = {
  messageHistories?: MessageHistory[]
}

export type MessageHistory = {
  caregiverId?: string
  caregiverName?: string
  hasActiveTask?: boolean
  messages?: Message[]
  patientId?: string
  phoneNumber?: string
}

export type Message = {
  body?: string
  dateCreated?: GoogleProtobufTimestamp.Timestamp
  dateSent?: GoogleProtobufTimestamp.Timestamp
  direction?: string
  errorMessage?: string
  from?: string
  sid?: string
  status?: string
  to?: string
}

export class PatientService {
  static UpdatePatientProgram(req: UpdatePatientProgramRequest, initReq?: fm.InitReq): Promise<PatientProgram> {
    return fm.fetchReq<UpdatePatientProgramRequest, PatientProgram>(`/messaging/v1/patients/${req["patientProgram"]?.["patientId"]}/program`, {...initReq, method: "PATCH", body: JSON.stringify(req["patient_program"], fm.replacer)})
  }
  static GetPatientSMSHistory(req: GetPatientSMSHistoryRequest, initReq?: fm.InitReq): Promise<GetPatientSMSHistoryResponse> {
    return fm.fetchReq<GetPatientSMSHistoryRequest, GetPatientSMSHistoryResponse>(`/messaging/v1/patients/${req["patientId"]}/sms-history?${fm.renderURLSearchParams(req, ["patientId"])}`, {...initReq, method: "GET"})
  }
}