import { FormattedMessage, useIntl } from 'react-intl';

import {
  FieldGroup,
  Script,
} from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared';
import { useTimeEstimate } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared/useTimeEstimate';
import { Wizard } from '@/shared/common/Wizard';
import { useWizardFormFromConfig } from '@/shared/common/Wizard/state';

import { INIT_VISIT_SECTIONS } from '../../metadata';
import { TobaccoUseForm } from '../../shared/generalAssessment';
import type { TobaccoUseFormFields } from '../../shared/generalAssessment/formConfig';
import { getTobaccoUseFormConfig } from '../../shared/generalAssessment/formConfig';
import { initVisitPath } from '../paths';

export function TobaccoUse() {
  const intl = useIntl();
  const form = useWizardFormFromConfig<TobaccoUseFormFields>(
    initVisitPath('/general-assessment', '/tobacco-use'),
    getTobaccoUseFormConfig(intl),
  );

  return (
    <Wizard.Step
      sections={INIT_VISIT_SECTIONS}
      form={form}
      title={intl.formatMessage({ defaultMessage: 'Tobacco Use' })}
      subtitle={useTimeEstimate(
        intl.formatMessage({ defaultMessage: '1-3 min' }),
      )}
    >
      <FieldGroup>
        <Script>
          <FormattedMessage defaultMessage="Now that we have your devices and action plan squared away, I want to collect some background information that will help us design your Cadence journey." />
        </Script>
        <TobaccoUseForm form={form} />
      </FieldGroup>
    </Wizard.Step>
  );
}
