import type { NextAppointmentRecommendation } from '@/shared/generated/grpc/go/pms/pkg/scheduling/scheduling.pb';
import { PatientStatus } from '@/shared/types/patient.types';

import { getCurrentEnvAcuityApptTypeIds } from './acuityIds.utils';
import type { AcuityIframeUrlParams } from './types';
import { ApptsFilter } from './types';

export function getAppointmentsFilterParam(
  apptsFilter: ApptsFilter,
  recommendedAppt: Maybe<NextAppointmentRecommendation>,
  patientStatus: PatientStatus,
): Exclude<AcuityIframeUrlParams['appointmentType[]'], undefined> {
  if (
    apptsFilter === ApptsFilter.SHOW_SUGGESTED &&
    recommendedAppt?.appointmentTypeAcuityId
  ) {
    return [`${recommendedAppt.appointmentTypeAcuityId}`];
  }

  if (patientStatus !== PatientStatus.Enrolled) {
    const apptTypeIds = getCurrentEnvAcuityApptTypeIds();
    return [
      `category:Enrollment`,
      `category:Onboarding`,
      `category:Clinical Visits`,
      apptTypeIds.CCM_CARE_PLAN_VISIT,
      apptTypeIds.CCM_CARE_PLAN_INTERPRETER_VISIT,
      apptTypeIds.APCM_CARE_PLAN_VISIT,
      apptTypeIds.APCM_CARE_PLAN_INTERPRETER_VISIT,
    ];
  }

  // Show all appointments
  return [];
}
