import { FormattedMessage, useIntl } from 'react-intl';

import { Form } from '@/shared/common/Form';
import type {
  AppointmentAvailability,
  CheckSameSlotAppointmentAvailabilityResponse,
} from '@/shared/generated/grpc/go/pms/pkg/scheduling/scheduling.pb';
import { Button } from '@/shared/tempo/atom/Button';

import type { ConfiguredForm } from '../Form/FormContainer';
import { BoxContainer } from './SmartScheduler/BoxContainer';
import { ErrorDisplay } from './SmartScheduler/ErrorDisplay';
import type { SameSlotReschedulingFormFields } from './types';

type Props = {
  form: ConfiguredForm<SameSlotReschedulingFormFields>;
  sameSlotAvailabilities: Maybe<CheckSameSlotAppointmentAvailabilityResponse>;
  formatInPatientTimezone: (
    date: Maybe<string | Date>,
    fmt: string,
  ) => Nullable<string>;
  onSelect: (values: SameSlotReschedulingFormFields) => void;
};

export function SameSlotRescheduler({
  form,
  sameSlotAvailabilities,
  formatInPatientTimezone,
  onSelect,
}: Props) {
  const intl = useIntl();

  return (
    <>
      {!sameSlotAvailabilities?.availabilities?.length ? (
        <ErrorDisplay>
          <FormattedMessage defaultMessage="No other available clinicians found for this time slot" />
        </ErrorDisplay>
      ) : (
        <BoxContainer>
          <Form form={form} onSubmit={onSelect}>
            <Form.Select
              required
              label={intl.formatMessage({
                defaultMessage: 'Calendar to reschedule with',
              })}
              name="selectedAvailability"
              size={8}
            >
              {sameSlotAvailabilities.availabilities.map((availability) => (
                <Form.Select.Option key={createKey(availability)}>
                  {generateLabel(availability, formatInPatientTimezone)}
                </Form.Select.Option>
              ))}
            </Form.Select>
            <Button
              type="submit"
              variant="secondary"
              size="small"
              isDisabled={!form.canSubmit}
            >
              <FormattedMessage defaultMessage="Select" />
            </Button>
          </Form>
        </BoxContainer>
      )}
    </>
  );
}

function createKey(availability: AppointmentAvailability) {
  if (!availability?.acuityCalendarId || !availability?.datetime) return null;
  return `${availability?.acuityCalendarId}+${availability?.datetime}`;
}

function generateLabel(
  availability: AppointmentAvailability,
  formatInPatientTimezone: (
    date: Maybe<string | Date>,
    fmt: string,
  ) => Nullable<string>,
) {
  if (!availability?.careProvider) return null;
  return `${availability?.careProvider?.careProviderFirstName} ${availability
    ?.careProvider?.careProviderLastName}, ${availability?.careProvider
    ?.careProviderRole} - ${formatInPatientTimezone(
    availability?.datetime,
    'h:mm a',
  )}`;
}
