import { useState } from 'react';

import { SidePanelPatientProvider } from '@/pages/patients/PatientProfile/SidePanelPatientContext';
import type { PatientDetails } from '@/shared/types/patient.types';

import { PatientAlertsDashboardPage } from './PatientAlertsDashboardPage';

export function PatientListDashboard() {
  const [patientDetails, setPatientDetails] = useState<PatientDetails>(
    {} as PatientDetails,
  );
  const [patientId, setPatientId] = useState('');

  return (
    <SidePanelPatientProvider
      value={{
        patientId,
        patientDetails,
        setPatientDetails: (patient) => {
          setPatientId(patient.id);
          // TODO: Get rid of this once all usages of patientDetails use only patientId
          setPatientDetails(patient);
        },
      }}
    >
      <PatientAlertsDashboardPage />
    </SidePanelPatientProvider>
  );
}
