import sortBy from 'lodash/sortBy';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { PatientTitrationRejectionReason } from '@/components/AsyncTitration/PatientTitrationRejectionReason';
import { Form } from '@/shared/common/Form';
import {
  ReasonForNotTitrating,
  type TReasonForNotTitrating,
  useClinicalGoalsOptions,
} from '@/shared/titrationRejectionReasons';

import type { ClinicalGoalReachedFormFields } from '../../../../../Notes.types';

type Props = {
  excludedKeys?: TReasonForNotTitrating[];
};

export function NonTitrationReason({ excludedKeys = [] }: Props) {
  const intl = useIntl();
  const form = useFormContext<ClinicalGoalReachedFormFields>();
  const value = form.watch('notTitratingReason');
  const items = sortBy([...useClinicalGoalsOptions()], 'label').filter(
    ({ key }) => !excludedKeys.includes(key),
  );

  return (
    <>
      <Form.Select
        size={12}
        name="notTitratingReason"
        label={intl.formatMessage({
          defaultMessage: 'Select a reason why medications were not adjusted',
        })}
        items={items}
        required
      >
        {({ key, label }) => (
          <Form.Select.Option key={key}>{label}</Form.Select.Option>
        )}
      </Form.Select>
      {value === ReasonForNotTitrating.PATIENT_REJECTED && (
        <PatientTitrationRejectionReason required />
      )}
    </>
  );
}
