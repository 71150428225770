import cx from 'classnames';
import { format, parseISO } from 'date-fns';
import { useIntl } from 'react-intl';

import ChevronDown from '@/shared/assets/svgs/chevron.svg?react';
import { AdditionalActions } from '@/shared/common/AdditionalActions';
import type { NoShowAttemptNote } from '@/shared/generated/grpc/go/pms/pkg/scheduling/scheduling.pb';
import { useFlags } from '@/shared/hooks';
import { Menu } from '@/shared/tempo/@labs/molecule/Menu';

import {
  apptNoShowDetails,
  attemptsButton,
  noShowAttemptRow,
  noShowAttemptStatusFailed,
  noShowAttemptStatusSucceeded,
  noShowSuccessTag,
  noShowTag,
} from './NoShowDetails.css';

type NoShowDetailsProps = {
  noShowAttemptNotes: NoShowAttemptNote[];
};

export function NoShowDetails({ noShowAttemptNotes }: NoShowDetailsProps) {
  const intl = useIntl();
  const { hideNoShowAttempt } = useFlags();

  const isResolved = !!noShowAttemptNotes.find(
    (nsAttempt) => !nsAttempt.patientNoShow,
  );

  return (
    <div className={apptNoShowDetails}>
      {isResolved ? (
        <div className={noShowSuccessTag}>
          {intl.formatMessage({ defaultMessage: 'No Show resolved' })}
        </div>
      ) : (
        <div className={noShowTag}>
          {intl.formatMessage({ defaultMessage: 'No Show' })}
        </div>
      )}
      {!hideNoShowAttempt && (
        <>
          <div className={attemptsButton}>
            {intl.formatMessage(
              { defaultMessage: '{numAttempts} attempts' },
              { numAttempts: noShowAttemptNotes.length },
            )}
          </div>
          {noShowAttemptNotes.length > 0 && (
            <AdditionalActions
              onAction={() => {
                // TODO: Open the corresponding note
              }}
              placement="bottom start"
              Icon={ChevronDown}
            >
              <Menu items={noShowAttemptNotes}>
                {(item: NoShowAttemptNote) => (
                  <Menu.Item key={item.noteId}>
                    <div className={noShowAttemptRow}>
                      <div
                        className={cx({
                          [noShowAttemptStatusSucceeded]: !item.patientNoShow,
                          [noShowAttemptStatusFailed]: item.patientNoShow,
                        })}
                      />
                      {format(
                        parseISO(item.updatedAt ?? ''),
                        "MM/dd/yyyy 'at' hh:mm a",
                      )}
                    </div>
                  </Menu.Item>
                )}
              </Menu>
            </AdditionalActions>
          )}
        </>
      )}
    </div>
  );
}
