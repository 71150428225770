import {
  type CCMGoal,
  type CCMGoalTask,
} from '@/shared/generated/grpc/go/pms/pkg/ccm_goal/ccm_goal.pb';

export enum GoalMeasure {
  Medications = 'medications',
  EDVisitOrHospitalization = 'ed_visit_or_hospitalization',
  CareCoordination = 'care_coordination',
  ChronicConditions = 'chronic_conditions',
  Tobacco = 'tobacco',
  Diet = 'diet',
  Exercise = 'exercise',
  Pneumovax = 'pneumovax',
  Influenza = 'influenza',
  Tdap = 'tdap',
  Shingrix = 'shingrix',
  Colonoscopy = 'colonoscopy',
  BreastCancerScreening = 'breast_cancer_screening',
  DiabeticEyeExam = 'diabetic_eye_exam',
  LivingSituation = 'living_situation',
  Food = 'food',
  Transportation = 'transportation',
  Utilities = 'utilities',
  Safety = 'safety',
  PhysicalActivity = 'physical_activity',
  Vitals = 'vitals',
}

export { GoalStatus } from '@/shared/generated/grpc/go/pms/pkg/ccm_goal/ccm_goal.pb';

export type Goal = CCMGoal;
export type GoalTask = CCMGoalTask;
