import { useMemo } from 'react';

import { getTitrationRecommendation } from '@/components/AsyncTitration/hooks';
import {
  AsyncTitrationAsyncTitrationStatus,
  type Medication,
  type NewMedSuggestion,
} from '@/shared/generated/grpc/go/pms/pkg/patient/medication/medication.pb';

export function usePendingTitrationList(
  meds?: (Medication | NewMedSuggestion)[],
) {
  return useMemo(
    () =>
      (meds ?? []).flatMap((med) => {
        // i.e. is a new med suggestion
        if (!isMedication(med)) {
          const rec = getTitrationRecommendation(
            { asyncTitration: med.asyncTitrations?.[0] },
            undefined,
            true,
            false,
          );

          if (
            rec.status !== AsyncTitrationAsyncTitrationStatus.INITIALLY_REVIEWED
          ) {
            return [];
          }

          return [rec];
        }

        return med.medChanges
          ?.map((medChange) =>
            getTitrationRecommendation(medChange, undefined, true, false),
          )
          .filter(
            (asyncTitration) =>
              asyncTitration.status ===
              AsyncTitrationAsyncTitrationStatus.INITIALLY_REVIEWED,
          );
      }),
    [meds],
  );
}

function isMedication(med: Medication | NewMedSuggestion): med is Medication {
  return 'medChanges' in med;
}
