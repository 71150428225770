import { logger } from '@/logger';
import type { MedManagementDelegation as GrpcMedManagementDelegation } from '@/shared/generated/grpc/cadence/models/models.pb';
import type { PatientDetails } from '@/shared/generated/grpc/go/pms/pkg/patient/pms.pb';
import { MedManagementDelegation } from '@/shared/types/shared.types';
import { grpcNameToId } from '@/shared/utils/grpc';

import { usePatientReferringPhysician } from './providers.queries';

export const MedManagementDelegationUiMapping = {
  // UI-Only Med Management Delegations
  OptInFullyManagedAndNotify: 'OPT_IN_FULLY_MANAGED_AND_NOTIFY',
  OptInCurrentMedsAndNotify: 'OPT_IN_CURRENT_MEDS_AND_NOTIFY',
  NoReferringPhysician: 'NO_REFERRING_PHYSICIAN',
  ProviderMedMgmtMissing: 'PROVIDER_MED_MANAGEMENT_DELEGATION_MISSING',
  // MedManagementDelegation enum delegations
  ToBeDetermined: `${MedManagementDelegation.ToBeDetermined}`,
  OptInFullyManaged: `${MedManagementDelegation.OptInFullyManaged}`,
  OptInCurrentMeds: `${MedManagementDelegation.OptInCurrentMeds}`,
  OptOutRecommendMeds: `${MedManagementDelegation.OptOutRecommendsMeds}`,
  OptOutProviderManaged: `${MedManagementDelegation.OptOutProviderManaged}`,
} as const;

export type MedManagementDelegationUiMappingValue =
  (typeof MedManagementDelegationUiMapping)[keyof typeof MedManagementDelegationUiMapping];

export function useMedManagementDelegation(
  patient: PatientDetails | undefined,
): {
  isLoading: boolean;
  medDelegation: MedManagementDelegationUiMappingValue;
  shouldNotifyProvider?: boolean;
} {
  const patientId = patient?.name ? grpcNameToId(patient.name) : '';
  const { referringPhysician, isLoading } = usePatientReferringPhysician(
    patientId || '',
    !!patientId,
  );

  if (!patient) {
    return {
      isLoading,
      medDelegation: MedManagementDelegationUiMapping.ToBeDetermined,
    };
  }

  if (!referringPhysician) {
    return {
      isLoading,
      medDelegation: MedManagementDelegationUiMapping.NoReferringPhysician,
    };
  }
  const { preferences: providerPreferences } = referringPhysician;
  const shouldNotifyProviderOfMedChanges =
    !!referringPhysician?.preferences?.notify_on_medication_change;

  const providerMedManagementDelegation =
    providerPreferences?.med_management_delegation ||
    MedManagementDelegationUiMapping.ProviderMedMgmtMissing;

  const medMgmtDelegationPreference =
    providerPreferences?.allow_patient_level_med_management_delegation
      ? patient.patient?.medManagementDelegation
      : providerMedManagementDelegation;

  const normalizedMedMgmtDelegationPref = normalizedMedManagementDelegation(
    medMgmtDelegationPreference
      ? (`${medMgmtDelegationPreference}` as const)
      : MedManagementDelegationUiMapping.ToBeDetermined,
  );

  const { shouldNotifyProvider, medDelegation } =
    getFriendlyMedManagementDelegation(
      shouldNotifyProviderOfMedChanges,
      normalizedMedMgmtDelegationPref,
    );
  return {
    isLoading,
    medDelegation,
    shouldNotifyProvider,
  };
}

const getFriendlyMedManagementDelegation = (
  shouldNotifyProvider: boolean,
  medDelegation: MedManagementDelegationUiMappingValue,
): {
  shouldNotifyProvider: boolean;
  medDelegation: MedManagementDelegationUiMappingValue;
} => {
  switch (medDelegation) {
    case MedManagementDelegationUiMapping.OptInFullyManaged:
      return {
        shouldNotifyProvider,
        medDelegation: shouldNotifyProvider
          ? MedManagementDelegationUiMapping.OptInFullyManagedAndNotify
          : MedManagementDelegationUiMapping.OptInFullyManaged,
      };
    case MedManagementDelegationUiMapping.OptInCurrentMeds:
      return {
        shouldNotifyProvider,
        medDelegation: shouldNotifyProvider
          ? MedManagementDelegationUiMapping.OptInCurrentMedsAndNotify
          : MedManagementDelegationUiMapping.OptInCurrentMeds,
      };
    default:
      return {
        shouldNotifyProvider: false,
        medDelegation,
      };
  }
};

/**
 * Takes in a delegation of either the python MedManagementDelegation enum,
 * or the gRPC GrpcMedManagementDelegation enum
 * and normalizes it to a MedManagementDelegationUiMapping
 */
export function normalizedMedManagementDelegation(
  delegation:
    | `${GrpcMedManagementDelegation}`
    | `${MedManagementDelegation}`
    | 'PROVIDER_MED_MANAGEMENT_DELEGATION_MISSING',
): MedManagementDelegationUiMappingValue {
  if (
    delegation === 'MED_MANAGEMENT_DELEGATION_UNSPECIFIED' ||
    delegation === 'MED_MANAGEMENT_DELEGATION_OTHER'
  ) {
    return MedManagementDelegationUiMapping.ToBeDetermined;
  }

  if (Object.values(MedManagementDelegationUiMapping).includes(delegation)) {
    return delegation;
  }

  logger.error(
    `Failed to normalize unknown med management delegation option: '${delegation}'`,
  );

  return MedManagementDelegationUiMapping.ToBeDetermined;
}
