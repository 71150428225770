import { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQueryClient } from 'react-query';

import {
  appointmentKeys,
  useRescheduleAppointment,
} from '@/pages/patients/PatientProfile/PatientScheduling/appointments.queries';
import { Modal } from '@/shared/common/Modal';
import {
  type AppointmentDetails,
  RescheduleAppointmentRequestType,
} from '@/shared/generated/grpc/go/pms/pkg/scheduling/scheduling.pb';
import { Button } from '@/shared/tempo/atom/Button';
import { useToaster } from '@/shared/tempo/molecule/Toast';
import { type Patient } from '@/shared/types/patient.types';
import { getErrorMsg } from '@/shared/utils/helpers';

import { AppointmentConfirmation } from './AppointmentConfirmation';
import { AppointmentRescheduler } from './AppointmentRescheduler';
import { footer } from './ReschedulePatientModal.css';
import { NoTimezoneView } from './SmartScheduler/NoTimezoneView';
import { SchedulingInfo } from './SmartScheduler/SchedulingInfo';
import { container, divider } from './SmartScheduler/SmartScheduler.css';
import { type RescheduleData, RescheduleMode } from './types';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  patient: Patient;
  appointment: AppointmentDetails;
};

export function ReschedulePatientModal({
  isOpen,
  onClose,
  patient,
  appointment,
}: Props) {
  const intl = useIntl();
  const { toaster } = useToaster();
  const queryClient = useQueryClient();
  const [rescheduleData, setRescheduleData] = useState<RescheduleData | null>(
    null,
  );

  const patientId = patient.id || '';
  const timezone = patient.timezone || '';

  const resetModalState = () => {
    setRescheduleData(null);
    queryClient.invalidateQueries(appointmentKeys.nextScheduled(patientId));
  };

  const {
    mutate: rescheduleAppointment,
    isLoading: isReschedulingAppointment,
  } = useRescheduleAppointment(appointment.name ?? '', {
    onSuccess: () => {
      toaster.success(
        intl.formatMessage({
          defaultMessage: 'Appointment successfully rescheduled.',
        }),
      );
      onClose();
      resetModalState();
    },
    onError: (err) => {
      toaster.error(
        getErrorMsg(err) ||
          intl.formatMessage({
            defaultMessage: 'An error occurred while rescheduling.',
          }),
      );
    },
  });

  const handleReschedulePress = useCallback(() => {
    if (!rescheduleData?.slot) return;
    rescheduleAppointment({
      type:
        rescheduleData.type === RescheduleMode.SAME_SLOT
          ? RescheduleAppointmentRequestType.SAME_SLOT_MANUAL
          : RescheduleAppointmentRequestType.MANUAL,
      acuityAppointmentId: appointment.appointmentAcuityId,
      acuityCalendarId: rescheduleData.slot.acuityCalendarId,
      apptDate: rescheduleData.slot.datetime,
      careProviderId: rescheduleData.currentUser,
    });
  }, [appointment, rescheduleData, rescheduleAppointment]);

  const scheduleInfo = (
    <SchedulingInfo patient={patient} currentAppt={appointment} />
  );
  const modalContent = !timezone ? (
    <NoTimezoneView patientId={patient.id} onNavigate={() => onClose?.()}>
      {scheduleInfo}
    </NoTimezoneView>
  ) : (
    <div className={container}>
      {scheduleInfo}
      <div className={divider} />
      {rescheduleData?.slot ? (
        <AppointmentConfirmation
          currentAppointment={appointment}
          newApptSlot={rescheduleData?.slot}
          patientTimezone={timezone}
        />
      ) : (
        <AppointmentRescheduler
          patient={patient}
          currentAppointment={appointment}
          onSelect={(data) => setRescheduleData(data)}
        />
      )}
    </div>
  );

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        onClose();
        resetModalState();
      }}
      size="large"
    >
      <Modal.Header
        title={<FormattedMessage defaultMessage="Reschedule Patient" />}
      />
      <Modal.Body>{modalContent}</Modal.Body>
      <Modal.Footer className={footer}>
        {rescheduleData?.slot && (
          <>
            <Button variant="secondary" onPress={() => setRescheduleData(null)}>
              <FormattedMessage defaultMessage="Back" />
            </Button>
            <Button
              variant="primary"
              type="submit"
              isProcessing={isReschedulingAppointment}
              onPress={handleReschedulePress}
            >
              <FormattedMessage defaultMessage="Reschedule" />
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
}
