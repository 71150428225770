import type {
  ReferenceMedicationMedicationClass,
  RxNorm,
} from '@/shared/generated/grpc/go/pms/pkg/patient/medication/medication.pb';

export enum RxNormRestriction {
  CURRENT_MED = 'current_med', // Used for Review and Titration of Disease Specific med
  NON_EXISTING_MEDS = 'non_existing_meds', // Used for Starting a med
  NON_EXISTING_MEDS_FREE_TEXT = 'non_existing_meds_free_text', // Used for Adding or Structuring a non-disease-specific med
  NON_EXISTING_MEDS_AND_CURRENT_FREE_TEXT = 'non_existing_meds_and_current_free_text', // Used for Editing a Review of non-specific med
}

export type RxNormOptionType = {
  medClasses?: ReferenceMedicationMedicationClass[] | undefined;
  norm?: RxNorm;
  disabled?: boolean;
  inputValue?: string;
  title?: string;
};
