import { useHistory } from 'react-router-dom';

import { useWizardStateContext } from '@/shared/common/Wizard/state';
import { ConditionProgram } from '@/shared/types/condition.types';

import { useCNFormContext } from '../../../CNFormContext';
import { SharedVitalRecommendation } from '../../shared/VitalProgress/VitalRecommendation';
import { initVisitPath } from '../paths';

export function VitalRecommendation() {
  const history = useHistory();
  const { program } = useCNFormContext();
  const { skipSectionStep } = useWizardStateContext();

  return (
    <SharedVitalRecommendation
      onNext={() => {
        const scriptPath = initVisitPath('/action-plan', '/script');

        if (program === ConditionProgram.CHF) {
          skipSectionStep(scriptPath);
          skipSectionStep(initVisitPath('/action-plan', '/set-goal'));
          history.push(initVisitPath('/general-assessment', '/tobacco-use'));
        } else {
          history.push(scriptPath);
        }
      }}
    />
  );
}
