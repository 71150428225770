import { ReasonForNotTitrating } from './types';
import { useFilteredOptions } from './useTitrationOptions';

const OPTIONS_TO_OMIT = [
  ReasonForNotTitrating.INSUFFICIENT_READINGS,
  ReasonForNotTitrating.MAX_TOLERATED_DOSE_REACHED,
  ReasonForNotTitrating.NEW_RX_REQUIRED_FOR_CURRENT_MEDS_MGMT_PATIENT,
  ReasonForNotTitrating.NON_COMPLIANT,
  ReasonForNotTitrating.PATIENT_REJECTED,
  ReasonForNotTitrating.RECOMMEND_ONLY_PATIENT,
];

export function useAsyncTitrationOptions() {
  return useFilteredOptions(OPTIONS_TO_OMIT);
}
