import jwt_decode from 'jwt-decode';
import { useSelector } from 'react-redux';

import { logger } from '@/logger';
import type {
  CognitoAccessToken,
  CognitoIdToken,
} from '@/shared/types/cognito.types';
import type { RootState } from '@/store/redux.types';

export function useCurrentUser() {
  const idToken = useSelector(
    (state: RootState) => state.auth?.token?.id_token,
  );
  const accessToken = useSelector(
    (state: RootState) => state.auth?.token?.access_token,
  );

  try {
    const currentUser = jwt_decode<CognitoIdToken>(idToken);
    const currentUserFullName = `${currentUser.given_name} ${currentUser.family_name}`;
    const currentUserId = jwt_decode<CognitoAccessToken>(accessToken).sub;
    return { currentUser, currentUserFullName, currentUserId };
  } catch {
    logger.warn('Failed to retrieve current authed user information.');
    return {
      currentUser: {} as CognitoIdToken,
      currentUserFullName: '',
      currentUserId: '',
    };
  }
}
