import { useIntl } from 'react-intl';

import { Form } from '@/shared/common/Form';

import { sectionTitle } from './carePlanSections.css';

export function CulturalFactors() {
  const intl = useIntl();

  return (
    <Form.Section
      title={intl.formatMessage({ defaultMessage: 'Cultural Factors' })}
      classes={{ title: sectionTitle }}
    >
      <Form.Row>
        <Form.TextField
          size={11}
          name="culturalPreferences"
          label={intl.formatMessage({
            defaultMessage:
              'Do you have any religious, spiritual, or cultural preferences relevant to your healthcare?',
          })}
        />
      </Form.Row>
      <Form.Row>
        <Form.TextField
          size={11}
          name="culturalCommunityResources"
          label={intl.formatMessage({
            defaultMessage:
              'Do you currently rely on any cultural or faith-based community resources?',
          })}
        />
      </Form.Row>
    </Form.Section>
  );
}
