import type { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  getMedDisplayName,
  getProactiveTitrationDoseStrings,
} from '@/pages/patients/PatientMedications/utils/medInfoUtils';
import { formatProviderForScript } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared/script.utils';
import type {
  MedicationChange,
  ReferenceMedication,
} from '@/shared/generated/grpc/go/pms/pkg/patient/medication/medication.pb';
import { usePatientContext } from '@/shared/hooks/queries';

import { type TitrationRecommendation, useIsNewMed } from '../../hooks';
import { container, highlight, section } from './medicationDetails.css';

type Props = {
  forCnEncounter: boolean;
  referenceMed: ReferenceMedication;
  medChange: MedicationChange;
  recommendation: TitrationRecommendation;
};

export function MedicationDetails({
  forCnEncounter,
  referenceMed,
  ...rest
}: Props) {
  if (forCnEncounter) {
    return <CnMedicationDetails referenceMed={referenceMed} {...rest} />;
  }

  const { mechanismOfAction, requiredLabs, sideEffects } = referenceMed;

  if (![mechanismOfAction, requiredLabs, sideEffects].some(Boolean)) {
    return null;
  }

  return (
    <div className={container}>
      {mechanismOfAction && (
        <Section
          header={<FormattedMessage defaultMessage="Mechanism of action" />}
        >
          {mechanismOfAction}
        </Section>
      )}
      {requiredLabs && (
        <Section header={<FormattedMessage defaultMessage="Required labs" />}>
          {requiredLabs}
        </Section>
      )}
      {sideEffects && (
        <Section header={<FormattedMessage defaultMessage="Side effects" />}>
          {sideEffects}
        </Section>
      )}
    </div>
  );
}

function CnMedicationDetails({
  referenceMed,
  medChange,
  recommendation,
}: Omit<Props, 'forCnEncounter'>) {
  const intl = useIntl();
  const { requiredLabs, sideEffects } = referenceMed;
  const { current, recommended } = getProactiveTitrationDoseStrings(
    intl,
    medChange,
    recommendation,
  );
  const { data: context } = usePatientContext(recommendation.patientId ?? '', {
    enabled: !!recommendation.patientId,
  });
  const isNew = useIsNewMed(recommendation);
  const providerName = formatProviderForScript(
    context?.primaryPhysicianGivenName,
    context?.primaryPhysicianFamilyName,
    context?.primaryPhysicianRole,
  );
  const medName = (
    <span className={highlight}>
      {getMedDisplayName(intl, medChange.rxnorm)}
    </span>
  );
  const currentDose = <span className={highlight}>{current}</span>;
  const nextDose = <span className={highlight}>{recommended}</span>;
  const provider = <span className={highlight}>{providerName}</span>;

  return (
    <div className={container}>
      <Section
        className={section.copyScript}
        header={
          <FormattedMessage defaultMessage="Explanation for the change" />
        }
      >
        {isNew ? (
          <FormattedMessage
            defaultMessage="After reviewing your recent vital readings, we've decided to start you on {medName} at {nextDose}."
            values={{
              medName,
              nextDose,
            }}
          />
        ) : (
          <FormattedMessage
            defaultMessage="After reviewing your recent vital readings, we've decided to increase your {medName} from {currentDose} to {nextDose}."
            values={{
              medName,
              currentDose,
              nextDose,
            }}
          />
        )}
        {/* leading space is intentional here */}
        <FormattedMessage
          defaultMessage=" We work closely with {provider} on a care plan to help you feel as healthy as possible."
          values={{ provider }}
        />
      </Section>
      {requiredLabs && (
        <Section
          header={<FormattedMessage defaultMessage="Required labs" />}
          className={section.copyScript}
        >
          <FormattedMessage defaultMessage="You will need some lab work for this medication adjustment in a week or so so the NP will order the labs to be done where you normally get lab work completed." />
        </Section>
      )}
      {sideEffects && (
        <Section
          header={<FormattedMessage defaultMessage="Side effects" />}
          className={section.copyScript}
        >
          <FormattedMessage
            defaultMessage="To remind you, this medication has side effects: {sideEffects}."
            values={{ sideEffects }}
          />
        </Section>
      )}
      <Section
        header={<FormattedMessage defaultMessage="What to expect next" />}
        className={section.copyScript}
      >
        <FormattedMessage
          defaultMessage="We'll monitor how you do on this dosage and align with {provider} on next steps. Please don't hesitate to text or call if you have any questions."
          values={{
            provider: <span className={highlight}>{provider}</span>,
          }}
        />
      </Section>
    </div>
  );
}

function Section({
  header,
  children,
  className = section.copy,
}: {
  header: ReactNode;
  children: ReactNode;
  className?: string;
}) {
  return (
    <div>
      <h6 className={section.header}>{header}</h6>
      <p className={className}>{children}</p>
    </div>
  );
}
