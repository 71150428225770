import type { ReactElement } from 'react';
import { useEffect } from 'react';
import type { UseFormReturn } from 'react-hook-form';
import { useController, useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { Form } from '@/shared/common/Form';
import { Tag } from '@/shared/tempo/atom/Tag';

import { GoalMeasure } from '../../../Goals/goals.types';
import { type FormFields, getAssessmentFieldNames } from '../../formConfig';
import { GapWidget } from './GapWidget';
import * as styles from './Safety.css';
import { SafetyChoice, SafetyFrequencyChoice } from './safety.types';

type Props = {
  form: UseFormReturn<FormFields>;
  createGoalWithMeasure: (measure: GoalMeasure, description?: string) => void;
};

export function Safety({ form, createGoalWithMeasure }: Props): ReactElement {
  const intl = useIntl();
  const { control } = useFormContext();
  const safetyChoice = form.watch('safetyChoice') as SafetyChoice | undefined;
  const hasGap = safetyChoice === SafetyChoice.Yes;

  const { description: descriptionFieldName, checked: checkedFieldName } =
    getAssessmentFieldNames('safety');

  const { field } = useController({ name: checkedFieldName, control });

  useEffect(() => {
    field.onChange(hasGap);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasGap]);

  return (
    <>
      <div className={styles.leftColumn}>
        <Form.RadioGroup
          name="safetyChoice"
          label={
            <>
              <Tag variant="default" className={styles.sectionTag}>
                <FormattedMessage defaultMessage="Safety" />
              </Tag>{' '}
              <FormattedMessage defaultMessage="Because we care about the safety of our patients, we'd like to know if you are experiencing any physical or verbal harm. Has anyone in your immediate circle been hurting you, including verbal violence like threats, screaming, or insults?" />
            </>
          }
          orientation="horizontal"
          size={11}
          required
        >
          <Form.Radio value={SafetyChoice.No}>
            <FormattedMessage defaultMessage="No" />
          </Form.Radio>
          <Form.Radio value={SafetyChoice.Yes}>
            <FormattedMessage defaultMessage="Yes" />
          </Form.Radio>
        </Form.RadioGroup>

        {safetyChoice === SafetyChoice.Yes && (
          <div className={styles.detailContainer}>
            <Form.RadioGroup
              name="physicalHarmFrequency"
              label={
                <FormattedMessage defaultMessage="How often does anyone physically hurt you?" />
              }
              orientation="horizontal"
              size={11}
              required
            >
              <Form.Radio value={SafetyFrequencyChoice.Never}>
                <FormattedMessage defaultMessage="Never" />
              </Form.Radio>
              <Form.Radio value={SafetyFrequencyChoice.Sometimes}>
                <FormattedMessage defaultMessage="Sometimes" />
              </Form.Radio>
              <Form.Radio value={SafetyFrequencyChoice.Often}>
                <FormattedMessage defaultMessage="Often" />
              </Form.Radio>
            </Form.RadioGroup>

            <Form.RadioGroup
              name="threatFrequency"
              label={
                <FormattedMessage defaultMessage="How often does anyone threaten you with harm?" />
              }
              orientation="horizontal"
              size={11}
              required
            >
              <Form.Radio value={SafetyFrequencyChoice.Never}>
                <FormattedMessage defaultMessage="Never" />
              </Form.Radio>
              <Form.Radio value={SafetyFrequencyChoice.Sometimes}>
                <FormattedMessage defaultMessage="Sometimes" />
              </Form.Radio>
              <Form.Radio value={SafetyFrequencyChoice.Often}>
                <FormattedMessage defaultMessage="Often" />
              </Form.Radio>
            </Form.RadioGroup>

            <Form.RadioGroup
              name="verbalAbuseFrequency"
              label={
                <FormattedMessage defaultMessage="How often does anyone insult you, talk you down, scream, or curse at you?" />
              }
              orientation="horizontal"
              size={11}
              required
            >
              <Form.Radio value={SafetyFrequencyChoice.Never}>
                <FormattedMessage defaultMessage="Never" />
              </Form.Radio>
              <Form.Radio value={SafetyFrequencyChoice.Sometimes}>
                <FormattedMessage defaultMessage="Sometimes" />
              </Form.Radio>
              <Form.Radio value={SafetyFrequencyChoice.Often}>
                <FormattedMessage defaultMessage="Often" />
              </Form.Radio>
            </Form.RadioGroup>
          </div>
        )}
      </div>

      {hasGap && (
        <div className={styles.rightColumn}>
          <GapWidget
            fieldName={descriptionFieldName}
            placeholder={intl.formatMessage({
              defaultMessage: 'Enter notes about issues with safety',
            })}
            onCreateGoal={createGoalWithMeasure}
            measure={GoalMeasure.Safety}
            form={form}
          />
        </div>
      )}
    </>
  );
}
