import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';

import { grpcGetNextPageParam, grpcQueryFunction } from '@/reactQuery';
import type {
  Instance as GrpcInstance,
  ListInstanceCampaignsResponse,
  ListInstancesResponse,
} from '@/shared/generated/grpc/go/ehr/pkg/ehr.pb';
import { EHRService } from '@/shared/generated/grpc/go/ehr/pkg/ehr.pb';
import type { AipPaginatedPartialData } from '@/shared/types/pagination.types';

type Instance = {
  instanceId: number;
  displayName: string;
};

const getInstances = (healthSystemId: number) => [
  'ehr',
  'v3',
  `instances?filter=healthSystemId=${healthSystemId}`,
];

export function useInstances(healthSystemId?: number) {
  return useQuery<{ instances: Instance[] }>(
    getInstances(healthSystemId ?? 0),
    {
      enabled: Boolean(healthSystemId),
    },
  );
}

const instanceKeys = {
  all: ['ehr', 'v3', 'instances'] as const,
  list: ['ehr', 'v3', 'instances', 'list'] as const,
  detail: (id: string) => [...instanceKeys.all, id],
  campaigns: (id: string) => [...instanceKeys.all, id, 'campaigns'],
};

export function useInstancesGrpc() {
  return useInfiniteQuery<AipPaginatedPartialData<GrpcInstance, 'instances'>>(
    instanceKeys.list,
    (ctx) =>
      grpcQueryFunction<ListInstancesResponse>(ctx, EHRService.ListInstances, {
        pageToken: ctx.pageParam,
      }),
    { getNextPageParam: grpcGetNextPageParam },
  );
}

export function useCreateInstance() {
  const queryClient = useQueryClient();
  return useMutation(EHRService.CreateInstance, {
    onSuccess: () => {
      queryClient.invalidateQueries(instanceKeys.list);
    },
  });
}

export function useUpdateInstance() {
  const queryClient = useQueryClient();
  return useMutation(EHRService.UpdateInstance, {
    onSuccess: (_, req) => {
      const instanceId = req.instance?.name?.split('/')?.[1];
      if (!instanceId) return;
      queryClient.invalidateQueries(instanceKeys.detail(instanceId));
      queryClient.invalidateQueries(instanceKeys.list);
    },
  });
}

export function useInstance(
  instanceId: string,
  options: { enabled?: boolean },
) {
  return useQuery(
    instanceKeys.detail(instanceId),
    (ctx) =>
      grpcQueryFunction<GrpcInstance>(ctx, EHRService.GetInstance, {
        name: `instances/${instanceId}`,
      }),
    options,
  );
}

export function useListInstanceCampaigns(
  instanceId: string,
  options: { enabled?: boolean },
) {
  return useQuery(
    instanceKeys.campaigns(instanceId),
    (ctx) =>
      grpcQueryFunction<ListInstanceCampaignsResponse>(
        ctx,
        EHRService.ListInstanceCampaigns,
        {
          parent: `instances/${instanceId}`,
        },
      ),
    options,
  );
}

export function useEnableInstanceCampaign() {
  const queryClient = useQueryClient();
  return useMutation(EHRService.EnableInstanceCampaign, {
    onSuccess: (_, req) => {
      const instanceId = req.parent?.split('/')?.[1];
      if (!instanceId) return;
      queryClient.invalidateQueries(instanceKeys.campaigns(instanceId));
    },
  });
}

export function useDisableInstanceCampaign() {
  const queryClient = useQueryClient();
  return useMutation(EHRService.DisableInstanceCampaign, {
    onSuccess: (_, req) => {
      const instanceId = req.parent?.split('/')?.[1];
      if (!instanceId) return;
      queryClient.invalidateQueries(instanceKeys.campaigns(instanceId));
    },
  });
}

export function useSendCpoeMessage() {
  return useMutation(EHRService.SendCPOEMessage);
}
