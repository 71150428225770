import {
  assessmentAndPlanPartial,
  ehrMedicationsPartial,
  freeTextSpacePartial,
  fullMedicationsListPartial,
  headerPartial,
  latestLabsPartial,
  medAllergiesPartial,
  medicationsListPartial,
  patientSummaryPartial,
  problemListPartial,
  regularVisitActionPlan,
  subheaderPartial,
  vitalsListPartial,
} from '../files/partials';
import type { TemplateContext, TemplateDelegate } from '../types';
import { engine } from './engine';

type PartialsDeclareSpec = Record<string, TemplateDelegate<string>>;

export function textFieldTemplate(
  template: TemplateSpecification,
): TemplateDelegate<string, TemplateContext> {
  return (context: TemplateContext) => engine.template(template)(context);
}

export const TEMPLATE_PARTIALS: PartialsDeclareSpec = {
  vitals_list: textFieldTemplate(vitalsListPartial),
  latest_labs: textFieldTemplate(latestLabsPartial),
  ehrMedications: textFieldTemplate(ehrMedicationsPartial),
  medications_list: textFieldTemplate(medicationsListPartial),
  problem_list: textFieldTemplate(problemListPartial),
  full_medications_list: textFieldTemplate(fullMedicationsListPartial),
  patient_summary: textFieldTemplate(patientSummaryPartial),
  assessment_and_plan: textFieldTemplate(assessmentAndPlanPartial),
  regular_visit_action_plan: textFieldTemplate(regularVisitActionPlan),
  med_allergies: textFieldTemplate(medAllergiesPartial),
  // Helper partials
  header: textFieldTemplate(headerPartial),
  subheader: textFieldTemplate(subheaderPartial),
  free_text_space: textFieldTemplate(freeTextSpacePartial),
};
