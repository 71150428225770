import { usePatientAutosavedNote } from '@/shared/hooks/queries/autosave-notes.queries';
import type { VitalsAlert } from '@/shared/types/alert.types';

import { useNoteEditorContext } from '../../NoteEditorContext';
import { EditableNoteType } from '../../Notes.types';

export function useOpenNewNoteForAlert(patientId: string) {
  const { editingNote } = useNoteEditorContext();
  const clearNoteEditor = useClearNoteEditor();
  const openNoteEditorForAlert = useOpenNoteEditorForAlert();

  const { data: autosavedNote } = usePatientAutosavedNote(patientId);

  return function openNewNoteForAlert(alert: VitalsAlert) {
    // Do not clear note editor if there is an autosaved note to load
    if (editingNote?.type !== EditableNoteType.Alert && !autosavedNote) {
      clearNoteEditor();
    }
    openNoteEditorForAlert(alert);
  };
}

export function useCloseAndClearNoteEditor() {
  const { closeEditor } = useNoteEditorContext();
  const clearNoteEditor = useClearNoteEditor();

  return function closeAndClearNoteEditor(
    options = { shouldClearNoteContent: true },
  ) {
    closeEditor();
    clearNoteEditor(options);
  };
}

export function useClearNoteEditor() {
  const { setNoteEditorContent, setEncounterModuleInstances, setEditingNote } =
    useNoteEditorContext();

  return function clearNoteEditor(options = { shouldClearNoteContent: true }) {
    setEditingNote(null);

    if (options.shouldClearNoteContent) {
      setNoteEditorContent({
        body: null,
        bodyHtml: null,
        title: null,
        labels: [],
        actionRequired: false,
        externalProviderId: null,
        escalationMessage: null,
        urgent: false,
        zendeskTicket: null,
      });

      // Reset all initial field values to undefined so that if clearNoteEditor
      // is called again, any effects that are dependent on the value changing
      // (e.g. the effect in useNoteField in NoteEditor.tsx) will run
      setTimeout(() => {
        setNoteEditorContent(null);
      });

      setEncounterModuleInstances([]);
    }
  };
}

function useOpenNoteEditorForAlert() {
  const { openEditor, setEditingNote } = useNoteEditorContext();

  return function openNoteEditorForAlert(alert: VitalsAlert) {
    openEditor();
    setEditingNote({
      type: EditableNoteType.Alert,
      alert,
      // note is set once we perform the first autosave
      note: null,
    });
  };
}
