import jwt_decode from 'jwt-decode';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import CalendarIcon from '@/shared/assets/svgs/calendar.svg?react';
import DebugIcon from '@/shared/assets/svgs/curlyBrackets.svg?react';
import SettingsIcon from '@/shared/assets/svgs/settings.svg?react';
import ZendeskIcon from '@/shared/assets/svgs/zendesk.svg?react';
import { SchedulePatientModal } from '@/shared/common/SchedulePatientModal';
import { useFlags } from '@/shared/hooks';
import { usePatientHospitals } from '@/shared/hooks/queries';
import { useShouldAutoOpenScheduler } from '@/shared/hooks/useShouldAutoOpenScheduler';
import { circular } from '@/shared/jsStyle/utils.css';
import { CreateTaskOverlayTrigger } from '@/shared/tasking/CreateTaskOverlay';
import { IconButton } from '@/shared/tempo/atom/IconButton';
import {
  HotkeyTooltip,
  SpecialChar,
} from '@/shared/tempo/molecule/HotkeyTooltip';
import type { CognitoAccessToken } from '@/shared/types/cognito.types';
import type { Patient } from '@/shared/types/patient.types';
import { isAdmin } from '@/shared/utils/userRoles.utils';
import { getPatientZendeskUrl } from '@/shared/utils/zendesk.utils';
import { store } from '@/store';

import { actionsContainer } from './PatientProfileActionButtonGroupContainer.css';
import { GoToChartRpaActionButton } from './RpaWorkflows/GoToChartRpaActionButton';
import { useShouldShowRpaButton } from './RpaWorkflows/useShouldShowRpaButton';
import { useSidePanelPatientCtx } from './SidePanelPatientContext';
import {
  PatientProfileModal,
  usePatientProfileCtx,
} from './context/PatientProfileCtx';

type Props = {
  defaultOpen: boolean;
  onClose: () => void;
};

function PatientProfileSchedulingButton({ defaultOpen, onClose }: Props) {
  const { patientDetails } = useSidePanelPatientCtx();
  const [isDialogOpen, setIsDialogOpen] = useState(defaultOpen);

  return (
    <>
      <IconButton.Tooltip
        content={<FormattedMessage defaultMessage="Schedule appointment" />}
      >
        <IconButton
          size="small"
          variant="secondary"
          onPress={() => setIsDialogOpen(true)}
        >
          <CalendarIcon />
        </IconButton>
      </IconButton.Tooltip>

      <SchedulePatientModal
        isOpen={isDialogOpen}
        onClose={() => {
          setIsDialogOpen(false);
          onClose();
        }}
        // TODO: abolish using patientDetails from SidePanelPatientCtx
        // Patient modal should load patient details via gRPC using patientId
        patientDetails={patientDetails as unknown as Patient}
      />
    </>
  );
}

export function PatientProfileActionButtonGroup() {
  const intl = useIntl();
  const { patientId, patientDetails } = useSidePanelPatientCtx();
  const { setCurrentModal } = usePatientProfileCtx();
  const { token } = store.getState().auth;
  const { shouldAutoOpenScheduler, onCloseScheduler } =
    useShouldAutoOpenScheduler();
  const { debugTools } = useFlags();

  const currentUserRole = jwt_decode<CognitoAccessToken>(token.access_token)[
    'cognito:groups'
  ];

  const patientHospitalQuery = usePatientHospitals({
    patientId: patientDetails.id,
    page: 1,
    pageSize: 1,
  });
  const { data: paginatedClinics } = patientHospitalQuery;
  const { hospitals } = paginatedClinics || {};
  const patientHospital = hospitals?.[0];

  const showRpaButton = useShouldShowRpaButton(
    patientHospital?.ehr_information?.ehr,
    patientHospital?.health_system_id?.toString(),
  );

  return (
    <div className={actionsContainer}>
      <CreateTaskOverlayTrigger patientId={patientId} />
      <PatientProfileSchedulingButton
        defaultOpen={shouldAutoOpenScheduler}
        onClose={onCloseScheduler}
      />
      {isAdmin(currentUserRole) && (
        <IconButton.Tooltip
          content={<FormattedMessage defaultMessage="Patient admin" />}
        >
          <IconButton
            size="small"
            variant="secondary"
            onPress={() =>
              window.open(`/admin/patient/${patientId}/profile`, '_blank')
            }
          >
            <SettingsIcon />
          </IconButton>
        </IconButton.Tooltip>
      )}
      <IconButton.Tooltip
        content={<FormattedMessage defaultMessage="Zendesk profile" />}
      >
        <IconButton
          size="small"
          variant="secondary"
          isDisabled={!patientDetails.zendesk_id}
          onPress={() =>
            patientDetails.zendesk_id
              ? window.open(
                  getPatientZendeskUrl(patientDetails.zendesk_id),
                  '_blank',
                )
              : undefined
          }
        >
          <ZendeskIcon />
        </IconButton>
      </IconButton.Tooltip>
      {showRpaButton && patientHospital && (
        <GoToChartRpaActionButton
          patient={patientDetails}
          patientHospital={patientHospital}
        />
      )}
      {debugTools && (
        <HotkeyTooltip
          title={intl.formatMessage({ defaultMessage: 'Debug' })}
          hotkey={[SpecialChar.Meta, SpecialChar.Shift, 'I']}
          classes={{ triggerContainer: circular }}
        >
          <IconButton
            size="small"
            variant="secondary"
            onPress={() => setCurrentModal(PatientProfileModal.Debug)}
          >
            <DebugIcon />
          </IconButton>
        </HotkeyTooltip>
      )}
    </div>
  );
}
