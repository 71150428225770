import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { useVitalsAndGoals } from '@/components/AsyncTitration/PatientActivity/useVitalsAndGoals';
import { useTitrationRecommendation } from '@/components/AsyncTitration/hooks';
import { isAsyncTitrationPending } from '@/components/AsyncTitration/statuses';
import {
  MedPermissions,
  PatientMedicationsList,
} from '@/pages/patients/PatientMedications/PatientMedicationsList';
import { usePatientMedications } from '@/pages/patients/PatientMedications/patientMedications.queries';
import { lastStructuredChange } from '@/pages/patients/PatientMedications/utils/medChangeUtils';
import { TypeOfEncounter } from '@/pages/patients/patientDetails/ui/Notes/Notes.types';
import { Form } from '@/shared/common/Form';
import { LoadingPlaceholder } from '@/shared/common/LoadingPlaceholder';
import { Wizard } from '@/shared/common/Wizard';
import { useWizardFormFromConfig } from '@/shared/common/Wizard/state';
import type { Medication } from '@/shared/generated/grpc/go/pms/pkg/patient/medication/medication.pb';
import { VitalType } from '@/shared/types/vitals.types';

import { FieldGroup, Script } from '../../../../shared';
import { useTimeEstimate } from '../../../../shared/useTimeEstimate';
import { useCNFormContext } from '../../../CNFormContext';
import { TITRATION_OUTREACH_SECTIONS } from '../../metadata';
import { medReviewStepInfo } from '../../shared/MedReview/SharedMedReview.css';
import { titrationOutreachPath } from '../paths';
import { FAQ } from '../shared';
import type { TitrationFormFields } from './formConfig';
import { getTitrationFormConfig } from './formConfig';

export function Titration() {
  const intl = useIntl();
  const history = useHistory();
  const { patientId, noteId } = useCNFormContext();
  const form = useWizardFormFromConfig<TitrationFormFields>(
    titrationOutreachPath('/titration', '/index'),
    getTitrationFormConfig(),
  );
  const { data: vitalsAndGoals } = useVitalsAndGoals(patientId);
  const { isLoading, data: patientMeds } = usePatientMedications(patientId);
  const bp = vitalsAndGoals?.[VitalType.BloodPressure];

  return (
    <Wizard.Step
      form={form}
      sections={TITRATION_OUTREACH_SECTIONS}
      title={intl.formatMessage({ defaultMessage: 'Proposed Titration' })}
      subtitle={useTimeEstimate(
        intl.formatMessage({ defaultMessage: '1-3 min' }),
      )}
      onNext={() => {
        history.push(
          titrationOutreachPath('/action-plan', '/prev-goal-progress'),
        );
      }}
    >
      <LoadingPlaceholder isLoading={isLoading}>
        <FieldGroup>
          <Script>
            <FormattedMessage defaultMessage="Now I'll discuss the proposed medication change(s)" />
          </Script>
          <div className={medReviewStepInfo}>
            <FormattedMessage defaultMessage="Below, find the script to voice over the explanation for the change, required labs, side effects, and what to expect next." />
          </div>
          <PatientMedicationsList
            showRequiredActions={false}
            noteId={noteId}
            patientId={patientId}
            medPermissions={MedPermissions.ProactiveTitrationConsent}
            typeOfEncounter={TypeOfEncounter.CN_TITRATION_OUTREACH}
            hideOtherMeds
          />
          <Form.TextArea
            size={12}
            rows={3}
            name="pharmacy"
            label={<FormattedMessage defaultMessage="Pharmacy info" />}
            placeholder={intl.formatMessage({
              defaultMessage: 'Enter pharmacy info',
            })}
          />
          <Form.TextArea
            size={12}
            rows={3}
            name="preferredLabwork"
            label={
              <FormattedMessage defaultMessage="Preferred labwork location" />
            }
            placeholder={intl.formatMessage({
              defaultMessage: 'Enter preferred labwork location',
            })}
          />
          <Form.TextArea
            size={12}
            rows={3}
            name="patientNote"
            label={<FormattedMessage defaultMessage="Optional patient note" />}
            placeholder={intl.formatMessage({
              defaultMessage: 'Enter patient questions or comments',
            })}
          />
          <FAQ.Container>
            <div>
              <FAQ.Question>
                <FormattedMessage defaultMessage="Why do I need to adjust my medications?" />
              </FAQ.Question>
              <FAQ.Answer>
                <FormattedMessage
                  defaultMessage="Managing high blood pressure is crucial to reducing the risk of serious complications such as heart disease, stroke, and kidney issues. Here at Cadence, we like to think of ourselves as an extra set of eyes, working alongside your doctor to help monitor you more closely. By receiving your readings we can help adjust your medications and can personalize your treatment, ensuring you're getting the right amount to effectively control your blood pressure. Our goal is to help you achieve and maintain a blood pressure consistently below {goal}."
                  values={{ goal: bp?.goal }}
                />
              </FAQ.Answer>
            </div>
            <div>
              <FAQ.Question>
                <FormattedMessage defaultMessage="Why do I need to get labs?" />
              </FAQ.Question>
              <FAQ.Answer>
                <FormattedMessage defaultMessage="These labs help us monitor changes in kidneys, electrolytes, and blood sugar - all of which can change overtime as a result of your condition.  These labs provides valuable information to your primary care doctor since many of the issues that might be detected with this panel won't have outward symptoms until the condition has progressed significantly." />
              </FAQ.Answer>
            </div>
            {!!patientMeds?.referencedMedications && (
              <div>
                <FAQ.Question>
                  <FormattedMessage defaultMessage="How does this medication work (or help me)?" />
                </FAQ.Question>
                <FAQ.Answer>
                  {patientMeds.referencedMedications?.map((med) => (
                    <MedFAQ
                      key={`changed-med-${med.referenceMedicationId}`}
                      med={med}
                    />
                  ))}
                </FAQ.Answer>
              </div>
            )}
          </FAQ.Container>
        </FieldGroup>
      </LoadingPlaceholder>
    </Wizard.Step>
  );
}

function MedFAQ({ med }: { med: Medication }) {
  const latestMedChange = lastStructuredChange(med);
  const titrationRecommendation = useTitrationRecommendation(
    latestMedChange,
    med.referenceMedicationId as string,
  );

  if (!isAsyncTitrationPending(titrationRecommendation)) {
    return null;
  }

  return (
    <div>
      <FormattedMessage
        defaultMessage="{medName}: {mechanismOfAction}"
        values={{
          medName: med.referenceMedicationName,
          mechanismOfAction: med.referenceMedication?.mechanismOfAction,
        }}
      />
    </div>
  );
}
