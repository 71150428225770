import classNames from 'classnames';
import type { ReactNode } from 'react';

import LightbulbIcon from '@/shared/assets/svgs/lightBulb.svg?react';
import { color } from '@/shared/tempo/theme';

import {
  body,
  container,
  lightBulbIcon,
  title as titleStyles,
} from './CarePlanSuggestionBanner.css';

type Props = {
  children: ReactNode;

  className?: string;
  title: ReactNode;
};

export function CarePlanSuggestionBanner({
  children,
  className,
  title,
}: Props) {
  return (
    <div className={classNames(container, className)}>
      <div className={titleStyles.container}>
        <LightbulbIcon
          className={lightBulbIcon}
          fill={color.Theme.Light.Primary}
        />
        <div className={titleStyles.text}>{title}</div>
      </div>
      <div className={body}>{children}</div>
    </div>
  );
}
