import first from 'lodash/first';
import { FormattedMessage } from 'react-intl';

import { useVitalsAndGoals } from '@/components/AsyncTitration/PatientActivity/useVitalsAndGoals';
import { usePatientMedications } from '@/pages/patients/PatientMedications/patientMedications.queries';
import { LoadingPlaceholder } from '@/shared/common/LoadingPlaceholder';
import { usePatientContext } from '@/shared/hooks/queries';
import { VitalType } from '@/shared/types/vitals.types';

import { Script } from '../../../../shared';
import { formatProviderForScript } from '../../../../shared/script.utils';
import { useCNFormContext } from '../../../CNFormContext';
import { TITRATION_OUTREACH_SECTIONS } from '../../metadata';
import { SharedIntroConfirmData } from '../../shared/IntroConfirmData';
import { usePendingTitrationList } from '../hooks/usePendingTitrationList';
import { titrationOutreachPath } from '../paths';
import { FAQ } from '../shared';

export function IntroConfirmData() {
  const { patientId } = useCNFormContext();
  const { data: context, isLoading: isLoadingContext } =
    usePatientContext(patientId);
  const { data: meds, isLoading: isLoadingMeds } =
    usePatientMedications(patientId);
  const { data: vitalsAndGoals, isLoading: isLoadingVitalsAndGoals } =
    useVitalsAndGoals(patientId);
  const bp = vitalsAndGoals?.[VitalType.BloodPressure];
  const titrations = usePendingTitrationList([
    ...(meds?.referencedMedications ?? []),
    ...(meds?.newMedSuggestions ?? []),
  ]);
  const np = first(titrations)?.approvingCareProvider;

  return (
    <SharedIntroConfirmData
      sections={TITRATION_OUTREACH_SECTIONS}
      nextPath={titrationOutreachPath('/med-review', '/index')}
      callExplainer={
        <LoadingPlaceholder
          isLoading={
            isLoadingContext || isLoadingMeds || isLoadingVitalsAndGoals
          }
        >
          <Script>
            <FormattedMessage
              defaultMessage="I'm reaching out to discuss your recent blood pressure readings. After reviewing your recent readings, your Nurse Practitioner, {npName} would like to adjust your medication in order to help bring your blood pressure closer to the target range. Your average blood pressure reading over the past 30 days was {bpAvg} which is above our target goal of {goal}. This medication adjustment will help you reach that goal and should help you feel better. We work closely with {provider} and our top priority is helping you feel as healthy as possible."
              values={{
                bpAvg: bp?.avg,
                goal: bp?.goal,
                npName: `${np?.givenName} ${np?.familyName}`,
                provider: formatProviderForScript(
                  context?.primaryPhysicianGivenName,
                  context?.primaryPhysicianFamilyName,
                  context?.primaryPhysicianRole,
                ),
              }}
            />
          </Script>
          <Script>
            <FormattedMessage defaultMessage="But first, let's review what medications you're taking on a regular basis..." />
          </Script>
          <FAQ.Container>
            <div>
              <FAQ.Question>
                <FormattedMessage defaultMessage="Why do I need to adjust my medications?" />
              </FAQ.Question>
              <FAQ.Answer>
                <FormattedMessage
                  defaultMessage="Managing high blood pressure is crucial to reducing the risk of serious complications such as heart disease, stroke, and kidney issues. By receiving your readings we can help adjust your medications and can personalize your treatment, ensuring you're getting the right amount to effectively control your blood pressure. Our goal is to help you achieve and maintain a blood pressure consistently below {goal}."
                  values={{ goal: bp?.goal }}
                />
              </FAQ.Answer>
            </div>
          </FAQ.Container>
        </LoadingPlaceholder>
      }
    />
  );
}
