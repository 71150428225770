import { FormattedMessage } from 'react-intl';

import { FoodSecurity } from './FoodSecurity';
import { LivingSituationForm } from './LivingSituationForm';
import { PhysicalActivity } from './PhysicalActivity';
import { Safety } from './Safety';
import { TransportationSecurity } from './TransportationSecurity';
import { Utilities } from './Utilities';

export const PSYCHOSOCIAL_ASSESSMENT_OPTIONS = [
  {
    name: 'livingSituation',
    label: <FormattedMessage defaultMessage="Living Situation" />,
    component: LivingSituationForm,
  },
  {
    name: 'food',
    label: <FormattedMessage defaultMessage="Food Security" />,
    component: FoodSecurity,
  },
  {
    name: 'transportation',
    label: <FormattedMessage defaultMessage="Transportation" />,
    component: TransportationSecurity,
  },
  {
    name: 'utilities',
    label: <FormattedMessage defaultMessage="Utilities" />,
    component: Utilities,
  },
  {
    name: 'safety',
    label: <FormattedMessage defaultMessage="Safety" />,
    component: Safety,
  },
  {
    name: 'physicalActivity',
    label: <FormattedMessage defaultMessage="Physical Activity" />,
    component: PhysicalActivity,
  },
] as const;
