import { FormattedMessage } from 'react-intl';

export const INIT_VISIT_SECTIONS = [
  {
    basePath: '/chart-prep',
    excludeFromProgress: true,
    title: <FormattedMessage defaultMessage="Chart Prep" />,
    steps: [{ path: '/index' }],
  },
  {
    basePath: '/intro',
    title: <FormattedMessage defaultMessage="Intro" />,
    steps: [
      { path: '/patient-attendance' },
      { path: '/contact-info' },
      { path: '/cadence-description' },
      { path: '/program-description' },
      { path: '/questions' },
    ],
  },
  {
    basePath: '/symptoms',
    title: <FormattedMessage defaultMessage="Symptoms" />,
    steps: [{ path: '/index' }, { path: '/secondary' }],
  },
  {
    basePath: '/emergency-visits',
    // only relevant for CHF patients and we're ok just not showing it
    // in the progress bar for now. might make it conditional in the future.
    excludeFromProgress: true,
    title: <FormattedMessage defaultMessage="Emergency Visits" />,
    steps: [{ path: '/index' }],
  },
  {
    basePath: '/profile-completion',
    title: <FormattedMessage defaultMessage="Profile Completion" />,
    steps: [{ path: '/index' }, { path: '/not-activated' }],
  },
  {
    basePath: '/device-tips',
    title: <FormattedMessage defaultMessage="Device Tips" />,
    steps: [{ path: '/index' }],
  },
  {
    basePath: '/vital-progress',
    title: <FormattedMessage defaultMessage="Vital Progress" />,
    steps: [{ path: '/intro' }, { path: '/recommendation' }],
  },
  {
    basePath: '/action-plan',
    title: <FormattedMessage defaultMessage="Action Plan" />,
    steps: [
      { path: '/script' },
      { path: '/set-goal' },
      { path: '/education-modules-review' },
      { path: '/set-action-steps' },
      { path: '/review-action-steps' },
    ],
  },
  {
    basePath: '/general-assessment',
    title: <FormattedMessage defaultMessage="General Assessment" />,
    steps: [
      { path: '/tobacco-use' },
      { path: '/diet' },
      { path: '/exercise' },
      { path: '/daily-living' },
    ],
  },
  {
    basePath: '/med-review',
    title: <FormattedMessage defaultMessage="Med Review" />,
    steps: [{ path: '/index' }],
  },
  {
    basePath: '/scheduling',
    title: <FormattedMessage defaultMessage="Scheduling" />,
    steps: [{ path: '/index' }],
  },
  {
    basePath: '/time-tracking',
    title: <FormattedMessage defaultMessage="Time Tracking" />,
    steps: [{ path: '/index' }],
  },
  {
    basePath: '/end-call',
    excludeFromProgress: true,
    title: <FormattedMessage defaultMessage="End Early" />,
    steps: [{ path: '/index' }],
  },
] as const;
