import { useIntl } from 'react-intl';

import { RPMOrderOrderStatus } from '@/shared/generated/grpc/go/pms/pkg/patient/pms.pb';

export function useOrderStatusI18n(): Record<RPMOrderOrderStatus, string> {
  const intl = useIntl();
  return {
    [RPMOrderOrderStatus.DENIED]: intl.formatMessage({
      defaultMessage: 'Denied',
    }),
    [RPMOrderOrderStatus.PENDED]: intl.formatMessage({
      defaultMessage: 'Pended',
    }),
    [RPMOrderOrderStatus.DELETED]: intl.formatMessage({
      defaultMessage: 'Deleted',
    }),
    [RPMOrderOrderStatus.APPROVED]: intl.formatMessage({
      defaultMessage: 'Approved',
    }),
    [RPMOrderOrderStatus.SKIPPED]: intl.formatMessage({
      defaultMessage: 'Skipped',
    }),
    [RPMOrderOrderStatus.ORDER_STATUS_UNSPECIFIED]: intl.formatMessage({
      defaultMessage: 'Unspecified',
    }),
  };
}
