import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { useRecentNoteFormData } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared/useRecentNoteFormData';
import { useWizardFormFromConfig } from '@/shared/common/Wizard/state';

import { CHF_VISIT_SECTIONS } from '../../metadata';
import type { DeviceUsageGoalsFormFields } from '../../shared/LowDeviceUsage';
import {
  SharedLowDeviceUsage,
  getDeviceUsageGoalsFormConfig,
} from '../../shared/LowDeviceUsage';
import { chfVisitPath } from '../paths';

export function LowDeviceUsage() {
  const intl = useIntl();
  const path = chfVisitPath('/product-support', '/low-device-usage');
  const { isLoading, data } = useRecentNoteFormData<DeviceUsageGoalsFormFields>(
    [path, chfVisitPath('/product-support', '/low-device-usage')],
  );
  const form = useWizardFormFromConfig<DeviceUsageGoalsFormFields>(path, {
    ...getDeviceUsageGoalsFormConfig(intl, data),
    triggerReset: !isLoading,
  });
  const history = useHistory();

  return (
    <SharedLowDeviceUsage
      form={form}
      sections={CHF_VISIT_SECTIONS}
      onNext={() => history.push(chfVisitPath('/med-review', '/index'))}
    />
  );
}
