import { type ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { usePatientTitrationRejectionReasonsI18n } from '@/components/AsyncTitration/hooks';
import { AsyncTitrationPatientRejectedTitrationReason } from '@/shared/generated/grpc/go/pms/pkg/patient/medication/medication.pb';
import type { TReasonForNotTitrating } from '@/shared/titrationRejectionReasons';
import {
  ReasonForNotTitrating,
  useTitrationRejectionReasonsI18n,
} from '@/shared/titrationRejectionReasons';

import {
  type ClinicalGoalReachedInputs,
  GoalUnmetReason,
  HfType,
} from '../Notes.types';
import { useGoalUnmetReasonsI18n } from '../i18n';

type Props = Pick<
  ClinicalGoalReachedInputs,
  | 'isHtn'
  | 'isChf'
  | 'isT2d'
  | 'isBgGoalMet'
  | 'isGdmtGoalMet'
  | 'isBpGoalMet'
  | 'bpGoalDetails'
  | 'isBgBpGoalMet'
  | 'bgBpGoalDetails'
  | 'gdmtGoalDetails'
  | 'bgGoalDetails'
  | 'reasonsGoalUnmet'
  | 'otherReasonDetails'
  | 'hfType'
  | 'historyOfHospitalizationOrSymptoms'
  | 'isT2dGoalMet'
  | 'notTitratingReason'
  | 'patientTitrationRejectionReason'
  | 'capturingNonTitrationReason'
>;

export function FormattedClinicalGoalReachedInputs({
  isHtn,
  isChf,
  isT2d,
  isBgGoalMet,
  isGdmtGoalMet,
  isBpGoalMet,
  bpGoalDetails,
  gdmtGoalDetails,
  bgGoalDetails,
  reasonsGoalUnmet,
  otherReasonDetails,
  hfType,
  isBgBpGoalMet,
  bgBpGoalDetails,
  historyOfHospitalizationOrSymptoms,
  isT2dGoalMet,
  notTitratingReason,
  patientTitrationRejectionReason,
  capturingNonTitrationReason,
}: Props) {
  if (isHtn || isT2d) {
    let goalMet: Maybe<boolean> = false;
    let goalDetails;

    if (isHtn && isT2d) {
      goalMet = capturingNonTitrationReason
        ? isBpGoalMet && isT2dGoalMet
        : isBgBpGoalMet;
      goalDetails = bgBpGoalDetails;
    } else if (isHtn) {
      goalMet = isBpGoalMet;
      goalDetails = bpGoalDetails;
    } else if (isT2d) {
      goalMet = capturingNonTitrationReason ? isT2dGoalMet : isBgGoalMet;
      goalDetails = bgGoalDetails;
    }

    return (
      <SingularGoalPreview
        isGoalMet={goalMet}
        goalDetails={goalDetails}
        reasonsGoalUnmet={reasonsGoalUnmet}
        otherReasonDetails={otherReasonDetails}
        goalAchievedMessage={
          <FormattedMessage defaultMessage="Patient has achieved clinical goal." />
        }
        goalNotAchievedMessage={
          <FormattedMessage defaultMessage="Patient has not achieved clinical goal." />
        }
        notTitratingReason={notTitratingReason}
        patientTitrationRejectionReason={patientTitrationRejectionReason}
        capturingNonTitrationReason={capturingNonTitrationReason}
      />
    );
  }

  if (isChf) {
    if (hfType === HfType.Hfref) {
      return (
        <SingularGoalPreview
          isGoalMet={isGdmtGoalMet}
          goalDetails={gdmtGoalDetails}
          reasonsGoalUnmet={reasonsGoalUnmet}
          otherReasonDetails={otherReasonDetails}
          goalAchievedMessage={
            <FormattedMessage defaultMessage="HFrEF patient, has achieved clinical goal: on max tolerated GDMT." />
          }
          goalNotAchievedMessage={
            <FormattedMessage defaultMessage="HFrEF patient, has not achieved clinical goal: not on target or max tolerated dose of all four pillars of GDMT." />
          }
          notTitratingReason={notTitratingReason}
          patientTitrationRejectionReason={patientTitrationRejectionReason}
          capturingNonTitrationReason={capturingNonTitrationReason}
        />
      );
    }
    if (hfType === HfType.Hfpef) {
      return capturingNonTitrationReason ? (
        <NewChfHfpefPreview
          historyOfHospitalizationOrSymptoms={
            historyOfHospitalizationOrSymptoms
          }
          isBpGoalMet={isBpGoalMet}
          isGdmtGoalMet={isGdmtGoalMet}
          notTitratingReason={notTitratingReason}
          patientTitrationRejectionReason={patientTitrationRejectionReason}
        />
      ) : (
        <LegacyChfHfpefPreview
          bpGoalDetails={bpGoalDetails}
          reasonsGoalUnmet={reasonsGoalUnmet}
          otherReasonDetails={otherReasonDetails}
          gdmtGoalDetails={gdmtGoalDetails}
          isBpGoalMet={isBpGoalMet}
          isGdmtGoalMet={isGdmtGoalMet}
        />
      );
    }
  }

  return <></>;
}

function SingularGoalPreview({
  isGoalMet,
  goalDetails,
  reasonsGoalUnmet,
  otherReasonDetails,
  goalAchievedMessage,
  goalNotAchievedMessage,
  notTitratingReason,
  patientTitrationRejectionReason,
  capturingNonTitrationReason,
}: {
  isGoalMet: boolean | undefined;
  goalDetails: string | undefined;
  reasonsGoalUnmet: Props['reasonsGoalUnmet'];
  otherReasonDetails: Props['otherReasonDetails'];
  goalAchievedMessage: ReactNode;
  goalNotAchievedMessage: ReactNode;
  notTitratingReason: Props['notTitratingReason'];
  patientTitrationRejectionReason: Props['patientTitrationRejectionReason'];
  capturingNonTitrationReason: Props['capturingNonTitrationReason'];
}) {
  if (isGoalMet === undefined) {
    return null;
  }

  return (
    <>
      <p>
        <b>
          <FormattedMessage defaultMessage="Clinical Goals" />
        </b>
      </p>
      {isGoalMet && (
        <p>
          {goalAchievedMessage}
          <br />
          {goalDetails}
        </p>
      )}
      {!isGoalMet && (
        <p>
          {goalNotAchievedMessage}
          <br />
          {!capturingNonTitrationReason && goalDetails}
          {!capturingNonTitrationReason && goalDetails && <br />}
          {capturingNonTitrationReason ? (
            <NonTitrationReason
              notTitratingReason={notTitratingReason}
              patientTitrationRejectionReason={patientTitrationRejectionReason}
            />
          ) : (
            <Reasons
              reasonsGoalUnmet={reasonsGoalUnmet}
              otherReasonDetails={otherReasonDetails}
            />
          )}
        </p>
      )}
    </>
  );
}

function NewChfHfpefPreview({
  isBpGoalMet,
  isGdmtGoalMet,
  historyOfHospitalizationOrSymptoms,
  notTitratingReason,
  patientTitrationRejectionReason,
}: Pick<
  Props,
  | 'isBpGoalMet'
  | 'isGdmtGoalMet'
  | 'historyOfHospitalizationOrSymptoms'
  | 'notTitratingReason'
  | 'patientTitrationRejectionReason'
>) {
  const goalMet = historyOfHospitalizationOrSymptoms
    ? isBpGoalMet && isGdmtGoalMet
    : isBpGoalMet;
  let content: ReactNode;

  if (historyOfHospitalizationOrSymptoms && goalMet) {
    content = (
      <FormattedMessage defaultMessage="HFpEF patient with history of hospitalizations or heart failure symptoms. Has achieved clinical goal: at blood pressure goal and on max tolerated GDMT." />
    );
  } else if (historyOfHospitalizationOrSymptoms && !goalMet) {
    const allGoalsMet = isBpGoalMet && isGdmtGoalMet;

    content = (
      <>
        <FormattedMessage defaultMessage="HFpEF patient with history of hospitalizations or heart failure symptoms. Has not achieved clinical goal: " />
        {allGoalsMet && (
          <FormattedMessage defaultMessage="not at blood pressure goal and not on max tolerated GDMT." />
        )}
        {!allGoalsMet && isBpGoalMet && (
          <FormattedMessage defaultMessage="not on max tolerated GDMT." />
        )}
        {!allGoalsMet && isGdmtGoalMet && (
          <FormattedMessage defaultMessage="not at blood pressure goal." />
        )}
      </>
    );
  } else {
    content = goalMet ? (
      <FormattedMessage defaultMessage="HFpEF patient without history of hospitalizations or heart failure symptoms. Has achieved clinical goal: at blood pressure goal." />
    ) : (
      <FormattedMessage defaultMessage="HFpEF patient without history of hospitalizations or heart failure symptoms. Has not achieved clinical goal: not at blood pressure goal." />
    );
  }

  return (
    <>
      <p>
        <b>
          <FormattedMessage defaultMessage="Clinical Goals" />
        </b>
      </p>
      <p>{content}</p>
      {!goalMet && (
        <NonTitrationReason
          notTitratingReason={notTitratingReason}
          patientTitrationRejectionReason={patientTitrationRejectionReason}
        />
      )}
    </>
  );
}

function LegacyChfHfpefPreview({
  isBpGoalMet,
  isGdmtGoalMet,
  bpGoalDetails,
  gdmtGoalDetails,
  reasonsGoalUnmet,
  otherReasonDetails,
}: Pick<
  Props,
  | 'isBpGoalMet'
  | 'isGdmtGoalMet'
  | 'bpGoalDetails'
  | 'gdmtGoalDetails'
  | 'reasonsGoalUnmet'
  | 'otherReasonDetails'
>) {
  if (isBpGoalMet === undefined || isGdmtGoalMet === undefined) {
    return null;
  }
  const bothGoalsUnmet = !isBpGoalMet && !isGdmtGoalMet;
  const bothGoalsMet = isBpGoalMet && isGdmtGoalMet;
  const onlyBpGoalMet = isBpGoalMet && !isGdmtGoalMet;
  const onlyGdmtGoalMet = !isBpGoalMet && isGdmtGoalMet;
  return (
    <>
      <p>
        <b>
          <FormattedMessage defaultMessage="Clinical Goals" />
        </b>
      </p>
      {bothGoalsMet && (
        <p>
          <FormattedMessage defaultMessage="HFpEF patient, has achieved clinical goal: at blood pressure goal and on max tolerated GDMT." />
          <br />
          {bpGoalDetails}
          {bpGoalDetails && <br />}
          {gdmtGoalDetails}
        </p>
      )}
      {!bothGoalsMet && (
        <p>
          <FormattedMessage defaultMessage="HFpEF patient, has not achieved clinical goal: " />
          {bothGoalsUnmet && (
            <FormattedMessage defaultMessage="not at blood pressure goal and not on max tolerated GDMT." />
          )}
          {onlyBpGoalMet && (
            <FormattedMessage defaultMessage="at blood pressure goal but not on max tolerated GDMT." />
          )}
          {onlyGdmtGoalMet && (
            <FormattedMessage defaultMessage="not at blood pressure goal and on max tolerated GDMT." />
          )}
          <br />
          {bpGoalDetails}
          {bpGoalDetails && <br />}
          {gdmtGoalDetails}
          {gdmtGoalDetails && <br />}
          {!bothGoalsMet && (
            <Reasons
              reasonsGoalUnmet={reasonsGoalUnmet}
              otherReasonDetails={otherReasonDetails}
            />
          )}
        </p>
      )}
    </>
  );
}

function Reasons({
  reasonsGoalUnmet,
  otherReasonDetails,
}: Pick<Props, 'reasonsGoalUnmet' | 'otherReasonDetails'>) {
  const reasonsI18n = useGoalUnmetReasonsI18n();
  if (!reasonsGoalUnmet?.length) {
    return null;
  }

  const reasonsWithoutOther = reasonsGoalUnmet?.filter(
    (reason) => reason !== GoalUnmetReason.Other,
  );
  const isOtherReason =
    reasonsGoalUnmet?.length !== reasonsWithoutOther?.length;
  return (
    <>
      <FormattedMessage defaultMessage="Reason(s) why clinical goal has not been achieved:" />
      <ul>
        {reasonsWithoutOther?.map((reason) => (
          <li key={reason}>{reasonsI18n[reason]}</li>
        ))}
        {isOtherReason && (
          <li>
            {reasonsI18n[GoalUnmetReason.Other]}: {otherReasonDetails}
          </li>
        )}
      </ul>
    </>
  );
}

function NonTitrationReason({
  notTitratingReason,
  patientTitrationRejectionReason,
}: Pick<Props, 'notTitratingReason' | 'patientTitrationRejectionReason'>) {
  const reasons = useTitrationRejectionReasonsI18n();
  const patientReasons = usePatientTitrationRejectionReasonsI18n();

  if (!notTitratingReason) {
    return null;
  }

  const reason =
    notTitratingReason ??
    ReasonForNotTitrating.MEDICATION_WAS_NOT_TITRATED_REASON_UNSPECIFIED;
  const patientReason =
    patientTitrationRejectionReason ??
    AsyncTitrationPatientRejectedTitrationReason.PATIENT_REJECTED_TITRATION_REASON_UNSPECIFIED;
  const reasonI18n = reasons[reason as TReasonForNotTitrating];
  const patientReasonI18n = patientReasons[patientReason];

  return (
    <>
      <p>
        <FormattedMessage
          defaultMessage="Reason why medications were not adjusted today: {reason}."
          values={{ reason: reasonI18n }}
        />
      </p>
      {notTitratingReason === ReasonForNotTitrating.PATIENT_REJECTED && (
        <p>
          <FormattedMessage
            defaultMessage="Reason why patient rejected titration: {patientReason}."
            values={{ patientReason: patientReasonI18n }}
          />
        </p>
      )}
    </>
  );
}
