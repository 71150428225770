import { FormattedMessage, useIntl } from 'react-intl';
import * as yup from 'yup';

import { getShortLinkHostname } from '@/config';
import { Form } from '@/shared/common/Form';
import { useFormFromConfig } from '@/shared/common/Form/FormContainer';
import { validators } from '@/shared/common/Form/validations';
import { Modal } from '@/shared/common/Modal';
import { Button } from '@/shared/tempo/atom/Button';
import { useToaster } from '@/shared/tempo/molecule/Toast';

import {
  useCreateAppointmentLink,
  useListAppointmentTypes,
} from '../../../pages/patients/PatientProfile/PatientScheduling/appointments.queries';

type FormFields = {
  appointmentType: string;
};

type Props = {
  isOpen: boolean;
  onClose: () => void;
  patientId: string;
};

export function SelfServeSchedulerModal({ isOpen, onClose, patientId }: Props) {
  const { toaster } = useToaster();
  const intl = useIntl();
  const { required } = validators(intl);

  const { data: appointmentTypes, isLoading: isLoadingAppointmentTypes } =
    useListAppointmentTypes();

  const form = useFormFromConfig<FormFields>({
    fields: {
      appointmentType: {
        defaultValue: '',
        validation: required(yup.string()),
      },
    },
  });

  const { mutate: createLink, isLoading: isCreatingLink } =
    useCreateAppointmentLink({
      onSuccess: async (response) => {
        const fullUrl = `https://${getShortLinkHostname()}/${response.slug}`;

        try {
          await navigator.clipboard.writeText(fullUrl);

          toaster.success({
            title: intl.formatMessage({ defaultMessage: 'Link Copied' }),
            content: intl.formatMessage(
              {
                defaultMessage:
                  'The scheduling link has been copied to your clipboard: {url}',
              },
              { url: fullUrl },
            ),
          });
          onClose();
        } catch (error) {
          toaster.error(
            intl.formatMessage({
              defaultMessage: 'Failed to copy scheduling link to clipboard',
            }),
          );
        }
      },
      onError: () => {
        toaster.error(
          intl.formatMessage({
            defaultMessage: 'Failed to create scheduling link',
          }),
        );
      },
    });

  const handleSubmit = (values: FormFields) => {
    createLink({
      patientId,
      appointmentTypeId: values.appointmentType,
    });
  };

  return (
    <Modal open={isOpen} onClose={onClose} size="large">
      <Modal.Header
        title={
          <FormattedMessage defaultMessage="Create Self-Serve Scheduling Link" />
        }
      />
      <Modal.Body>
        <Form form={form} onSubmit={handleSubmit}>
          {({ canSubmit }) => (
            <>
              <div>
                <Form.Row>
                  <Form.Select
                    required
                    label={intl.formatMessage({
                      defaultMessage: 'Appointment type',
                    })}
                    name="appointmentType"
                    size={6}
                  >
                    {(appointmentTypes?.appointmentTypes ?? []).map(
                      (appointment) => (
                        <Form.Select.Option key={appointment.name}>
                          {appointment.canonicalName}
                        </Form.Select.Option>
                      ),
                    )}
                  </Form.Select>
                </Form.Row>
              </div>
              <Modal.Footer>
                <Button variant="secondary" onPress={onClose}>
                  <FormattedMessage defaultMessage="Cancel" />
                </Button>
                <Button
                  id="schedule-appointment"
                  type="submit"
                  variant="primary"
                  isDisabled={!canSubmit || isLoadingAppointmentTypes}
                  isProcessing={isCreatingLink}
                >
                  <FormattedMessage defaultMessage="Create Link" />
                </Button>
              </Modal.Footer>
            </>
          )}
        </Form>
      </Modal.Body>
    </Modal>
  );
}
