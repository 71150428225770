import { TypeOfEncounter } from '@/pages/patients/patientDetails/ui/Notes/Notes.types';

import type { StronglyTypeCnNoteField } from '../CNWizardForm/types';

type NoteLike = {
  clinical_navigator_note?: Array<Record<string, unknown>>;
};

export function isCnNote<T extends NoteLike>(
  note: T,
): note is T & StronglyTypeCnNoteField<T> {
  if (note.clinical_navigator_note?.[0]) {
    return true;
  }
  return false;
}

export function isInitialCnVisit(encounterType: TypeOfEncounter) {
  return (
    encounterType === TypeOfEncounter.INITIAL_CN_VISIT ||
    encounterType === TypeOfEncounter.INITIAL_CN_CHF_VISIT
  );
}
