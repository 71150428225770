import cx from 'classnames';
import type { ReactNode } from 'react';

import { Button } from '@/shared/tempo/atom/Button';
import { Tooltip, type TooltipProps } from '@/shared/tempo/atom/Tooltip';

import type { Props as SegmentProps } from './Segment';
import {
  segmentedButton,
  segmentedButtonDefault,
  segmentedButtonSize,
} from './SegmentedButton.css';
import type { SegmentedButtonGroupState } from './segmentedButtonGroupState';

type Props = {
  size?: 'default' | 'small';
  className?: string;
  tooltip?: Omit<TooltipProps, 'children'>;
  state: SegmentedButtonGroupState;
  variant?: 'default' | 'icon-only';
} & SegmentProps;

export function SegmentedButton({
  value,
  children,
  state,
  className,
  tooltip = { content: null },
  size = 'default',
  variant = 'default',
}: Props) {
  const { isDisabled, selectedValue, setSelectedValue } = state;

  return (
    <MaybeTooltip {...tooltip}>
      <Button
        size={size}
        isDisabled={isDisabled}
        classes={{
          default: segmentedButtonDefault,
        }}
        className={cx(
          className,
          segmentedButton.default,
          segmentedButtonSize[size],
          {
            [segmentedButton.iconOnly]: variant === 'icon-only',
          },
        )}
        onPress={() => setSelectedValue(value)}
        variant={
          selectedValue && selectedValue === value ? 'secondary' : 'tertiary'
        }
      >
        {children}
      </Button>
    </MaybeTooltip>
  );
}

function MaybeTooltip({
  children,
  tooltip,
}: {
  children: ReactNode;
  tooltip?: Omit<TooltipProps, 'children'>;
}) {
  if (!tooltip?.content) {
    return <>{children}</>;
  }

  return <Tooltip {...tooltip}>{children}</Tooltip>;
}
