import type { QueryKey, UseQueryOptions } from 'react-query';
import { useQuery } from 'react-query';

import { TelemetryService } from '@/shared/generated/grpc/go/telemetry/pkg/telemetry/v1/telemetry.pb';
import type { PatientDeviceHealth } from '@/shared/generated/grpc/go/telemetry/pkg/telemetry/v1/telemetry.pb';

const DEVICE_HEALTH_QUERY_BASE = ['telemetry', 'v1'] as const;

const deviceKeys = {
  patient: (patientId: string) =>
    [...DEVICE_HEALTH_QUERY_BASE, patientId, 'deviceHealth'] as const,
};

export function useDeviceHealth(
  patientId: string,
  params: UseQueryOptions<PatientDeviceHealth> = {},
) {
  return useQuery(
    deviceKeys.patient(patientId) as QueryKey,
    () =>
      TelemetryService.GetPatientDeviceHealth({
        name: `patients/${patientId}`,
      }),
    { ...params, enabled: Boolean(patientId) },
  );
}
