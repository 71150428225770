import { FormattedMessage, useIntl } from 'react-intl';

import { Modal } from '@/shared/common/Modal';
import { ReportIssueRequestCategory } from '@/shared/generated/grpc/go/pms/pkg/issue_reporter/issue_reporter.pb';
import { Link } from '@/shared/tempo/atom/Link';

import { DeviceStatuses } from '../DeviceStatuses';
import { SubHeader } from '../SubHeader';
import { useSupportModalContext } from '../SupportModalContext';
import { TicketCta } from '../TicketCta';

export function Vitals() {
  const intl = useIntl();
  const { patientId } = useSupportModalContext();

  return (
    <Modal.Body>
      {patientId && <DeviceStatuses patientId={patientId} />}
      <SubHeader>
        <FormattedMessage defaultMessage="Resources" />
      </SubHeader>
      <p>
        <FormattedMessage
          defaultMessage={`If vitals are delayed or otherwise not being received,\
            please review the following resources to troubleshoot potential device issues.`}
        />
      </p>
      <ul>
        <li>
          <Link.Native
            target="_blank"
            href="https://www.notion.so/cadencesolutions/Device-Information-Body-Trace-Smartmeter-WITHINGS-5393951c09ae40028457cd20a5934a44?pvs=4"
          >
            {intl.formatMessage({
              defaultMessage: 'Device Resolution: Cheat Sheet',
            })}
          </Link.Native>
        </li>
        <li>
          <Link.Native
            target="_blank"
            href="https://www.notion.so/cadencesolutions/Delay-in-Vitals-Text-Job-Aid-f8e2979378ea45558c36490a2d0cbad5"
          >
            {intl.formatMessage({
              defaultMessage: 'Delay in Vitals Text Job Aid',
            })}
          </Link.Native>
        </li>
      </ul>
      <TicketCta category={ReportIssueRequestCategory.CATEGORY_VITALS} />
    </Modal.Body>
  );
}
