import { parseISO } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import shuffle from 'lodash/shuffle';

import { conditionsToProgram } from '@/pages/patients/patientDetails/ui/Notes/NoteEditor/templates/hooks';
import type {
  AppointmentAvailability,
  NextAppointmentRecommendation,
} from '@/shared/generated/grpc/go/pms/pkg/scheduling/scheduling.pb';
import { getDeprecatedRpmConditionsFromProgramAndStatus } from '@/shared/patient/deprecated/conditions.utils';
import { ConditionProgram } from '@/shared/types/condition.types';
import { type Patient, PatientStatus } from '@/shared/types/patient.types';

export function isRtePatientWithRecommendedStartDate(
  patient: Patient,
  recommendedAppt: NextAppointmentRecommendation,
): recommendedAppt is NextAppointmentRecommendation & { startDate: string } {
  return (
    patient.status === PatientStatus.ReadyToEnroll &&
    !!recommendedAppt.startDate
  );
}

export function shouldBeScheduledAsap(
  patient: Patient,
  recommendedAppt: NextAppointmentRecommendation,
) {
  if (isRtePatientWithRecommendedStartDate(patient, recommendedAppt)) {
    return false;
  }
  // TODO: Rewrite this to be if recommended date is today or null/empty instead of initial appointment
  const isInitAppointment = isInitialAppointment(
    recommendedAppt.appointmentTypeName,
  );
  const isUnstableChfCnVisit = checkUnstableChfCnVisit(
    patient,
    recommendedAppt,
  );
  const isCarePlanAppointment = isCarePlanVisit(
    recommendedAppt.appointmentTypeName,
  );
  return Boolean(
    isInitAppointment || isUnstableChfCnVisit || isCarePlanAppointment,
  );
}

export function isInitialAppointment(apptTypeName: string = '') {
  return apptTypeName.startsWith('Initial');
}

export function isCarePlanVisit(apptTypeName: string = '') {
  return apptTypeName.includes('Care Plan Visit');
}

export function isInitialCNAppointment(apptTypeName: string = '') {
  return apptTypeName.startsWith('Initial CN');
}

export function isInitialNPAppointment(apptTypeName: string = '') {
  return apptTypeName.startsWith('Initial NP');
}

export function isRegularAppointment(apptTypeName: string = '') {
  return apptTypeName.startsWith('Regular');
}

export function isRegularCNAppointment(apptTypeName: string = '') {
  return apptTypeName.startsWith('Regular CN');
}

export function isRegularNPAppointment(apptTypeName: string = '') {
  return apptTypeName.startsWith('Regular NP');
}

function checkUnstableChfCnVisit(
  patient: Patient,
  recommendedAppt: NextAppointmentRecommendation,
) {
  if (!patient || !recommendedAppt.appointmentTypeName) {
    return false;
  }

  const { sourceAppointmentTypeName, appointmentTypeName, startDate } =
    recommendedAppt;
  const rpmConditions = getDeprecatedRpmConditionsFromProgramAndStatus(
    patient?.programs,
    patient?.status,
  );
  const hasChf = conditionsToProgram(rpmConditions) === ConditionProgram.CHF;
  return (
    sourceAppointmentTypeName?.includes('CN Visit') &&
    appointmentTypeName.includes('NP Visit') &&
    !startDate &&
    hasChf
  );
}

export function createFormatInTzFn(timezone: string) {
  return function formatInTz(date: Maybe<string | Date>, fmt: string) {
    if (date instanceof Date) {
      return formatInTimeZone(date, timezone, fmt);
    }
    return date ? formatInTimeZone(parseISO(date), timezone, fmt) : null;
  };
}

/**
 * This function aims to get the availabilities for the day, ensuring that the selection of appointment slots
 * is balanced across different acuityCalendarIds. It groups the availabilities by their datetime and
 * selects the least chosen acuityCalendarId from each group to maintain an even distribution.
 */
export function getUniformUniqueAvailabilities(
  availabilities: AppointmentAvailability[],
): AppointmentAvailability[] {
  const countMap = new Map();

  const getLeastSelected = (group: AppointmentAvailability[]) =>
    shuffle(group).reduce((leastSelected, current) => {
      const currentCount = countMap.get(current.acuityCalendarId) || 0;
      const leastSelectedCount =
        countMap.get(leastSelected.acuityCalendarId) || 0;
      return currentCount < leastSelectedCount ? current : leastSelected;
    });

  const groupedByDatetime = groupBy(availabilities, 'datetime');

  // Select the least selected element from each group
  return map(groupedByDatetime, (group) => {
    const selected = getLeastSelected(group);
    countMap.set(
      selected.acuityCalendarId,
      (countMap.get(selected.acuityCalendarId) || 0) + 1,
    );
    return selected;
  }) as AppointmentAvailability[];
}
