import { useIntl } from 'react-intl';

import { useWizardFormFromConfig } from '@/shared/common/Wizard/state';

import type { EmergencyVisitsFormFields } from '../../shared/emergencyVisits';
import {
  SharedEmergencyVisits,
  getEmergencyVisitsFormConfig,
} from '../../shared/emergencyVisits';
import { INIT_VISIT_SECTIONS } from '../metadata';
import { initVisitPath } from '../paths';

export function EmergencyVisits() {
  const intl = useIntl();
  const form = useWizardFormFromConfig<EmergencyVisitsFormFields>(
    initVisitPath('/emergency-visits', '/index'),
    getEmergencyVisitsFormConfig(intl),
  );

  return <SharedEmergencyVisits form={form} sections={INIT_VISIT_SECTIONS} />;
}
