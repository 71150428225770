import type { Dispatch, SetStateAction } from 'react';

import type {
  DataObject,
  Schema,
  TimeTrackingRequirementSchema,
} from '@/shared/common/@deprecated/SchemaDrivenForm/';
import type { PatientSchema } from '@/shared/generated/api/pms';
import type { AsyncTitrationPatientRejectedTitrationReason } from '@/shared/generated/grpc/go/pms/pkg/patient/medication/medication.pb';
import type { TReasonForNotTitrating } from '@/shared/titrationRejectionReasons';
import type { VitalsAlert } from '@/shared/types/alert.types';
import type { StandaloneCaregiver } from '@/shared/types/caregiver.types';
import type { CommunicationType } from '@/shared/types/monitoringSession.types';
import type { RequiredNoteType, TNoteBodyRTF } from '@/shared/types/note.types';

import type { EndFormValues } from './NoteEditor/noteFormState';

export type NoteEditorContent = Maybe<{
  title: Nullable<string>;
  // TODO: Remove when switch over to V2 Rich Text Editor
  // https://cadencerpm.atlassian.net/browse/PLAT-4330
  body: Nullable<TNoteBodyRTF>;
  bodyHtml: Nullable<string>;
  labels?: number[] | [];
  externalProviderId?: Nullable<string>;
  escalationMessage?: Nullable<string>;
  actionRequired?: boolean;
  urgent?: boolean;
  timeTracking?: Partial<TimeTracking>;
  zendeskTicket?: Nullable<number>;
  appointmentId?: Nullable<string>;
  noShowAppointmentId?: Nullable<string>;
  endEncounter?: EndFormValues;
  timeElapsed?: Nullable<number>;
}>;

export enum EditableNoteType {
  Alert = 'alert_note',
  Draft = 'draft_note',
  Autosaved = 'autosaved_note',
}

export type EditableNoteState =
  | {
      type: EditableNoteType.Alert;
      alert: VitalsAlert;
      // Autosave note id
      note: Nullable<RequiredNoteType>;
    }
  | { type: EditableNoteType.Draft; note: Nullable<RequiredNoteType> }
  | { type: EditableNoteType.Autosaved; note: Nullable<RequiredNoteType> };

export type NoteEditorContext = {
  editingNote: Nullable<EditableNoteState>;
  setEditingNote: (noteState: Nullable<EditableNoteState>) => void;
  isEditorOpen: boolean;
  openEditor: () => void;
  closeEditor: () => void;
  noteEditorContent: NoteEditorContent;
  setNoteEditorContent: Dispatch<SetStateAction<NoteEditorContent>>;
  encounterModuleInstances: EncounterModuleInstance[];
  setEncounterModuleInstances: (instances: EncounterModuleInstance[]) => void;
  isPreviewing: boolean;
  setIsPreviewing: Dispatch<SetStateAction<boolean>>;
  contacts: Exclude<Required<PatientSchema>['contacts'], undefined>;
  caregivers: StandaloneCaregiver[];
  isSubmitting: boolean;
  setIsSubmitting: Dispatch<SetStateAction<boolean>>;
};

export interface EncounterModuleInstance<Inputs = {}> {
  id?: number;
  note_id?: number;
  encounter_module_id: EncounterModuleId;
  inputs: Inputs;
  addedToFormTimestamp?: number;
}

export enum EncounterModuleId {
  EncounterType = 1,
  Symptoms = 2,
  Hospitalization = 3,
  Medications = 4,
  PatientNotes = 5,
  GeneralAssessmentAndPlan = 6,
  ClinicalAttestation = 7,
  CarePlan = 8,
  ClinicalGoalReached = 9,
}

export type EncounterTypeModuleSchema = {
  time_tracking_required: {
    type_of_encounter: Array<TypeOfEncounter>;
  };
};

export type EncounterTypeInputs = {
  type_of_encounter?: Exclude<
    TypeOfEncounter,
    TypeOfEncounter.NP_VISIT_DEPRECATED | TypeOfEncounter.RN_VISIT_DEPRECATED
  >;
  additional_information?: string;
  patient_outreach_follow_up?: boolean;
  patient_no_show?: boolean;
  has_meds_to_report?: boolean;
  has_emergency_visits_to_report?: boolean;
  has_symptoms_to_report?: boolean;
  // TODO: Remove once we fully remove visit_layout from stored encounter instance
  // data in BE making sure to follow https://cadencerpm.atlassian.net/browse/PLAT-4505
  visit_layout?: boolean;
};

type NotesBody = TNoteBodyRTF & DataObject;

export type PatientNotesInputs = {
  notes_body?: NotesBody;
};

export type GeneralAssessmentInputs = {
  assessment_body?: NotesBody;
};

export type ClinicalAttestationInputs = {
  clinical_attestation_body?: NotesBody;
};

export type CarePlanInputs = {
  care_plan_body?: NotesBody;
};

export enum HfType {
  Hfref = 'hfref',
  Hfpef = 'hfpef',
}

export enum GoalUnmetReason {
  ProviderPreference = 'ProviderPreference',
  PatientPreference = 'PatientPreference',
  MedicalReason = 'MedicalReason',
  FinancialReason = 'FinancialReason',
  Other = 'Other',
}

export interface ClinicalGoalReachedFormFields {
  hfType?: HfType;
  isBpGoalMet?: boolean;
  bpGoalDetails?: string;
  isBgGoalMet?: boolean;
  bgGoalDetails?: string;
  isBgBpGoalMet?: boolean;
  bgBpGoalDetails?: string;
  isGdmtGoalMet?: boolean;
  gdmtGoalDetails?: string;
  reasonsGoalUnmet?: GoalUnmetReason[];
  otherReasonDetails?: string;
  historyOfHospitalizationOrSymptoms?: boolean;
  notTitratingReason?: TReasonForNotTitrating;
  isA1cGoalMet?: boolean;
  isT2dGoalMet?: boolean;
  hasMedChange?: boolean;
  patientTitrationRejectionReason?: AsyncTitrationPatientRejectedTitrationReason;
  capturingNonTitrationReason?: boolean;
}

export type ClinicalGoalReachedInputs = ClinicalGoalReachedFormFields & {
  isChf?: boolean;
  isHtn?: boolean;
  isT2d?: boolean;
  systolicAvg30d?: number;
  diastolicAvg30d?: number;
  systolicGoal?: number;
  diastolicGoal?: number;
  bgAvg30d?: number;
  a1c?: number | null;
  bgGoal?: number;
  a1cGoal?: number;
};

export enum TypeOfEncounter {
  // Clinical types - encounters only
  NP_VISIT = 'Regular NP visit',
  INITIAL_NP_VISIT = 'Initial NP Visit',
  RESULTS_FOLLOW_UP = 'Results follow-up',
  CCM_CARE_PLAN = 'CCM Care Plan',
  CCM_VISIT = 'Regular CCM Visit',
  APCM_CARE_PLAN = 'APCM Care Plan',
  APCM_VISIT = 'Regular APCM Visit',
  ASYNC_REVIEW = 'Async Review',
  TITRATION_OUTREACH = 'Titration Outreach',

  // Clinical types - shared between encounters and CN experience
  INITIAL_CN_VISIT = 'Initial CN visit',
  CN_VISIT = 'Regular CN visit',

  // Clinical types - only used in CN experience
  CN_CHF_VISIT = 'CN CHF visit',
  INITIAL_CN_CHF_VISIT = 'Initial CN CHF visit',
  PATIENT_FOLLOW_UP = 'Patient follow-up',
  CN_TITRATION_OUTREACH = 'CN Titration outreach',

  // Alert types
  ALERT_DOCUMENTATION = 'Alert documentation',

  // Non-clinical types
  PATIENT_ENROLLMENT = 'Patient enrollment',
  VIRTUAL_ENROLLMENT = 'Virtual enrollment',
  PATIENT_INBOUND = 'Patient inbound',
  ENROLLER_VISIT = 'Enroller visit',
  PS_VISIT = 'PS visit',
  WELCOME_CALL = 'Welcome call',
  DISENROLLMENT = 'Disenrollment',
  ENROLLMENT_CALL = 'Enrollment call',

  /**
   * Old encounter types. There are still historical notes in the db that have these encounter types
   * but these are no longer selectable via the Encounter Type Module
   */
  FOURTY_EIGHT_HOUR_FOLLOW_UP_DEPRECATED = '48-hour follow up',
  NP_VISIT_DEPRECATED = 'NP visit',
  RN_VISIT_DEPRECATED = 'RN visit',
  // Deprecated after care model 3.0
  INITIAL_RN_VISIT_DEPRECATED = 'Initial RN Visit',
  REGULAR_RN_VISIT_DEPRECATED = 'Regular RN visit',
}

export interface EncounterModule {
  condition_types: string[] | null;
  disabled: boolean;
  id: number;
  name: string;
  schema: Schema & TimeTrackingRequirementSchema;
}

export type TimeEntry = {
  duration?: number;
  note?: string;
  communication_type?: CommunicationType;
  start_date: Date;
  start_time: string;
  interactive_duration?: number;
  non_interactive_duration?: number;
  tasks_accomplished?: TimeTrackedTaskType[];
  other_task_description?: string;
  // Only used for displaying total time validation
  totalTimeDisplay: number;
};

export type TimeTracking = Omit<TimeEntry, 'start_date' | 'start_time'> & {
  start_datetime: string;
  timezone: string;
};

export enum NotesFilterValue {
  AllNotes,
  ClinicalEncounters,
  ClinicalNotes,
  NPVisits,
  RNVisits,
  CNVisits,
  AlertNotes,
  NonClinicalNotes,
  PatientNoShows,
  InitialVisits,
}

export enum TimeTrackedTaskType {
  ChartPrepOrDocumentation = 'CHART_PREP_OR_DOCUMENTATION',
  PatientVoicemail = 'PATIENT_VOICEMAIL',
  LabOrMedOrder = 'LAB_OR_MED_ORDER',
  PatientSMS = 'PATIENT_SMS',
  InternalOrProviderCommunication = 'INTERNAL_OR_PROVIDER_COMMUNICATION',
  Other = 'OTHER',
}
