import { format } from 'date-fns';
import sortBy from 'lodash/sortBy';
import { useIntl } from 'react-intl';

import type {
  LabGroup,
  ReferenceLab,
} from '@/shared/generated/grpc/go/pms/pkg/patient/labs/labs.pb';
import { MultiSelect } from '@/shared/tempo/@labs/molecule/MultiSelect';
import { Select } from '@/shared/tempo/@labs/molecule/Select';

import { filterDropdown } from './Labs.css';
import type { LabGroupAndDate } from './types';
import { useLabGroupi18nMap } from './useLabGroupI18nMap';

type Props = {
  referenceLabs: ReferenceLab[];
  analytes: Maybe<string[]>;
  labGroup: Maybe<string>;
  latestLabGroups: LabGroupAndDate[];
  onAnalytesChange: (analytes: string[]) => void;
  onLabGroupChange: (labGroup: string) => void;
};

export function LabFilters({
  referenceLabs,
  analytes,
  labGroup,
  latestLabGroups,
  onAnalytesChange,
  onLabGroupChange,
}: Props) {
  const intl = useIntl();
  const labGroupi18nMap = useLabGroupi18nMap();
  const labGroupLabel = intl.formatMessage({
    defaultMessage: 'Filter by lab group',
  });
  const analytesLabel = intl.formatMessage({
    defaultMessage: 'Filter by analytes',
  });
  const distinctGroups = new Set(latestLabGroups.map((lab) => lab));
  const groupOptions = Array.from(distinctGroups).map((group) => {
    const title = labGroupi18nMap[group.group as LabGroup];
    const formattedDate = group.date
      ? `(${format(group.date, 'MM/dd/yyyy')})`
      : '';

    return {
      title: `${title} ${formattedDate}`,
      value: group.group,
    };
  });

  return (
    <>
      <Select
        className={filterDropdown}
        selectedKey={labGroup}
        onSelectionChange={(key) => onLabGroupChange(key as string)}
        aria-label={labGroupLabel}
        placeholder={labGroupLabel}
        items={sortBy(groupOptions, 'title')}
      >
        {(group) => (
          <Select.Option key={group.value}>{group.title}</Select.Option>
        )}
      </Select>
      <MultiSelect
        className={filterDropdown}
        selectedKeys={analytes ?? []}
        onSelectionChange={(key) => {
          const selected = key instanceof Set ? Array.from(key) : [key];
          onAnalytesChange(selected as string[]);
        }}
        aria-label={analytesLabel}
        placeholder={analytesLabel}
        items={sortBy(referenceLabs, 'labName')}
      >
        {(referenceLab) => (
          <Select.Option key={referenceLab.labName}>
            {referenceLab.labName}
          </Select.Option>
        )}
      </MultiSelect>
    </>
  );
}
