import { compareDesc, parseISO } from 'date-fns';

import { logger } from '@/logger';
import type {
  PatientProgramEnum,
  RPMOrder,
} from '@/shared/generated/grpc/go/pms/pkg/patient/pms.pb';
import { PatientProgramEnum as PatientProgram } from '@/shared/generated/grpc/go/pms/pkg/patient/pms.pb';

export function getCodesFromRPMOrder(order: RPMOrder): Nullable<string> {
  if (!order.icd10Codes) {
    return null;
  }
  try {
    return JSON.parse(order.icd10Codes).codes.join(', ');
  } catch {
    return null;
  }
}

export function getOrderCondition(
  order: RPMOrder,
): Nullable<PatientProgramEnum> {
  if (!order.icd10Codes) {
    return null;
  }

  let codes;
  try {
    codes = JSON.parse(order.icd10Codes).detailed_codes;
  } catch (e) {
    if (e instanceof SyntaxError) {
      logger.error('Failed to parse icd10Codes:', e);
    } else {
      logger.error('Unexpected error:', e as Error);
    }

    return null;
  }

  const conditionType = codes?.[0]?.type;

  if (conditionType in PatientProgram) {
    return PatientProgram[conditionType as keyof typeof PatientProgram];
  }

  return null;
}

export function sortOrders(orders: RPMOrder[]): RPMOrder[] {
  return [...orders].sort((a, b) => {
    if (!a.ehrCreatedAt) {
      return 0;
    }
    if (!b.ehrCreatedAt) {
      return 1;
    }
    return compareDesc(parseISO(a.ehrCreatedAt), parseISO(b.ehrCreatedAt));
  });
}
