import uniq from 'lodash/uniq';
import type { IntlShape } from 'react-intl';
import { useIntl } from 'react-intl';

import { logger } from '@/logger';
import {
  ConditionConfidence,
  type PatientDetails,
  PatientStatusEnum,
  Condition as PmsCondition,
  type Program,
  ProgramStatus,
  ProgramType,
} from '@/shared/generated/grpc/go/pms/pkg/patient/pms.pb';
import type {
  CcmCondition,
  RpmCondition,
} from '@/shared/types/clinicalprofile.types';
import { Condition } from '@/shared/types/clinicalprofile.types';

export function getRpmConditions(patient?: PatientDetails): RpmCondition[] {
  return getRpmConditionsFromProgramAndStatus(
    patient?.programs,
    patient?.patient?.status,
  );
}

export function getRpmConditionsFromProgramAndStatus(
  programs: Maybe<Program[]>,
  status: Maybe<PatientStatusEnum>,
): RpmCondition[] {
  if (!programs?.length || !status) {
    return [];
  }
  let programStatus: ProgramStatus;
  if ([PatientStatusEnum.ENROLLED].includes(status)) {
    programStatus = ProgramStatus.ENROLLED;
  } else {
    programStatus = ProgramStatus.SELECTED;
  }
  const foundProgram = programs.find(
    (program) =>
      program.programType === ProgramType.RPM &&
      program.programStatus === programStatus,
  );

  return getRpmConditionsByProgram(foundProgram);
}

export function getCcmConditionsByProgram(
  program: Maybe<Program>,
): CcmCondition[] {
  if (!program || !program.conditions) {
    return [];
  }
  const conditionTypes: PmsCondition[] = program.conditions.reduce(
    (acc: PmsCondition[], condition) => {
      if (condition.conditionType) {
        return [...acc, condition.conditionType];
      }
      return acc;
    },
    [],
  );
  return uniq(convertToCcmConditions(conditionTypes));
}

export function getRpmConditionsByProgram(
  program: Maybe<Program>,
  highConfidenceOnly?: boolean,
): RpmCondition[] {
  if (!program || !program.conditions) {
    return [];
  }
  const conditionTypes: PmsCondition[] = [];
  program.conditions.forEach((condition) => {
    if (condition.conditionType) {
      if (
        highConfidenceOnly &&
        condition.conditionConfidence !== ConditionConfidence.HIGH_CONFIDENCE
      ) {
        return;
      }
      conditionTypes.push(condition.conditionType);
    }
  });
  return uniq(convertToRpmConditions(conditionTypes));
}

export function getConditionAbbreviationI18nMap(intl: IntlShape) {
  return {
    [Condition.CHF]: intl.formatMessage({
      defaultMessage: 'CHF',
    }),
    [Condition.Hypertension]: intl.formatMessage({
      defaultMessage: 'HTN',
    }),
    [Condition.TypeTwoDiabetes]: intl.formatMessage({
      defaultMessage: 'T2D',
    }),
    [Condition.COPD]: intl.formatMessage({
      defaultMessage: 'COPD',
    }),
    [Condition.Generic]: intl.formatMessage({
      defaultMessage: 'GENERIC',
    }),
  };
}

export function useConditionI18nMap() {
  const intl = useIntl();
  return {
    [PmsCondition.HEART_FAILURE]: intl.formatMessage({
      defaultMessage: 'Heart Failure',
    }),
    [PmsCondition.HYPERTENSION]: intl.formatMessage({
      defaultMessage: 'Hypertension',
    }),
    [PmsCondition.TYPE_2_DIABETES]: intl.formatMessage({
      defaultMessage: 'Type 2 Diabetes',
    }),
    [PmsCondition.COPD]: intl.formatMessage({
      defaultMessage: 'COPD',
    }),
    [PmsCondition.ASTHMA]: intl.formatMessage({
      defaultMessage: 'Asthma',
    }),
    [PmsCondition.AFIB_AND_AFL]: intl.formatMessage({
      defaultMessage: 'Afib and Afl',
    }),
    [PmsCondition.CHRONIC_KIDNEY_DISEASE]: intl.formatMessage({
      defaultMessage: 'Chronic Kidney Disease',
    }),
    [PmsCondition.HYPERLIPIDEMIA]: intl.formatMessage({
      defaultMessage: 'Hyperlipidemia',
    }),
    [PmsCondition.HYPOTHYROIDISM]: intl.formatMessage({
      defaultMessage: 'Hypothyroidism',
    }),
    [PmsCondition.ISCHEMIC_HEART_DISEASE]: intl.formatMessage({
      defaultMessage: 'Ischemic Heart Disease',
    }),
    [PmsCondition.MORBID_OBESITY]: intl.formatMessage({
      defaultMessage: 'Morbid Obesity',
    }),
    [PmsCondition.OBSTRUCTIVE_SLEEP_APNEA]: intl.formatMessage({
      defaultMessage: 'Obstructive Sleep Apnea',
    }),
    [PmsCondition.OSTEOARTHRITIS]: intl.formatMessage({
      defaultMessage: 'Osteoarthritis',
    }),
    [PmsCondition.PERIPHERAL_ARTERY_DISEASE]: intl.formatMessage({
      defaultMessage: 'Peripheral Artery Disease',
    }),
    [PmsCondition.GENERIC]: intl.formatMessage({
      defaultMessage: 'Generic',
    }),
    [PmsCondition.CONDITION_UNSPECIFIED]: intl.formatMessage({
      defaultMessage: 'Unspecified',
    }),
  };
}

export function convertToRpmConditions(
  conditions: PmsCondition[],
): RpmCondition[] {
  function mapCondition(sourceValue: PmsCondition): Nullable<RpmCondition> {
    switch (sourceValue) {
      case PmsCondition.HEART_FAILURE:
        return Condition.CHF;
      case PmsCondition.TYPE_2_DIABETES:
        return Condition.TypeTwoDiabetes;
      case PmsCondition.HYPERTENSION:
        return Condition.Hypertension;
      default:
        logger.error(
          `Could not map unknown condition '${sourceValue}' to RPM condition`,
        );
        return null;
    }
  }
  return Array.from(
    new Set(
      conditions.map(mapCondition).filter((x): x is RpmCondition => x !== null),
    ),
  );
}

export function convertToCcmConditions(
  conditions: PmsCondition[],
): CcmCondition[] {
  return Array.from(
    new Set(
      conditions
        .map(convertToCcmCondition)
        .filter((x): x is CcmCondition => x !== null),
    ),
  );
}

export function convertToCcmCondition(
  condition: PmsCondition,
): Nullable<CcmCondition> {
  switch (condition) {
    case PmsCondition.HEART_FAILURE:
      return Condition.CHF;
    case PmsCondition.TYPE_2_DIABETES:
      return Condition.TypeTwoDiabetes;
    case PmsCondition.HYPERTENSION:
      return Condition.Hypertension;
    case PmsCondition.COPD:
      return Condition.COPD;
    case PmsCondition.ASTHMA:
      return Condition.Asthma;
    case PmsCondition.AFIB_AND_AFL:
      return Condition.AfibAndFlutter;
    case PmsCondition.CHRONIC_KIDNEY_DISEASE:
      return Condition.ChronicKidneyDisease;
    case PmsCondition.HYPERLIPIDEMIA:
      return Condition.Hyperlipidemia;
    case PmsCondition.HYPOTHYROIDISM:
      return Condition.Hypothyroidism;
    case PmsCondition.ISCHEMIC_HEART_DISEASE:
      return Condition.IschemicHeartDisease;
    case PmsCondition.MORBID_OBESITY:
      return Condition.MorbidObesity;
    case PmsCondition.OBSTRUCTIVE_SLEEP_APNEA:
      return Condition.ObstructiveSleepApnea;
    case PmsCondition.OSTEOARTHRITIS:
      return Condition.Osteoarthritis;
    case PmsCondition.PERIPHERAL_ARTERY_DISEASE:
      return Condition.PeripheralArteryDisease;

    default:
      logger.error(
        `Could not map unknown condition '${condition}' to CCM condition`,
      );
      return null;
  }
}
