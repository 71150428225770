import cx from 'classnames';
import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Form } from '@/shared/common/Form';
import type { MedicationChange } from '@/shared/generated/grpc/go/pms/pkg/patient/medication/medication.pb';
import { Button } from '@/shared/tempo/atom/Button';
import { useToaster } from '@/shared/tempo/molecule/Toast';
import { getGrpcErrorMessage } from '@/shared/utils/helpers';

import { usePatientMedicationsContext } from '../PatientMedicationsContext';
import { useStopMedication } from '../patientMedications.queries';
import type { StopMedFormFields } from './stopMedicationFormConfig';
import {
  serializeStopMedFormFields,
  useStopMedicationForm,
} from './stopMedicationFormConfig';
import {
  dateFormField,
  medFormField,
  medFormInput,
  medFormRow,
  medicationForm,
} from './styles.css';

type Props = {
  initValues?: MedicationChange;
  onClose: () => void;
  onSuccess: () => void;
};

export function StopMedicationForm({ initValues, onClose, onSuccess }: Props) {
  const intl = useIntl();
  const { toaster } = useToaster();
  const { noteId } = usePatientMedicationsContext();
  const stopMutation = useStopMedication(onSuccess);

  function handleSubmit(values: StopMedFormFields) {
    const payload = serializeStopMedFormFields(values, noteId);
    stopMutation.mutate(
      {
        ...payload,
        rxnormId: initValues?.rxnorm?.id,
      },
      {
        onSuccess: () => {
          toaster.success(
            intl.formatMessage({
              defaultMessage: 'Successfully stopped medication',
            }),
          );
          onClose();
        },
        onError: (err) => {
          toaster.error(
            intl.formatMessage(
              {
                defaultMessage: 'Failed to stop medication: {message}',
              },
              { message: getGrpcErrorMessage(err) },
            ),
          );
        },
      },
    );
  }

  const form = useStopMedicationForm(initValues);

  // Force the form to be dirty initially to make the form submittable if no fields are filled out/changed from their default values
  useEffect(() => {
    form.setValue('reason', '', {
      shouldDirty: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Form form={form} onSubmit={handleSubmit}>
      {({ canSubmit }) => (
        <div className={medicationForm}>
          <div className={medFormRow}>
            <Form.DatePicker
              className={cx(medFormField, dateFormField)}
              name="endDate"
              label={intl.formatMessage({ defaultMessage: 'End date' })}
              inputClass={medFormInput}
            />
          </div>
          <Form.TextArea
            name="reason"
            size={12}
            rows={3}
            maxLength={255}
            label={intl.formatMessage({ defaultMessage: 'Patient notes' })}
            placeholder={intl.formatMessage({
              defaultMessage: 'Enter optional notes',
            })}
            classes={{
              root: medFormField,
              input: medFormInput,
            }}
          />
          <Form.Actions direction="row-reverse">
            <Button
              variant="primary"
              size="small"
              onPress={() => form.handleSubmit(handleSubmit)()}
              isProcessing={stopMutation.isLoading}
              isDisabled={!canSubmit}
            >
              <FormattedMessage defaultMessage="Update" />
            </Button>
            <Button variant="secondary" size="small" onPress={onClose}>
              <FormattedMessage defaultMessage="Cancel" />
            </Button>
          </Form.Actions>
        </div>
      )}
    </Form>
  );
}
