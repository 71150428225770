import sortBy from 'lodash/sortBy';

import { useTitrationRejectionReasonsI18n } from './i18n';
import { ReasonForNotTitrating, type TReasonForNotTitrating } from './types';

// these reasons are no longer used and should be filtered for any consumer
const FILTER_FROM_ALL = [
  ReasonForNotTitrating.CALCIUM_TOO_LOW,
  ReasonForNotTitrating.CALCIUM_TOO_HIGH,
  ReasonForNotTitrating.ELECTROLYTE_ABNORMALITY,
  ReasonForNotTitrating.FINANCIAL_REASON,
  ReasonForNotTitrating.KIDNEY_FUNCTION_TOO_LOW,
  ReasonForNotTitrating.MEDICAL_REASON,
  ReasonForNotTitrating.PATIENT_PREFERENCES,
  ReasonForNotTitrating.POTASSIUM_TOO_LOW,
  ReasonForNotTitrating.POTASSIUM_TOO_HIGH,
  ReasonForNotTitrating.PROVIDER_PREFERENCE,
  ReasonForNotTitrating.SODIUM_TOO_LOW,
  ReasonForNotTitrating.MEDICATION_WAS_NOT_TITRATED_REASON_UNSPECIFIED,
];

type UseFilteredOptionsReturn = {
  key: TReasonForNotTitrating;
  label: string;
}[];

export function useFilteredOptions(
  omit: TReasonForNotTitrating[],
): UseFilteredOptionsReturn {
  const filter = [...FILTER_FROM_ALL, ...omit];
  const i18n = useTitrationRejectionReasonsI18n();
  const filteredOptions = (Object.keys(i18n) as TReasonForNotTitrating[])
    .filter((key) => !filter.includes(key as TReasonForNotTitrating))
    .map((key) => ({
      key,
      label: i18n[key as TReasonForNotTitrating],
    }));

  return sortBy(filteredOptions, 'label');
}
