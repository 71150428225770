import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQueryClient } from 'react-query';

import { logger } from '@/logger';
import {
  appointmentKeys,
  useGetNextRecommendedAppointment,
} from '@/pages/patients/PatientProfile/PatientScheduling/appointments.queries';
import { Modal } from '@/shared/common/Modal';
import type { NextAppointmentRecommendation } from '@/shared/generated/grpc/go/pms/pkg/scheduling/scheduling.pb';
import { useFlags } from '@/shared/hooks';
import { useCareProviders, useCaregivers } from '@/shared/hooks/queries';
import { Button } from '@/shared/tempo/atom/Button';
import { useToaster } from '@/shared/tempo/molecule/Toast';
import { type Patient, PatientStatus } from '@/shared/types/patient.types';
import {
  extractPhoneNumberFromContact,
  getPreferredPhoneContact,
} from '@/shared/utils/contact.utils';

import { footer } from './SchedulePatientModal.css';
import { Scheduler } from './Scheduler';
import { SelfServeSchedulerModal } from './SelfServeSchedulerModal';
import { SmartScheduler } from './SmartScheduler';
import { useAcuityIframeUrlParams } from './acuityIframeUrl.utils';
import {
  isInitialCNAppointment,
  isInitialNPAppointment,
  isRegularCNAppointment,
  isRegularNPAppointment,
} from './appointment.utils';
import { ApptsFilter } from './types';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  patientDetails: Patient;
};

export function SchedulePatientModal({
  isOpen,
  onClose,
  patientDetails,
}: Props) {
  const intl = useIntl();
  const { toaster } = useToaster();
  const queryClient = useQueryClient();
  const { showCreateAppointmentLink } = useFlags();
  const [filterType, setFilterType] = useState<ApptsFilter>(
    ApptsFilter.SHOW_ALL,
  );
  const npiId = patientDetails.npi_id || undefined;
  const { data: careProviderList, isLoading: isLoadingCareProviders } =
    useCareProviders({ npiId }, { enabled: Boolean(npiId) && isOpen });

  const careProvider = careProviderList?.care_providers?.[0];
  const careProviderName = careProvider
    ? `${careProvider.first_name} ${careProvider.last_name}, ${careProvider.role}`
    : '';

  const { isLoading: isLoadingCaregivers, data: caregivers } = useCaregivers(
    patientDetails.id || '',
    // The has_caregiver field means we want to use the caregiver contact as the primary contact method
    //  so we need to fetch it
    { enabled: patientDetails.has_caregiver },
  );

  const preferredPhoneContact = getPreferredPhoneContact(
    patientDetails,
    caregivers || [],
  );

  const patientId = patientDetails.id || '';
  const { isLoading: isLoadingRecommendedAppt, data: recommendedAppt } =
    useGetNextRecommendedAppointment(patientId, {
      enabled: isOpen,
      onError: () => {
        toaster.error(
          intl.formatMessage({
            defaultMessage: 'Failed to fetch next appointment recommendation',
          }),
        );
      },
    });

  useEffect(() => {
    if (!isLoadingRecommendedAppt && isOpen) {
      setFilterType(
        recommendedAppt ? ApptsFilter.SHOW_SUGGESTED : ApptsFilter.SHOW_ALL,
      );
    }
  }, [isOpen, isLoadingRecommendedAppt, recommendedAppt]);

  const { phone: preferredContactPhone } = extractPhoneNumberFromContact(
    preferredPhoneContact,
  );

  const acuityIframeUrlParams = useAcuityIframeUrlParams({
    patientDetails,
    recommendedAppt,
    apptsFilter: filterType,
    careProviderName,
    patientOrCaregiverPhone: preferredContactPhone || '',
  });

  const isLoading =
    isLoadingCareProviders || isLoadingCaregivers || isLoadingRecommendedAppt;

  const showSmartScheduler =
    useShouldShowSmartScheduler(patientDetails, recommendedAppt) &&
    // Added just for type inference
    recommendedAppt;

  return (
    <>
      {showCreateAppointmentLink ? (
        <SelfServeSchedulerModal
          isOpen={isOpen}
          onClose={onClose}
          patientId={patientId}
        />
      ) : (
        <Modal
          open={isOpen}
          onClose={() => {
            onClose();
            // Invalidate the next scheduled appt. query. Assuming that a new appt. was
            // likely created and/or that it's generally helpful to refetch on close
            queryClient.invalidateQueries(
              appointmentKeys.nextScheduled(patientId),
            );
          }}
          size="large"
          isLoading={isLoading}
        >
          <Modal.Header
            title={<FormattedMessage defaultMessage="Schedule Patient" />}
          />
          <Modal.Body>
            {!isLoading && (
              <>
                {showSmartScheduler ? (
                  <SmartScheduler
                    onCancel={onClose}
                    patient={patientDetails}
                    filterType={filterType}
                    iframeParams={acuityIframeUrlParams}
                    recommendedAppt={recommendedAppt}
                  />
                ) : (
                  <Scheduler
                    patientId={patientId}
                    filterType={filterType}
                    iframeParams={acuityIframeUrlParams}
                    recommendedAppt={recommendedAppt}
                  />
                )}
              </>
            )}
          </Modal.Body>
          <Modal.Footer className={footer}>
            {recommendedAppt && (
              <Button
                variant="tertiary"
                onPress={() =>
                  setFilterType(
                    filterType === ApptsFilter.SHOW_ALL
                      ? ApptsFilter.SHOW_SUGGESTED
                      : ApptsFilter.SHOW_ALL,
                  )
                }
              >
                {filterType === ApptsFilter.SHOW_ALL ? (
                  <FormattedMessage defaultMessage="View Suggested Options" />
                ) : (
                  <FormattedMessage defaultMessage="View All Options" />
                )}
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}

function useShouldShowSmartScheduler(
  patient: Patient,
  recommendedAppt: Maybe<NextAppointmentRecommendation>,
) {
  const {
    smartSchedulingInitialVisit,
    smartSchedulingRegularVisit,
    smartSchedulingInitialNpVisit,
    smartSchedulingRegularNpVisit,
    smartSchedulingRte,
  } = useFlags();
  return useMemo(() => {
    if (!smartSchedulingInitialVisit) {
      return false;
    }
    if (!recommendedAppt?.appointmentTypeAcuityId) {
      logger.info(
        'SmartScheduler is disabled: No recommendation appointmentTypeAcuityId',
      );
      return false;
    }
    if (
      !(
        isInitialCNAppointment(recommendedAppt?.appointmentTypeName) ||
        (isRegularCNAppointment(recommendedAppt?.appointmentTypeName) &&
          smartSchedulingRegularVisit) ||
        (isRegularNPAppointment(recommendedAppt?.appointmentTypeName) &&
          smartSchedulingRegularNpVisit) ||
        (isInitialNPAppointment(recommendedAppt?.appointmentTypeName) &&
          smartSchedulingInitialNpVisit)
      )
    ) {
      logger.info(
        `SmartScheduler is disabled for recommended appointment type: ${recommendedAppt?.appointmentTypeName}. Flags: initial=${smartSchedulingInitialVisit}, regular=${smartSchedulingRegularVisit}, regularNP=${smartSchedulingRegularNpVisit}`,
      );
      return false;
    }
    const validStatuses = [
      PatientStatus.Enrolled,
      smartSchedulingRte && PatientStatus.ReadyToEnroll,
    ].filter(Boolean);
    if (!validStatuses.includes(patient.status)) {
      logger.info(
        `SmartScheduler is disabled: Patient is not in ${validStatuses.join(
          ' or ',
        )} status: (${patient.status})`,
      );
      return false;
    }
    logger.info('SmartScheduler is enabled');
    return true;
  }, [
    patient.status,
    recommendedAppt?.appointmentTypeAcuityId,
    recommendedAppt?.appointmentTypeName,
    smartSchedulingInitialVisit,
    smartSchedulingRegularVisit,
    smartSchedulingRegularNpVisit,
    smartSchedulingInitialNpVisit,
    smartSchedulingRte,
  ]);
}
