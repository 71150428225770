import classnames from 'classnames/bind';
import type { ReactNode } from 'react';
import { useRef } from 'react';

import styles from '../styles.module.scss';
import { xlContainer } from './Body.css';
import { PageBodyElementContext } from './PageBodyElementContext';

const cx = classnames.bind(styles);

type Props = {
  children: ReactNode;
  maxWidth?: 'xl' | 'full';
  className?: string;
};

export function Body({ children, className, maxWidth = 'full' }: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const content =
    maxWidth === 'xl' ? (
      <div className={xlContainer}>{children}</div>
    ) : (
      children
    );

  return (
    <PageBodyElementContext.Provider value={ref.current}>
      <div className={cx('container-content', className)} ref={ref}>
        {content}
      </div>
    </PageBodyElementContext.Provider>
  );
}
