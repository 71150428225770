import { FormattedMessage, FormattedNumber } from 'react-intl';

import {
  CommonInfoField,
  PLACEHOLDER,
} from '@/shared/common/CommonInfoField/CommonInfoField';
import {
  type PatientClinicalProfile,
  type PatientDetails,
  ProgramType,
} from '@/shared/generated/grpc/go/pms/pkg/patient/pms.pb';
import { usePatientClinicalProfile } from '@/shared/hooks/queries';
import { isParticipatingInProgramType } from '@/shared/patient/programs.utils';
import type { RpmCondition } from '@/shared/types/clinicalprofile.types';
import { Condition } from '@/shared/types/clinicalprofile.types';
import { grpcNameToId } from '@/shared/utils/grpc';
import { displayA1c, displayEjectionFraction } from '@/shared/utils/helpers';
import { convertToLbs } from '@/shared/utils/unit-helpers';

type Props = {
  patientDetails: PatientDetails;
};

export function RpmClinicalPatientInfoFieldsWrapper({ patientDetails }: Props) {
  const patientId = patientDetails.name
    ? grpcNameToId(patientDetails.name)
    : '';
  const { data: clinicalProfile } = usePatientClinicalProfile(patientId || '');
  const { conditions: rpmConditions } = isParticipatingInProgramType(
    patientDetails,
    ProgramType.RPM,
  );

  return (
    <>
      {clinicalProfile && (
        <ClinicalPatientInfoFields
          clinicalProfile={clinicalProfile}
          conditions={rpmConditions}
        />
      )}{' '}
    </>
  );
}

type ClinicalPatientInfoFieldsProps = {
  clinicalProfile: PatientClinicalProfile;
  conditions: RpmCondition[];
};

function ClinicalPatientInfoFields({
  clinicalProfile,
  conditions,
}: ClinicalPatientInfoFieldsProps) {
  return (
    <>
      {conditions.includes(Condition.CHF) && (
        <ChfInfo chfProfile={clinicalProfile} />
      )}
      {conditions.includes(Condition.TypeTwoDiabetes) && (
        <T2dInfo t2dProfile={clinicalProfile} />
      )}
      {conditions.includes(Condition.Hypertension) && (
        <HtnInfo htnProfile={clinicalProfile} />
      )}
    </>
  );
}

function ChfInfo({ chfProfile }: { chfProfile: PatientClinicalProfile }) {
  return (
    <>
      <CommonInfoField
        label={<FormattedMessage defaultMessage="Dry weight" />}
        text={
          chfProfile.dryWeight ? (
            <FormattedNumber
              value={convertToLbs(chfProfile.dryWeight)}
              /* the style prop for this component isn't CSS like this rule assumes */
              /* eslint-disable-next-line react/style-prop-object */
              style="unit"
              unit="pound"
            />
          ) : (
            PLACEHOLDER
          )
        }
      />
      <CommonInfoField
        label={<FormattedMessage defaultMessage="ACC/AHA stage" />}
        text={chfProfile?.chfStage || PLACEHOLDER}
      />
      <CommonInfoField
        label={<FormattedMessage defaultMessage="NYHA Class" />}
        text={chfProfile?.chfClass || PLACEHOLDER}
      />
      <CommonInfoField
        label={<FormattedMessage defaultMessage="EJ Fract" />}
        text={displayEjectionFraction({
          lower: chfProfile?.efLower,
          upper: chfProfile?.efUpper,
        })}
      />
    </>
  );
}

function T2dInfo({ t2dProfile }: { t2dProfile: PatientClinicalProfile }) {
  return (
    <CommonInfoField
      label={<FormattedMessage defaultMessage="Starting A1C" />}
      text={displayA1c(t2dProfile.a1c)}
    />
  );
}

function HtnInfo({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  htnProfile,
}: {
  htnProfile?: PatientClinicalProfile;
}) {
  return <>{/* No HTN-Specific Fields */}</>;
}
