import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';

import BackButtonIcon from '@/shared/assets/icons/BackButtonIcon';
import { Form } from '@/shared/common/Form';
import { useFormFromConfig } from '@/shared/common/Form/FormContainer';
import { useOnMount } from '@/shared/hooks/useOnMount';
import { Button } from '@/shared/tempo/atom/Button';
import { useToaster } from '@/shared/tempo/molecule/Toast';
import { getErrorMsg } from '@/shared/utils/helpers';
import { persistor } from '@/store';
import type { RootState } from '@/store/redux.types';

import { AuthPageWrapper } from '../shared';
import {
  clear,
  loginFormLink,
  loginFormTitleContent,
  loginPageSections,
  loginTitle,
} from '../shared/LoginPage.css';
import { bigButton } from '../shared/auth.css';
import { backLinkContainer } from './LoginPasswordPage.css';
import { type FormFields, getFormConfig } from './formConfig';
import { usePasswordLogin } from './login.queries';

export function LoginPasswordPage() {
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation<Partial<{ from: { pathname: string } }>>();
  const { toaster } = useToaster();
  const {
    isAuthenticated,
    error: loginError,
    email,
  } = useSelector((state: RootState) => state.auth);

  const { mutate: requestLogin, isLoading } = usePasswordLogin({
    onError(err) {
      toaster.error(getErrorMsg(err));
    },
  });
  const form = useFormFromConfig<FormFields>(getFormConfig(intl, { email }));

  useOnMount(() => {
    if (isAuthenticated) {
      history.replace('/');
    }
  });

  useEffect(() => {
    const { from } = location.state || { from: { pathname: '/' } };
    if (isAuthenticated) {
      // Flush state before redirect
      persistor.flush().then(() => {
        history.replace(from?.pathname || '/');
      });
    }
  }, [isAuthenticated, history, location, loginError, toaster]);

  return (
    <AuthPageWrapper>
      <div className={backLinkContainer}>
        <Link className={loginFormLink} to="/login">
          <BackButtonIcon />
        </Link>
      </div>
      <div className={loginTitle}>
        <FormattedMessage defaultMessage="Sign in" />
        <div className={clear} />
        <div className={loginFormTitleContent}>
          <FormattedMessage defaultMessage="Enter your account information." />
        </div>
      </div>
      <Form form={form} onSubmit={(values) => requestLogin(values)}>
        <div>
          <Form.TextField
            size={12}
            name="email"
            placeholder={intl.formatMessage({
              defaultMessage: 'Company email',
            })}
          />
        </div>
        <div className={loginPageSections}>
          <Form.Password
            size={12}
            name="password"
            placeholder={intl.formatMessage({
              defaultMessage: 'Your password',
            })}
          />
        </div>
        <div className={loginPageSections}>
          <Button
            variant="primary"
            type="submit"
            className={bigButton}
            isProcessing={isLoading}
            isDisabled={!form.canSubmit}
          >
            <FormattedMessage defaultMessage="Log in" />
          </Button>
        </div>
        <div className={loginPageSections}>
          <Link className={loginFormLink} to="/forgot-password">
            <FormattedMessage defaultMessage="Forgot password?" />
          </Link>
        </div>
      </Form>
    </AuthPageWrapper>
  );
}
