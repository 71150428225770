import type { ReactElement } from 'react';
import type { UseFormReturn } from 'react-hook-form';

import { Form } from '@/shared/common/Form';

import { ContextualGoalButton } from '../../../Goals/ContextualGoalButton';
import { type GoalMeasure } from '../../../Goals/goals.types';
import { useGoalGoalMeasureI18n } from '../../../Goals/i18nMapping';
import { type FormFields } from '../../formConfig';
import * as styles from './shared.css';

interface Props {
  fieldName: keyof FormFields;
  placeholder: string;
  onCreateGoal: (measure: GoalMeasure, description?: string) => void;
  measure: GoalMeasure;
  form: UseFormReturn<FormFields>;
}

export function GapWidget({
  fieldName,
  placeholder,
  onCreateGoal,
  measure,
  form,
}: Props): ReactElement {
  const goalMeasureI18n = useGoalGoalMeasureI18n();

  return (
    <div className={styles.gapContainer}>
      <Form.TextArea
        name={fieldName}
        placeholder={placeholder}
        size={12}
        rows={2}
        classes={{ root: styles.notesField }}
      />
      <ContextualGoalButton
        className={styles.gapButton}
        goalName={goalMeasureI18n[measure]}
        onPress={() => {
          const notes = form.getValues(fieldName);
          onCreateGoal(
            measure,
            notes === undefined ? undefined : (notes as string),
          );
        }}
      />
    </div>
  );
}
