import intersection from 'lodash/intersection';
import { FormattedMessage, useIntl } from 'react-intl';

import { Form } from '@/shared/common/Form';
import { ReferenceMedicationMedicationClass as MedClass } from '@/shared/generated/grpc/go/pms/pkg/patient/medication/medication.pb';
import { useFlags } from '@/shared/hooks';
import type { PatientVitalAvgAndGoals } from '@/shared/hooks/queries/vitalsContext.queries';
import { tiledRadioLabel } from '@/shared/jsStyle/ui.css';
import { ReasonForNotTitrating } from '@/shared/titrationRejectionReasons';

import { HfType } from '../../../../Notes.types';
import { goalInfo } from '../ClinicalGoalReachedForm.css';
import { BpSummary, isAtBpGoal } from '../summary';
import { Goal } from './Goal';
import { GoalUnmetReasons } from './GoalUnmetReason';
import { NonTitrationReason } from './NonTitrationReason';
import { GdmtGoal, HfTypeRadio } from './SharedChfInputs';
import { shouldCollectNonTitrationReason } from './shouldCollectNonTitrationReason';
import { STRING_TO_BOOL_MAP } from './stringToBoolMap';
import { useHasMedChange } from './useHasMedChange';

type SharedProps = {
  hfType?: HfType;
  isGdmtGoalMet?: boolean;
  vitalValuesAndGoals: PatientVitalAvgAndGoals;
};

type NewProps = {
  historyOfHospitalizationOrSymptoms?: boolean;
} & SharedProps;

type LegacyProps = {
  isBpGoalMet?: boolean;
} & SharedProps;

export function ChfGoals({
  hfType,
  historyOfHospitalizationOrSymptoms,
  isBpGoalMet,
  isGdmtGoalMet,
  vitalValuesAndGoals,
}: NewProps & LegacyProps) {
  const { gatherNonTitrationReasons } = useFlags();

  if (!gatherNonTitrationReasons) {
    return (
      <LegacyChfGoals
        hfType={hfType}
        isBpGoalMet={isBpGoalMet}
        isGdmtGoalMet={isGdmtGoalMet}
        vitalValuesAndGoals={vitalValuesAndGoals}
      />
    );
  }

  return (
    <NewChfGoals
      hfType={hfType}
      historyOfHospitalizationOrSymptoms={historyOfHospitalizationOrSymptoms}
      isGdmtGoalMet={isGdmtGoalMet}
      vitalValuesAndGoals={vitalValuesAndGoals}
    />
  );
}

function NewChfGoals({
  hfType,
  historyOfHospitalizationOrSymptoms,
  isGdmtGoalMet,
  vitalValuesAndGoals,
}: NewProps) {
  const intl = useIntl();
  const { isLoading, hasMedChange } = useHasMedChange((med) => {
    const dsOnly = !!med.isDiseaseSpecific;

    if (hfType === HfType.Hfpef) {
      return dsOnly;
    }

    const validMedClasses = [
      MedClass.ACE_INHIBITOR,
      MedClass.ALDOSTERONE_ANTAGONISTS,
      MedClass.ARB,
      MedClass.ARNI,
      MedClass.BETA_BLOCKERS,
      MedClass.SODIUM_GLUCOSE_CO_TRANSPORTER_2_INHIBITORS,
    ];
    const medClassIntersection = intersection(
      validMedClasses,
      med.referenceMedication?.medClasses,
    );

    return dsOnly && medClassIntersection.length > 0;
  });

  if (isLoading) {
    return null;
  }

  const { isGoalMet: isBpGoalMet } = isAtBpGoal(vitalValuesAndGoals);
  const shouldCheckMaxToleratedGdmt =
    hfType === HfType.Hfref || historyOfHospitalizationOrSymptoms;

  return (
    <>
      <div className={goalInfo}>
        <BpSummary />
      </div>
      <Form.Hidden
        name="isBpGoalMet"
        value={String(isBpGoalMet)}
        valueMap={STRING_TO_BOOL_MAP}
      />
      <Form.Hidden
        name="hasMedChange"
        value={String(hasMedChange)}
        valueMap={STRING_TO_BOOL_MAP}
      />
      <HfTypeRadio />
      {hfType === HfType.Hfpef && (
        <Form.RadioGroup
          size={12}
          name="historyOfHospitalizationOrSymptoms"
          label={
            <FormattedMessage defaultMessage="Does the patient have a history of hospitalizations or heart failure symptoms?" />
          }
          orientation="horizontal"
          valueMap={STRING_TO_BOOL_MAP}
        >
          <Form.Radio
            value="true"
            classes={{ label: tiledRadioLabel.horizontal }}
          >
            <FormattedMessage defaultMessage="Yes" />
          </Form.Radio>
          <Form.Radio
            value="false"
            classes={{ label: tiledRadioLabel.horizontal }}
          >
            <FormattedMessage defaultMessage="No" />
          </Form.Radio>
        </Form.RadioGroup>
      )}
      {shouldCheckMaxToleratedGdmt && (
        <GdmtGoal
          label={intl.formatMessage({
            defaultMessage: 'Has the patient reached max tolerated GDMT?',
          })}
        />
      )}
      {!shouldCheckMaxToleratedGdmt && (
        // this should be temporary until we get rid of legacy validation after full rollout.
        // https://linear.app/cadencerpm/issue/CARE-836/remove-legacy-validation-rules-when-fully-rolled-out
        <Form.Hidden
          name="isGdmtGoalMet"
          value="true"
          valueMap={STRING_TO_BOOL_MAP}
        />
      )}
      {shouldCollectNonTitrationReason({
        isChf: true,
        hasRelevantMedChange: hasMedChange,
        fields: {
          hfType,
          isGdmtGoalMet,
          isBpGoalMet,
          historyOfHospitalizationOrSymptoms,
        },
      }) && (
        <NonTitrationReason
          excludedKeys={
            shouldCheckMaxToleratedGdmt
              ? [ReasonForNotTitrating.MAX_TOLERATED_DOSE_REACHED]
              : undefined
          }
        />
      )}
    </>
  );
}

function LegacyChfGoals({
  hfType,
  isBpGoalMet,
  isGdmtGoalMet,
  vitalValuesAndGoals,
}: LegacyProps) {
  const intl = useIntl();

  return (
    <>
      <HfTypeRadio />
      {hfType === HfType.Hfpef ? (
        <Goal
          isGoalMetFieldName="isBpGoalMet"
          isGoalMetFieldValue={isBpGoalMet}
          goalDetailsFieldName="bpGoalDetails"
          description={intl.formatMessage({
            defaultMessage:
              'Goal 1 of 2: Is the patient at blood pressure goal?',
          })}
          areGoalsDefined={Boolean(
            vitalValuesAndGoals.systolicGoal &&
              vitalValuesAndGoals.diastolicGoal,
          )}
          vitalSummary={<BpSummary />}
        />
      ) : null}
      {hfType ? (
        <GdmtGoal
          isGdmtGoalMet={isGdmtGoalMet}
          label={
            hfType === HfType.Hfpef
              ? intl.formatMessage({
                  defaultMessage:
                    'Goal 2 of 2: Has the patient reached max tolerated GDMT?',
                })
              : intl.formatMessage({
                  defaultMessage:
                    'Goal 1 of 1: Has the patient reached max tolerated GDMT?',
                })
          }
        />
      ) : null}
      {((hfType === HfType.Hfpef && isBpGoalMet === false) ||
        isGdmtGoalMet === false) && <GoalUnmetReasons />}
    </>
  );
}
