export enum UtilitySecurityChoice {
  No = 'NO',
  Yes = 'YES',
}

export enum UtilityStatus {
  Threatened = 'THREATENED',
  AlreadyShutOff = 'ALREADY_SHUT_OFF',
}

export type UtilitySecurityFields = {
  utilitySecurityChoice?: UtilitySecurityChoice;
  utilityStatus?: UtilityStatus;
};
