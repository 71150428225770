import type { PatientEhrInfo } from '@/shared/generated/grpc/cadence/models/models.pb';

export function parseEhrInfos(allEhrInformation: PatientEhrInfo[] | undefined) {
  const sortedEhrInfo = sortEhrInfos(allEhrInformation);
  const mainEhrInfo = sortedEhrInfo?.[0];
  const mrns = sortedEhrInfo
    ?.filter((ehrInfo) => ehrInfo.mrn)
    .map((ehrInfo) => ehrInfo.mrn as string);

  return { mainEhrInfo, mrns };
}

function sortEhrInfos(allEhrInformation: PatientEhrInfo[] | undefined) {
  const infos = allEhrInformation || [];
  return [...infos].sort(
    (ehrInfo1, ehrInfo2) =>
      (ehrInfo1.primaryProfile ? -1 : 1) - (ehrInfo2.primaryProfile ? -1 : 1),
  );
}
