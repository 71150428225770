import invert from 'lodash/invert';
import type { IntlShape } from 'react-intl';

import {
  FrequencyChoice,
  HousingConcern as GrpcHousingConcern,
  HousingStabilityDetail as GrpcHousingStabilityDetail,
  UtilityStatus as GrpcUtilityStatus,
  type PsychosocialAssessmentData,
} from '@/shared/generated/grpc/go/pms/pkg/care_plan/care_plan.pb';
import type { CarePlan } from '@/shared/generated/grpc/go/pms/pkg/ccm_care_plan/ccm_care_plan.pb';
import { CCMCondition as GrpcCondition } from '@/shared/generated/grpc/go/pms/pkg/ccm_care_plan/ccm_care_plan.pb';
import type { CcmCondition } from '@/shared/types/clinicalprofile.types';
import { Condition } from '@/shared/types/clinicalprofile.types';
import { dateToGrpcDate, parseGrpcDate } from '@/shared/utils/grpc';

import type { FormFields } from './formConfig';
import {
  getAssessmentFieldNames,
  getDefaultGoalsAndInterventions,
  getPreventativeCareFieldNames,
} from './formConfig';
import {
  HousingConcern,
  HousingConcernsChoice,
  HousingStabilityChoice,
  HousingStabilityDetail,
} from './sections/PsychosocialAssessment/livingSituation.types';
import type {
  DaysPerWeek,
  MinutesPerDay,
} from './sections/PsychosocialAssessment/physicalActivity.types';
import { PSYCHOSOCIAL_ASSESSMENT_OPTIONS } from './sections/PsychosocialAssessment/psychoSocialAssessmentOptions';
import type { SafetyFrequencyChoice } from './sections/PsychosocialAssessment/safety.types';
import { SafetyChoice } from './sections/PsychosocialAssessment/safety.types';
import { TransportationSecurityChoice } from './sections/PsychosocialAssessment/transportationSecurity.types';
import {
  UtilitySecurityChoice,
  UtilityStatus,
} from './sections/PsychosocialAssessment/utilities.types';
import { PREVENTATIVE_CARE_OPTIONS } from './sections/preventativeCareOptions';
import {
  FoodFrequencyChoice,
  FoodSecurityChoice,
} from './sections/reliableSourceOfFood.types';

export function marshalFormDataToCarePlan(
  data: FormFields,
  intl: IntlShape,
  carePlanVisitFlowImprovements: boolean,
): CarePlan {
  return {
    diet: {
      fastFood: data.fastFood,
      snacks: data.snacks,
      desserts: data.desserts,
      regularSoda: data.regularSoda,
      sweetTea: data.sweetTea,
      waterIntake: data.waterIntake,
      saltIntake: data.saltIntake,
    },
    ...(carePlanVisitFlowImprovements
      ? { dietNotes: data.dietNotes }
      : { exercise: data.exercise }),
    socialHistory: {
      tobaccoUse: data.tobaccoUse,
    },
    emergencyVisits: data.emergencyVisits?.map(({ date, ...rest }) => ({
      ...rest,
      ...(date && {
        date: dateToGrpcDate(date),
      }),
    })),
    preventativeCare: PREVENTATIVE_CARE_OPTIONS.map(({ name }) => {
      const { checked, date, nextSteps, notApplicable } =
        getPreventativeCareFieldNames(name);

      return {
        name,
        enabled: !!data[checked],
        notApplicable: !!data[notApplicable],
        date: data[date] as string,
        nextSteps: data[nextSteps] as string,
      };
    }),
    surgicalHistories: data.surgicalHistories,
    specialists: data.specialists,
    medsNotReviewed: data.medsNotReviewed,
    medicationAllergies: data.medicationAllergies,
    goalsAndInterventions: data.goalsAndInterventions
      ?.map(({ condition, ...rest }) => ({
        ...rest,
        condition:
          FALCON_CONDITION_TO_GRPC_CONDITION[condition as CcmCondition] ||
          GrpcCondition.CCM_CONDITION_UNSPECIFIED,
      }))
      .filter(
        ({ condition }) =>
          condition !== GrpcCondition.CCM_CONDITION_UNSPECIFIED,
      ),
    psychosocialAssessments: PSYCHOSOCIAL_ASSESSMENT_OPTIONS.map(
      ({ name: assessmentName }) => {
        const { checked, description } =
          getAssessmentFieldNames(assessmentName);

        return {
          name: assessmentName,
          enabled: !!data[checked],
          description: data[description] as string,
        };
      },
    ),
    ...(carePlanVisitFlowImprovements
      ? { psychosocialAssessmentData: formToGrpcData(data) }
      : {}),
    followUp: {
      ccmAppointment: data.ccmAppointment,
      providerAppointment: data.providerAppointment,
    },
    culturalPreferences:
      data.culturalPreferences ||
      intl.formatMessage({ defaultMessage: 'Not answered at this time' }),
    culturalCommunityResources:
      data.culturalCommunityResources ||
      intl.formatMessage({ defaultMessage: 'Not answered at this time' }),
  };
}

export function marshalCarePlanToFormData(
  data: CarePlan,
  carePlanVisitFlowImprovements: boolean,
): FormFields {
  return {
    ...(carePlanVisitFlowImprovements
      ? { dietNotes: data.dietNotes || '' }
      : {
          fastFood: data.diet?.fastFood || '',
          snacks: data.diet?.snacks || '',
          desserts: data.diet?.desserts || '',
          regularSoda: data.diet?.regularSoda || '',
          sweetTea: data.diet?.sweetTea || '',
          waterIntake: data.diet?.waterIntake || '',
          saltIntake: data.diet?.saltIntake || '',
          exercise: data.exercise || '',
        }),
    tobaccoUse: data.socialHistory?.tobaccoUse || '',
    emergencyVisits: data.emergencyVisits?.map(({ date, ...rest }) => ({
      ...rest,
      date: date ? parseGrpcDate(date) : '',
    })),
    surgicalHistories: data.surgicalHistories,
    specialists: data.specialists,
    medsNotReviewed: data.medsNotReviewed ?? false,
    medicationAllergies: data.medicationAllergies,
    goalsAndInterventions:
      data.goalsAndInterventions?.map(({ condition, ...rest }) => ({
        ...rest,
        condition:
          GRPC_CONDITION_TO_FALCON_CONDITION[condition as GrpcCondition] ||
          Condition.Unspecified,
      })) ?? getDefaultGoalsAndInterventions(null),
    ...PREVENTATIVE_CARE_OPTIONS.reduce((acc, { name }) => {
      const { checked, date, nextSteps, notApplicable } =
        getPreventativeCareFieldNames(name);
      const preventativeCare = data.preventativeCare?.find(
        (pc) => pc.name === name,
      );

      return {
        ...acc,
        [checked]: preventativeCare?.enabled || false,
        [notApplicable]: preventativeCare?.notApplicable || false,
        [nextSteps]: preventativeCare?.nextSteps || '',
        [date]: preventativeCare?.date || '',
      };
    }, {}),
    ...PSYCHOSOCIAL_ASSESSMENT_OPTIONS.reduce((acc, { name }) => {
      const { checked, description } = getAssessmentFieldNames(name);
      const psychosocialAssessment = data.psychosocialAssessments?.find(
        (pa) => pa.name === name,
      );

      return {
        ...acc,
        [checked]: psychosocialAssessment?.enabled || false,
        [description]: psychosocialAssessment?.description || '',
      };
    }, {}),
    ...(carePlanVisitFlowImprovements
      ? grpcDataToFormData(data.psychosocialAssessmentData)
      : {}),
    ccmAppointment: data.followUp?.ccmAppointment || '',
    providerAppointment: data.followUp?.providerAppointment || '',
    culturalPreferences: data.culturalPreferences || '',
    culturalCommunityResources: data.culturalCommunityResources || '',
  };
}

export const FALCON_CONDITION_TO_GRPC_CONDITION: Record<
  CcmCondition,
  GrpcCondition
> = {
  [Condition.CHF]: GrpcCondition.CHF,
  [Condition.Hypertension]: GrpcCondition.HYPERTENSION,
  [Condition.COPD]: GrpcCondition.COPD,
  [Condition.TypeTwoDiabetes]: GrpcCondition.TYPE2_DIABETES,
  [Condition.Asthma]: GrpcCondition.ASTHMA,
  [Condition.AfibAndFlutter]: GrpcCondition.AFIB_AND_FLUTTER,
  [Condition.ChronicKidneyDisease]: GrpcCondition.CHRONIC_KIDNEY_DISEASE,
  [Condition.Hyperlipidemia]: GrpcCondition.HYPERLIPIDEMIA,
  [Condition.Hypothyroidism]: GrpcCondition.HYPOTHYROIDISM,
  [Condition.IschemicHeartDisease]: GrpcCondition.ISCHEMIC_HEART_DISEASE,
  [Condition.MorbidObesity]: GrpcCondition.MORBID_OBESITY,
  [Condition.ObstructiveSleepApnea]: GrpcCondition.OBSTRUCTIVE_SLEEP_APNEA,
  [Condition.Osteoarthritis]: GrpcCondition.OSTEOARTHRITIS,
  [Condition.PeripheralArteryDisease]: GrpcCondition.PERIPHERAL_ARTERY_DISEASE,
  [Condition.Dementia]: GrpcCondition.DEMENTIA,
};

export const GRPC_CONDITION_TO_FALCON_CONDITION = invert(
  FALCON_CONDITION_TO_GRPC_CONDITION,
) as Record<GrpcCondition, CcmCondition>;

export const FORM_TO_GRPC_HOUSING_STABILITY_DETAIL: Record<
  HousingStabilityDetail,
  GrpcHousingStabilityDetail
> = {
  [HousingStabilityDetail.FutureWorries]:
    GrpcHousingStabilityDetail.HOUSING_STABILITY_DETAIL_FUTURE_WORRIES,
  [HousingStabilityDetail.Unstable]:
    GrpcHousingStabilityDetail.HOUSING_STABILITY_DETAIL_UNSTABLE,
};

export const FORM_TO_GRPC_HOUSING_CONCERN: Record<
  HousingConcern,
  GrpcHousingConcern
> = {
  [HousingConcern.Pests]: GrpcHousingConcern.HOUSING_CONCERN_PESTS,
  [HousingConcern.Mold]: GrpcHousingConcern.HOUSING_CONCERN_MOLD,
  [HousingConcern.Lead]: GrpcHousingConcern.HOUSING_CONCERN_LEAD,
  [HousingConcern.NoHeat]: GrpcHousingConcern.HOUSING_CONCERN_NO_HEAT,
  [HousingConcern.BrokenAppliances]:
    GrpcHousingConcern.HOUSING_CONCERN_BROKEN_APPLIANCES,
  [HousingConcern.MissingDetectors]:
    GrpcHousingConcern.HOUSING_CONCERN_SMOKE_DETECTORS,
  [HousingConcern.WaterLeaks]: GrpcHousingConcern.HOUSING_CONCERN_WATER_LEAKS,
  [HousingConcern.None]: GrpcHousingConcern.HOUSING_CONCERN_NONE,
};

export const FORM_TO_GRPC_UTILITY_STATUS: Record<
  UtilityStatus,
  GrpcUtilityStatus
> = {
  [UtilityStatus.Threatened]: GrpcUtilityStatus.UTILITY_STATUS_THREATENED,
  [UtilityStatus.AlreadyShutOff]:
    GrpcUtilityStatus.UTILITY_STATUS_ALREADY_SHUT_OFF,
};

export const FORM_TO_GRPC_FREQUENCY: Record<
  FoodFrequencyChoice | SafetyFrequencyChoice,
  FrequencyChoice
> = {
  [FoodFrequencyChoice.Never]: FrequencyChoice.FREQUENCY_CHOICE_NEVER,
  [FoodFrequencyChoice.Sometimes]: FrequencyChoice.FREQUENCY_CHOICE_SOMETIMES,
  [FoodFrequencyChoice.Often]: FrequencyChoice.FREQUENCY_CHOICE_OFTEN,
};

function formToGrpcData(data: FormFields): PsychosocialAssessmentData {
  return {
    exerciseDaysPerWeek: data.exerciseDaysPerWeek,
    exerciseMinutesPerDay: data.exerciseMinutesPerDay,
    hasHousingStabilityConcerns:
      data.housingStabilityChoice === HousingStabilityChoice.Yes,
    housingStabilityDetail: data.housingStabilityDetail
      ? FORM_TO_GRPC_HOUSING_STABILITY_DETAIL[data.housingStabilityDetail]
      : undefined,
    hasHousingConcerns:
      data.housingConcernsChoice === HousingConcernsChoice.Yes,
    housingConcerns: data.housingConcerns?.map(
      (concern) => FORM_TO_GRPC_HOUSING_CONCERN[concern],
    ),
    hasFoodSecurityConcerns: data.foodSecurityChoice === FoodSecurityChoice.Yes,
    foodWorryFrequency: data.foodWorryFrequency
      ? FORM_TO_GRPC_FREQUENCY[data.foodWorryFrequency]
      : undefined,
    foodRunOutFrequency: data.foodRunOutFrequency
      ? FORM_TO_GRPC_FREQUENCY[data.foodRunOutFrequency]
      : undefined,
    hasTransportationConcerns:
      data.transportationSecurityChoice === TransportationSecurityChoice.Yes,
    hasUtilityConcerns:
      data.utilitySecurityChoice === UtilitySecurityChoice.Yes,
    utilityStatus: data.utilityStatus
      ? FORM_TO_GRPC_UTILITY_STATUS[data.utilityStatus]
      : undefined,
    hasSafetyConcerns: data.safetyChoice === SafetyChoice.Yes,
    physicalHarmFrequency: data.physicalHarmFrequency
      ? FORM_TO_GRPC_FREQUENCY[data.physicalHarmFrequency]
      : undefined,
    threatFrequency: data.threatFrequency
      ? FORM_TO_GRPC_FREQUENCY[data.threatFrequency]
      : undefined,
    verbalAbuseFrequency: data.verbalAbuseFrequency
      ? FORM_TO_GRPC_FREQUENCY[data.verbalAbuseFrequency]
      : undefined,
  };
}

function grpcDataToFormData(
  data: PsychosocialAssessmentData | undefined,
): Partial<FormFields> {
  if (!data) {
    return {};
  }

  return {
    exerciseDaysPerWeek: data.exerciseDaysPerWeek as DaysPerWeek | undefined,
    exerciseMinutesPerDay: data.exerciseMinutesPerDay as
      | MinutesPerDay
      | undefined,
    housingStabilityChoice: data.hasHousingStabilityConcerns
      ? HousingStabilityChoice.Yes
      : HousingStabilityChoice.No,
    housingStabilityDetail: data.housingStabilityDetail
      ? GRPC_TO_FORM_HOUSING_STABILITY_DETAIL[data.housingStabilityDetail]
      : undefined,
    housingConcernsChoice: data.hasHousingConcerns
      ? HousingConcernsChoice.Yes
      : HousingConcernsChoice.No,
    housingConcerns:
      data.housingConcerns
        ?.map((concern) => GRPC_TO_FORM_HOUSING_CONCERN[concern])
        .filter(
          (concern): concern is HousingConcern => concern !== undefined,
        ) ?? [],
    foodSecurityChoice: data.hasFoodSecurityConcerns
      ? FoodSecurityChoice.Yes
      : FoodSecurityChoice.No,
    foodWorryFrequency: data.foodWorryFrequency
      ? (GRPC_TO_FORM_FREQUENCY[data.foodWorryFrequency] as FoodFrequencyChoice)
      : undefined,
    foodRunOutFrequency: data.foodRunOutFrequency
      ? (GRPC_TO_FORM_FREQUENCY[
          data.foodRunOutFrequency
        ] as FoodFrequencyChoice)
      : undefined,
    transportationSecurityChoice: data.hasTransportationConcerns
      ? TransportationSecurityChoice.Yes
      : TransportationSecurityChoice.No,
    utilitySecurityChoice: data.hasUtilityConcerns
      ? UtilitySecurityChoice.Yes
      : UtilitySecurityChoice.No,
    utilityStatus: data.utilityStatus
      ? GRPC_TO_FORM_UTILITY_STATUS[data.utilityStatus]
      : undefined,
    safetyChoice: data.hasSafetyConcerns ? SafetyChoice.Yes : SafetyChoice.No,
    physicalHarmFrequency: data.physicalHarmFrequency
      ? (GRPC_TO_FORM_FREQUENCY[
          data.physicalHarmFrequency
        ] as SafetyFrequencyChoice)
      : undefined,
    threatFrequency: data.threatFrequency
      ? (GRPC_TO_FORM_FREQUENCY[data.threatFrequency] as SafetyFrequencyChoice)
      : undefined,
    verbalAbuseFrequency: data.verbalAbuseFrequency
      ? (GRPC_TO_FORM_FREQUENCY[
          data.verbalAbuseFrequency
        ] as SafetyFrequencyChoice)
      : undefined,
  };
}

const GRPC_TO_FORM_HOUSING_STABILITY_DETAIL: Record<
  GrpcHousingStabilityDetail,
  HousingStabilityDetail | undefined
> = {
  HOUSING_STABILITY_DETAIL_UNSPECIFIED: undefined,
  HOUSING_STABILITY_DETAIL_FUTURE_WORRIES: HousingStabilityDetail.FutureWorries,
  HOUSING_STABILITY_DETAIL_UNSTABLE: HousingStabilityDetail.Unstable,
};

const GRPC_TO_FORM_HOUSING_CONCERN: Record<
  GrpcHousingConcern,
  HousingConcern | undefined
> = {
  HOUSING_CONCERN_UNSPECIFIED: undefined,
  HOUSING_CONCERN_PESTS: HousingConcern.Pests,
  HOUSING_CONCERN_MOLD: HousingConcern.Mold,
  HOUSING_CONCERN_LEAD: HousingConcern.Lead,
  HOUSING_CONCERN_NO_HEAT: HousingConcern.NoHeat,
  HOUSING_CONCERN_BROKEN_APPLIANCES: HousingConcern.BrokenAppliances,
  HOUSING_CONCERN_SMOKE_DETECTORS: HousingConcern.MissingDetectors,
  HOUSING_CONCERN_WATER_LEAKS: HousingConcern.WaterLeaks,
  HOUSING_CONCERN_NONE: HousingConcern.None,
};

const GRPC_TO_FORM_FREQUENCY: Record<
  FrequencyChoice,
  FoodFrequencyChoice | SafetyFrequencyChoice | undefined
> = {
  FREQUENCY_CHOICE_UNSPECIFIED: undefined,
  FREQUENCY_CHOICE_NEVER: FoodFrequencyChoice.Never,
  FREQUENCY_CHOICE_SOMETIMES: FoodFrequencyChoice.Sometimes,
  FREQUENCY_CHOICE_OFTEN: FoodFrequencyChoice.Often,
};

const GRPC_TO_FORM_UTILITY_STATUS: Record<
  GrpcUtilityStatus,
  UtilityStatus | undefined
> = {
  UTILITY_STATUS_UNSPECIFIED: undefined,
  UTILITY_STATUS_THREATENED: UtilityStatus.Threatened,
  UTILITY_STATUS_ALREADY_SHUT_OFF: UtilityStatus.AlreadyShutOff,
};
