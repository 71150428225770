/* eslint-disable */
// @ts-nocheck
/*
* This file is a generated Typescript file for GRPC Gateway, DO NOT MODIFY
*/

import * as CadenceModelsV1Models from "../../../../cadence/models/models.pb"
import * as GoogleProtobufEmpty from "../../../../google/protobuf/empty.pb"
import * as GoogleProtobufField_mask from "../../../../google/protobuf/field_mask.pb"
import * as GoogleProtobufTimestamp from "../../../../google/protobuf/timestamp.pb"
import * as GoogleProtobufWrappers from "../../../../google/protobuf/wrappers.pb"
import * as GoogleTypeDate from "../../../../google/type/date.pb"
import * as GoEhrPkgEmr_adapterEmr_adapter from "../../../ehr/pkg/emr_adapter/emr_adapter.pb"
import * as fm from "@/shared/generated/grpc/fetch.pb"
import * as GoPmsPkgPatientSynchronizationSynchronization from "./synchronization/synchronization.pb"

export enum ProgramStatus {
  PROGRAM_STATUS_UNSPECIFIED = "PROGRAM_STATUS_UNSPECIFIED",
  ELIGIBLE = "ELIGIBLE",
  ORDERED = "ORDERED",
  ENROLLED = "ENROLLED",
  DISENROLLED = "DISENROLLED",
  SELECTED = "SELECTED",
  SUGGESTED = "SUGGESTED",
}

export enum ProgramType {
  PROGRAM_TYPE_UNSPECIFIED = "PROGRAM_TYPE_UNSPECIFIED",
  RPM = "RPM",
  CCM = "CCM",
  APCM = "APCM",
}

export enum ConditionConfidence {
  CONDITION_CONFIDENCE_UNSPECIFIED = "CONDITION_CONFIDENCE_UNSPECIFIED",
  HIGH_CONFIDENCE = "HIGH_CONFIDENCE",
  LOW_CONFIDENCE = "LOW_CONFIDENCE",
}

export enum ProgramConditionMatchType {
  PROGRAM_CONDITION_MATCH_TYPE_UNSPECIFIED = "PROGRAM_CONDITION_MATCH_TYPE_UNSPECIFIED",
  UNIQUE = "UNIQUE",
  NON_UNIQUE = "NON_UNIQUE",
  PARTIAL = "PARTIAL",
  TOTAL = "TOTAL",
}

export enum Gender {
  GENDER_UNSPECIFIED = "GENDER_UNSPECIFIED",
  FEMALE = "FEMALE",
  MALE = "MALE",
  GENDER_OTHER = "GENDER_OTHER",
  X = "X",
}

export enum Race {
  RACE_UNSPECIFIED = "RACE_UNSPECIFIED",
  WHITE = "WHITE",
  BLACK_OR_AFRICAN_AMERICAN = "BLACK_OR_AFRICAN_AMERICAN",
  AMERICAN_INDIAN_OR_ALASKA_NATIVE = "AMERICAN_INDIAN_OR_ALASKA_NATIVE",
  NATIVE_HAWAIIAN = "NATIVE_HAWAIIAN",
  ASIAN_INDIAN = "ASIAN_INDIAN",
  HISPANIC_OR_LATINO = "HISPANIC_OR_LATINO",
  RACE_OTHER = "RACE_OTHER",
}

export enum Acuity {
  ACUITY_UNSPECIFIED = "ACUITY_UNSPECIFIED",
  HIGH = "HIGH",
  MEDIUM = "MEDIUM",
  LOW = "LOW",
}

export enum NoteStatus {
  NOTE_STATUS_UNSPECIFIED = "NOTE_STATUS_UNSPECIFIED",
  PUBLISHED = "PUBLISHED",
  DRAFT = "DRAFT",
  AUTOSAVED = "AUTOSAVED",
}

export enum Condition {
  CONDITION_UNSPECIFIED = "CONDITION_UNSPECIFIED",
  HEART_FAILURE = "HEART_FAILURE",
  HYPERTENSION = "HYPERTENSION",
  COPD = "COPD",
  TYPE_2_DIABETES = "TYPE_2_DIABETES",
  ASTHMA = "ASTHMA",
  AFIB_AND_AFL = "AFIB_AND_AFL",
  CHRONIC_KIDNEY_DISEASE = "CHRONIC_KIDNEY_DISEASE",
  HYPERLIPIDEMIA = "HYPERLIPIDEMIA",
  HYPOTHYROIDISM = "HYPOTHYROIDISM",
  ISCHEMIC_HEART_DISEASE = "ISCHEMIC_HEART_DISEASE",
  MORBID_OBESITY = "MORBID_OBESITY",
  OBSTRUCTIVE_SLEEP_APNEA = "OBSTRUCTIVE_SLEEP_APNEA",
  OSTEOARTHRITIS = "OSTEOARTHRITIS",
  PERIPHERAL_ARTERY_DISEASE = "PERIPHERAL_ARTERY_DISEASE",
  GENERIC = "GENERIC",
}

export enum PrimaryLanguage {
  PRIMARY_LANGUAGE_UNSPECIFIED = "PRIMARY_LANGUAGE_UNSPECIFIED",
  ENGLISH = "ENGLISH",
  SPANISH = "SPANISH",
  ARABIC = "ARABIC",
  CHINESE = "CHINESE",
  FILIPINO = "FILIPINO",
  FRENCH = "FRENCH",
  GERMAN = "GERMAN",
  HAITIAN_CREOLE = "HAITIAN_CREOLE",
  HINDI = "HINDI",
  ITALIAN = "ITALIAN",
  JAPANESE = "JAPANESE",
  KOREAN = "KOREAN",
  PERSIAN = "PERSIAN",
  POLISH = "POLISH",
  PORTUGUESE = "PORTUGUESE",
  ROMANIAN = "ROMANIAN",
  RUSSIAN = "RUSSIAN",
  UKRAINIAN = "UKRAINIAN",
  URDU = "URDU",
  VIETNAMESE = "VIETNAMESE",
  AFAR = "AFAR",
  ABKHAZIAN = "ABKHAZIAN",
  ACHINESE = "ACHINESE",
  ACOLI = "ACOLI",
  ADANGME = "ADANGME",
  ADYGHE = "ADYGHE",
  AFRO_ASIATIC_LANGUAGES = "AFRO_ASIATIC_LANGUAGES",
  AFRIHILI = "AFRIHILI",
  AINU = "AINU",
  AKAN = "AKAN",
  AKKADIAN = "AKKADIAN",
  ALEUT = "ALEUT",
  ALGONQUIAN_LANGUAGES = "ALGONQUIAN_LANGUAGES",
  SOUTHERN_ALTAI = "SOUTHERN_ALTAI",
  AMHARIC = "AMHARIC",
  OLD_ENGLISH = "OLD_ENGLISH",
  ANGIKA = "ANGIKA",
  APACHE_LANGUAGES = "APACHE_LANGUAGES",
  ARAMAIC = "ARAMAIC",
  ARAGONESE = "ARAGONESE",
  MAPUCHE = "MAPUCHE",
  ARAPAHO = "ARAPAHO",
  ARTIFICIAL_LANGUAGES = "ARTIFICIAL_LANGUAGES",
  ARAWAK = "ARAWAK",
  ASSAMESE = "ASSAMESE",
  ASTURIAN = "ASTURIAN",
  ATHAPASCAN_LANGUAGES = "ATHAPASCAN_LANGUAGES",
  AUSTRALIAN_LANGUAGES = "AUSTRALIAN_LANGUAGES",
  AVARIC = "AVARIC",
  AVESTAN = "AVESTAN",
  AWADHI = "AWADHI",
  AYMARA = "AYMARA",
  BANDA_LANGUAGES = "BANDA_LANGUAGES",
  BAMILEKE_LANGUAGES = "BAMILEKE_LANGUAGES",
  BASHKIR = "BASHKIR",
  BALUCHI = "BALUCHI",
  BAMBARA = "BAMBARA",
  BALINESE = "BALINESE",
  BASQUE = "BASQUE",
  BASAA = "BASAA",
  BALTIC_LANGUAGES = "BALTIC_LANGUAGES",
  BEJA = "BEJA",
  BELARUSIAN = "BELARUSIAN",
  BEMBA = "BEMBA",
  BANGLA = "BANGLA",
  BERBER_LANGUAGES = "BERBER_LANGUAGES",
  BHOJPURI = "BHOJPURI",
  BIHARI_LANGUAGES = "BIHARI_LANGUAGES",
  BIKOL = "BIKOL",
  BINI = "BINI",
  BISLAMA = "BISLAMA",
  SIKSIKA = "SIKSIKA",
  BANTU_LANGUAGES = "BANTU_LANGUAGES",
  BOSNIAN = "BOSNIAN",
  BRAJ = "BRAJ",
  BRETON = "BRETON",
  BATAK_LANGUAGES = "BATAK_LANGUAGES",
  BURIAT = "BURIAT",
  BUGINESE = "BUGINESE",
  BULGARIAN = "BULGARIAN",
  BLIN = "BLIN",
  CADDO = "CADDO",
  CENTRAL_AMERICAN_INDIAN_LANGUAGES = "CENTRAL_AMERICAN_INDIAN_LANGUAGES",
  CARIB = "CARIB",
  CATALAN = "CATALAN",
  CAUCASIAN_LANGUAGES = "CAUCASIAN_LANGUAGES",
  CEBUANO = "CEBUANO",
  CELTIC_LANGUAGES = "CELTIC_LANGUAGES",
  CZECH = "CZECH",
  CHAMORRO = "CHAMORRO",
  CHIBCHA = "CHIBCHA",
  CHECHEN = "CHECHEN",
  CHAGATAI = "CHAGATAI",
  CHUUKESE = "CHUUKESE",
  MARI = "MARI",
  CHINOOK_JARGON = "CHINOOK_JARGON",
  CHOCTAW = "CHOCTAW",
  CHIPEWYAN = "CHIPEWYAN",
  CHEROKEE = "CHEROKEE",
  CHURCH_SLAVIC = "CHURCH_SLAVIC",
  CHUVASH = "CHUVASH",
  CHEYENNE = "CHEYENNE",
  CHAMIC_LANGUAGES = "CHAMIC_LANGUAGES",
  SERBIAN_MONTENEGRO = "SERBIAN_MONTENEGRO",
  COPTIC = "COPTIC",
  CORNISH = "CORNISH",
  CORSICAN = "CORSICAN",
  ENGLISH_BASED_CREOLES_AND_PIDGINS = "ENGLISH_BASED_CREOLES_AND_PIDGINS",
  FRENCH_BASED_CREOLES_AND_PIDGINS = "FRENCH_BASED_CREOLES_AND_PIDGINS",
  PORTUGUESE_BASED_CREOLES_AND_PIDGINS = "PORTUGUESE_BASED_CREOLES_AND_PIDGINS",
  CREE = "CREE",
  CRIMEAN_TURKISH = "CRIMEAN_TURKISH",
  CREOLES_AND_PIDGINS = "CREOLES_AND_PIDGINS",
  KASHUBIAN = "KASHUBIAN",
  CUSHITIC_LANGUAGES = "CUSHITIC_LANGUAGES",
  DAKOTA = "DAKOTA",
  DANISH = "DANISH",
  DARGWA = "DARGWA",
  LAND_DAYAK_LANGUAGES = "LAND_DAYAK_LANGUAGES",
  DELAWARE = "DELAWARE",
  SLAVE = "SLAVE",
  DOGRIB = "DOGRIB",
  DINKA = "DINKA",
  DIVEHI = "DIVEHI",
  DOGRI = "DOGRI",
  DRAVIDIAN_LANGUAGES = "DRAVIDIAN_LANGUAGES",
  LOWER_SORBIAN = "LOWER_SORBIAN",
  DUALA = "DUALA",
  MIDDLE_DUTCH = "MIDDLE_DUTCH",
  DUTCH = "DUTCH",
  DYULA = "DYULA",
  DZONGKHA = "DZONGKHA",
  EFIK = "EFIK",
  ANCIENT_EGYPTIAN = "ANCIENT_EGYPTIAN",
  EKAJUK = "EKAJUK",
  GREEK = "GREEK",
  ELAMITE = "ELAMITE",
  MIDDLE_ENGLISH = "MIDDLE_ENGLISH",
  ESPERANTO = "ESPERANTO",
  ESTONIAN = "ESTONIAN",
  EWE = "EWE",
  EWONDO = "EWONDO",
  FANG = "FANG",
  FAROESE = "FAROESE",
  FANTI = "FANTI",
  FIJIAN = "FIJIAN",
  FINNISH = "FINNISH",
  FINNO_UGRIAN_LANGUAGES = "FINNO_UGRIAN_LANGUAGES",
  FON = "FON",
  MIDDLE_FRENCH = "MIDDLE_FRENCH",
  OLD_FRENCH = "OLD_FRENCH",
  NORTHERN_FRISIAN = "NORTHERN_FRISIAN",
  EASTERN_FRISIAN = "EASTERN_FRISIAN",
  WESTERN_FRISIAN = "WESTERN_FRISIAN",
  FULAH = "FULAH",
  FRIULIAN = "FRIULIAN",
  GA = "GA",
  GAYO = "GAYO",
  GBAYA = "GBAYA",
  GERMANIC_LANGUAGES = "GERMANIC_LANGUAGES",
  GEORGIAN = "GEORGIAN",
  GEEZ = "GEEZ",
  GILBERTESE = "GILBERTESE",
  SCOTTISH_GAELIC = "SCOTTISH_GAELIC",
  IRISH = "IRISH",
  GALICIAN = "GALICIAN",
  MANX = "MANX",
  MIDDLE_HIGH_GERMAN = "MIDDLE_HIGH_GERMAN",
  OLD_HIGH_GERMAN = "OLD_HIGH_GERMAN",
  GONDI = "GONDI",
  GORONTALO = "GORONTALO",
  GOTHIC = "GOTHIC",
  GREBO = "GREBO",
  ANCIENT_GREEK = "ANCIENT_GREEK",
  GUARANI = "GUARANI",
  SWISS_GERMAN = "SWISS_GERMAN",
  GUJARATI = "GUJARATI",
  GWICHIN = "GWICHIN",
  HAIDA = "HAIDA",
  HAUSA = "HAUSA",
  HAWAIIAN = "HAWAIIAN",
  HEBREW = "HEBREW",
  HERERO = "HERERO",
  HILIGAYNON = "HILIGAYNON",
  HIMACHALI_LANGUAGES = "HIMACHALI_LANGUAGES",
  HITTITE = "HITTITE",
  HMONG = "HMONG",
  HIRI_MOTU = "HIRI_MOTU",
  CROATIAN = "CROATIAN",
  UPPER_SORBIAN = "UPPER_SORBIAN",
  HUNGARIAN = "HUNGARIAN",
  HUPA = "HUPA",
  IBAN = "IBAN",
  IGBO = "IGBO",
  ICELANDIC = "ICELANDIC",
  IDO = "IDO",
  SICHUAN_YI = "SICHUAN_YI",
  IJO_LANGUAGES = "IJO_LANGUAGES",
  INUKTITUT = "INUKTITUT",
  INTERLINGUE = "INTERLINGUE",
  ILOKO = "ILOKO",
  INTERLINGUA = "INTERLINGUA",
  INDIC_LANGUAGES = "INDIC_LANGUAGES",
  INDONESIAN = "INDONESIAN",
  INDO_EUROPEAN_LANGUAGES = "INDO_EUROPEAN_LANGUAGES",
  INGUSH = "INGUSH",
  INUPIAQ = "INUPIAQ",
  IRANIAN_LANGUAGES = "IRANIAN_LANGUAGES",
  IROQUOIAN_LANGUAGES = "IROQUOIAN_LANGUAGES",
  JAVANESE = "JAVANESE",
  LOJBAN = "LOJBAN",
  JUDEO_PERSIAN = "JUDEO_PERSIAN",
  JUDEO_ARABIC = "JUDEO_ARABIC",
  KARA_KALPAK = "KARA_KALPAK",
  KABYLE = "KABYLE",
  KACHIN = "KACHIN",
  KALAALLISUT = "KALAALLISUT",
  KAMBA = "KAMBA",
  KANNADA = "KANNADA",
  KAREN_LANGUAGES = "KAREN_LANGUAGES",
  KASHMIRI = "KASHMIRI",
  KANURI = "KANURI",
  KAWI = "KAWI",
  KAZAKH = "KAZAKH",
  KABARDIAN = "KABARDIAN",
  KHASI = "KHASI",
  KHOISAN_LANGUAGES = "KHOISAN_LANGUAGES",
  KHMER = "KHMER",
  KHOTANESE = "KHOTANESE",
  KIKUYU = "KIKUYU",
  KINYARWANDA = "KINYARWANDA",
  KYRGYZ = "KYRGYZ",
  KIMBUNDU = "KIMBUNDU",
  KONKANI = "KONKANI",
  KOMI = "KOMI",
  KONGO = "KONGO",
  KOSRAEAN = "KOSRAEAN",
  KPELLE = "KPELLE",
  KARACHAY_BALKAR = "KARACHAY_BALKAR",
  KARELIAN = "KARELIAN",
  KRU_LANGUAGES = "KRU_LANGUAGES",
  KURUKH = "KURUKH",
  KUANYAMA = "KUANYAMA",
  KUMYK = "KUMYK",
  KURDISH = "KURDISH",
  KUTENAI = "KUTENAI",
  LADINO = "LADINO",
  LAHNDA = "LAHNDA",
  LAMBA = "LAMBA",
  LAO = "LAO",
  LATIN = "LATIN",
  LATVIAN = "LATVIAN",
  LEZGHIAN = "LEZGHIAN",
  LIMBURGISH = "LIMBURGISH",
  LINGALA = "LINGALA",
  LITHUANIAN = "LITHUANIAN",
  MONGO = "MONGO",
  LOZI = "LOZI",
  LUXEMBOURGISH = "LUXEMBOURGISH",
  LUBA_LULUA = "LUBA_LULUA",
  LUBA_KATANGA = "LUBA_KATANGA",
  GANDA = "GANDA",
  LUISENO = "LUISENO",
  LUNDA = "LUNDA",
  LUO_KENYA_AND_TANZANIA = "LUO_KENYA_AND_TANZANIA",
  MIZO = "MIZO",
  MACEDONIAN = "MACEDONIAN",
  MADURESE = "MADURESE",
  MAGAHI = "MAGAHI",
  MARSHALLESE = "MARSHALLESE",
  MAITHILI = "MAITHILI",
  MAKASAR = "MAKASAR",
  MALAYALAM = "MALAYALAM",
  MANDINGO = "MANDINGO",
  MAORI = "MAORI",
  AUSTRONESIAN_LANGUAGES = "AUSTRONESIAN_LANGUAGES",
  MARATHI = "MARATHI",
  MASAI = "MASAI",
  MOKSHA = "MOKSHA",
  MANDAR = "MANDAR",
  MENDE = "MENDE",
  MIDDLE_IRISH = "MIDDLE_IRISH",
  MIKMAQ = "MIKMAQ",
  MINANGKABAU = "MINANGKABAU",
  UNCODED_LANGUAGES = "UNCODED_LANGUAGES",
  MON_KHMER_LANGUAGES = "MON_KHMER_LANGUAGES",
  MALAGASY = "MALAGASY",
  MALTESE = "MALTESE",
  MANCHU = "MANCHU",
  MANIPURI = "MANIPURI",
  MANOBO_LANGUAGES = "MANOBO_LANGUAGES",
  MOHAWK = "MOHAWK",
  MONGOLIAN = "MONGOLIAN",
  MOSSI = "MOSSI",
  MULTIPLE_LANGUAGES = "MULTIPLE_LANGUAGES",
  MUNDA_LANGUAGES = "MUNDA_LANGUAGES",
  MUSCOGEE = "MUSCOGEE",
  MIRANDESE = "MIRANDESE",
  MARWARI = "MARWARI",
  BURMESE = "BURMESE",
  MAYAN_LANGUAGES = "MAYAN_LANGUAGES",
  ERZYA = "ERZYA",
  NAHUATL_LANGUAGES = "NAHUATL_LANGUAGES",
  NORTH_AMERICAN_INDIAN_LANGUAGES = "NORTH_AMERICAN_INDIAN_LANGUAGES",
  NEAPOLITAN = "NEAPOLITAN",
  NAURU = "NAURU",
  NAVAJO = "NAVAJO",
  SOUTH_NDEBELE = "SOUTH_NDEBELE",
  NORTH_NDEBELE = "NORTH_NDEBELE",
  NDONGA = "NDONGA",
  LOW_GERMAN = "LOW_GERMAN",
  NEPALI = "NEPALI",
  NEWARI = "NEWARI",
  NIAS = "NIAS",
  NIGER_KORDOFANIAN_LANGUAGES = "NIGER_KORDOFANIAN_LANGUAGES",
  NIUEAN = "NIUEAN",
  NORWEGIAN_NYNORSK = "NORWEGIAN_NYNORSK",
  NORWEGIAN_BOKMAL = "NORWEGIAN_BOKMAL",
  NOGAI = "NOGAI",
  OLD_NORSE = "OLD_NORSE",
  NKO = "NKO",
  NORTHERN_SOTHO = "NORTHERN_SOTHO",
  NUBIAN_LANGUAGES = "NUBIAN_LANGUAGES",
  CLASSICAL_NEWARI = "CLASSICAL_NEWARI",
  NYANJA = "NYANJA",
  NYAMWEZI = "NYAMWEZI",
  NYANKOLE = "NYANKOLE",
  NYORO = "NYORO",
  NZIMA = "NZIMA",
  OCCITAN = "OCCITAN",
  OJIBWA = "OJIBWA",
  ODIA = "ODIA",
  OROMO = "OROMO",
  OSAGE = "OSAGE",
  OSSETIC = "OSSETIC",
  OTTOMAN_TURKISH = "OTTOMAN_TURKISH",
  OTOMIAN_LANGUAGES = "OTOMIAN_LANGUAGES",
  PAPUAN_LANGUAGES = "PAPUAN_LANGUAGES",
  PANGASINAN = "PANGASINAN",
  PAHLAVI = "PAHLAVI",
  PAMPANGA = "PAMPANGA",
  PUNJABI = "PUNJABI",
  PAPIAMENTO = "PAPIAMENTO",
  PALAUAN = "PALAUAN",
  OLD_PERSIAN = "OLD_PERSIAN",
  PHILIPPINE_LANGUAGES = "PHILIPPINE_LANGUAGES",
  PHOENICIAN = "PHOENICIAN",
  PALI = "PALI",
  POHNPEIAN = "POHNPEIAN",
  PRAKRIT_LANGUAGES = "PRAKRIT_LANGUAGES",
  OLD_PROVENCAL = "OLD_PROVENCAL",
  PASHTO = "PASHTO",
  QUECHUA = "QUECHUA",
  RAJASTHANI = "RAJASTHANI",
  RAPANUI = "RAPANUI",
  RAROTONGAN = "RAROTONGAN",
  ROMANCE_LANGUAGES = "ROMANCE_LANGUAGES",
  ROMANSH = "ROMANSH",
  ROMANY = "ROMANY",
  RUNDI = "RUNDI",
  AROMANIAN = "AROMANIAN",
  SANDAWE = "SANDAWE",
  SANGO = "SANGO",
  SAKHA = "SAKHA",
  SOUTH_AMERICAN_INDIAN_LANGUAGES = "SOUTH_AMERICAN_INDIAN_LANGUAGES",
  SALISHAN_LANGUAGES = "SALISHAN_LANGUAGES",
  SAMARITAN_ARAMAIC = "SAMARITAN_ARAMAIC",
  SANSKRIT = "SANSKRIT",
  SASAK = "SASAK",
  SANTALI = "SANTALI",
  SICILIAN = "SICILIAN",
  SCOTS = "SCOTS",
  SELKUP = "SELKUP",
  SEMITIC_LANGUAGES = "SEMITIC_LANGUAGES",
  OLD_IRISH = "OLD_IRISH",
  SIGN_LANGUAGES = "SIGN_LANGUAGES",
  SHAN = "SHAN",
  SIDAMO = "SIDAMO",
  SINHALA = "SINHALA",
  SIOUAN_LANGUAGES = "SIOUAN_LANGUAGES",
  SINO_TIBETAN_LANGUAGES = "SINO_TIBETAN_LANGUAGES",
  SLAVIC_LANGUAGES = "SLAVIC_LANGUAGES",
  SLOVAK = "SLOVAK",
  SLOVENIAN = "SLOVENIAN",
  SOUTHERN_SAMI = "SOUTHERN_SAMI",
  NORTHERN_SAMI = "NORTHERN_SAMI",
  SAMI_LANGUAGES = "SAMI_LANGUAGES",
  LULE_SAMI = "LULE_SAMI",
  INARI_SAMI = "INARI_SAMI",
  SAMOAN = "SAMOAN",
  SKOLT_SAMI = "SKOLT_SAMI",
  SHONA = "SHONA",
  SINDHI = "SINDHI",
  SONINKE = "SONINKE",
  SOGDIEN = "SOGDIEN",
  SOMALI = "SOMALI",
  SONGHAI_LANGUAGES = "SONGHAI_LANGUAGES",
  SOUTHERN_SOTHO = "SOUTHERN_SOTHO",
  ALBANIAN = "ALBANIAN",
  SARDINIAN = "SARDINIAN",
  SRANAN_TONGO = "SRANAN_TONGO",
  SERBIAN = "SERBIAN",
  NILO_SAHARAN_LANGUAGES = "NILO_SAHARAN_LANGUAGES",
  SWATI = "SWATI",
  SUKUMA = "SUKUMA",
  SUNDANESE = "SUNDANESE",
  SUSU = "SUSU",
  SUMERIAN = "SUMERIAN",
  SWAHILI = "SWAHILI",
  CLASSICAL_SYRIAC = "CLASSICAL_SYRIAC",
  SYRIAC = "SYRIAC",
  TAHITIAN = "TAHITIAN",
  TAI_LANGUAGES = "TAI_LANGUAGES",
  TAMIL = "TAMIL",
  TATAR = "TATAR",
  TELUGU = "TELUGU",
  TIMNE = "TIMNE",
  TERENO = "TERENO",
  TETUM = "TETUM",
  TAJIK = "TAJIK",
  TIBETAN = "TIBETAN",
  TIGRE = "TIGRE",
  TIGRINYA = "TIGRINYA",
  TIV = "TIV",
  TOKELAU = "TOKELAU",
  KLINGON = "KLINGON",
  TLINGIT = "TLINGIT",
  TAMASHEK = "TAMASHEK",
  NYASA_TONGA = "NYASA_TONGA",
  TONGAN = "TONGAN",
  TOK_PISIN = "TOK_PISIN",
  TSIMSHIAN = "TSIMSHIAN",
  TSWANA = "TSWANA",
  TSONGA = "TSONGA",
  TURKMEN = "TURKMEN",
  TUMBUKA = "TUMBUKA",
  TUPI_LANGUAGES = "TUPI_LANGUAGES",
  ALTAIC_LANGUAGES = "ALTAIC_LANGUAGES",
  TUVALU = "TUVALU",
  TWI = "TWI",
  TUVINIAN = "TUVINIAN",
  UDMURT = "UDMURT",
  UGARITIC = "UGARITIC",
  UYGHUR = "UYGHUR",
  UMBUNDU = "UMBUNDU",
  UNKNOWN_LANGUAGE = "UNKNOWN_LANGUAGE",
  UZBEK = "UZBEK",
  VAI = "VAI",
  VENDA = "VENDA",
  VOLAPUK = "VOLAPUK",
  VOTIC = "VOTIC",
  WAKASHAN_LANGUAGES = "WAKASHAN_LANGUAGES",
  WOLAYTTA = "WOLAYTTA",
  WARAY = "WARAY",
  WASHO = "WASHO",
  WELSH = "WELSH",
  SORBIAN_LANGUAGES = "SORBIAN_LANGUAGES",
  WALLOON = "WALLOON",
  WOLOF = "WOLOF",
  KALMYK = "KALMYK",
  XHOSA = "XHOSA",
  YAO = "YAO",
  YAPESE = "YAPESE",
  YIDDISH = "YIDDISH",
  YORUBA = "YORUBA",
  YUPIK_LANGUAGES = "YUPIK_LANGUAGES",
  ZAPOTEC = "ZAPOTEC",
  BLISSYMBOLS = "BLISSYMBOLS",
  ZENAGA = "ZENAGA",
  STANDARD_MOROCCAN_TAMAZIGHT = "STANDARD_MOROCCAN_TAMAZIGHT",
  ZHUANG = "ZHUANG",
  ZANDE_LANGUAGES = "ZANDE_LANGUAGES",
  ZULU = "ZULU",
  ZUNI = "ZUNI",
  ZAZA = "ZAZA",
  PRIMARY_LANGUAGE_OTHER = "PRIMARY_LANGUAGE_OTHER",
  TURKISH = "TURKISH",
  NORWEGIAN = "NORWEGIAN",
  AFRIKAANS = "AFRIKAANS",
  ARMENIAN = "ARMENIAN",
  AZERBAIJANI = "AZERBAIJANI",
  MALAY = "MALAY",
  SWEDISH = "SWEDISH",
  THAI = "THAI",
}

export enum Residence {
  RESIDENCE_ENUM_UNSPECIFIED = "RESIDENCE_ENUM_UNSPECIFIED",
  LIVE_ALONE = "LIVE_ALONE",
  LIVE_WITH_SPOUSE = "LIVE_WITH_SPOUSE",
  LIVE_WITH_FAMILY = "LIVE_WITH_FAMILY",
  ASSISTED_LIVING = "ASSISTED_LIVING",
}

export enum Accommodation {
  ACCOMMODATION_UNSPECIFIED = "ACCOMMODATION_UNSPECIFIED",
  HARD_OF_HEARING = "HARD_OF_HEARING",
  SIGHT_ISSUES = "SIGHT_ISSUES",
  LANGUAGE_INTERPRETER = "LANGUAGE_INTERPRETER",
  ACCOMMODATION_OTHER = "ACCOMMODATION_OTHER",
}

export enum EnrollmentType {
  ENROLLMENT_TYPE_UNSPECIFIED = "ENROLLMENT_TYPE_UNSPECIFIED",
  VIRTUAL = "VIRTUAL",
  CLINIC = "CLINIC",
}

export enum CareProviderRole {
  CARE_PROVIDER_ROLE_UNSPECIFIED = "CARE_PROVIDER_ROLE_UNSPECIFIED",
  MD = "MD",
  DO = "DO",
  NP = "NP",
  RN = "RN",
  MA = "MA",
  PA = "PA",
  OTHER = "OTHER",
  LPN = "LPN",
}

export enum AcuityAssessmentReason {
  REASON_UNSPECIFIED = "REASON_UNSPECIFIED",
  MAINTAIN = "MAINTAIN",
  CHANGE_IN_VITALS = "CHANGE_IN_VITALS",
  ER_VISIT = "ER_VISIT",
  SYMPTOMATIC_RED_ALERT = "SYMPTOMATIC_RED_ALERT",
  NEW_SYMPTOMS = "NEW_SYMPTOMS",
  CHANGE_IN_FUNCTIONAL_STATUS = "CHANGE_IN_FUNCTIONAL_STATUS",
  OTHER = "OTHER",
}

export enum ContactDetailsContactType {
  CONTACT_TYPE_UNSPECIFIED = "CONTACT_TYPE_UNSPECIFIED",
  CONTACT_TYPE_PATIENT = "CONTACT_TYPE_PATIENT",
  CONTACT_TYPE_PROSPECTIVE_PATIENT = "CONTACT_TYPE_PROSPECTIVE_PATIENT",
  CONTACT_TYPE_CAREGIVER = "CONTACT_TYPE_CAREGIVER",
}

export enum ExternalAppointmentStatus {
  STATUS_UNSPECIFIED = "STATUS_UNSPECIFIED",
  OPEN = "OPEN",
  CANCELLED = "CANCELLED",
  FUTURE = "FUTURE",
  CHECKED_IN = "CHECKED_IN",
  CHECKED_OUT = "CHECKED_OUT",
  CHARGE_ENTERED = "CHARGE_ENTERED",
}

export enum PatientExternalProviderType {
  TYPE_UNSPECIFIED = "TYPE_UNSPECIFIED",
  USUAL = "USUAL",
  REFERRING = "REFERRING",
  IDENTIFIED = "IDENTIFIED",
  IDENTIFIED_CARDIOLOGIST = "IDENTIFIED_CARDIOLOGIST",
}

export enum PatientExternalProviderProviderEligibilityStatus {
  PROVIDER_ELIGIBILITY_STATUS_UNSPECIFIED = "PROVIDER_ELIGIBILITY_STATUS_UNSPECIFIED",
  NOT_ELIGIBLE = "NOT_ELIGIBLE",
  ELIGIBLE = "ELIGIBLE",
  PENDED = "PENDED",
}

export enum RPMOrderOrderStatus {
  ORDER_STATUS_UNSPECIFIED = "ORDER_STATUS_UNSPECIFIED",
  PENDED = "PENDED",
  APPROVED = "APPROVED",
  DENIED = "DENIED",
  DELETED = "DELETED",
  SKIPPED = "SKIPPED",
}

export enum RPMOrderOrderError {
  ORDER_ERROR_UNSPECIFIED = "ORDER_ERROR_UNSPECIFIED",
  PATIENT_DISENROLLED = "PATIENT_DISENROLLED",
  PATIENT_DX_OVERWRITE_FAILED = "PATIENT_DX_OVERWRITE_FAILED",
  PATIENT_HAS_APPLIED_ORDER = "PATIENT_HAS_APPLIED_ORDER",
  PATIENT_PROFILE_CREATION_FAILED = "PATIENT_PROFILE_CREATION_FAILED",
  HOSPITAL_CREATION_FAILED = "HOSPITAL_CREATION_FAILED",
  ICD10_LIST_FAILED_ELIGIBILITY_CHECK = "ICD10_LIST_FAILED_ELIGIBILITY_CHECK",
  ICD10_LIST_MULTIPLE_CONDITIONS = "ICD10_LIST_MULTIPLE_CONDITIONS",
  FEATURE_FLAG_PREVENTS_PATIENT_CREATION = "FEATURE_FLAG_PREVENTS_PATIENT_CREATION",
  FEATURE_FLAG_PREVENTS_APPLICATION = "FEATURE_FLAG_PREVENTS_APPLICATION",
  ORDER_STATUS_TRANSITION_NOT_SUPPORTED = "ORDER_STATUS_TRANSITION_NOT_SUPPORTED",
  EHR_PROVIDER_NOT_FOUND = "EHR_PROVIDER_NOT_FOUND",
  PROVIDER_MISSING_NPI = "PROVIDER_MISSING_NPI",
  PROVIDER_CREATION_FAILED = "PROVIDER_CREATION_FAILED",
  PROVIDER_STATUS_NOT_ONBOARDED = "PROVIDER_STATUS_NOT_ONBOARDED",
  PATIENT_HAS_DIFFERENT_PROVIDER = "PATIENT_HAS_DIFFERENT_PROVIDER",
  ORDER_COPIED_FROM_OTHER_PROFILE = "ORDER_COPIED_FROM_OTHER_PROFILE",
}

export enum AddressRegion {
  REGION_UNSPECIFIED = "REGION_UNSPECIFIED",
  AL = "AL",
  AK = "AK",
  AZ = "AZ",
  AR = "AR",
  CA = "CA",
  CO = "CO",
  CT = "CT",
  DE = "DE",
  FL = "FL",
  GA = "GA",
  HI = "HI",
  ID = "ID",
  IL = "IL",
  IN = "IN",
  IA = "IA",
  KS = "KS",
  KY = "KY",
  LA = "LA",
  ME = "ME",
  MD = "MD",
  MA = "MA",
  MI = "MI",
  MN = "MN",
  MS = "MS",
  MO = "MO",
  MT = "MT",
  NE = "NE",
  NV = "NV",
  NH = "NH",
  NJ = "NJ",
  NM = "NM",
  NY = "NY",
  NC = "NC",
  ND = "ND",
  OH = "OH",
  OK = "OK",
  OR = "OR",
  PA = "PA",
  RI = "RI",
  SC = "SC",
  SD = "SD",
  TN = "TN",
  TX = "TX",
  UT = "UT",
  VT = "VT",
  VA = "VA",
  WA = "WA",
  WV = "WV",
  WI = "WI",
  WY = "WY",
}

export enum ContactRelationship {
  RELATIONSHIP_ENUM_UNSPECIFIED = "RELATIONSHIP_ENUM_UNSPECIFIED",
  ASSISTED_LIVING = "ASSISTED_LIVING",
  AUNT = "AUNT",
  BROTHER = "BROTHER",
  BROTHER_IN_LAW = "BROTHER_IN_LAW",
  COUSIN = "COUSIN",
  DAUGHTER = "DAUGHTER",
  FATHER = "FATHER",
  FATHER_IN_LAW = "FATHER_IN_LAW",
  FORMER_SPOUSE = "FORMER_SPOUSE",
  FRIEND = "FRIEND",
  GRANDFATHER = "GRANDFATHER",
  GRANDMOTHER = "GRANDMOTHER",
  GREAT_GRANDPAREN = "GREAT_GRANDPAREN",
  LEGAL_GUARDIAN = "LEGAL_GUARDIAN",
  LEGALLY_SEPARATED_SPOUSE = "LEGALLY_SEPARATED_SPOUSE",
  MENTAL_HEALTH_CONTACT = "MENTAL_HEALTH_CONTACT",
  MOTHER = "MOTHER",
  MOTHER_IN_LAW = "MOTHER_IN_LAW",
  NEPHEW = "NEPHEW",
  NIECE = "NIECE",
  PARTNER = "PARTNER",
  SISTER = "SISTER",
  SISTER_IN_LAW = "SISTER_IN_LAW",
  SON = "SON",
  SPOUSE = "SPOUSE",
  STEPBROTHER = "STEPBROTHER",
  STEPDAUGHTER = "STEPDAUGHTER",
  STEPFATHER = "STEPFATHER",
  STEPMOTHER = "STEPMOTHER",
  STEPSISTER = "STEPSISTER",
  STEPSON = "STEPSON",
  UNCLE = "UNCLE",
  RELATIONSHIP_OTHER = "RELATIONSHIP_OTHER",
  GRANDSON = "GRANDSON",
  GRANDDAUGHTER = "GRANDDAUGHTER",
  SON_IN_LAW = "SON_IN_LAW",
  DAUGHTER_IN_LAW = "DAUGHTER_IN_LAW",
}

export enum ContactPhoneType {
  PHONE_TYPE_ENUM_UNSPECIFIFED = "PHONE_TYPE_ENUM_UNSPECIFIFED",
  LANDLINE = "LANDLINE",
  CELLULAR = "CELLULAR",
}

export enum PatientProgramEnum {
  PROGRAM_ENUM_UNSPECIFIED = "PROGRAM_ENUM_UNSPECIFIED",
  HEART_FAILURE = "HEART_FAILURE",
  HYPERTENSION = "HYPERTENSION",
  COPD = "COPD",
  TYPE_2_DIABETES = "TYPE_2_DIABETES",
}

export enum PatientSelectedCareProviderConfidence {
  SELECTED_CARE_PROVIDER_CONFIDENCE_UNSPECIFIED = "SELECTED_CARE_PROVIDER_CONFIDENCE_UNSPECIFIED",
  HIGH = "HIGH",
  MEDIUM = "MEDIUM",
  LOW = "LOW",
}

export enum StatusNoteReason {
  REASON_UNSPECIFIED = "REASON_UNSPECIFIED",
  INSURANCE = "INSURANCE",
  CLINICAL = "CLINICAL",
  PROVIDER_DECLINED = "PROVIDER_DECLINED",
  PATIENT_DECLINED = "PATIENT_DECLINED",
  FINANCIAL_CONCERNS = "FINANCIAL_CONCERNS",
  ENROLLED_IN_ALTERNATE_PROGRAM = "ENROLLED_IN_ALTERNATE_PROGRAM",
  DECEASED = "DECEASED",
  DOCTOR_RECOMMENDED_DISENROLLMENT = "DOCTOR_RECOMMENDED_DISENROLLMENT",
  LACK_OF_CONNECTIVITY = "LACK_OF_CONNECTIVITY",
  LACK_OF_PARTICIPATION = "LACK_OF_PARTICIPATION",
  LEAVING_THE_PRACTICE = "LEAVING_THE_PRACTICE",
  MOVED_TO_HOSPICE_PALLIATIVE_CARE = "MOVED_TO_HOSPICE_PALLIATIVE_CARE",
  PRIVACY_CONCERNS = "PRIVACY_CONCERNS",
  UNABLE_TO_SAFELY_USE_EQUIPMENT = "UNABLE_TO_SAFELY_USE_EQUIPMENT",
  AT_DISCHARGE = "AT_DISCHARGE",
  IN_CLINIC = "IN_CLINIC",
  RE_ENROLLED = "RE_ENROLLED",
  HYPERTENSION = "HYPERTENSION",
  CONGESTIVE_HEART_FAILURE = "CONGESTIVE_HEART_FAILURE",
  TYPE_2_DIABETES = "TYPE_2_DIABETES",
  CLINIC_NO_SHOW = "CLINIC_NO_SHOW",
  CADENCE_APPOINTMENT_NO_SHOW = "CADENCE_APPOINTMENT_NO_SHOW",
  NOT_INTERESTED = "NOT_INTERESTED",
  NO_CELL_PHONE = "NO_CELL_PHONE",
  FINANCIAL_REASONS = "FINANCIAL_REASONS",
  OTHER = "OTHER",
  ACUTE_VISIT = "ACUTE_VISIT",
  UNHAPPY_WITH_DEVICES = "UNHAPPY_WITH_DEVICES",
  PATIENT_IS_STABLE = "PATIENT_IS_STABLE",
  NO_MENTION_OF_CADENCE = "NO_MENTION_OF_CADENCE",
  RESCHEDULED = "RESCHEDULED",
  PATIENT_STILL_CONSIDERING = "PATIENT_STILL_CONSIDERING",
  CAUSED_STRESS = "CAUSED_STRESS",
  NAB_ORDERED = "NAB_ORDERED",
  VIRTUAL = "VIRTUAL",
  NAB_ORDERS_CANDIDATE = "NAB_ORDERS_CANDIDATE",
  PENDING_ORDER_FAILED = "PENDING_ORDER_FAILED",
  APPOINTMENT_BASED_ORDER = "APPOINTMENT_BASED_ORDER",
  ABANDONED_ENROLLMENT = "ABANDONED_ENROLLMENT",
  ENGAGEMENT_FATIGUE = "ENGAGEMENT_FATIGUE",
  PREFER_SELF_MANAGE = "PREFER_SELF_MANAGE",
  PREFER_PHYSICIAN_MANAGE = "PREFER_PHYSICIAN_MANAGE",
  PATIENT_IS_A_SNOWBIRD = "PATIENT_IS_A_SNOWBIRD",
  TOO_BUSY = "TOO_BUSY",
  NO_LONGER_A_PATIENT_OF_THE_PROVIDER = "NO_LONGER_A_PATIENT_OF_THE_PROVIDER",
}

export enum PatientStatusEnum {
  ENUM_UNSPECIFIED = "ENUM_UNSPECIFIED",
  IDENTIFIED = "IDENTIFIED",
  ELIGIBLE = "ELIGIBLE",
  NOT_ELIGIBLE = "NOT_ELIGIBLE",
  PENDING = "PENDING",
  PENDING_ORDER = "PENDING_ORDER",
  PENDING_ENROLLMENT = "PENDING_ENROLLMENT",
  NEEDS_REVIEW = "NEEDS_REVIEW",
  ENROLLED = "ENROLLED",
  NOT_ENROLLED = "NOT_ENROLLED",
  DISENROLLED = "DISENROLLED",
  PROVIDER_DECLINED = "PROVIDER_DECLINED",
  PATIENT_DECLINED = "PATIENT_DECLINED",
  WAITING_FOR_PROVIDER = "WAITING_FOR_PROVIDER",
  READY_TO_ENROLL = "READY_TO_ENROLL",
  APPROVED = "APPROVED",
  ORDER_PENDED = "ORDER_PENDED",
  DUPLICATE = "DUPLICATE",
  DELETED = "DELETED",
}

export enum LegacyOrderOrderStatus {
  ORDER_STATUS_UNSPECIFIED = "ORDER_STATUS_UNSPECIFIED",
  PENDED = "PENDED",
  APPROVED = "APPROVED",
  DENIED = "DENIED",
  SKIPPED = "SKIPPED",
}

export enum LegacyOrderOrderSource {
  ORDER_SOURCE_UNSPECIFIED = "ORDER_SOURCE_UNSPECIFIED",
  UNKNOWN = "UNKNOWN",
  APPOINTMENT = "APPOINTMENT",
  INBOX = "INBOX",
  APP = "APP",
}

export enum LegacyOrderOrderCreationType {
  ORDER_CREATION_TYPE_UNSPECIFIED = "ORDER_CREATION_TYPE_UNSPECIFIED",
  MANUAL = "MANUAL",
  AUTOMATED = "AUTOMATED",
}

export enum PatientDeviceProvisioningStatus {
  PROVISIONING_STATUS_UNSPECIFIED = "PROVISIONING_STATUS_UNSPECIFIED",
  SUCCESS = "SUCCESS",
  INVALID_DEVICE_ID = "INVALID_DEVICE_ID",
  API_ERROR = "API_ERROR",
  SERVER_ERROR = "SERVER_ERROR",
  UNKNOWN_ERROR = "UNKNOWN_ERROR",
  USER_PROVISIONING_ERROR = "USER_PROVISIONING_ERROR",
  NOTIFICATION_SUBSCRIPTION_ERROR = "NOTIFICATION_SUBSCRIPTION_ERROR",
}

export enum PatientDeviceDeviceType {
  DEVICE_TYPE_UNSPECIFIED = "DEVICE_TYPE_UNSPECIFIED",
  WEIGHT_SCALE = "WEIGHT_SCALE",
  BLOOD_PRESSURE_METER = "BLOOD_PRESSURE_METER",
  BLOOD_GLUCOSE_METER = "BLOOD_GLUCOSE_METER",
  BLOOD_PRESSURE_CUFF_EXTENDER = "BLOOD_PRESSURE_CUFF_EXTENDER",
}

export enum PatientDeviceProvider {
  PROVIDER_UNSPECIFIED = "PROVIDER_UNSPECIFIED",
  BODYTRACE = "BODYTRACE",
  BIOTEL = "BIOTEL",
  SMART_METER = "SMART_METER",
  WITHINGS = "WITHINGS",
  TRANSTEK = "TRANSTEK",
}

export type ListPatientAllergiesRequest = {
  parent?: string
  pageSize?: number
  pageToken?: string
  includeInactive?: boolean
}

export type GetPatientBestThreeConditionsFromProblemListRequest = {
  name?: string
}

export type BestThreeConditionsResponse = {
  conditions?: ConditionEntry[]
}

export type ConditionEntry = {
  conditionType?: Condition
  diagnosisCode?: DiagnosisCode
}

export type PatientConditionPair = {
  patientId?: string
  conditionId?: string
}

export type BatchUpdatePatientSelectedProgramConditionsRequest = {
  patientsConditions?: PatientConditionPair[]
}

export type LookupPatientsByAthenaEhrInfoRequest = {
  athenaPracticeId?: string
  mrn?: string
}

export type LookupPatientsByEpicEhrInfoRequest = {
  instanceId?: number
  mrn?: string
}

export type LookupPatientsByAthenaEhrInfoResponse = {
  patients?: EmbeddedAppPatient[]
}

export type LookupPatientsByEpicEhrInfoResponse = {
  patients?: EmbeddedAppPatient[]
}

export type EmbeddedAppPatient = {
  name?: string
  status?: PatientStatusEnum
}

export type GetPatientContextRequest = {
  name?: string
  readMask?: GoogleProtobufField_mask.FieldMask
}

export type PatientContextPatientContextMedicationPatientContextMedicationReason = {
  healthTeam?: boolean
  medical?: boolean
  patientCentered?: boolean
  systemBased?: boolean
}

export type PatientContextPatientContextMedication = {
  name?: string
  dose?: string
  frequency?: string
  titration?: string
  titrationDate?: GoogleTypeDate.Date
  titrationEndDate?: GoogleTypeDate.Date
  isTargetDose?: boolean
  isMaxToleratedDose?: boolean
  previousDose?: string
  titrationReason?: PatientContextPatientContextMedicationPatientContextMedicationReason
  reasonNotOnTargetDose?: PatientContextPatientContextMedicationPatientContextMedicationReason
  reasonNotOnMaxToleratedDose?: PatientContextPatientContextMedicationPatientContextMedicationReason
  medClass?: string
}

export type PatientContext = {
  name?: string
  givenName?: string
  familyName?: string
  dob?: GoogleTypeDate.Date
  gender?: Gender
  conditions?: Condition[]
  rpmConsentDateTime?: GoogleProtobufTimestamp.Timestamp
  primaryPhysicianGivenName?: string
  primaryPhysicianFamilyName?: string
  clinicName?: string
  medications?: PatientContextPatientContextMedication[]
  timeZone?: string
  primaryPhysicianRole?: CareProviderRole
  externalCareProviders?: PatientExternalProvider[]
}

export type AcuityAssessment = {
  name?: string
  acuity?: Acuity
  reasons?: AcuityAssessmentReason[]
  description?: string
  createTime?: GoogleProtobufTimestamp.Timestamp
  careProvider?: string
}

export type Encounter = {
  name?: string
  careProvider?: string
  type?: CadenceModelsV1Models.EncounterType
  startTime?: GoogleProtobufTimestamp.Timestamp
}

export type EncounterSuggestion = {
  suggestedTime?: GoogleProtobufTimestamp.Timestamp
  type?: CadenceModelsV1Models.EncounterType
}

export type ListPatientDiagnosisCodeConditionsRequest = {
  parent?: string
  pageSize?: number
  pageToken?: string
  readMask?: GoogleProtobufField_mask.FieldMask
  filter?: string
  orderBy?: string
}

export type ListEncountersRequest = {
  parent?: string
  pageSize?: number
  pageToken?: string
  readMask?: GoogleProtobufField_mask.FieldMask
  filter?: string
  orderBy?: string
}

export type ListEncountersResponse = {
  encounters?: Encounter[]
  nextPageToken?: string
}

export type ListAcuityAssessmentsResponse = {
  acuityAssessments?: AcuityAssessment[]
  nextPageToken?: string
}

export type ListPatientDiagnosisCodeConditionsResponse = {
  diagnosisCodeConditions?: DiagnosisCodeCondition[]
  nextPageToken?: string
}

export type ListPatientAllergiesResponse = {
  allergies?: PatientAllergy[]
  nextPageToken?: string
}

export type ListAcuityAssessmentsRequest = {
  parent?: string
  pageSize?: number
  pageToken?: string
  readMask?: GoogleProtobufField_mask.FieldMask
}

export type CreateAcuityAssessmentRequest = {
  parent?: string
  acuity_assessment?: AcuityAssessment
}

export type SuggestEncountersRequest = {
  parent?: string
}

export type SuggestEncountersResponse = {
  suggestion?: EncounterSuggestion
}

export type ListAllPatientsPreviewsRequest = {
  pageSize?: number
  pageToken?: string
  readMask?: GoogleProtobufField_mask.FieldMask
  filter?: string
  orderBy?: string
}

export type ListAllPatientsPreviewsResponse = {
  patients?: PatientPreview[]
  nextPageToken?: string
  totalSize?: number
}

export type ListProspectivePatientsRequest = {
  pageSize?: number
  pageToken?: string
  readMask?: GoogleProtobufField_mask.FieldMask
  filter?: string
  orderBy?: string
}

export type ListProspectivePatientsResponse = {
  patients?: Patient[]
  nextPageToken?: string
  totalSize?: number
}

export type ListAllPatientsRequest = {
  pageSize?: number
  pageToken?: string
  readMask?: GoogleProtobufField_mask.FieldMask
  filter?: string
  orderBy?: string
}

export type ListAllPatientsResponse = {
  patients?: Patient[]
  nextPageToken?: string
  totalSize?: number
}

export type PatientPreview = {
  createTime?: GoogleProtobufTimestamp.Timestamp
  updateTime?: GoogleProtobufTimestamp.Timestamp
  name?: string
  givenName?: string
  familyName?: string
  dob?: GoogleTypeDate.Date
  mrn?: string
  ehr?: CadenceModelsV1Models.EHR
  status?: PatientStatusEnum
  hospital?: string
}

export type PatientDetails = {
  name?: string
  patient?: PatientBase
  addresses?: Address[]
  rpmOrders?: RPMOrder[]
  contacts?: Contact[]
  programs?: Program[]
  externalAppointments?: ExternalAppointment[]
  externalCareProviders?: PatientExternalProvider[]
  ehrInformation?: CadenceModelsV1Models.PatientEhrInfo
  dxInfo?: PatientDxInfo
  allEhrInformation?: CadenceModelsV1Models.PatientEhrInfo[]
  synchronizationData?: SynchronizationData[]
  programStatuses?: PatientProgramStatus[]
}

export type SynchronizationData = {
  scope?: GoPmsPkgPatientSynchronizationSynchronization.EhrSyncTaskRequestScope
  syncTime?: GoogleProtobufTimestamp.Timestamp
}

export type PatientEhrDetails = {
  name?: string
  ehrInformation?: CadenceModelsV1Models.PatientEhrInfo
  allEhrInformation?: CadenceModelsV1Models.PatientEhrInfo[]
}

export type PatientDxInfo = {
  rpmDxSuggestedDate?: GoogleTypeDate.Date
  rpmDxCorrectedDate?: GoogleTypeDate.Date
}

export type ContactDetails = {
  patientId?: string
  timeZone?: string
  patientName?: string
  caregiverName?: string
  caregiverId?: string
  patientZendeskId?: string
  contactType?: ContactDetailsContactType
  programType?: ProgramType
}

export type ExternalAppointment = {
  name?: string
  hospital?: CadenceModelsV1Models.Hospital
  careProvider?: CadenceModelsV1Models.CareProvider
  appointmentDatetime?: GoogleProtobufTimestamp.Timestamp
  appointmentType?: string
  athenaAppointmentId?: string
  athenaEncounterId?: string
  appointmentDurationMin?: number
  appointmentStatus?: ExternalAppointmentStatus
  notes?: string[]
}

export type PatientExternalProvider = {
  careProviderType?: PatientExternalProviderType
  careProvider?: CadenceModelsV1Models.CareProvider
  eligibilityStatus?: PatientExternalProviderProviderEligibilityStatus
  careProviderConfidence?: PatientSelectedCareProviderConfidence
}

export type PatientBase = {
  givenName?: string
  familyName?: string
  dob?: GoogleTypeDate.Date
  status?: PatientStatusEnum
  timeZone?: string
  zendeskId?: GoogleProtobufWrappers.StringValue
  hasCaregiver?: boolean
  email?: string
  race?: Race
  gender?: Gender
  primaryLanguage?: PrimaryLanguage
  residence?: Residence
  residenceOther?: string
  rpmConsentDate?: GoogleProtobufTimestamp.Timestamp
  ccmConsentDate?: GoogleProtobufTimestamp.Timestamp
  medManagementDelegation?: CadenceModelsV1Models.MedManagementDelegation
  emergencyContactDisabled?: boolean
  accommodations?: Accommodation[]
  accommodationsOther?: string
  notificationSettings?: NotificationSettings
  patientType?: string
  enrollmentType?: EnrollmentType
  lastEhrSyncDate?: GoogleProtobufTimestamp.Timestamp
  isTest?: boolean
  vitalsRemindersTimeLocal?: string
  activationDate?: GoogleTypeDate.Date
  npiId?: GoogleProtobufWrappers.Int32Value
}

export type RPMOrder = {
  id?: string
  applied?: boolean
  status?: RPMOrderOrderStatus
  ehrCreatedAt?: GoogleProtobufTimestamp.Timestamp
  icd10Codes?: GoogleProtobufWrappers.StringValue
  careProvider?: CadenceModelsV1Models.CareProvider
  athenaOrderId?: string
  epicOrderId?: string
  cernerOrderId?: string
  encounterFhirId?: string
  errors?: RPMOrderOrderError[]
  ehrOrderNote?: GoogleProtobufWrappers.StringValue
  ehrOrderStatus?: GoogleProtobufWrappers.StringValue
}

export type Address = {
  country?: string
  fullName?: string
  locality?: string
  postalCode?: string
  premise?: string
  region?: AddressRegion
  streetAddress?: string
  primary?: boolean
}

export type Contact = {
  emergency?: boolean
  primary?: boolean
  relationship?: ContactRelationship
  name?: string
  phoneNumber?: string
  phoneType?: ContactPhoneType
}

export type Patient = {
  createTime?: GoogleProtobufTimestamp.Timestamp
  creator?: string
  updateTime?: GoogleProtobufTimestamp.Timestamp
  updater?: string
  name?: string
  referringProviderNpiId?: GoogleProtobufWrappers.Int32Value
  givenName?: string
  familyName?: string
  dob?: GoogleTypeDate.Date
  gender?: Gender
  race?: Race
  email?: string
  timeZone?: string
  status?: PatientStatusEnum
  rpmConsentDate?: GoogleTypeDate.Date
  isCadencePended?: boolean
  isPrepped?: GoogleProtobufWrappers.BoolValue
  isTest?: boolean
  rpmOrdered?: boolean
  mrn?: string
  ehr?: CadenceModelsV1Models.EHR
  hospital?: string
  eplListIds?: GoogleProtobufWrappers.Int64Value[]
  programs?: PatientProgramEnum[]
  diagnosisCodes?: {[key: string]: DiagnosisCode}
  diagnosisCodesByConditionType?: DiagnosisCodesByConditionType
  bmi?: GoogleProtobufWrappers.DoubleValue
  upcomingAppointmentDuration?: GoogleProtobufWrappers.Int32Value
  lastClinicApptTime?: GoogleProtobufTimestamp.Timestamp
  lastClinicApptDepartment?: GoogleProtobufWrappers.StringValue
  lastClinicApptProvider?: GoogleProtobufWrappers.StringValue
  primaryInsurance?: GoogleProtobufWrappers.StringValue
  secondaryInsurance?: GoogleProtobufWrappers.StringValue
  usualProvider?: GoogleProtobufWrappers.StringValue
  usualProviderNpi?: GoogleProtobufWrappers.StringValue
  schedulingCareProvider?: GoogleProtobufWrappers.StringValue
  visitedEmergencyDepartment?: GoogleProtobufWrappers.BoolValue
  zendeskId?: GoogleProtobufWrappers.StringValue
  selectedCareProviderConfidence?: PatientSelectedCareProviderConfidence
  statusNotes?: StatusNote[]
  isProspective?: boolean
  latestRpmOrderStatusNote?: RpmOrderStatusNote
  ccmConsentDate?: GoogleTypeDate.Date
  medications?: Medication[]
  observations?: Observations
  identifiedProvider?: GoogleProtobufWrappers.StringValue
  referringProvider?: GoogleProtobufWrappers.StringValue
  careProviders?: string[]
  identifiedProviderNpiId?: GoogleProtobufWrappers.Int32Value
  addressRegion?: GoogleProtobufWrappers.StringValue
  lastStatusNoteReason?: StatusNoteReason
  lastStatusNoteChangeDate?: GoogleTypeDate.Date
  upcomingAppointmentTime?: GoogleProtobufTimestamp.Timestamp
  chfPrimaryIcd10Code?: GoogleProtobufWrappers.StringValue
  htnPrimaryIcd10Code?: GoogleProtobufWrappers.StringValue
  t2dPrimaryIcd10Code?: GoogleProtobufWrappers.StringValue
  orderDate?: GoogleTypeDate.Date
  isDuplicate?: boolean
  latestEplListGenerationTime?: GoogleProtobufTimestamp.Timestamp
  isAcuityUncontrolled?: boolean
  fullName?: string
  hospitalId?: GoogleProtobufWrappers.StringValue
  eligiblePatientListsPendStatuses?: EligiblePatientListPendStatus[]
  enrollmentScore?: GoogleProtobufWrappers.DoubleValue
  program?: Program
  hasHighConfidenceProgram?: boolean
  patientTier?: GoogleProtobufWrappers.StringValue
  programStatuses?: PatientProgramStatus[]
}

export type StatusNote = {
  description?: string
  statusTo?: PatientStatusEnum
  createTime?: GoogleProtobufTimestamp.Timestamp
  reasons?: StatusNoteReason[]
  statusChangeDate?: GoogleTypeDate.Date
  programType?: ProgramType
}

export type Medication = {
  name?: string
}

export type RpmOrderStatusNoteICD10Codes = {
  codes?: string[]
  detailedCodes?: RpmOrderStatusNoteDetailedCode[]
}

export type RpmOrderStatusNoteDetailedCode = {
  code?: string
  type?: string
  description?: string
}

export type RpmOrderStatusNoteIgnorableError = {
  orderConditions?: string[]
  patientConditions?: string[]
  error?: string
}

export type RpmOrderStatusNote = {
  noteId?: number
  providerLastName?: string
  providerFirstName?: string
  ehrCreateTime?: GoogleProtobufTimestamp.Timestamp
  createTime?: GoogleProtobufTimestamp.Timestamp
  icd10Codes?: RpmOrderStatusNoteICD10Codes
  ignorableErrorList?: RpmOrderStatusNoteIgnorableError[]
  reasons?: string[]
  statusChangeDate?: GoogleTypeDate.Date
  description?: string
  statusTo?: PatientStatusEnum
}

export type PatientStatus = {
}

export type LegacyOrder = {
  ehr?: CadenceModelsV1Models.EHR
  status?: LegacyOrderOrderStatus
  orderedTime?: GoogleProtobufTimestamp.Timestamp
  ehrCreatedAt?: GoogleProtobufTimestamp.Timestamp
  icd10Codes?: string[]
  mrn?: string
  healthSystemId?: number
  npi?: string
  epicOrderId?: string
  epicProviderId?: string
  epicDepartmentId?: string
  cernerOrderId?: string
  cernerProviderId?: string
  cernerDepartmentId?: string
  instanceId?: number
  encounterFhirId?: string
  source?: LegacyOrderOrderSource
  creationType?: LegacyOrderOrderCreationType
}

export type LegacyUpdateOrderRequest = {
  data?: LegacyOrder[]
}

export type DiagnosisCode = {
  code?: string
  codeDescription?: string
  category?: string
  etiology?: string
}

export type DiagnosisCodesByConditionType = {
  chf?: string[]
  htn?: string[]
  t2d?: string[]
  other?: string[]
}

export type Observations = {
  efLower?: GoogleProtobufWrappers.DoubleValue
  efUpper?: GoogleProtobufWrappers.DoubleValue
  chfClass?: GoogleProtobufWrappers.StringValue
  chfStage?: GoogleProtobufWrappers.StringValue
  a1c?: GoogleProtobufWrappers.DoubleValue
  a1cUri?: GoogleProtobufWrappers.StringValue
  echoUri?: GoogleProtobufWrappers.StringValue
  lastBloodPressureAverageSystolic?: GoogleProtobufWrappers.DoubleValue
  lastBloodPressureAverageDiastolic?: GoogleProtobufWrappers.DoubleValue
  lastBloodPressureMaxSystolic?: GoogleProtobufWrappers.DoubleValue
  lastBloodPressureMaxDiastolic?: GoogleProtobufWrappers.DoubleValue
  lastBloodPressureSystolic?: GoogleProtobufWrappers.DoubleValue
  lastBloodPressureDiastolic?: GoogleProtobufWrappers.DoubleValue
}

export type DiagnosisCodeCondition = {
  name?: string
  conditionType?: Condition
  category?: string
  etiology?: GoogleProtobufWrappers.StringValue
  description?: GoogleProtobufWrappers.StringValue
  snomedCode?: GoogleProtobufWrappers.StringValue
}

export type ProgramCondition = {
  name?: string
  conditionType?: Condition
  category?: string
  etiology?: GoogleProtobufWrappers.StringValue
  description?: GoogleProtobufWrappers.StringValue
  snomedCode?: GoogleProtobufWrappers.StringValue
  conditionConfidence?: ConditionConfidence
}

export type ProgramConditionWithMatchType = {
  name?: string
  conditionType?: Condition
  category?: string
  etiology?: GoogleProtobufWrappers.StringValue
  description?: GoogleProtobufWrappers.StringValue
  snomedCode?: GoogleProtobufWrappers.StringValue
  conditionConfidence?: ConditionConfidence
  matchType?: ProgramConditionMatchType
}

export type ProgramConditionHistory = {
  name?: string
  conditionType?: Condition
  category?: string
  etiology?: GoogleProtobufWrappers.StringValue
  description?: GoogleProtobufWrappers.StringValue
  snomedCode?: GoogleProtobufWrappers.StringValue
  conditionConfidence?: ConditionConfidence
  operation?: GoogleProtobufWrappers.StringValue
  operationTime?: GoogleProtobufTimestamp.Timestamp
}

export type Programs = {
  programs?: Program[]
}

export type Program = {
  name?: string
  programType?: ProgramType
  programStatus?: ProgramStatus
  conditions?: ProgramCondition[]
  conditionHistory?: ProgramConditionHistory[]
}

export type EligiblePatientListPendStatus = {
  eplId?: string
  orderId?: GoogleProtobufWrappers.StringValue
  errorDescription?: GoogleProtobufWrappers.StringValue
}

export type GetPatientConditionsPerProgramRequest = {
  name?: string
}

export type FindProspectivePatientForEhrDetailsRequest = {
  mrn?: GoogleProtobufWrappers.StringValue
  ehr?: CadenceModelsV1Models.EHR
  ehrHospitalId?: GoogleProtobufWrappers.StringValue
}

export type ProspectivePatientForEhrDetails = {
  name?: string
  givenName?: string
  familyName?: string
  status?: PatientStatusEnum
  mrn?: GoogleProtobufWrappers.StringValue
  hospitalId?: string
  athenaPracticeId?: GoogleProtobufWrappers.StringValue
  epicDepartmentId?: GoogleProtobufWrappers.StringValue
  cernerDepartmentId?: GoogleProtobufWrappers.StringValue
  ehr?: CadenceModelsV1Models.EHR
  primaryInsuranceName?: string
  secondaryInsuranceName?: string
  epicDepartmentAbbr?: GoogleProtobufWrappers.StringValue
  patientTier?: string
  epicFhirId?: string
  programStatuses?: PatientProgramStatus[]
}

export type FindProspectivePatientForEhrAppointmentRequest = {
  ehrAppointmentId?: GoogleProtobufWrappers.StringValue
  ehr?: CadenceModelsV1Models.EHR
  ehrHospitalId?: GoogleProtobufWrappers.StringValue
}

export type ProspectivePatientForEhrAppointment = {
  name?: string
  givenName?: string
  familyName?: string
  status?: PatientStatusEnum
  mrn?: GoogleProtobufWrappers.StringValue
  hospitalId?: string
  athenaPracticeId?: GoogleProtobufWrappers.StringValue
  epicDepartmentId?: GoogleProtobufWrappers.StringValue
  cernerDepartmentId?: GoogleProtobufWrappers.StringValue
  ehr?: CadenceModelsV1Models.EHR
  primaryInsuranceName?: string
  secondaryInsuranceName?: string
  patientTier?: string
  programStatuses?: PatientProgramStatus[]
}

export type FindProspectivePatientByContactInfoRequest = {
  contactInfo?: string
}

export type ProspectivePatientByContactInfo = {
  name?: string
  status?: PatientStatusEnum
  contactInfo?: string[]
}

export type CheckProspectivePatientABEligibilityRequest = {
  ehr?: CadenceModelsV1Models.EHR
  contextId?: string
  patientId?: string
  patientMrn?: string
  patientFhirId?: string
  careProviderId?: string
  careProviderExternalProviderId?: string
  careProviderFhirId?: string
  isInstantOrder?: boolean
  primaryProvidersOnly?: boolean
  identifiedProvidersOnly?: boolean
  deprioritizeChf?: boolean
  acuityTierPrioritization?: boolean
}

export type ProspectivePatientABEligibility = {
  name?: string
  abEligible?: boolean
  eligibilityReason?: string
  status?: PatientStatusEnum
  suggestedCondition?: ProgramCondition
  clinicalProfile?: PatientClinicalProfile
  patientId?: string
  patientMrn?: string
  patientFhirId?: string
  careProviderId?: string
  careProviderExternalProviderId?: string
  careProviderFhirId?: string
  careProviderNpi?: string
}

export type PatientConditionsPerProgram = {
  programs?: Program[]
}

export type GetPatientClinicalProfileRequest = {
  name?: string
}

export type GetPatientEnrollmentInfoRequest = {
  name?: string
}

export type GetPatientDetailsRequest = {
  name?: string
}

export type GetAllPatientEhrDetailsRequest = {
  name?: string
}

export type GetContactDetailsRequest = {
  patientId?: GoogleProtobufWrappers.StringValue
  phoneNumber?: GoogleProtobufWrappers.StringValue
}

export type CreatePatientProgramsRequest = {
  parent?: string
  programs?: Programs
}

export type UpdatePatientProgramsRequest = {
  parent?: string
  programs?: Programs
}

export type PatientDiagnosisCodeConditions = {
  condition?: DiagnosisCodeCondition
}

export type PatientProgramWithProfile = {
  name?: string
  clinicalProfile?: PatientClinicalProfile
  programs?: Programs
}

export type PatientClinicalProfile = {
  name?: string
  efLower?: GoogleProtobufWrappers.Int32Value
  efUpper?: GoogleProtobufWrappers.Int32Value
  efUrl?: GoogleProtobufWrappers.StringValue
  efDate?: GoogleProtobufTimestamp.Timestamp
  chfClass?: GoogleProtobufWrappers.StringValue
  chfStage?: GoogleProtobufWrappers.StringValue
  maxSystolic?: GoogleProtobufWrappers.Int32Value
  maxDiastolic?: GoogleProtobufWrappers.Int32Value
  lastSystolic?: GoogleProtobufWrappers.Int32Value
  lastDiastolic?: GoogleProtobufWrappers.Int32Value
  dryWeight?: GoogleProtobufWrappers.DoubleValue
  weight?: GoogleProtobufWrappers.DoubleValue
  a1CUrl?: GoogleProtobufWrappers.StringValue
  a1CDate?: GoogleProtobufTimestamp.Timestamp
  a1c?: GoogleProtobufWrappers.DoubleValue
  bmi?: GoogleProtobufWrappers.DoubleValue
  ccmCarePlanApprovalDate?: GoogleProtobufTimestamp.Timestamp
  lastEdVisitDate?: GoogleProtobufTimestamp.Timestamp
  lastFacilityDischargeDate?: GoogleProtobufTimestamp.Timestamp
  insulinTreatment?: boolean
  hasCgm?: boolean
  avgSystolic?: GoogleProtobufWrappers.Int32Value
  avgDiastolic?: GoogleProtobufWrappers.Int32Value
}

export type PatientEnrollmentInfo = {
  name?: string
  epicFhirId?: string
  providerId?: string
  encounterStartDate?: GoogleTypeDate.Date
  encounterEndDate?: GoogleTypeDate.Date
  epicProviderId?: string
  epicExternalId?: string
}

export type GetPatientNotificationSettingsRequest = {
  name?: string
}

export type NotificationSettings = {
  name?: string
  smsConsent?: boolean
  vitalsConfirmation?: boolean
  vitalsValue?: boolean
  vitalsReminder?: boolean
  appointmentsReminder?: boolean
  emailConsent?: boolean
  weeklySummary?: boolean
  streaks?: boolean
  bingo?: boolean
  notificationConsent?: boolean
}

export type UpdatePatientNotificationSettingsRequest = {
  notificationSettings?: NotificationSettings
  updateMask?: GoogleProtobufField_mask.FieldMask
}

export type CreatePatientClinicalProfileRequest = {
  parent?: string
  clinicalProfile?: PatientClinicalProfile
}

export type CreatePatientProgramWithProfileRequest = {
  parent?: string
  programWithProfile?: PatientProgramWithProfile
}

export type UpdatePatientClinicalProfileRequest = {
  clinicalProfile?: PatientClinicalProfile
  updateMask?: GoogleProtobufField_mask.FieldMask
}

export type UpsertPatientProgramWithProfileRequest = {
  programWithProfile?: PatientProgramWithProfile
}

export type UpdatePatientEHRInfoRequest = {
  name?: string
  ehrInformation?: CadenceModelsV1Models.PatientEhrInfo
}

export type PatientChangeNotificationsRequest = {
  patientId?: string
}

export type PatientChangeNotificationResponse = {
  activated?: boolean
  patientId?: string
}

export type GetPatientAssignedDevicesRequest = {
  name?: string
}

export type AssignedDevices = {
  devices?: PatientDevice[]
}

export type PatientDevice = {
  name?: string
  type?: PatientDeviceDeviceType
  provider?: PatientDeviceProvider
  provisioningStatus?: PatientDeviceProvisioningStatus
  isProvisioned?: boolean
  imei?: string
  serialNumber?: string
}

export type GetPatientVitalsHistoryRequest = {
  name?: string
}

export type PatientVitalsHistoryLastVitalTaken = {
  date?: GoogleTypeDate.Date
  value?: GoogleProtobufWrappers.Int32Value
}

export type PatientVitalsHistory = {
  name?: string
  lastSystolicTaken?: PatientVitalsHistoryLastVitalTaken
  lastDiastolicTaken?: PatientVitalsHistoryLastVitalTaken
  lastBloodGlucoseTaken?: PatientVitalsHistoryLastVitalTaken
  lastWeightTaken?: PatientVitalsHistoryLastVitalTaken
  avgSystolicLast7D?: GoogleProtobufWrappers.Int32Value
  avgDiastolicLast7d?: GoogleProtobufWrappers.Int32Value
  avgGlucoseLast7d?: GoogleProtobufWrappers.Int32Value
  avgWeightLast7d?: GoogleProtobufWrappers.Int32Value
  avgSystolicLast30d?: GoogleProtobufWrappers.Int32Value
  avgDiastolicLast30d?: GoogleProtobufWrappers.Int32Value
  avgGlucoseLast30d?: GoogleProtobufWrappers.Int32Value
  avgWeightLast30d?: GoogleProtobufWrappers.Int32Value
}

export type CreateShellProspectivePatientFromDemographicsRequest = {
  ehr?: CadenceModelsV1Models.EHR
  contextId?: string
  patientMrn?: string
  demographics?: GoEhrPkgEmr_adapterEmr_adapter.EMRPatient
  careProviderNpi?: GoogleProtobufWrappers.StringValue
  careProviderId?: GoogleProtobufWrappers.StringValue
  hospitalId?: GoogleProtobufWrappers.StringValue
  createdBy?: string
}

export type ShellProspectivePatient = {
  name?: string
  mrn?: GoogleProtobufWrappers.StringValue
}

export type MarkProspectivePatientAsProviderDeclinedRequest = {
  name?: string
  declineReason?: GoogleProtobufWrappers.StringValue
  declinedBy?: string
}

export type PatientProgramStatus = {
  programType?: ProgramType
  status?: PatientStatusEnum
  consentDate?: GoogleProtobufTimestamp.Timestamp
  enrollmentDate?: GoogleProtobufTimestamp.Timestamp
}

export type PatientAllergy = {
  name?: string
  allergen?: string
  categories?: string[]
  criticality?: string
  onsetDate?: GoogleTypeDate.Date
  deactivatedDate?: GoogleTypeDate.Date
  active?: boolean
  untrackedTime?: GoogleProtobufTimestamp.Timestamp
  reactions?: string[]
}

export type GetInitialCarePlanVisitRequest = {
  name?: string
}

export type InitialCarePlanVisit = {
  patient?: string
  appointment?: Appointment
  careProvider?: CareProvider
}

export type Appointment = {
  name?: string
  startTime?: GoogleProtobufTimestamp.Timestamp
  endTime?: GoogleProtobufTimestamp.Timestamp
  type?: string
}

export type CareProvider = {
  name?: string
  givenName?: string
  familyName?: string
  role?: string
}

export class PatientService {
  static ListAcuityAssessments(req: ListAcuityAssessmentsRequest, initReq?: fm.InitReq): Promise<ListAcuityAssessmentsResponse> {
    return fm.fetchReq<ListAcuityAssessmentsRequest, ListAcuityAssessmentsResponse>(`/rpm/v1/patients/${req["parent"]}/acuityAssessments?${fm.renderURLSearchParams(req, ["parent"])}`, {...initReq, method: "GET"})
  }
  static CreateAcuityAssessment(req: CreateAcuityAssessmentRequest, initReq?: fm.InitReq): Promise<AcuityAssessment> {
    return fm.fetchReq<CreateAcuityAssessmentRequest, AcuityAssessment>(`/rpm/v1/patients/${req["parent"]}/acuityAssessments`, {...initReq, method: "POST", body: JSON.stringify(req["acuity_assessment"], fm.replacer)})
  }
  static ListEncounters(req: ListEncountersRequest, initReq?: fm.InitReq): Promise<ListEncountersResponse> {
    return fm.fetchReq<ListEncountersRequest, ListEncountersResponse>(`/rpm/v1/patients/${req["parent"]}/encounters?${fm.renderURLSearchParams(req, ["parent"])}`, {...initReq, method: "GET"})
  }
  static SuggestEncounters(req: SuggestEncountersRequest, initReq?: fm.InitReq): Promise<SuggestEncountersResponse> {
    return fm.fetchReq<SuggestEncountersRequest, SuggestEncountersResponse>(`/rpm/v1/patients/${req["parent"]}/encounters:suggest?${fm.renderURLSearchParams(req, ["parent"])}`, {...initReq, method: "GET"})
  }
  static ListAllPatients(req: ListAllPatientsRequest, initReq?: fm.InitReq): Promise<ListAllPatientsResponse> {
    return fm.fetchReq<ListAllPatientsRequest, ListAllPatientsResponse>(`/rpm/v1/patients/all?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static GetPatientDetails(req: GetPatientDetailsRequest, initReq?: fm.InitReq): Promise<PatientDetails> {
    return fm.fetchReq<GetPatientDetailsRequest, PatientDetails>(`/rpm/v1/${req["name"]}?${fm.renderURLSearchParams(req, ["name"])}`, {...initReq, method: "GET"})
  }
  static GetAllPatientEhrDetails(req: GetAllPatientEhrDetailsRequest, initReq?: fm.InitReq): Promise<PatientEhrDetails> {
    return fm.fetchReq<GetAllPatientEhrDetailsRequest, PatientEhrDetails>(`/rpm/v1/${req["name"]}?${fm.renderURLSearchParams(req, ["name"])}`, {...initReq, method: "GET"})
  }
  static GetContactDetails(req: GetContactDetailsRequest, initReq?: fm.InitReq): Promise<ContactDetails> {
    return fm.fetchReq<GetContactDetailsRequest, ContactDetails>(`/rpm/v1/contactDetails?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static ListPatientDiagnosisCodeConditions(req: ListPatientDiagnosisCodeConditionsRequest, initReq?: fm.InitReq): Promise<ListPatientDiagnosisCodeConditionsResponse> {
    return fm.fetchReq<ListPatientDiagnosisCodeConditionsRequest, ListPatientDiagnosisCodeConditionsResponse>(`/rpm/v1/patients/${req["parent"]}/patientDiagnosisCodeConditions?${fm.renderURLSearchParams(req, ["parent"])}`, {...initReq, method: "GET"})
  }
  static GetPatientClinicalProfile(req: GetPatientClinicalProfileRequest, initReq?: fm.InitReq): Promise<PatientClinicalProfile> {
    return fm.fetchReq<GetPatientClinicalProfileRequest, PatientClinicalProfile>(`/rpm/v1/${req["name"]}?${fm.renderURLSearchParams(req, ["name"])}`, {...initReq, method: "GET"})
  }
  static CreatePatientClinicalProfile(req: CreatePatientClinicalProfileRequest, initReq?: fm.InitReq): Promise<PatientClinicalProfile> {
    return fm.fetchReq<CreatePatientClinicalProfileRequest, PatientClinicalProfile>(`/rpm/v1/${req["parent"]}/clinicalProfile`, {...initReq, method: "POST", body: JSON.stringify(req["clinical_profile"], fm.replacer)})
  }
  static UpdatePatientClinicalProfile(req: UpdatePatientClinicalProfileRequest, initReq?: fm.InitReq): Promise<PatientClinicalProfile> {
    return fm.fetchReq<UpdatePatientClinicalProfileRequest, PatientClinicalProfile>(`/rpm/v1/${req["clinicalProfile"]?.["name"]}`, {...initReq, method: "PATCH", body: JSON.stringify(req["clinical_profile"], fm.replacer)})
  }
  static CreatePatientProgramWithProfile(req: CreatePatientProgramWithProfileRequest, initReq?: fm.InitReq): Promise<PatientProgramWithProfile> {
    return fm.fetchReq<CreatePatientProgramWithProfileRequest, PatientProgramWithProfile>(`/rpm/v1/${req["parent"]}/programWithProfile`, {...initReq, method: "POST", body: JSON.stringify(req["program_with_profile"], fm.replacer)})
  }
  static UpsertPatientProgramWithProfile(req: UpsertPatientProgramWithProfileRequest, initReq?: fm.InitReq): Promise<PatientProgramWithProfile> {
    return fm.fetchReq<UpsertPatientProgramWithProfileRequest, PatientProgramWithProfile>(`/rpm/v1/${req["programWithProfile"]?.["name"]}`, {...initReq, method: "PUT", body: JSON.stringify(req["program_with_profile"], fm.replacer)})
  }
  static GetPatientEnrollmentInfo(req: GetPatientEnrollmentInfoRequest, initReq?: fm.InitReq): Promise<PatientEnrollmentInfo> {
    return fm.fetchReq<GetPatientEnrollmentInfoRequest, PatientEnrollmentInfo>(`/rpm/v1/patients/${req["name"]}/enrollmentInfo?${fm.renderURLSearchParams(req, ["name"])}`, {...initReq, method: "GET"})
  }
  static ListProspectivePatients(req: ListProspectivePatientsRequest, initReq?: fm.InitReq): Promise<ListProspectivePatientsResponse> {
    return fm.fetchReq<ListProspectivePatientsRequest, ListProspectivePatientsResponse>(`/rpm/v1/patients/all/prospective?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static ListAllPatientsPreviews(req: ListAllPatientsPreviewsRequest, initReq?: fm.InitReq): Promise<ListAllPatientsPreviewsResponse> {
    return fm.fetchReq<ListAllPatientsPreviewsRequest, ListAllPatientsPreviewsResponse>(`/rpm/v1/patients/all/previews?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static GetPatientContext(req: GetPatientContextRequest, initReq?: fm.InitReq): Promise<PatientContext> {
    return fm.fetchReq<GetPatientContextRequest, PatientContext>(`/rpm/v1/patients/${req["name"]}/context?${fm.renderURLSearchParams(req, ["name"])}`, {...initReq, method: "GET"})
  }
  static GetPatientConditionsPerProgram(req: GetPatientConditionsPerProgramRequest, initReq?: fm.InitReq): Promise<PatientConditionsPerProgram> {
    return fm.fetchReq<GetPatientConditionsPerProgramRequest, PatientConditionsPerProgram>(`/rpm/v1/patients/${req["name"]}/programConditions?${fm.renderURLSearchParams(req, ["name"])}`, {...initReq, method: "GET"})
  }
  static FindProspectivePatientForEhrDetails(req: FindProspectivePatientForEhrDetailsRequest, initReq?: fm.InitReq): Promise<ProspectivePatientForEhrDetails> {
    return fm.fetchReq<FindProspectivePatientForEhrDetailsRequest, ProspectivePatientForEhrDetails>(`/rpm/v1/patients/all/prospective:findProspectivePatientForEhrDetails?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static FindProspectivePatientWithEhrAppointment(req: FindProspectivePatientForEhrAppointmentRequest, initReq?: fm.InitReq): Promise<ProspectivePatientForEhrAppointment> {
    return fm.fetchReq<FindProspectivePatientForEhrAppointmentRequest, ProspectivePatientForEhrAppointment>(`/rpm/v1/patients/all/prospective:findProspectivePatientWithEhrAppointment?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static FindProspectivePatientByContactInfo(req: FindProspectivePatientByContactInfoRequest, initReq?: fm.InitReq): Promise<ProspectivePatientByContactInfo> {
    return fm.fetchReq<FindProspectivePatientByContactInfoRequest, ProspectivePatientByContactInfo>(`/rpm/v1/patients/all/prospective:findProspectivePatientByContactInfo?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static CheckProspectivePatientABEligibility(req: CheckProspectivePatientABEligibilityRequest, initReq?: fm.InitReq): Promise<ProspectivePatientABEligibility> {
    return fm.fetchReq<CheckProspectivePatientABEligibilityRequest, ProspectivePatientABEligibility>(`/rpm/v1/patients/all/prospective:checkProspectivePatientABEligibility?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static GetPatientNotificationSettings(req: GetPatientNotificationSettingsRequest, initReq?: fm.InitReq): Promise<NotificationSettings> {
    return fm.fetchReq<GetPatientNotificationSettingsRequest, NotificationSettings>(`/rpm/v1/${req["name"]}?${fm.renderURLSearchParams(req, ["name"])}`, {...initReq, method: "GET"})
  }
  static UpdatePatientNotificationSettings(req: UpdatePatientNotificationSettingsRequest, initReq?: fm.InitReq): Promise<NotificationSettings> {
    return fm.fetchReq<UpdatePatientNotificationSettingsRequest, NotificationSettings>(`/rpm/v1/${req["notificationSettings"]?.["name"]}`, {...initReq, method: "PATCH", body: JSON.stringify(req["notification_settings"], fm.replacer)})
  }
  static PatientChangeNotifications(req: PatientChangeNotificationsRequest, entityNotifier?: fm.NotifyStreamEntityArrival<PatientChangeNotificationResponse>, initReq?: fm.InitReq): Promise<void> {
    return fm.fetchStreamingRequest<PatientChangeNotificationsRequest, PatientChangeNotificationResponse>(`/rpm/v1/patient:changeNotifications?${fm.renderURLSearchParams(req, [])}`, entityNotifier, {...initReq, method: "GET"})
  }
  static CreatePatientPrograms(req: CreatePatientProgramsRequest, initReq?: fm.InitReq): Promise<Programs> {
    return fm.fetchReq<CreatePatientProgramsRequest, Programs>(`/rpm/v1/${req["parent"]}/programs`, {...initReq, method: "POST", body: JSON.stringify(req["programs"], fm.replacer)})
  }
  static UpdatePatientPrograms(req: UpdatePatientProgramsRequest, initReq?: fm.InitReq): Promise<Programs> {
    return fm.fetchReq<UpdatePatientProgramsRequest, Programs>(`/rpm/v1/patients/${req["parent"]}/programs`, {...initReq, method: "PUT", body: JSON.stringify(req, fm.replacer)})
  }
  static BatchUpdatePatientSelectedProgramConditions(req: BatchUpdatePatientSelectedProgramConditionsRequest, initReq?: fm.InitReq): Promise<GoogleProtobufEmpty.Empty> {
    return fm.fetchReq<BatchUpdatePatientSelectedProgramConditionsRequest, GoogleProtobufEmpty.Empty>(`/rpm/v1/batchUpdatePatientSelectedProgramConditions:update`, {...initReq, method: "PUT", body: JSON.stringify(req, fm.replacer)})
  }
  static GetPatientAssignedDevices(req: GetPatientAssignedDevicesRequest, initReq?: fm.InitReq): Promise<AssignedDevices> {
    return fm.fetchReq<GetPatientAssignedDevicesRequest, AssignedDevices>(`/rpm/v1/patients/${req["name"]}/assignedDevices?${fm.renderURLSearchParams(req, ["name"])}`, {...initReq, method: "GET"})
  }
  static GetPatientVitalsHistory(req: GetPatientVitalsHistoryRequest, initReq?: fm.InitReq): Promise<PatientVitalsHistory> {
    return fm.fetchReq<GetPatientVitalsHistoryRequest, PatientVitalsHistory>(`/rpm/v1/${req["name"]}?${fm.renderURLSearchParams(req, ["name"])}`, {...initReq, method: "GET"})
  }
  static UpdatePatientEHRInfo(req: UpdatePatientEHRInfoRequest, initReq?: fm.InitReq): Promise<CadenceModelsV1Models.PatientEhrInfo> {
    return fm.fetchReq<UpdatePatientEHRInfoRequest, CadenceModelsV1Models.PatientEhrInfo>(`/rpm/v1/${req["name"]}/ehrInfo`, {...initReq, method: "PATCH", body: JSON.stringify(req["ehr_information"], fm.replacer)})
  }
  static CreateShellProspectivePatientFromDemographics(req: CreateShellProspectivePatientFromDemographicsRequest, initReq?: fm.InitReq): Promise<ShellProspectivePatient> {
    return fm.fetchReq<CreateShellProspectivePatientFromDemographicsRequest, ShellProspectivePatient>(`/rpm/v1/patients/prospective`, {...initReq, method: "POST"})
  }
  static MarkProspectivePatientAsProviderDeclined(req: MarkProspectivePatientAsProviderDeclinedRequest, initReq?: fm.InitReq): Promise<GoogleProtobufEmpty.Empty> {
    return fm.fetchReq<MarkProspectivePatientAsProviderDeclinedRequest, GoogleProtobufEmpty.Empty>(`/rpm/v1/patients/prospective:markAsProviderDeclined`, {...initReq, method: "POST"})
  }
  static LookupPatientsByAthenaEhrInfo(req: LookupPatientsByAthenaEhrInfoRequest, initReq?: fm.InitReq): Promise<LookupPatientsByAthenaEhrInfoResponse> {
    return fm.fetchReq<LookupPatientsByAthenaEhrInfoRequest, LookupPatientsByAthenaEhrInfoResponse>(`/rpm/v1/embeddedAppPatient:withAthenaEhrInfo?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static LookupPatientsByEpicEhrInfo(req: LookupPatientsByEpicEhrInfoRequest, initReq?: fm.InitReq): Promise<LookupPatientsByEpicEhrInfoResponse> {
    return fm.fetchReq<LookupPatientsByEpicEhrInfoRequest, LookupPatientsByEpicEhrInfoResponse>(`/rpm/v1/embeddedAppPatient:withEpicEhrInfo?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static GetPatientBestThreeConditionsFromProblemList(req: GetPatientBestThreeConditionsFromProblemListRequest, initReq?: fm.InitReq): Promise<BestThreeConditionsResponse> {
    return fm.fetchReq<GetPatientBestThreeConditionsFromProblemListRequest, BestThreeConditionsResponse>(`/rpm/v1/patients/${req["name"]}/threeChronicConditions?${fm.renderURLSearchParams(req, ["name"])}`, {...initReq, method: "GET"})
  }
  static ListPatientAllergies(req: ListPatientAllergiesRequest, initReq?: fm.InitReq): Promise<ListPatientAllergiesResponse> {
    return fm.fetchReq<ListPatientAllergiesRequest, ListPatientAllergiesResponse>(`/rpm/v1/patients/${req["parent"]}/patientAllergies?${fm.renderURLSearchParams(req, ["parent"])}`, {...initReq, method: "GET"})
  }
  static GetInitialCarePlanVisit(req: GetInitialCarePlanVisitRequest, initReq?: fm.InitReq): Promise<InitialCarePlanVisit> {
    return fm.fetchReq<GetInitialCarePlanVisitRequest, InitialCarePlanVisit>(`/rpm/v1/patients/${req["name"]}/initialCarePlanVisit?${fm.renderURLSearchParams(req, ["name"])}`, {...initReq, method: "GET"})
  }
}