import { format } from 'date-fns';
import last from 'lodash/last';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import type { QueryOptions } from 'react-query';
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import type {
  UseInfiniteQueryOptions,
  UseQueryOptions,
} from 'react-query/types/react/types';

import { logger } from '@/logger';
import {
  CACHE_TIME,
  STALE_TIME,
  errorInQueries,
  getFirstError,
  grpcQueryFunction,
  queriesAreLoading,
} from '@/reactQuery';
import type { EHRCredentialsSchema } from '@/shared/generated/api/pms';
import {
  EhrCredentialsService,
  EhrHealthSystemProviderService,
} from '@/shared/generated/api/pms';
import type {
  CareProviderHealthSystems,
  CareProviderStateLicensure,
  CareProviderStateLicensureResponse,
  DeleteCareProviderStateLicensureRequest,
  GetCareProviderEhrHospitalsResponse,
  UpdateCareProviderStateLicensureRequest,
} from '@/shared/generated/grpc/go/pms/pkg/care_provider/care_provider.pb';
import { CareProviderService } from '@/shared/generated/grpc/go/pms/pkg/care_provider/care_provider.pb';
import type {
  ListCareProviderMarketsRequest,
  Market,
} from '@/shared/generated/grpc/go/pms/pkg/market/market.pb';
import { MarketService } from '@/shared/generated/grpc/go/pms/pkg/market/market.pb';
import { useToaster } from '@/shared/tempo/molecule/Toast';
import type { Hospital } from '@/shared/types/hospital.types';
import type {
  AipPaginatedData,
  PaginatedData,
} from '@/shared/types/pagination.types';
import type {
  Provider,
  ProviderSpecialty,
  ProviderStatus,
  ProviderTitle,
  ProviderType,
} from '@/shared/types/provider.types';
import type { PhoneType } from '@/shared/types/shared.types';
import type { SortDirection } from '@/shared/types/sorting.types';
import Session from '@/shared/utils/session';

import { idToGrpcName, parseGrpcDate } from '../../utils/grpc';
import { usePatientDetails } from './patients.queries';

type PaginatedMarkets = AipPaginatedData<Market, 'markets'>;

type ProvidersParams = Partial<{
  page: number;
  pageSize: number;
  sortBy: string;
  orderBy: SortDirection;
  providerType: ProviderType;
  firstName: string;
  lastName: string;
  fullName: string;
  healthSystemId: string;
  clinicIds: string[];
  include: string[];
  npiId: string | number;
  email: string;
  teamIds: string[];
}>;

const getProvidersParams = (params: ProvidersParams) => ({
  ...(params.page && { page: params.page }),
  ...(params.pageSize && { page_size: params.pageSize }),
  ...(params.sortBy && { sort_by: params.sortBy }),
  ...(params.orderBy && { order_by: params.orderBy }),
  ...(params.providerType && { provider_type: params.providerType }),
  ...(params.firstName && { first_name: params.firstName }),
  ...(params.lastName && { last_name: params.lastName }),
  ...(params.fullName && { full_name: params.fullName }),
  ...(params.healthSystemId && { health_system_id: params.healthSystemId }),
  ...(params.clinicIds && { hospital_id: params.clinicIds }),
  ...(params.include && { include: params.include }),
  ...(params.npiId && { npi_id: params.npiId }),
  ...(params.email && { email: params.email }),
  ...(params.teamIds && { team_id: params.teamIds }),
});

const CACHE_AND_STALE_SETTINGS = {
  cacheTime: CACHE_TIME.FIVE_MINUTES,
  staleTime: STALE_TIME.FIVE_MINUTES,
};

const PROVIDERS_QUERY_KEY_BASE = ['pms', 'api', 'v1', 'care_provider'];
const GRPC_PROVIDERS_QUERY_KEY_BASE = ['rpm', 'v1', 'care_provider'];

const getCareProviderQueryKeyBase = (providerId: string) => [
  ...PROVIDERS_QUERY_KEY_BASE,
  providerId,
];

const getGrpcCareProviderQueryKeyBase = (providerId: string) => [
  ...GRPC_PROVIDERS_QUERY_KEY_BASE,
  providerId,
];

const getGrpcCareProviderHealthSystemsQueryKeyBase = (providerId: string) => [
  ...getGrpcCareProviderQueryKeyBase(providerId),
  'health-systems',
];

const getGrpcCareProviderHospitalsQueryKeyBase = (providerId: string) => [
  ...getGrpcCareProviderQueryKeyBase(providerId),
  'ehr-hospitals',
];

const getProviderHospitalsQueryKeyBase = (providerId: string) => [
  ...getCareProviderQueryKeyBase(providerId),
  'hospitals',
];

const getProviderMarketsQueryKeyBase = (providerId: string) => [
  ...getCareProviderQueryKeyBase(providerId),
  'markets',
];

export const getProviderStateLicensureQueryKeyBase = (providerId: string) => [
  ...getCareProviderQueryKeyBase(providerId),
  'state-licensure',
];

const getEhrHealthSystemProviderQueryKeyBase = (
  providerId: string,
  healthSystemId: string,
) => [
  ...PROVIDERS_QUERY_KEY_BASE,
  providerId,
  'health-systems',
  healthSystemId,
];

export function marketNameToId(resourceName?: string) {
  return last(resourceName?.split('/') ?? []) ?? '';
}

export function careProviderNameToId(resourceName?: string) {
  return last(resourceName?.split('/')) ?? '';
}

export function useInvalidateProviderHospitals() {
  const queryClient = useQueryClient();
  return async (providerId: string) =>
    queryClient.invalidateQueries(getProviderHospitalsQueryKeyBase(providerId));
}

export function useInvalidateProviderMarkets() {
  const queryClient = useQueryClient();
  return async (providerId: string) =>
    queryClient.invalidateQueries(getProviderMarketsQueryKeyBase(providerId));
}

export function useInvalidateCareProvider() {
  const queryClient = useQueryClient();
  return async (providerId: string) =>
    queryClient.invalidateQueries(getCareProviderQueryKeyBase(providerId));
}

export function useCareProviders(
  params: ProvidersParams,
  config?: UseQueryOptions<PaginatedData<Provider, 'care_providers'>>,
) {
  return useQuery<PaginatedData<Provider, 'care_providers'>>(
    [...PROVIDERS_QUERY_KEY_BASE, getProvidersParams(params)],
    { ...CACHE_AND_STALE_SETTINGS, ...config },
  );
}

export function useCareProvidersInfinite(
  params: Omit<ProvidersParams, 'page'> = {},
  config: UseInfiniteQueryOptions<
    PaginatedData<Provider, 'care_providers'>
  > = {},
) {
  return useInfiniteQuery<PaginatedData<Provider, 'care_providers'>>(
    [
      ...PROVIDERS_QUERY_KEY_BASE,
      {
        sort_by: 'last_name',
        order_by: 'asc',
        page_size: 100,
        ...getProvidersParams(params),
      },
      'infinite',
    ],
    { ...CACHE_AND_STALE_SETTINGS, ...config },
  );
}

export function useCareProviderHospitalsInfinite(
  providerId: string,
  name: string = '',
  config: UseInfiniteQueryOptions<PaginatedData<Hospital, 'hospitals'>> = {},
) {
  return useInfiniteQuery<PaginatedData<Hospital, 'hospitals'>>(
    [
      ...getProviderHospitalsQueryKeyBase(providerId),
      { page_size: 100, name },
      'infinite',
    ],
    { ...CACHE_AND_STALE_SETTINGS, ...config },
  );
}

export function useCareProviderMarketsInfinite(
  providerId: string,
  req: ListCareProviderMarketsRequest = {},
  config: UseInfiniteQueryOptions<PaginatedMarkets> = {},
) {
  return useInfiniteQuery<PaginatedMarkets>(
    [...getProviderMarketsQueryKeyBase(providerId), 'infinite'],
    async (ctx) => {
      const marketResponse = await MarketService.ListCareProviderMarkets({
        parent: `careProvider/${providerId}`,
        ...req,
        pageToken: ctx.pageParam,
      });

      return {
        markets: (marketResponse.markets as Market[]) ?? [],
        nextPageToken: marketResponse.nextPageToken ?? '',
      };
    },
    config,
  );
}

export function useCareProvider<TQueryData = Provider, TData = Provider>(
  careProviderId: string,
  config?: UseQueryOptions<TQueryData, unknown, TData>,
) {
  return useQuery<TQueryData, unknown, TData>(
    [...getCareProviderQueryKeyBase(careProviderId)],
    { ...CACHE_AND_STALE_SETTINGS, ...config },
  );
}

export function usePatientReferringPhysician(
  patientId: string,
  enabled = true,
) {
  const patientDetailsQuery = usePatientDetails(
    patientId,
    true,
    Boolean(patientId),
  );
  const npiId = patientDetailsQuery.data?.patient?.npiId || undefined;

  const careProvidersQuery = useCareProviders(
    { npiId },
    { enabled: enabled && Boolean(npiId) },
  );
  const referringPhysician = careProvidersQuery.data?.care_providers?.[0];
  return {
    isLoading: queriesAreLoading([patientDetailsQuery, careProvidersQuery]),
    isError: errorInQueries([patientDetailsQuery, careProvidersQuery]),
    error: getFirstError([patientDetailsQuery, careProvidersQuery]),
    referringPhysician,
  };
}

const getPatientCareProvidersQueryKeyBase = (patientId: string) => [
  'pms',
  'api',
  'v1',
  'patients',
  patientId,
  'care_providers',
];

export function usePatientCareProviders(
  patientId: string,
  params: ProvidersParams,
  config: UseQueryOptions<PaginatedData<Provider, 'care_providers'>> = {},
) {
  return useQuery<PaginatedData<Provider, 'care_providers'>>(
    [
      ...getPatientCareProvidersQueryKeyBase(patientId),
      getProvidersParams(params),
    ],
    { ...CACHE_AND_STALE_SETTINGS, enabled: Boolean(patientId), ...config },
  );
}

export function useDeletePatientCareProvider({
  onSuccess,
}: {
  onSuccess?: () => void;
} = {}) {
  const client = useQueryClient();
  const intl = useIntl();
  const { toaster } = useToaster();

  return useMutation(
    ({ providerId }: { providerId: string }) =>
      Session.Api.delete(`/pms/api/v1/care_provider/${providerId}`),
    {
      onSuccess: async () => {
        await client.invalidateQueries(PROVIDERS_QUERY_KEY_BASE);
        onSuccess?.();
      },
      onError: (error: Error, { providerId }) => {
        logger.error(
          `Failed to delete a provider with id: ${providerId}. Error message: ${error.message}`,
        );
        toaster.error(
          intl.formatMessage({
            defaultMessage: 'Failed to delete a provider.',
          }),
        );
      },
    },
  );
}

export function useBulkAssignCareProviders(patientId: string) {
  const queryClient = useQueryClient();
  return useMutation(
    (providers: Provider[]) =>
      Promise.all(
        providers.map((p) =>
          Session.Api.post<Provider>(
            `/pms/api/v1/patients/${patientId}/care_providers/${p.id}`,
          ),
        ),
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          getPatientCareProvidersQueryKeyBase(patientId),
        );
      },
    },
  );
}

export function useBulkUnassignCareProviders(patientId: string) {
  const queryClient = useQueryClient();
  return useMutation(
    (providers: Provider[]) =>
      Promise.all(
        providers.map((p) =>
          Session.Api.delete<Provider>(
            `/pms/api/v1/patients/${patientId}/care_providers/${p.id}`,
          ),
        ),
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          getPatientCareProvidersQueryKeyBase(patientId),
        );
      },
    },
  );
}

type ProviderPayload = {
  first_name: string;
  last_name: string;
  provider_type: ProviderType;
  provider_specialty?: ProviderSpecialty;
  email?: string | null;
  npi_id?: number;
  role?: ProviderTitle;
  status?: ProviderStatus;
  is_admin?: boolean;
  contact?: {
    name: string;
    phone_number: string;
    phone_type: PhoneType;
  };
};

export function useCreateProvider() {
  const client = useQueryClient();
  return useMutation(
    (payload: ProviderPayload) =>
      Session.Api.post<Provider>('/pms/api/v1/care_provider', payload),
    { onSuccess: () => client.invalidateQueries(PROVIDERS_QUERY_KEY_BASE) },
  );
}

export function useUpdateProvider(providerId: string) {
  const client = useQueryClient();

  return useMutation(
    (payload: ProviderPayload) =>
      Session.Api.put<Provider>(
        `/pms/api/v1/care_provider/${providerId}`,
        payload,
      ),
    {
      onSuccess: () =>
        client.invalidateQueries([...PROVIDERS_QUERY_KEY_BASE, providerId]),
    },
  );
}

const getHospitalCareProvidersQueryKeyBase = (hospitalId: string) => [
  'pms',
  'api',
  'v1',
  'hospitals',
  hospitalId,
  'care_providers',
];

export function useHospitalCareProviders(
  patientId: string,
  params: ProvidersParams,
) {
  return useQuery<PaginatedData<Provider, 'care_providers'>>(
    [
      ...getHospitalCareProvidersQueryKeyBase(patientId),
      getProvidersParams(params),
    ],
    { ...CACHE_AND_STALE_SETTINGS, enabled: Boolean(patientId) },
  );
}

export function useBulkAssignProvidersToHospital(hospitalId: string) {
  const queryClient = useQueryClient();
  return useMutation(
    (careProviders: Provider[]) =>
      Promise.all(
        careProviders.map((careProvider) =>
          Session.Api.post<Hospital>(
            `/pms/api/v1/hospitals/${hospitalId}/care_providers/${careProvider.id}`,
          ),
        ),
      ),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(
          getHospitalCareProvidersQueryKeyBase(hospitalId),
        );
      },
    },
  );
}

export function useBulkUnassignProvidersToHospital(hospitalId: string) {
  const queryClient = useQueryClient();
  return useMutation(
    (careProviders: Provider[]) =>
      Promise.all(
        careProviders.map((careProvider) =>
          Session.Api.delete<Hospital>(
            `/pms/api/v1/hospitals/${hospitalId}/care_providers/${careProvider.id}`,
          ),
        ),
      ),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(
          getHospitalCareProvidersQueryKeyBase(hospitalId),
        );
      },
    },
  );
}

export function useGetEhrHealthSystemProvider(
  providerId: string,
  healthSystemId: string,
  config: UseQueryOptions<EHRCredentialsSchema> = {},
) {
  return useQuery<EHRCredentialsSchema>(
    getEhrHealthSystemProviderQueryKeyBase(providerId, healthSystemId),
    () =>
      EhrHealthSystemProviderService.getPmsApiV1CareProviderHealthSystems(
        providerId,
        healthSystemId,
      ),
    config,
  );
}

export function useCreateEhrHealthSystemProviderCredentials(
  careProviderId: string,
  healthSystemId: string,
) {
  const queryClient = useQueryClient();
  return useMutation(
    (requestBody: EHRCredentialsSchema) =>
      EhrCredentialsService.postPmsApiV1CareProviderHealthSystemsEhrCredentials(
        careProviderId,
        healthSystemId,
        requestBody,
      ),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(
          getEhrHealthSystemProviderQueryKeyBase(
            careProviderId,
            healthSystemId,
          ),
        );
      },
    },
  );
}

export function useDeleteEhrHealthSystemProviderCredentials(
  careProviderId: string,
  healthSystemId: string,
  params: { onSuccess?: () => void } = {},
) {
  const queryClient = useQueryClient();
  return useMutation(
    () =>
      EhrCredentialsService.deletePmsApiV1CareProviderHealthSystemsEhrCredentials(
        careProviderId,
        healthSystemId,
      ),
    {
      onSuccess: async () => {
        params.onSuccess?.();
        await queryClient.invalidateQueries(
          getEhrHealthSystemProviderQueryKeyBase(
            careProviderId,
            healthSystemId,
          ),
        );
      },
    },
  );
}
export function useGetCareProviderHealthSystems(
  careProviderId: string,
  config: Omit<
    UseQueryOptions<CareProviderHealthSystems>,
    'queryKey' | 'queryFn'
  > = {},
) {
  return useQuery(
    getGrpcCareProviderHealthSystemsQueryKeyBase(careProviderId),
    (ctx) =>
      grpcQueryFunction<CareProviderHealthSystems>(
        ctx,
        CareProviderService.GetCareProviderHealthSystems,
        { name: idToGrpcName('careProvider', careProviderId) },
      ),
    config,
  );
}

export function useGetCareProviderHospitals(
  careProviderId: string,
  config: UseQueryOptions<GetCareProviderEhrHospitalsResponse> = {},
) {
  return useQuery<GetCareProviderEhrHospitalsResponse>(
    getGrpcCareProviderHospitalsQueryKeyBase(careProviderId),
    () =>
      CareProviderService.GetCareProviderEhrHospitals({
        name: idToGrpcName('careProvider', careProviderId),
      }),
    {
      ...config,
      enabled: config?.enabled ?? true,
    },
  );
}

export function useUpdateCareProviderPrimaryHospital(
  careProviderId: string,
  onSuccess: () => void,
) {
  const queryClient = useQueryClient();

  return useMutation(
    (hospitalId: string) =>
      CareProviderService.UpdatePrimaryHospitalByProviderId({
        name: idToGrpcName('careProvider', careProviderId),
        hospitalId,
      }),
    {
      onSuccess: async () => {
        onSuccess();
        await queryClient.invalidateQueries(
          getGrpcCareProviderHospitalsQueryKeyBase(careProviderId),
        );
      },
    },
  );
}

export function useCareProviderWithHealthSystems(careProviderId: string) {
  const { data: careProviderData } = useCareProvider(careProviderId ?? '', {
    enabled: Boolean(careProviderId),
  });

  const { data: careProviderHealthSystems } = useGetCareProviderHealthSystems(
    careProviderData?.id ?? '',
    { enabled: Boolean(careProviderData?.id) },
  );

  return useMemo(
    () => ({
      careProviderData,
      healthSystems: careProviderHealthSystems?.healthSystems ?? [],
    }),
    [careProviderData, careProviderHealthSystems],
  );
}

export function useProviderStateLicensures(
  providerId: string,
  config: QueryOptions<CareProviderStateLicensureResponse> = {},
) {
  return useQuery(
    getProviderStateLicensureQueryKeyBase(providerId),
    () =>
      CareProviderService.GetCareProviderStateLicensure({
        name: idToGrpcName('careProvider', providerId),
      }),
    {
      ...config,
    },
  );
}

export function useProviderStateLicensureByState(
  providerId: string,
  state?: string,
  config: QueryOptions<CareProviderStateLicensureResponse> = {},
) {
  return useQuery(
    getProviderStateLicensureQueryKeyBase(providerId),
    () =>
      CareProviderService.GetCareProviderStateLicensureByState({
        name: idToGrpcName('careProvider', providerId),
        state,
      }),
    {
      ...config,
      enabled: !!state,
    },
  );
}

export function useUpdateStateLicensure(
  providerId: string,
  onSuccess: () => void,
) {
  const queryClient = useQueryClient();

  return useMutation(
    (state_licensure: CareProviderStateLicensure) =>
      CareProviderService.UpdateCareProviderStateLicensure({
        name: idToGrpcName('careProvider', providerId),
        state_licensure,
      } as UpdateCareProviderStateLicensureRequest),
    {
      onSuccess: async () => {
        onSuccess();
        await queryClient.invalidateQueries(
          getProviderStateLicensureQueryKeyBase(providerId),
        );
      },
    },
  );
}

export function useDeleteStateLicensure(
  providerId: string,
  onSuccess: () => void,
) {
  const queryClient = useQueryClient();

  return useMutation(
    (state_licensure: CareProviderStateLicensure) =>
      CareProviderService.DeleteCareProviderStateLicensure({
        name: idToGrpcName('careProvider', providerId),
        state: String(state_licensure.state),
        // We pass the expiration date to update a care provider's license before deleting the record
        // We do this to keep history of expiration dates for billing purposes
        expireOn: state_licensure.expireOn
          ? format(parseGrpcDate(state_licensure.expireOn), 'yyyy-MM-dd')
          : '',
      } as DeleteCareProviderStateLicensureRequest),
    {
      onSuccess: async () => {
        onSuccess();
        await queryClient.invalidateQueries(
          getProviderStateLicensureQueryKeyBase(providerId),
        );
      },
    },
  );
}
