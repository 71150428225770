import { useParams } from 'react-router-dom';

import type { Medication } from '@/shared/generated/grpc/go/pms/pkg/patient/medication/medication.pb';
import { MedicationChangeChangeType } from '@/shared/generated/grpc/go/pms/pkg/patient/medication/medication.pb';
import { useNoteHasMedChange } from '@/shared/hooks/useNoteHasMedChange';

import { useNoteEditorContext } from '../../../../NoteEditorContext';

export function useHasMedChange(
  medFilterOverride?: (med: Medication) => boolean,
) {
  const { editingNote } = useNoteEditorContext();
  const { patientId } = useParams<{ patientId: string }>();
  const defaultFilter = (med: Medication) => !!med.isDiseaseSpecific;

  return useNoteHasMedChange(
    patientId,
    editingNote?.note?.id,
    [MedicationChangeChangeType.TITRATION, MedicationChangeChangeType.START],
    medFilterOverride ?? defaultFilter,
  );
}
