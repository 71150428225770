import type { ReferenceMedication } from '@/shared/generated/grpc/go/pms/pkg/patient/medication/medication.pb';
import { ReferenceMedicationMedicationClass } from '@/shared/generated/grpc/go/pms/pkg/patient/medication/medication.pb';

import type { RxNormOptionType } from './types';

export function getMedClasses(
  refMeds?: ReferenceMedication[],
  rxnormId?: string,
) {
  return refMeds?.find((m) => m.rxNorms?.find((r) => r.id === rxnormId))
    ?.medClasses;
}

export function isChfGdmt(refMeds?: ReferenceMedication[], rxnormId?: string) {
  const medClasses = getMedClasses(refMeds, rxnormId);
  return Boolean(
    medClasses?.some((c) =>
      [
        ReferenceMedicationMedicationClass.ACE_INHIBITOR,
        ReferenceMedicationMedicationClass.ARB,
        ReferenceMedicationMedicationClass.ARNI,
        ReferenceMedicationMedicationClass.BETA_BLOCKERS,
        ReferenceMedicationMedicationClass.SODIUM_GLUCOSE_CO_TRANSPORTER_2_INHIBITORS,
        ReferenceMedicationMedicationClass.ALDOSTERONE_ANTAGONISTS,
      ].includes(c),
    ),
  );
}

export function isHtnMed(refMeds?: ReferenceMedication[], rxnormId?: string) {
  const medClasses = getMedClasses(refMeds, rxnormId);
  return Boolean(
    medClasses?.some((c) =>
      [
        ReferenceMedicationMedicationClass.THIAZIDE_DIURETICS,
        ReferenceMedicationMedicationClass.ARB,
        ReferenceMedicationMedicationClass.CCB_DHP,
        ReferenceMedicationMedicationClass.ACE_INHIBITOR,
        ReferenceMedicationMedicationClass.CCB_NON_DHP,
        ReferenceMedicationMedicationClass.LOOP_DIURETICS,
        ReferenceMedicationMedicationClass.ALDOSTERONE_ANTAGONISTS,
        ReferenceMedicationMedicationClass.BETA_BLOCKERS,
        ReferenceMedicationMedicationClass.RENIN_INHIBITORS,
        ReferenceMedicationMedicationClass.ALPHA_ADRENERGIC_BLOCKERS,
        ReferenceMedicationMedicationClass.CENTRAL_ALPHA_2_AGONIST,
        ReferenceMedicationMedicationClass.VASODILATOR,
      ].includes(c),
    ),
  );
}

export function getRxNorm(refMeds?: ReferenceMedication[], rxnormId?: string) {
  const rxnorms = refMeds?.flatMap((m) => m.rxNorms || []);
  return rxnorms?.find((r) => r.id === rxnormId);
}

export function sortRxNormOptions(a: RxNormOptionType, b: RxNormOptionType) {
  // Sort disabled options to the bottom
  if (a.disabled && !b.disabled) {
    return 1;
  }
  if (b.disabled && !a.disabled) {
    return -1;
  }

  // Then sort options with a single med class above combo meds
  const aMedClassCount = a.medClasses?.length || 0;
  const bMedClassCount = b.medClasses?.length || 0;
  if (aMedClassCount === 1 && bMedClassCount > 1) {
    return -1;
  }
  if (bMedClassCount === 1 && aMedClassCount > 1) {
    return 1;
  }

  // Then sort by first ingredient that will be displayed
  const aFirstIngredient = a.norm?.components?.[0]?.ingredient || 'a';
  const bFirstIngredient = b.norm?.components?.[0]?.ingredient || 'b';
  return aFirstIngredient.localeCompare(bFirstIngredient);
}
