import { FormattedMessage } from 'react-intl';

import { AcceptButton } from '@/components/AsyncTitration/SuggestedTitration/Actions/AcceptButton';
import { RejectButton } from '@/components/AsyncTitration/SuggestedTitration/Actions/RejectButton';
import { AsyncTitrationAsyncTitrationType } from '@/shared/generated/grpc/go/pms/pkg/patient/medication/medication.pb';

import { patientDecision } from '../SuggestedTitration.css';
import type { SuggestedTitrationMode } from '../types';

type Props = {
  mode: SuggestedTitrationMode;
  isDisabled?: boolean;
  onReject: () => void;
  onAccept: () => void;
  asyncTitrationType: AsyncTitrationAsyncTitrationType;
};

export function InitiallyReviewedActions({
  mode,
  isDisabled = false,
  onReject,
  onAccept,
  asyncTitrationType,
}: Props) {
  return (
    <div className={patientDecision.container}>
      <div className={patientDecision.header}>
        <FormattedMessage defaultMessage="Patient decision" />
      </div>
      <div className={patientDecision.description}>
        {asyncTitrationType ===
          AsyncTitrationAsyncTitrationType.UP_TITRATION && (
          <FormattedMessage defaultMessage="Ask the patient if they accept the pending titration." />
        )}
        {asyncTitrationType === AsyncTitrationAsyncTitrationType.NEW_MED && (
          <FormattedMessage defaultMessage="Ask the patient if they accept the pending medication." />
        )}
      </div>
      <div className={patientDecision.actionsContainer}>
        <RejectButton onReject={onReject} mode={mode} isDisabled={isDisabled}>
          <FormattedMessage defaultMessage="Reject" />
        </RejectButton>
        <AcceptButton onAccept={onAccept} mode={mode} isDisabled={isDisabled}>
          <FormattedMessage defaultMessage="Accept" />
        </AcceptButton>
      </div>
    </div>
  );
}
