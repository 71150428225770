import { useIntl } from 'react-intl';

import { AsyncTitrationAsyncTitrationStatus } from '@/shared/generated/grpc/go/pms/pkg/patient/medication/medication.pb';
import { useUpdateAsyncTitration } from '@/shared/hooks/queries/medication.queries';
import { useToaster } from '@/shared/tempo/molecule/Toast';
import { getGrpcErrorMessage } from '@/shared/utils/helpers';

import { usePatientMedicationsContext } from '../../PatientMedicationsContext';
import { useTitrateMedication } from '../../patientMedications.queries';
import { useHasChf } from '../../utils/useHasChf';
import { useHasHtn } from '../../utils/useHasHtn';
import type { MedicationFormProps } from '../MedicationForm';
import { MedicationForm } from '../MedicationForm';
import type { MedPayload } from '../editMedicationFormConfig';
import { TitrationFormType } from '../formTypeEnum';

type Props = MedicationFormProps & {
  onSuccess: () => void;
};

export function TitrateMedicationForm({
  onSuccess,
  initValues,
  ...rest
}: Props) {
  const { onClose } = rest;

  const intl = useIntl();
  const { toaster } = useToaster();
  const { patientId, noteId } = usePatientMedicationsContext();

  const hasChf = useHasChf(patientId);
  const hasHtn = useHasHtn(patientId);
  const titrateMutation = useTitrateMedication(onSuccess);
  const titrateRecommendationMutation = useUpdateAsyncTitration(
    patientId,
    initValues?.asyncTitrationId ?? '',
    {
      onSuccess: () => {
        onSuccess();
        onClose();
      },
      onError: () => {
        toaster.error(
          intl.formatMessage({
            defaultMessage: 'Failed to update titration recommendation',
          }),
        );
      },
    },
  );

  function handleSubmit(payload: MedPayload) {
    if (payload.asyncTitration?.isAsyncTitration) {
      titrateRecommendationMutation.mutate({
        editedDoseQuantities: payload.doseQuantities,
        editedFrequencies: payload.frequencies,
        editedRxcui: payload.asyncTitration.rxcui,
        editedStartDate: payload.startDate,
        editedEndDate: payload.endDate,
        editedMaxToleratedDoseAchieved: payload.maxToleratedDoseAchieved,
        editedNote: payload.reason,
        status: AsyncTitrationAsyncTitrationStatus.INITIALLY_REVIEWED,
        initialReviewNoteId: noteId ?? 0,
      });
      return;
    }

    titrateMutation.mutate(payload, {
      onSuccess: onClose,
      onError: (err) => {
        toaster.error(
          intl.formatMessage(
            {
              defaultMessage: 'Failed to titrate medication: {message}',
            },
            { message: getGrpcErrorMessage(err) },
          ),
        );
      },
    });
  }

  return (
    <MedicationForm
      {...rest}
      initValues={initValues}
      hasChf={hasChf}
      hasHtn={hasHtn}
      titrationFormType={TitrationFormType.Titrate}
      isProcessing={
        titrateMutation.isLoading || titrateRecommendationMutation.isLoading
      }
      onSubmit={handleSubmit}
    />
  );
}
