import { useIntl } from 'react-intl';

import { StatusChangeReason } from '@/shared/types/patient.types';

export enum DisenrollmentReasonCategory {
  DeviceIssues,
  PatientDrivenReasons,
  Ineligibility,
  ProviderDrivenReasons,
}

export const PRIMARY_TO_SECONDARY_REASON_MAP: Record<
  DisenrollmentReasonCategory,
  StatusChangeReason[]
> = {
  [DisenrollmentReasonCategory.DeviceIssues]: [
    StatusChangeReason.LackOfConnectivity,
    StatusChangeReason.ConcernsAboutDeviceAccuracy,
    StatusChangeReason.UnableToSafelyUseEquipment,
    StatusChangeReason.PrefersUsingPersonalDevices,
  ],
  [DisenrollmentReasonCategory.PatientDrivenReasons]: [
    StatusChangeReason.PreferPhysicianManage,
    StatusChangeReason.CausedStress,
    StatusChangeReason.DoesntWantToTakeVitals,
    StatusChangeReason.DoesntWantToHaveAppointments,
    StatusChangeReason.DoesntWantToReceiveAlertCalls,
    StatusChangeReason.PrivacyConcerns,
    StatusChangeReason.ReceivedAnUnexpectedBill,
    StatusChangeReason.FinancialReasons,
    StatusChangeReason.BelievesHealthGoalMet,
    StatusChangeReason.Deceased,
    StatusChangeReason.NoLongerAPatientOfTheProvider,
    StatusChangeReason.PatientMarkedInactiveWithClinic,
  ],
  [DisenrollmentReasonCategory.Ineligibility]: [
    StatusChangeReason.EnrolledInAlternateProgram,
    StatusChangeReason.HasCGM,
    StatusChangeReason.ClinicalExclusion,
    StatusChangeReason.LivesInFacilityThatManagesTheirMedication,
    StatusChangeReason.PrimaryResidenceOutsideCadenceFootprint,
  ],
  [DisenrollmentReasonCategory.ProviderDrivenReasons]: [
    StatusChangeReason.ProviderLeftPractice,
    StatusChangeReason.ProviderRequestsPatientGraduate,
    StatusChangeReason.ProviderToldPatientProgramNotNeeded,
    StatusChangeReason.ProviderNoLongerParticipating,
  ],
};

export function useDisenrollmentReasonCategoryI18n() {
  const intl = useIntl();

  return {
    [DisenrollmentReasonCategory.DeviceIssues]: intl.formatMessage({
      defaultMessage: 'Device issues',
    }),
    [DisenrollmentReasonCategory.PatientDrivenReasons]: intl.formatMessage({
      defaultMessage: 'Patient-driven reasons',
    }),
    [DisenrollmentReasonCategory.Ineligibility]: intl.formatMessage({
      defaultMessage: 'Ineligibility',
    }),
    [DisenrollmentReasonCategory.ProviderDrivenReasons]: intl.formatMessage({
      defaultMessage: 'Provider-driven reasons',
    }),
  };
}

export function lookupCategory(
  reason: Maybe<StatusChangeReason>,
): Maybe<DisenrollmentReasonCategory> {
  if (!reason) {
    return undefined;
  }

  const categories = Object.keys(
    PRIMARY_TO_SECONDARY_REASON_MAP,
  ) as unknown as DisenrollmentReasonCategory[];

  for (let i = 0; i < categories.length; i++) {
    const category = categories[i];
    const reasons = PRIMARY_TO_SECONDARY_REASON_MAP[category];

    if (reasons.find((toCheck) => toCheck === reason)) {
      return category;
    }
  }

  return undefined;
}
