import { useIntl } from 'react-intl';

import { LabGroup } from '@/shared/generated/grpc/go/pms/pkg/patient/labs/labs.pb';

export function useLabGroupi18nMap(): Record<LabGroup, string> {
  const intl = useIntl();

  return {
    [LabGroup.A1C]: intl.formatMessage({
      defaultMessage: 'Hemoglobin A1c',
    }),
    [LabGroup.BNP]: intl.formatMessage({ defaultMessage: 'BNP' }),
    [LabGroup.CMP]: intl.formatMessage({
      defaultMessage: 'Comprehensive Metabolic Panel',
    }),
    [LabGroup.ECHO]: intl.formatMessage({
      defaultMessage: 'Echocardiogram',
    }),
    [LabGroup.LIPIDS]: intl.formatMessage({
      defaultMessage: 'Lipid Panel',
    }),
    [LabGroup.TROPONIN]: intl.formatMessage({
      defaultMessage: 'Troponin',
    }),
    [LabGroup.UNKNOWN]: intl.formatMessage({
      defaultMessage: 'Unknown',
    }),
    [LabGroup.LAB_GROUP_UNSPECIFIED]: intl.formatMessage({
      defaultMessage: 'Unspecified',
    }),
  };
}
