import cx from 'classnames';
import { format } from 'date-fns';

import { logger } from '@/logger';
import { Table } from '@/shared/common/Table';
import type { LabGroupWithReferenceLabs } from '@/shared/generated/grpc/go/pms/pkg/patient/labs/labs.pb';
import { parseGrpcDate } from '@/shared/utils/grpc';

import { getRefLabsAndTaggedLabs } from '../labs.utils';
import type { LabsWithRefInfo } from '../types';
import {
  analyteInitial,
  firstColumn,
  innerTableHeader,
  referenceMeasurement,
  table,
  tableHeader,
  unitText,
} from './LabsTable.css';
import { LabResultCells } from './LabsTableCells';

type Props = {
  visibleLabs: LabGroupWithReferenceLabs[];
};

export function LabsTable({ visibleLabs }: Props) {
  const { referenceLabs, taggedLabs } = getRefLabsAndTaggedLabs(visibleLabs);
  const groupedLabs = groupLabsByDate(taggedLabs);
  const columns = getColumnTitles(groupedLabs);

  return (
    <Table className={table}>
      <Table.Header columns={columns} />
      <Table.Body>
        {referenceLabs.map((referenceLab) => (
          <Table.Row key={referenceLab.name}>
            <Table.NodeCell className={firstColumn}>
              <span className={analyteInitial}>{referenceLab.initial}</span>
              <span className={referenceMeasurement}>
                {referenceLab.clinicalMinValue}-{referenceLab.clinicalMaxValue}
              </span>
              <span className={unitText.default}> {referenceLab.unit}</span>
            </Table.NodeCell>
            <LabResultCells
              columns={columns}
              referenceLab={referenceLab}
              groupedLabs={groupedLabs}
            />
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
}

export type GroupedLabs = Record<string, LabsWithRefInfo[]>;

function groupLabsByDate(labs: LabsWithRefInfo[]) {
  return labs.reduce((acc, lab) => {
    const { observationDate } = lab;
    const date = parseGrpcDate(observationDate as GoogleDate);
    const formattedDate = format(date, 'MM/dd/yyyy');

    // We want to account for more than one lab of the same type on a given date
    const labsPerDate = acc[formattedDate] ?? [];

    // We should not display duplicate labs, but we'll log if it does happen.
    if (
      labsPerDate.some(
        (l) => l.referenceName === lab.referenceName && l.value === lab.value,
      )
    ) {
      logger.error(
        `Duplicate lab found for ${lab.referenceName} on ${formattedDate}`,
      );
      return acc;
    }

    return {
      ...acc,
      [formattedDate]: [...labsPerDate, lab],
    };
  }, {} as GroupedLabs);
}

// we always want to have at least five columns + the header column,
// so we'll use this to fill out the rest of the columns if we don't have enough
const MIN_COLUMNS = 6;

function getColumnTitles(labs: GroupedLabs) {
  const columns: { title: string; className?: string }[] = [
    { title: '', className: firstColumn },
    ...Object.keys(labs)
      .sort((a, b) => new Date(b).getTime() - new Date(a).getTime())
      .map((date) => ({ title: date })),
  ];

  while (columns.length < MIN_COLUMNS) {
    columns.push({ title: '' });
  }

  return columns.map((col) => ({
    ...col,
    className: cx(tableHeader, col.className),
    // TODO: is there a better way to do this for centering the text?
    customHeader: <span className={innerTableHeader}>{col.title}</span>,
  }));
}
