import type { UseQueryOptions } from 'react-query';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import {
  Channel,
  MessageDirection,
  MessageRecipientType,
  MessageRouterService,
} from '@/shared/generated/grpc/go/messaging/pkg/message_router/router.pb';
import {
  type GetPatientSMSHistoryResponse,
  PatientService,
} from '@/shared/generated/grpc/go/messaging/pkg/patient/patient.pb';
import { idToGrpcName } from '@/shared/utils/grpc';

import Session from '../../utils/session';

const POLLING_INTERVAL = 10000;

const NOTIFICATIONS_COMMUNICATIONS_KEY_BASE = [
  'notification',
  'api',
  'v1',
  'communications',
] as const;

const communicationsKeys = {
  smsHistory: (patientId: string) =>
    [
      ...NOTIFICATIONS_COMMUNICATIONS_KEY_BASE,
      'sms_history',
      patientId,
    ] as const,
  recording: (recordingId: string) =>
    [
      ...NOTIFICATIONS_COMMUNICATIONS_KEY_BASE,
      'recording_stream',
      recordingId,
    ] as const,
};

export function useSmsHistory(
  patientId: string,
  config: UseQueryOptions<GetPatientSMSHistoryResponse> = {},
) {
  return useQuery({
    queryKey: communicationsKeys.smsHistory(patientId),
    queryFn: () => PatientService.GetPatientSMSHistory({ patientId }),
    ...config,
    refetchInterval: POLLING_INTERVAL,
  });
}

export function useSendSms(patientId: string, caregiverId: Nullable<string>) {
  const queryClient = useQueryClient();
  const parent = idToGrpcName('conversations', patientId);

  return useMutation(
    (smsBody: string) =>
      MessageRouterService.CreateMessage({
        message: {
          body: smsBody,
          patientId,
          channel: Channel.CHANNEL_SMS,
          direction: MessageDirection.OUTBOUND,
          parent,
          ...(caregiverId
            ? {
                caregiverId,
                recipientType: MessageRecipientType.RECIPIENT_TYPE_CAREGIVER,
              }
            : {
                recipientType: MessageRecipientType.RECIPIENT_TYPE_PATIENT,
              }),
        },
        parent,
      }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(
          communicationsKeys.smsHistory(patientId ?? ''),
        );
      },
    },
  );
}

export function useRecordingStream(recordingId: string, enabled: boolean) {
  return useQuery({
    queryKey: communicationsKeys.recording(recordingId),
    queryFn: async () => {
      const { data } = await Session.Api.get<Blob>(
        `/messaging/recordings?recordingSid=${recordingId}`,
        {
          responseType: 'blob',
        },
      );

      const blob = new Blob([data], { type: 'audio/mpeg' });
      const stream = blob.stream();
      const streamReader = stream.getReader();
      const chunks = [];
      let receivedLength = 0;

      // eslint-disable-next-line no-constant-condition
      while (true) {
        // eslint-disable-next-line no-await-in-loop
        const { done, value } = await streamReader.read();
        if (done) {
          break;
        }
        chunks.push(value);
        receivedLength += value.length;
      }

      const chunksAll = new Uint8Array(receivedLength);
      let position = 0;
      for (let i = 0; i < chunks.length; i++) {
        const chunk = chunks[i];
        chunksAll.set(chunk, position);
        position += chunk.length;
      }

      return URL.createObjectURL(new Blob([chunksAll]));
    },
    enabled,
  });
}
