import cx from 'classnames';
import { FormattedMessage } from 'react-intl';

import { CommonInfoField, PLACEHOLDER } from '@/shared/common/CommonInfoField';
import {
  type Address,
  type PatientDetails,
  type PrimaryLanguage,
} from '@/shared/generated/grpc/go/pms/pkg/patient/pms.pb';
import {
  displayGenderV2,
  displayGoogleDateAge,
  displayGoogleDateDOB,
  displayHomeStateFromAddresses,
  displayPatientLanguage,
} from '@/shared/patient/patientInfoUtils';

import { useNoteEditorContext } from '../../patientDetails/ui/Notes/NoteEditorContext';
import { MrnsInfoField } from '../MrnsInfoField';
import {
  useNumberOfPatientInfoColumns,
  useSetNumberOfPatientInfoColumnsFromRef,
} from '../numberOfPatientInfoColumns';
import { parseEhrInfos } from '../utils/ehrInfo.utils';
import { patientInfoFieldsContainer } from './DynamicPatientInfoFields.css';
import { LocationInfoField } from './LocationInfoField';
import { RpmClinicalPatientInfoFieldsWrapper } from './RpmClinicalPatientInfoFields';

type Props = {
  patientDetails: PatientDetails;
  patientAddresses?: Address[];
  patientPrimaryLanguage?: PrimaryLanguage;
};

export function DynamicPatientInfoFields({
  patientDetails,
  patientAddresses,
  patientPrimaryLanguage,
}: Props) {
  const { patient, allEhrInformation } = patientDetails;
  const { mainEhrInfo, mrns } = parseEhrInfos(allEhrInformation);

  const { isEditorOpen } = useNoteEditorContext();
  const divElement = useSetNumberOfPatientInfoColumnsFromRef();
  const numberOfPatientInfoColumns = useNumberOfPatientInfoColumns();
  const patientStateName = displayHomeStateFromAddresses(patientAddresses);
  const patientLanguage = displayPatientLanguage(patientPrimaryLanguage);
  return (
    <div
      ref={divElement}
      className={cx({
        [patientInfoFieldsContainer.editorOpen]: isEditorOpen,
        [patientInfoFieldsContainer.fourCols]:
          !isEditorOpen && numberOfPatientInfoColumns === 4,
        [patientInfoFieldsContainer.lessThan4cols]:
          !isEditorOpen && numberOfPatientInfoColumns < 4,
      })}
    >
      <CommonInfoField
        label={<FormattedMessage defaultMessage="Age" />}
        text={patient?.dob ? displayGoogleDateAge(patient.dob) : PLACEHOLDER}
      />
      <CommonInfoField
        label={<FormattedMessage defaultMessage="DoB" />}
        text={patient?.dob ? displayGoogleDateDOB(patient.dob) : PLACEHOLDER}
      />
      <CommonInfoField
        label={<FormattedMessage defaultMessage="Gender" />}
        text={patient?.gender ? displayGenderV2(patient.gender) : PLACEHOLDER}
      />
      <CommonInfoField
        label={<FormattedMessage defaultMessage="State" />}
        text={patientStateName !== 'N/A' ? patientStateName : PLACEHOLDER}
      />
      <CommonInfoField
        label={<FormattedMessage defaultMessage="Lang" />}
        text={patientLanguage !== 'N/A' ? patientLanguage : PLACEHOLDER}
      />
      {mainEhrInfo?.mrn && <MrnsInfoField mrns={mrns} />}
      <RpmClinicalPatientInfoFieldsWrapper patientDetails={patientDetails} />
      <LocationInfoField hospital={mainEhrInfo?.hospital} />
    </div>
  );
}
