import type { ReactElement } from 'react';
import { useEffect } from 'react';
import type { UseFormReturn } from 'react-hook-form';
import { useController, useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { Form } from '@/shared/common/Form';
import { Tag } from '@/shared/tempo/atom/Tag';

import { GoalMeasure } from '../../../Goals/goals.types';
import { type FormFields, getAssessmentFieldNames } from '../../formConfig';
import { GapWidget } from './GapWidget';
import * as styles from './shared.css';
import { UtilitySecurityChoice, UtilityStatus } from './utilities.types';

type Props = {
  form: UseFormReturn<FormFields>;
  createGoalWithMeasure: (measure: GoalMeasure, description?: string) => void;
};

export function Utilities({
  form,
  createGoalWithMeasure,
}: Props): ReactElement {
  const intl = useIntl();
  const { control } = useFormContext();
  const utilitySecurityChoice = form.watch('utilitySecurityChoice');
  const hasGap = utilitySecurityChoice === UtilitySecurityChoice.Yes;

  const { description: descriptionFieldName, checked: checkedFieldName } =
    getAssessmentFieldNames('utilities');

  const { field } = useController({ name: checkedFieldName, control });

  useEffect(() => {
    field.onChange(hasGap);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasGap]);

  return (
    <>
      <div className={styles.leftColumn}>
        <Form.RadioGroup
          name="utilitySecurityChoice"
          label={
            <>
              <Tag variant="default" className={styles.sectionTag}>
                <FormattedMessage defaultMessage="Utilities" />
              </Tag>{' '}
              <FormattedMessage defaultMessage="In the past 12 months has the electric, gas, or water company threatened to shut off services in your home?" />
            </>
          }
          orientation="horizontal"
          size={11}
          required
        >
          <Form.Radio value={UtilitySecurityChoice.No}>
            <FormattedMessage defaultMessage="No" />
          </Form.Radio>
          <Form.Radio value={UtilitySecurityChoice.Yes}>
            <FormattedMessage defaultMessage="Yes" />
          </Form.Radio>
        </Form.RadioGroup>

        {hasGap && (
          <Form.RadioGroup
            name="utilityStatus"
            label={<FormattedMessage defaultMessage="Utilities were:" />}
            orientation="horizontal"
            size={11}
            required
          >
            <Form.Radio value={UtilityStatus.Threatened}>
              <FormattedMessage defaultMessage="Threatened to shut off" />
            </Form.Radio>
            <Form.Radio value={UtilityStatus.AlreadyShutOff}>
              <FormattedMessage defaultMessage="Already shut off" />
            </Form.Radio>
          </Form.RadioGroup>
        )}
      </div>

      {hasGap && (
        <div className={styles.rightColumn}>
          <GapWidget
            fieldName={descriptionFieldName}
            placeholder={intl.formatMessage({
              defaultMessage: 'Enter notes about utilities',
            })}
            onCreateGoal={createGoalWithMeasure}
            measure={GoalMeasure.Utilities}
            form={form}
          />
        </div>
      )}
    </>
  );
}
