import type { ReactElement } from 'react';
import type { UseFormReturn } from 'react-hook-form';

import type { GoalMeasure } from '../../Goals/goals.types';
import { type FormFields } from '../formConfig';
import { PSYCHOSOCIAL_ASSESSMENT_OPTIONS } from './PsychosocialAssessment/psychoSocialAssessmentOptions';
import * as styles from './PsychosocialAssessmentV2.css';

type Props = {
  form: UseFormReturn<FormFields>;
  createGoalWithMeasure: (measure: GoalMeasure) => void;
};

export function PsychosocialAssessmentV2({
  form,
  createGoalWithMeasure,
}: Props): ReactElement {
  return (
    <>
      {PSYCHOSOCIAL_ASSESSMENT_OPTIONS.map(
        ({ name, component: Component }) =>
          Component && (
            <div key={name} className={styles.container}>
              <Component
                form={form}
                createGoalWithMeasure={createGoalWithMeasure}
              />
            </div>
          ),
      )}
    </>
  );
}
