import cx from 'classnames';

import { LoadingPlaceholder } from '@/shared/common/LoadingPlaceholder';
import { type PatientDetails } from '@/shared/generated/grpc/go/pms/pkg/patient/pms.pb';
import { usePatientDetails } from '@/shared/hooks/queries';

import { PatientMedsInfoTag } from '../BasicPatientInfoSummary/PatientMedsInfoTag';
import { ProgramStatusTags } from '../ProgramStatusTags';
import { SkeletonInfoSummary } from '../SkeletonInfoSummary';
import { loadingContainer, tagsContainer } from './PatientInfoTags.css';
import { TagType } from './types';

type WrapperProps = {
  patientId: string;
  className?: string;
  show?: TagType[];
};

export function PatientInfoTagsWrapper({
  patientId,
  className,
  show,
}: WrapperProps) {
  const { data: patientDetails, isLoading: isLoadingPatient } =
    usePatientDetails(patientId, true);
  return (
    <LoadingPlaceholder
      className={loadingContainer}
      isLoading={isLoadingPatient}
      placeholder={<SkeletonInfoSummary />}
    >
      {patientDetails && (
        <PatientInfoTags
          patientDetails={patientDetails}
          className={className}
          show={show}
        />
      )}
    </LoadingPlaceholder>
  );
}

type Props = {
  patientDetails: PatientDetails;
  className?: string;
  show?: TagType[];
};

export function PatientInfoTags({
  className,
  patientDetails,
  show = [TagType.Programs, TagType.MedInfo],
}: Props) {
  const showProgramTags = show.includes(TagType.Programs);
  const showMedInfoTags = show.includes(TagType.MedInfo);
  return (
    <div className={cx(tagsContainer, className)}>
      {showProgramTags && (
        <ProgramStatusTags
          className={className}
          patientDetails={patientDetails}
        />
      )}
      {showMedInfoTags && <PatientMedsInfoTag patient={patientDetails} />}
    </div>
  );
}
