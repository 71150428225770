import { useIntl } from 'react-intl';

import { getRxNorm } from '@/pages/patients/PatientMedications/forms/medClassUtil';
import { useReferenceMedications } from '@/pages/patients/PatientMedications/referenceMedications.queries';
import { Modal } from '@/shared/common/Modal';
import {
  AsyncTitrationAsyncTitrationStatus,
  AsyncTitrationAsyncTitrationType,
} from '@/shared/generated/grpc/go/pms/pkg/patient/medication/medication.pb';
import {
  useCreateManualAsyncTitration,
  useUpdateAsyncTitration,
} from '@/shared/hooks/queries/medication.queries';
import { useToaster } from '@/shared/tempo/molecule/Toast';
import { getGrpcErrorMessage } from '@/shared/utils/helpers';

import { MedicationForm } from '../forms/MedicationForm';
import type { MedPayload } from '../forms/editMedicationFormConfig';
import { TitrationFormType } from '../forms/formTypeEnum';
import { RxNormRestriction } from '../forms/types';
import { modalBodyContainer } from '../styles.css';
import { useHasChf } from '../utils/useHasChf';
import { useHasHtn } from '../utils/useHasHtn';

export function SuggestNewMedicationModal({
  patientId,
  onClose,
  existingReferencedMedIds,
  asyncTitrationId,
}: {
  patientId: string;
  onClose: () => void;
  existingReferencedMedIds: Maybe<string>[];
  asyncTitrationId?: string;
}) {
  const intl = useIntl();
  const { toaster } = useToaster();

  const hasChf = useHasChf(patientId);
  const hasHtn = useHasHtn(patientId);
  const { data: referenceMedications } = useReferenceMedications();
  const updateAsyncTitration = useUpdateAsyncTitration(
    patientId,
    asyncTitrationId ?? '',
  );
  const createManualAsyncTitration = useCreateManualAsyncTitration(patientId);

  function handleSubmitForm(payload: MedPayload) {
    const rxnorm = getRxNorm(
      referenceMedications?.referenceMedications,
      payload.rxnormId,
    );
    const commonProps = {
      editedDoseQuantities: payload.doseQuantities,
      editedFrequencies: payload.frequencies,
      editedRxcui: rxnorm?.rxcui,
      editedStartDate: payload.startDate,
      editedEndDate: payload.endDate,
      editedMaxToleratedDoseAchieved: payload.maxToleratedDoseAchieved,
      editedNote: payload.reason,
      initialReviewNoteId: payload.noteId ?? 0,
    };
    if (asyncTitrationId) {
      updateAsyncTitration.mutate(
        {
          ...commonProps,
          status: AsyncTitrationAsyncTitrationStatus.INITIALLY_REVIEWED,
        },
        {
          onSuccess: onClose,
          onError: (err) => {
            toaster.error(
              intl.formatMessage(
                {
                  defaultMessage: `Failed to suggest new medication: {message}`,
                },
                { message: getGrpcErrorMessage(err) },
              ),
            );
          },
        },
      );
    } else {
      createManualAsyncTitration.mutate(
        {
          ...commonProps,
          type: AsyncTitrationAsyncTitrationType.NEW_MED,
          inputPatientId: patientId,
        },
        {
          onSuccess: onClose,
          onError: (err) => {
            toaster.error(
              intl.formatMessage(
                {
                  defaultMessage: `Failed to suggest new medication: {message}`,
                },
                { message: getGrpcErrorMessage(err) },
              ),
            );
          },
        },
      );
    }
  }

  return (
    <Modal open onClose={onClose}>
      <Modal.Header
        title={intl.formatMessage({ defaultMessage: 'Suggest new medication' })}
      />
      <Modal.Body className={modalBodyContainer}>
        <MedicationForm
          titrationFormType={TitrationFormType.Start}
          hasChf={hasChf}
          hasHtn={hasHtn}
          onSubmit={handleSubmitForm}
          onClose={onClose}
          isProcessing={updateAsyncTitration.isLoading}
          rxNormRestriction={RxNormRestriction.NON_EXISTING_MEDS}
          existingReferencedMedIds={existingReferencedMedIds}
        />
      </Modal.Body>
    </Modal>
  );
}
