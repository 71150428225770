import type { MedReviewFormFields } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/CNWizardForm/sections/shared/MedReview/formConfig';
import { getMedicationNotePreview } from '@/pages/patients/patientDetails/ui/Notes/NotePreview/hooks/useMedReviewNoteBody';
import type { PatientMedications } from '@/shared/generated/grpc/go/pms/pkg/patient/medication/medication.pb';

import { MarkdownBuilder } from '../../engine/MarkdownBuilder';

type Params = {
  noteId: number;
  meds: PatientMedications;
  medReviewData: Maybe<MedReviewFormFields>;
};

export function cnMedicationReview({ noteId, meds, medReviewData }: Params) {
  const md = new MarkdownBuilder();
  const declinedMedReview = medReviewData?.patientDeclineMedReview;

  const noteBody = getMedicationNotePreview(noteId, meds, undefined, true);

  md.h3('Medication Review');

  if (declinedMedReview) {
    md.p('Patient declined to verify medications');
  } else if (!noteBody) {
    return '';
  }

  md.newline();
  md.concat(noteBody);
  md.newline();

  return md.toString();
}
