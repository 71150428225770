import { FormattedMessage } from 'react-intl';

import { InitiallyReviewedActions } from '@/components/AsyncTitration/SuggestedTitration/Actions/InitiallyReviewedActions';
import { useReferenceMedication } from '@/pages/patients/PatientMedications/referenceMedications.queries';
import { TypeOfEncounter } from '@/pages/patients/patientDetails/ui/Notes/Notes.types';
import { NoteStatus } from '@/shared/generated/grpc/go/pms/pkg/note/note.pb';
import type { RxNorm } from '@/shared/generated/grpc/go/pms/pkg/patient/medication/medication.pb';
import {
  AsyncTitrationAsyncTitrationType,
  AsyncTitrationAsyncTitrationStatus as AsyncTitrationStatus,
  type MedicationChange,
} from '@/shared/generated/grpc/go/pms/pkg/patient/medication/medication.pb';

import type { TitrationRecommendation } from '../hooks/useTitrationRecommendation';
import { NewActions, UndoAction } from './Actions';
import { MedicationDetails } from './MedicationDetails';
import { actions } from './SuggestedTitration.css';
import type { SuggestedTitrationMode } from './types';

export type Props = {
  titrationRecommendation: TitrationRecommendation;
  currentMed: MedicationChange;
  readOnly: boolean;
  referenceMedicationId?: string;
  typeOfEncounter?: TypeOfEncounter;
  isSaving: boolean;
  mode: SuggestedTitrationMode;
  onEdit: (proposedChange: MedicationChange) => void;
  onInitialApproved: () => void;
  onInitialRejected: () => void;
  onPatientConsented: () => void;
  onPatientRejected: () => void;
  onUndo: () => void;
};

export function ConditionalActions({
  titrationRecommendation,
  currentMed,
  readOnly,
  referenceMedicationId,
  typeOfEncounter,
  isSaving,
  mode,
  onEdit,
  onInitialApproved,
  onInitialRejected,
  onPatientConsented,
  onPatientRejected,
  onUndo,
}: Props) {
  const { data: referenceMed } = useReferenceMedication(
    referenceMedicationId ?? '',
    !!referenceMedicationId,
  );
  const recommendedRxNorm =
    titrationRecommendation.rxNorm as NonNullable<RxNorm>;
  const { status: recommendationStatus } = titrationRecommendation;
  const isInitialReviewNotePublished =
    titrationRecommendation.initialReviewNoteStatus === NoteStatus.PUBLISHED;

  if (
    readOnly &&
    recommendationStatus !== AsyncTitrationStatus.REJECTED_ON_INITIAL_REVIEW
  ) {
    return null;
  }

  if (recommendationStatus === AsyncTitrationStatus.NEW) {
    return (
      <div className={actions.container}>
        <NewActions
          mode={mode}
          isDisabled={isSaving}
          onEdit={() => {
            onEdit({
              rxnorm: recommendedRxNorm,
              doseQuantities: titrationRecommendation.doseQuantities,
              frequencies: titrationRecommendation.frequencies,
            });
          }}
          onAccept={onInitialApproved}
          onReject={onInitialRejected}
        />
      </div>
    );
  }

  if (
    recommendationStatus === AsyncTitrationStatus.REJECTED_ON_INITIAL_REVIEW
  ) {
    return (
      <div className={actions.container}>
        <UndoAction
          variant="rejected"
          onUndo={onUndo}
          readOnly={readOnly}
          isProcessing={isSaving}
          rejectedMessage={
            <FormattedMessage defaultMessage="Titration rejected" />
          }
        />
      </div>
    );
  }

  if (
    recommendationStatus === AsyncTitrationStatus.INITIALLY_REVIEWED &&
    !isInitialReviewNotePublished
  ) {
    return (
      <div className={actions.container}>
        <UndoAction
          variant="accepted"
          onUndo={onUndo}
          readOnly={readOnly}
          isProcessing={isSaving}
          acceptedHeader={
            <FormattedMessage defaultMessage="Titration captured" />
          }
          acceptedBody={
            <FormattedMessage defaultMessage="The patient will be notified about the titration suggestion" />
          }
        />
      </div>
    );
  }

  if (
    recommendationStatus === AsyncTitrationStatus.INITIALLY_REVIEWED &&
    isInitialReviewNotePublished
  ) {
    return (
      <>
        {referenceMed && (
          <MedicationDetails
            referenceMed={referenceMed}
            medChange={currentMed}
            recommendation={titrationRecommendation}
            forCnEncounter={
              typeOfEncounter === TypeOfEncounter.CN_TITRATION_OUTREACH
            }
          />
        )}
        <InitiallyReviewedActions
          mode={mode}
          isDisabled={isSaving}
          onAccept={onPatientConsented}
          onReject={onPatientRejected}
          asyncTitrationType={AsyncTitrationAsyncTitrationType.UP_TITRATION}
        />
      </>
    );
  }

  if (recommendationStatus === AsyncTitrationStatus.PATIENT_REJECTED) {
    return (
      <div className={actions.container}>
        <UndoAction
          variant="rejected"
          onUndo={onUndo}
          readOnly={readOnly}
          isProcessing={isSaving}
          rejectedMessage={
            <FormattedMessage defaultMessage="Titration rejected by the patient" />
          }
        />
      </div>
    );
  }

  if (recommendationStatus === AsyncTitrationStatus.PATIENT_CONSENTED) {
    return (
      <div className={actions.container}>
        <UndoAction
          variant="accepted"
          onUndo={onUndo}
          readOnly={readOnly}
          isProcessing={isSaving}
          acceptedBody={
            <FormattedMessage defaultMessage="Titration consented to by the patient" />
          }
        />
      </div>
    );
  }

  return null;
}
