import { useIntl } from 'react-intl';

import type { AsyncTitrationPatientRejectedTitrationReason as Reason } from '@/shared/generated/grpc/go/pms/pkg/patient/medication/medication.pb';
import { fullWidth } from '@/shared/jsStyle/utils.css';
import { Select } from '@/shared/tempo/@labs/molecule/Select';

import { useOptions } from './useOptions';

type Props = {
  required?: boolean;
  onChange?: (reason: Reason) => void;
};

export function FormlessPatientTitrationRejectionReason({
  required,
  onChange,
}: Props) {
  const intl = useIntl();
  const options = useOptions();

  return (
    <Select
      className={fullWidth}
      label={intl.formatMessage({
        defaultMessage: 'Select a reason why patient rejected titration',
      })}
      items={options}
      isRequired={required}
      onSelectionChange={(key) => onChange?.(key as Reason)}
    >
      {({ key, label }) => <Select.Option key={key}>{label}</Select.Option>}
    </Select>
  );
}
