import { EHR as GrpcEHR } from '@/shared/generated/grpc/cadence/models/models.pb';

export enum EHR {
  Athena = 'ATHENA',
  Epic = 'EPIC',
  Cerner = 'CERNER',
  NON_INTEGRATED = 'NON_INTEGRATED',
}

export enum AthenaOrderType {
  HomeMonitoring = 'HOME_MONITORING_ORDER',
  RpmReferral = 'RPM_REFERRAL_ORDER',
}

export const GRPC_EHR_TO_LOCAL_ENUM_MAP = {
  [GrpcEHR.EHR_UNSPECIFIED]: EHR.NON_INTEGRATED,
  [GrpcEHR.CUSTOM]: EHR.NON_INTEGRATED,
  [GrpcEHR.ATHENA]: EHR.Athena,
  [GrpcEHR.EPIC]: EHR.Epic,
  [GrpcEHR.CERNER]: EHR.Cerner,
};
