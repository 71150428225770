{{#if flags.autopopulateEncounterMedicationAllergies}}
  {{#if medAllergies}}
    <ul>
    {{#each medAllergies}}
      <li><i>{{this.allergen}}{{#if this.reactions}}: {{/if}}{{#each this.reactions as |reaction index|}}{{#if index}}, {{/if}}{{reaction}}{{/each}}</i></li>
    {{/each}}
    </ul>
  {{else}}
    <p>No medication allergies on file.</p>
  {{/if}}
  <br />
{{else}}
  {{> free_text_space }}
{{/if}}
