import { combineEpics } from 'redux-observable';

import confirmUserEpic from './confirmUserEpic';
import forceChangePasswordEpic from './forceChangePasswordEpic';
import forgotPasswordEpic from './forgotPasswordEpic';
import loginSSOEpic from './loginSSOEpic';
import logoutEpic from './logoutEpic';
import signUpSSOEpic from './signUpSSOEpic';

const authEpics = combineEpics(
  loginSSOEpic,
  logoutEpic,
  forgotPasswordEpic,
  forceChangePasswordEpic,
  confirmUserEpic,
  signUpSSOEpic,
);

export default authEpics;
