import cx from 'classnames';
import type { HTMLAttributes, ReactElement, ReactNode } from 'react';
import { Children, cloneElement, isValidElement } from 'react';

import { tag } from './Tag.css';
import { TagIcon, type TagIconPosition } from './TagIcon';

export type TagVariant =
  | 'default'
  | 'success'
  | 'warning'
  | 'danger'
  | 'info'
  | 'error'
  | 'unstyled';

export type Props = {
  variant?: TagVariant;
  children: ReactNode;
} & HTMLAttributes<HTMLDivElement>;

export function Tag({
  children,
  className,
  variant = 'default',
  ...props
}: Props) {
  return (
    <div {...props} className={cx(tag[variant], className)}>
      {getProcessedChildren(children)}
    </div>
  );
}

function getProcessedChildren(children: ReactNode) {
  const childArray = Children.toArray(children);
  return childArray.map((child, index) => {
    if (isValidElement(child) && child.type === TagIcon) {
      let position: TagIconPosition = 'middle';
      if (index === 0) {
        position = 'left';
      } else if (index === childArray.length - 1) {
        position = 'right';
      } else {
        position = 'middle';
      }

      return cloneElement(child as ReactElement, { ...child.props, position });
    }
    return child;
  });
}
