import type { ListPatientVitalsResponseVital } from '@/shared/generated/grpc/go/telemetry/pkg/telemetry/v1/telemetry.pb';

import type {
  BloodGlucoseTags,
  BloodPressureTags,
  HeartRateTags,
  WeightTags,
} from './tagsAndThreshold.types';

export enum VitalType {
  BloodPressure = 'blood_pressure',
  HeartRate = 'heart_rate',
  Weight = 'weight',
  BloodGlucose = 'blood_glucose',
}

export type GenericVital = {
  id: string;
  timestamp: string;
  timezone: string;
  // TODO: make this not optional in https://cadencerpm.atlassian.net/browse/PLAT-3978
  isSuppressed?: boolean;
};

export type BloodPressureVital = {
  systolic: number;
  diastolic: number;
  tags: BloodPressureTags | {};
} & GenericVital;

export type HeartRateVital = {
  pulse: number;
  regular: boolean;
  tags: HeartRateTags | {};
} & GenericVital;

export type WeightVital = {
  weight: number;
  tags: WeightTags | {};
} & GenericVital;

export type BloodGlucoseReadingQualifier =
  | 'BEFORE_MEAL'
  | 'AFTER_MEAL'
  | 'CONTROL_SOLUTION'
  | 'MOCK_TEST'
  | 'NO_SELECTION'
  | 'UNKNOWN';

export type BloodGlucoseVital = {
  glucose_level: number;
  reading_qualifier: BloodGlucoseReadingQualifier;
  tags: BloodGlucoseTags | {};
} & GenericVital;

export type Vital =
  | WeightVital
  | HeartRateVital
  | BloodPressureVital
  | BloodGlucoseVital;

export type PatientVitals = {
  blood_pressure: BloodPressureVital[];
  heart_rate: HeartRateVital[];
  weight: WeightVital[];
  blood_glucose: BloodGlucoseVital[];
};

export type TVitalsListPayload = {
  blood_pressure: BloodPressureVital[];
  heart_rate: HeartRateVital[];
  weight: WeightVital[];
  blood_glucose: BloodGlucoseVital[];
};

export type VitalsGroupedByDate = {
  [date: string]: Partial<PatientVitals>;
};

export type VitalsGraphs = {
  [VitalType.BloodPressure]: {
    diastolic: GraphInfo;
    systolic: GraphInfo;
  };
  [VitalType.HeartRate]: {
    base: GraphInfo;
  };
  [VitalType.Weight]: {
    base: GraphInfo;
  };
  [VitalType.BloodGlucose]: {
    base: GraphInfo;
  };
};

export type GroupedVitals = {
  [date: string]: {
    [type: string]: ListPatientVitalsResponseVital[];
  };
};
export type VitalsForDateType = GroupedVitals[string];
export type UnsupressedListPatientVitalsResponseVital =
  ListPatientVitalsResponseVital & {
    isSuppressed: boolean;
  };

export type ListPatientVitalsResponseVitalType = {
  BLOOD_PRESSURE: 'BLOOD_PRESSURE';
  HEART_RATE: 'HEART_RATE';
  WEIGHT: 'WEIGHT';
  BLOOD_GLUCOSE: 'BLOOD_GLUCOSE';
};
