import { differenceInYears, parseISO } from 'date-fns';

import { getRegionName } from '@/pages/adminPanel/patient-enrollment-tracker/ui/tabs/EligibleCandidatesTab/utils/getRegionName';
import states from '@/shared/assets/states.json';
import {
  type Address,
  AddressRegion,
  PrimaryLanguage,
} from '@/shared/generated/grpc/go/pms/pkg/patient/pms.pb';
import type { Patient } from '@/shared/types/patient.types';

export const displayGender = (gender: Maybe<string>) => {
  switch (gender) {
    case 'MALE':
      return 'M';
    case 'FEMALE':
      return 'F';
    default:
      return gender;
  }
};

export const displayGenderV2 = (gender: string) => {
  switch (gender) {
    case 'MALE':
      return 'Male';
    case 'FEMALE':
      return 'Female';
    case 'X':
      return 'X';
    default:
      return 'Other';
  }
};

export const displayAge = (dob: string) =>
  differenceInYears(new Date(), parseISO(dob));

export const displayGoogleDateAge = (dob: GoogleDate) =>
  differenceInYears(new Date(), new Date(dob.year, dob.month - 1, dob.day));

export const displayGoogleDateDOB = (dob: GoogleDate) =>
  `${dob.month}/${dob.day}/${dob.year}`;

export const displayPatientLabel = (patient: Patient) => {
  const age = displayAge(patient.dob);
  const gender = displayGender(patient.gender);

  return `${patient.first_name} ${patient.last_name} (${age}, ${gender})`;
};

export const displayHomeState = (patient: Patient) => {
  const primaryAddress = patient?.addresses?.find((address) => address.primary);
  const stateAbbreviation = primaryAddress?.address?.region;

  if (!stateAbbreviation) {
    return 'N/A';
  }

  return getRegionName(stateAbbreviation);
};

export const displayHomeStateFromAddresses = (addresses?: Address[]) => {
  const primaryAddress = addresses?.find((address) => address.primary);
  const stateAbbreviation = primaryAddress?.region;

  if (
    !stateAbbreviation ||
    stateAbbreviation === AddressRegion.REGION_UNSPECIFIED
  ) {
    return 'N/A';
  }

  return (
    states.find((state) => state.abbreviation === stateAbbreviation)?.name ||
    stateAbbreviation
  );
};

export const displayPatientLanguage = (primaryLanguage?: PrimaryLanguage) => {
  if (
    !primaryLanguage ||
    primaryLanguage === PrimaryLanguage.PRIMARY_LANGUAGE_UNSPECIFIED
  ) {
    return 'N/A';
  }

  // Convert enum value to display string (e.g., "HAITIAN_CREOLE" -> "Haitian")
  const languageMap: Record<PrimaryLanguage, string> = {
    [PrimaryLanguage.ENGLISH]: 'English',
    [PrimaryLanguage.SPANISH]: 'Spanish',
    [PrimaryLanguage.ARABIC]: 'Arabic',
    [PrimaryLanguage.CHINESE]: 'Chinese',
    [PrimaryLanguage.FILIPINO]: 'Filipino',
    [PrimaryLanguage.FRENCH]: 'French',
    [PrimaryLanguage.GERMAN]: 'German',
    [PrimaryLanguage.HAITIAN_CREOLE]: 'Haitian',
    [PrimaryLanguage.HINDI]: 'Hindi',
    [PrimaryLanguage.ITALIAN]: 'Italian',
    [PrimaryLanguage.JAPANESE]: 'Japanese',
    [PrimaryLanguage.KOREAN]: 'Korean',
    [PrimaryLanguage.PERSIAN]: 'Persian',
    [PrimaryLanguage.POLISH]: 'Polish',
    [PrimaryLanguage.PORTUGUESE]: 'Portuguese',
    [PrimaryLanguage.ROMANIAN]: 'Romanian',
    [PrimaryLanguage.RUSSIAN]: 'Russian',
    [PrimaryLanguage.UKRAINIAN]: 'Ukrainian',
    [PrimaryLanguage.URDU]: 'Urdu',
    [PrimaryLanguage.VIETNAMESE]: 'Vietnamese',
    // Handle default/unspecified cases
    [PrimaryLanguage.PRIMARY_LANGUAGE_UNSPECIFIED]: 'N/A',
    [PrimaryLanguage.PRIMARY_LANGUAGE_OTHER]: 'Other',
    // Add other mappings as needed but keep labels ≤10 chars
  } as Record<PrimaryLanguage, string>;

  return languageMap[primaryLanguage] || 'Other';
};
