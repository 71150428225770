import axios from 'axios';
import { type UseQueryOptions, useMutation } from 'react-query';
import { useDispatch } from 'react-redux';

import { settings } from '@/config';
import { registerMonitoredUser } from '@/monitoring.utils';
import {
  type AuthenticationResultSchema,
  type LoginSchema,
} from '@/shared/generated/api/auth';
import { getErrorMsg } from '@/shared/utils/helpers';

import {
  loginFailed,
  loginRequested,
  requestLogin,
} from '../../actionCreators';

export function usePasswordLogin(
  config?: UseQueryOptions<AuthenticationResultSchema>,
) {
  // TODO: Remove need for redux state
  const dispatch = useDispatch();
  return useMutation(
    (payload: LoginSchema) => {
      dispatch(requestLogin(payload.email, payload.password));
      return axios.post<AuthenticationResultSchema>(
        `${settings.API_URL}/auth/api/v1/login`,
        payload,
      );
    },
    {
      onSuccess(resp, variables) {
        const { email } = variables;
        registerMonitoredUser(email);
        dispatch(loginRequested({ ...resp.data, email }));
        config?.onSuccess?.(resp.data);
      },
      onError(error) {
        dispatch(loginFailed({ message: getErrorMsg(error) }));
        config?.onError?.(error);
      },
    },
  );
}
