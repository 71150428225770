import { getLatestLabGroupsWithDatesAndLabs } from '@/pages/patients/patientDetails/ui/tabs/Labs/labs.utils';
import { useLabGroupi18nMap } from '@/pages/patients/patientDetails/ui/tabs/Labs/useLabGroupI18nMap';
import type { LabGroup } from '@/shared/generated/grpc/go/pms/pkg/patient/labs/labs.pb';
import { usePatientLabsAndGroups } from '@/shared/hooks/queries/labs.queries';

export type LatestLabSummary = {
  labDate: Date;
  groupName: string;
  values: {
    analyte: string;
    value: Maybe<string>;
  }[];
};

export function usePatientLatestLabsContext(patientId: string) {
  const labGroupi18nMap = useLabGroupi18nMap();
  const { data: patientLabsAndGroups, isLoading: isLoadingLabsAndGroups } =
    usePatientLabsAndGroups(patientId);

  const labGroups = patientLabsAndGroups?.labGroups;
  const latesLabsPerGroup = getLatestLabGroupsWithDatesAndLabs(labGroups || []);

  const data: LatestLabSummary[] = Object.entries(latesLabsPerGroup).map(
    ([group, { date, labs }]) => {
      const labGroupName = labGroupi18nMap[group as LabGroup];

      const values = labs.map((lab) => ({
        analyte: lab.referenceLabName,
        value: lab.value,
        unit: lab.referenceUnit,
      }));

      return {
        labDate: date,
        groupName: labGroupName,
        values,
      };
    },
  );

  const isLoading = isLoadingLabsAndGroups;
  return { isLoading, data };
}
