import type { IntlShape } from 'react-intl';
import * as yup from 'yup';

import type { FormConfig } from '@/shared/common/Form';
import { validators } from '@/shared/common/Form/validations';

export type FormFields = {
  email: string;
  password: string;
};

export function getFormConfig(
  intl: IntlShape,
  initialValues?: Partial<FormFields>,
): FormConfig {
  const { required, email } = validators(intl);
  return {
    fields: {
      email: {
        defaultValue: initialValues?.email ?? '',
        validation: required(email()),
      },
      password: {
        defaultValue: '',
        validation: required(yup.string()),
      },
    },
  };
}
