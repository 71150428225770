import type { PatientClinicalProfile } from '@/shared/generated/grpc/go/pms/pkg/patient/pms.pb';

// Note: These enum values are the values we send/receive from the backend
export enum Condition {
  CHF = 'CHF',
  Hypertension = 'Hypertension',
  COPD = 'COPD',
  TypeTwoDiabetes = 'Type 2 Diabetes',
  Asthma = 'Asthma',
  AfibAndFlutter = 'Atrial Fibrillation and Flutter',
  ChronicKidneyDisease = 'Chronic Kidney Disease',
  Hyperlipidemia = 'Hyperlipidemia',
  Hypothyroidism = 'Hypothyroidism',
  IschemicHeartDisease = 'Ischemic Heart Disease',
  MorbidObesity = 'Morbid Obesity',
  ObstructiveSleepApnea = 'Obstructive Sleep Apnea',
  Osteoarthritis = 'Osteoarthritis',
  PeripheralArteryDisease = 'Peripheral Artery Disease',
  Dementia = 'Dementia',
  Generic = 'Generic',
  Unspecified = 'Unspecified',
}

export type CcmCondition =
  | Condition.CHF
  | Condition.Hypertension
  | Condition.COPD
  | Condition.TypeTwoDiabetes
  | Condition.Asthma
  | Condition.AfibAndFlutter
  | Condition.ChronicKidneyDisease
  | Condition.Hyperlipidemia
  | Condition.Hypothyroidism
  | Condition.IschemicHeartDisease
  | Condition.MorbidObesity
  | Condition.ObstructiveSleepApnea
  | Condition.Osteoarthritis
  | Condition.PeripheralArteryDisease
  | Condition.Dementia;

export type ApcmCondition = ClonedType<CcmCondition>;

export type RpmCondition =
  | Condition.CHF
  | Condition.Hypertension
  | Condition.TypeTwoDiabetes
  | Condition.COPD
  | Condition.Generic;

export enum ConditionAbbreviation {
  CHF = 'CHF',
  HTN = 'HTN',
  COPD = 'COPD',
  T2D = 'T2D',
  GENERIC = 'GENERIC',
  ABBREVIATION_UNAVAILABLE = '',
}

export type ConditionAbbreviationMap = {
  [k in Condition]: ConditionAbbreviation;
};

export type ICD10Code = {
  id: number;
  category: string;
  etiology: string;
  description: string;
  type: string;
};

export type ClinicalProfileFormData = {
  'chronic-conditions': string;
  'icd10-codes': ICD10Code[];
  'is-participating': boolean;
} & Partial<
  CHFFormData &
    T2dFormData &
    HtnFormData &
    CcmFormData &
    SuggestedRPMFormData &
    ApcmFormData
>;

type SuggestedRPMFormData = {
  'suggested-rpm-conditions': string;
  'suggested-rpm-icd10-codes': Nullable<ICD10Code[]>;
  'is-rpm-suggestion-active': boolean;
};

type CcmFormData = {
  'ccm-conditions': ICD10Code[];
  'ccm-care-plan-approval-date': Nullable<Date>;
  'ccm-is-participating': boolean;
};

type ApcmFormData = {
  'apcm-is-participating': boolean;
};

type CHFFormData = {
  'chf-stage': string;
  'chf-class': string;
  'chf-ejection-fraction-high': string;
  'chf-ejection-fraction-low': string;
  'chf-dry-weight': string;
};

type T2dFormData = {
  't2d-a1c': string;
};

type HtnFormData = {};

export type EjectionFraction = {
  lower: Maybe<number>;
  upper: Maybe<number>;
};

type DryWeight = {
  weight: Nullable<number>;
};

export type ChfProfile = {
  dry_weight: Nullable<DryWeight>;
  ejection_fraction: Nullable<EjectionFraction>;
  echo_s3_url: Maybe<string>;
  chf_data?: {
    chf_class: Nullable<string>;
    chf_stage: Nullable<string>;
  };
};

export type T2dProfile = {
  dry_weight: Nullable<DryWeight>;
  lab_s3_url: Maybe<string>;
  t2d_data?: {
    a1c: Nullable<number>;
  };
};

export type HtnProfile = {
  dry_weight: Nullable<DryWeight>;
};

export type CcmProfile = {
  is_participating: boolean;
  care_plan_approval_date: Nullable<string>;
  icd_10: ICD10Code[];
};

export type SuggestedRpmProfile = {
  is_suggestion_active: boolean;
  conditions: Condition[];
  icd_10: ICD10Code[];
};

export type ClinicalProfile = {
  conditions: Condition[];
  icd_10: ICD10Code[];
  chf_profile?: ChfProfile;
  t2d_profile?: T2dProfile;
  htn_profile?: HtnProfile;
  is_participating: boolean;
  ccm?: CcmProfile;
  suggested_rpm?: SuggestedRpmProfile;
};

export type NullableValuesPatientClinicalProfile = {
  [K in keyof PatientClinicalProfile]: PatientClinicalProfile[K] | null;
};
