import { FormattedMessage } from 'react-intl';

import { Tag } from '@/shared/tempo/atom/Tag';

import type { View } from './ProgramStatusTags';
import { errorTag } from './ProgramStatusTags.css';

type Props = {
  view: View;
};

export function NoProgramsTag({ view }: Props) {
  return (
    <Tag variant="error" className={errorTag}>
      {view === 'admin' ? (
        <FormattedMessage defaultMessage="No Eligible Programs" />
      ) : (
        <FormattedMessage defaultMessage="No Program Assigned" />
      )}
    </Tag>
  );
}
