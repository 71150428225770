import { FormattedMessage } from 'react-intl';

import type { LobLetter } from '@/shared/generated/grpc/go/pms/pkg/patient_outreach/patient_outreach.pb';
import { parseGrpcDate } from '@/shared/utils/grpc';

import { contentText } from './LetterSends.css';
import {
  getLetterDeliveredDate,
  getLetterStatusMetadata,
  letterDelivered,
  letterDeliveryFailed,
  letterInFailedStatus,
} from './status.utils';
import { useFormatDateInUserTz } from './useFormatDateInUserTz';

type Props = {
  letter: Maybe<LobLetter>;
};

export function LetterStatusText({ letter }: Props) {
  const formatDateInUserTz = useFormatDateInUserTz();
  if (!letter) {
    return (
      <div className={contentText.notMailed}>
        <FormattedMessage defaultMessage="This Care Plan has not been mailed to the patient." />
      </div>
    );
  }

  if (letterInFailedStatus(letter)) {
    return (
      <div className={contentText.error}>
        <FormattedMessage
          defaultMessage="Care Plan letter failed to send {updateTime}"
          values={{
            updateTime: formatDateInUserTz(
              letter.updateTime,
              "MM/dd/yyyy 'at' h:mm aa",
            ),
          }}
        />
      </div>
    );
  }

  const letterStatusMeta = getLetterStatusMetadata(letter);
  if (letterDelivered(letterStatusMeta)) {
    const deliveredAt = getLetterDeliveredDate(letter);
    return (
      <div className={contentText.default}>
        <FormattedMessage
          defaultMessage="Mailed to patient on {deliveredAt}"
          values={{
            deliveredAt: formatDateInUserTz(
              deliveredAt,
              "MM/dd/yyyy 'at' h:mm aa",
            ),
          }}
        />
      </div>
    );
  }

  if (letterDeliveryFailed(letterStatusMeta)) {
    return (
      <div className={contentText.error}>
        <FormattedMessage
          defaultMessage="Care Plan letter failed to deliver on {updateTime}"
          values={{
            updateTime: formatDateInUserTz(
              letter.updateTime,
              "MM/dd/yyyy 'at' h:mm aa",
            ),
          }}
        />
      </div>
    );
  }

  return (
    <div className={contentText.default}>
      <FormattedMessage
        defaultMessage="Sent {sentDateTime}. Est. delivery: {deliveryEta}"
        values={{
          sentDateTime: formatDateInUserTz(
            letter.sendTime,
            "MM/dd/yyyy 'at' h:mm aa",
          ),
          deliveryEta: formatDateInUserTz(
            letter.expectedDeliveryDate
              ? parseGrpcDate(letter.expectedDeliveryDate)
              : undefined,
            'MM/dd/yyyy',
          ),
        }}
      />
    </div>
  );
}
