import { useState } from 'react';

import { TextField, type TextFieldProps } from '../TextField';
import EyeOff from './eyeOff.svg?react';
import EyeOn from './eyeOn.svg?react';
import { icon } from './styles.css';

export type Props<TVal> = Omit<
  TextFieldProps<TVal>,
  'inputType' | 'endAdornmentText'
>;

export function Password<TVal>({ ...baseProps }: TextFieldProps<TVal>) {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <TextField
      {...baseProps}
      inputType={showPassword ? 'text' : 'password'}
      endAdornment={
        showPassword ? (
          <EyeOff
            className={icon}
            onClick={() => setShowPassword(!showPassword)}
          />
        ) : (
          <EyeOn
            className={icon}
            onClick={() => setShowPassword(!showPassword)}
          />
        )
      }
    />
  );
}
