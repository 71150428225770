import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import {
  FieldGroup,
  Script,
} from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared';
import { verificationsList } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared/shared.css';
import { Wizard } from '@/shared/common/Wizard';
import { useWizardStateContext } from '@/shared/common/Wizard/state';
import { usePatientDetails } from '@/shared/hooks/queries';
import { PatientStatus } from '@/shared/types/patient.types';

import { useCNFormContext } from '../../../CNFormContext';
import { INIT_VISIT_SECTIONS } from '../../metadata';
import { AdminProfileButton } from '../../shared/AdminProfileButton';
import { initVisitPath } from '../paths';

export function ProfileCompletion() {
  const intl = useIntl();
  const history = useHistory();
  const { patientId } = useCNFormContext();
  const { skipSectionStep } = useWizardStateContext();
  const { data: patient, isLoading } = usePatientDetails(patientId, false);
  const isActivated =
    Boolean(patient?.activated_at) &&
    patient?.status === PatientStatus.Enrolled;

  return (
    <Wizard.Step
      isLoading={isLoading}
      sections={INIT_VISIT_SECTIONS}
      title={intl.formatMessage({ defaultMessage: 'Profile Completion' })}
      onNext={() => {
        const notActivatedPath = initVisitPath(
          '/profile-completion',
          '/not-activated',
        );

        if (isActivated) {
          skipSectionStep(notActivatedPath);
          history.push(initVisitPath('/device-tips', '/index'));
        } else {
          history.push(notActivatedPath);
        }
      }}
    >
      <FieldGroup>
        <Script>
          <FormattedMessage defaultMessage="Our goals for the call today are to make sure that your devices are connected to us and assure you feel comfortable using them. We'll also review your medical and lifestyle information to develop a game plan that we'll work on together." />
        </Script>
        <Script>
          <FormattedMessage defaultMessage="First, we need to gather some information to be able to contact you." />
        </Script>
        <ol className={verificationsList}>
          <li>
            <FormattedMessage defaultMessage="Confirm patient profile is complete" />
          </li>
          <li>
            <FormattedMessage defaultMessage="Complete communication preferences" />
          </li>
          <li>
            <FormattedMessage defaultMessage="Complete emergency contact" />
          </li>
          <li>
            <FormattedMessage defaultMessage="Complete caregiver info" />
          </li>
        </ol>
        <AdminProfileButton />
      </FieldGroup>
    </Wizard.Step>
  );
}
