import { CommonInfoField, PLACEHOLDER } from '@/shared/common/CommonInfoField';
import { type PatientDetails } from '@/shared/generated/grpc/go/pms/pkg/patient/pms.pb';
import {
  displayGenderV2,
  displayGoogleDateAge,
  displayGoogleDateDOB,
} from '@/shared/patient/patientInfoUtils';

type Props = {
  patientDetails: PatientDetails;
};

export function GenderAgeDobCompoundInfoField({ patientDetails }: Props) {
  const patient = patientDetails?.patient;
  const age = patient?.dob ? displayGoogleDateAge(patient.dob) : PLACEHOLDER;
  const genderText = patient?.gender
    ? displayGenderV2(patient.gender)
    : PLACEHOLDER;
  const displayDob = patient?.dob
    ? displayGoogleDateDOB(patient.dob)
    : PLACEHOLDER;
  return <CommonInfoField text={`${genderText}, ${age} y.o., ${displayDob}`} />;
}
