import cx from 'classnames';
import { useIntl } from 'react-intl';

import { useTimeEstimate } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared/useTimeEstimate';
import { Wizard } from '@/shared/common/Wizard';
import { flexContainer } from '@/shared/jsStyle/flex.css';
import { gap } from '@/shared/jsStyle/gap.css';

import { INIT_VISIT_SECTIONS } from '../../metadata';
import { ActionPlanIntroScript } from '../../shared/actionPlan/ActionPlanIntroScript';

export function ActionPlanScript() {
  const intl = useIntl();

  return (
    <Wizard.Step
      sections={INIT_VISIT_SECTIONS}
      title={intl.formatMessage({ defaultMessage: 'Action Plan' })}
      subtitle={useTimeEstimate(
        intl.formatMessage({ defaultMessage: '1-3 min' }),
      )}
    >
      <div className={cx(flexContainer.column, gap.M)}>
        <ActionPlanIntroScript />
      </div>
    </Wizard.Step>
  );
}
