import isEmpty from 'lodash/isEmpty';
import type { ReactElement, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { Grid, Typography } from '@/deprecated/mui';
import CheckIcon from '@/shared/assets/svgs/checkMark.svg?react';
import SpeechBalloonIcon from '@/shared/assets/svgs/speech-balloon.svg?react';
import { useSidePanelCtx } from '@/shared/common/Page';
import { Tooltip } from '@/shared/common/Tooltip';
import type { PatientClinicalProfile } from '@/shared/generated/grpc/go/pms/pkg/patient/pms.pb';
import { useFlags } from '@/shared/hooks';
import { isAlertTagVerified, isAlertVerified } from '@/shared/patient/alerts';
import type { TagVariant } from '@/shared/tempo/atom/Tag';
import { Tag } from '@/shared/tempo/atom/Tag';
import { color, typography } from '@/shared/tempo/theme';
import type { VitalsAlert } from '@/shared/types/alert.types';
import { AlertStatus } from '@/shared/types/alert.types';
import type { PatientVitals } from '@/shared/types/vitals.types';

import {
  alertDescriptionBody,
  labelTooltip,
  pendingTagIcon,
  validatedTagIcon,
} from './AlertDescription.css';
import { AlertDescriptionRow } from './AlertDescriptionRow';
import type { AlertDescriptionMeta } from './hooks';
import { useAlertDescription } from './hooks';
import { isWeightRelatedTags } from './utils';

export const AlertDescription = ({
  alert,
  vitals,
  isProfilePage,
  patientProfile,
}: {
  alert: VitalsAlert;
  vitals: PatientVitals;
  isProfilePage: boolean;
  patientProfile?: PatientClinicalProfile;
}) => {
  const { validatedAlerts } = useFlags();
  const { state: sidePanelState } = useSidePanelCtx();

  let alertDescriptions: AlertDescriptionMeta[] = [];
  alertDescriptions = useAlertDescription(alert, vitals, patientProfile);

  const getColumnSize = (tag: string) => {
    if (isWeightRelatedTags(tag)) {
      return 12;
    }

    return sidePanelState.isExpanded ? 4 : 6;
  };

  return (
    <>
      {isEmpty(alertDescriptions) ? (
        <FormattedMessage defaultMessage="Invalid input data" />
      ) : (
        <>
          {isProfilePage && (
            <Typography
              style={{
                ...typography.Body.Small,
                color: color.Theme.Light['Base Font Subtle'],
              }}
            >
              <FormattedMessage defaultMessage="Alert Description" />
            </Typography>
          )}
          <div className={alertDescriptionBody}>
            <AlertValidationLabel alert={alert} />
            <Grid container spacing={2} sx={{ flexGrow: 1 }}>
              {alertDescriptions.map(
                ({ tag, readings, timestamp, formatter }, index) => (
                  <Grid item sm={getColumnSize(tag)} key={`${tag}-${index}`}>
                    {isEmpty(readings) ? (
                      <FormattedMessage defaultMessage="Readings is empty" />
                    ) : (
                      <AlertDescriptionRow
                        isTagVerified={
                          validatedAlerts && isAlertTagVerified(alert, tag)
                        }
                        tag={tag}
                        readings={readings}
                        timestamp={timestamp}
                        formatter={formatter}
                      />
                    )}
                  </Grid>
                ),
              )}
            </Grid>
          </div>
        </>
      )}
    </>
  );
};

function AlertValidationLabel({ alert }: { alert: VitalsAlert }) {
  const alertLabelProps = useAlertLabelProps(alert);
  return alertLabelProps ? (
    <Tooltip
      tooltipMessage={alertLabelProps.tooltipMessage}
      tooltipPlacement="bottom"
      className={labelTooltip}
    >
      <Tag variant={alertLabelProps.variant}>
        <Tag.Icon>{alertLabelProps.icon}</Tag.Icon>
        {alertLabelProps.labelMessage}
      </Tag>
    </Tooltip>
  ) : null;
}

type AlertLabelProps = {
  tooltipMessage: ReactNode;
  icon: ReactElement;
  labelMessage: ReactNode;
  variant: TagVariant;
};

function useAlertLabelProps(alert: VitalsAlert): Nullable<AlertLabelProps> {
  const { validatedAlerts } = useFlags();

  if (alert.status === AlertStatus.Pending) {
    return {
      tooltipMessage: (
        <FormattedMessage defaultMessage="Patient was sent an SMS request to recheck their vitals – the alert is hidden from the board for 1 hour or until the retaken vital comes back abnormal" />
      ),
      icon: <SpeechBalloonIcon className={pendingTagIcon} />,
      labelMessage: <FormattedMessage defaultMessage="Pending" />,
      variant: 'default',
    };
  }

  if (validatedAlerts && isAlertVerified(alert)) {
    return {
      tooltipMessage: (
        <FormattedMessage defaultMessage="Patient rechecked vitals and validated original alert" />
      ),
      icon: <CheckIcon className={validatedTagIcon} />,
      labelMessage: <FormattedMessage defaultMessage="Validated" />,
      variant: 'danger',
    };
  }

  if (alert.retake_notification_for_device?.length) {
    return {
      tooltipMessage: (
        <FormattedMessage defaultMessage="Patient was sent an SMS request to recheck their vitals" />
      ),
      icon: <SpeechBalloonIcon className={pendingTagIcon} />,
      labelMessage: <FormattedMessage defaultMessage="SMS Sent" />,
      variant: 'info',
    };
  }

  return null;
}
