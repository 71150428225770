import { convertProgramResponseToConditions } from '@/pages/adminPanel/patient-profile/tabs/ClinicalProfileTab/clinicalprofile.utils';
import { PatientStatusEnum } from '@/shared/generated/grpc/go/pms/pkg/patient/pms.pb';
import { usePatientPrograms } from '@/shared/hooks/queries/program-condition.queries';
import { Condition } from '@/shared/types/clinicalprofile.types';

export function useHasChf(patientId: string) {
  const { data: ptConditionsPerProgram } = usePatientPrograms(patientId);
  const [programConditions] = convertProgramResponseToConditions(
    ptConditionsPerProgram,
    PatientStatusEnum.ENROLLED,
  );
  return programConditions.includes(Condition.CHF);
}
