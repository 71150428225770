import type { IntlShape } from 'react-intl';
import { FormattedMessage, useIntl } from 'react-intl';

import { Table } from '@/shared/common/Table';
import type { Program } from '@/shared/generated/grpc/go/pms/pkg/patient/pms.pb';
import {
  Condition,
  ProgramStatus,
  ProgramType,
} from '@/shared/generated/grpc/go/pms/pkg/patient/pms.pb';
import { useConditionI18nMap } from '@/shared/patient/conditions.utils';
import { useProgramTypeI18n } from '@/shared/patient/useProgramI18n';
import { useProgramStatusI18n } from '@/shared/patient/useProgramStatusI18n';

import './Orders.css';

type OrdersProgramDetailProps = {
  programs: Program[];
};

export function OrdersProgramDetails({ programs }: OrdersProgramDetailProps) {
  return (
    <div>
      {programs.map((program) => (
        <ProgramItem key={program.name} program={program} />
      ))}
    </div>
  );
}

interface ProgramItemProps {
  program: Program;
}

function ProgramItem({ program }: ProgramItemProps) {
  const intl = useIntl();
  const columnHeaders = getColumnHeaders(intl);
  const colSpan = columnHeaders.length;

  const { programType, programStatus, conditions } = program;
  const programTypeMapping = useProgramTypeI18n();
  const programStatusMapping = useProgramStatusI18n();
  const conditionMapping = useConditionI18nMap();

  return (
    <div>
      <h3>{`${
        programTypeMapping[programType || ProgramType.PROGRAM_TYPE_UNSPECIFIED]
      } - ${
        programStatusMapping[
          programStatus || ProgramStatus.PROGRAM_STATUS_UNSPECIFIED
        ]
      }`}</h3>
      <Table.Container>
        <Table>
          <Table.Header columns={columnHeaders} />
          <Table.Body>
            {conditions && conditions.length > 0 ? (
              conditions.map((condition, index) => (
                <Table.Row key={index}>
                  <Table.NodeCell>
                    {conditionMapping[
                      condition.conditionType || Condition.CONDITION_UNSPECIFIED
                    ] || (
                      <FormattedMessage defaultMessage="Condition Unspecified" />
                    )}
                  </Table.NodeCell>
                  <Table.NodeCell>
                    {condition.category || (
                      <FormattedMessage defaultMessage="N/A" />
                    )}
                    {condition.category && condition.etiology ? (
                      <span>.{condition.etiology}</span>
                    ) : null}
                  </Table.NodeCell>
                  <Table.NodeCell>
                    {condition.description || (
                      <FormattedMessage defaultMessage="No Description" />
                    )}
                  </Table.NodeCell>
                </Table.Row>
              ))
            ) : (
              <Table.Row>
                <Table.NodeCell colSpan={colSpan}>
                  <FormattedMessage defaultMessage="No conditions found for patient" />
                </Table.NodeCell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </Table.Container>
    </div>
  );
}

function getColumnHeaders(intl: IntlShape) {
  return [
    { title: intl.formatMessage({ defaultMessage: 'Condition Name' }) },
    { title: intl.formatMessage({ defaultMessage: 'Code' }) },
    { title: intl.formatMessage({ defaultMessage: 'Description' }) },
  ];
}
