export enum SafetyChoice {
  No = 'NO',
  Yes = 'YES',
}

export enum SafetyFrequencyChoice {
  Never = 'NEVER',
  Sometimes = 'SOMETIMES',
  Often = 'OFTEN',
}

export type SafetyFields = {
  safetyChoice?: SafetyChoice;
  physicalHarmFrequency?: SafetyFrequencyChoice;
  threatFrequency?: SafetyFrequencyChoice;
  verbalAbuseFrequency?: SafetyFrequencyChoice;
};
