**Cadence Encounter Type:**

Initial clinical visit

{{ initialVisitIntro data }}

{{#if (isInitialVisitPatientNoShow data) }}
{{ initialVisitPatientNoShow data intl }}
{{else}}
### HPI
{{> patient_summary }}

{{ initialVisitAdditionalNotes data }}

#### Recent Vital Trends:
{{> vitals_list }}

{{ initialVisitActionPlan data intl }}

{{ initialVisitSymptoms data rpmConditions intl }}

{{ initialVisitEmergencyVisits data intl }}

{{ initialVisitDeviceTips data }}

{{ initialVisitMedicationReview data noteId patientMedications }}

{{ initialVisitGeneralAssessment data }}

{{ initialVisitEarlyEndCall data intl }}
{{/if}}
