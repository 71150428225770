import uniq from 'lodash/uniq';
import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import type { ReferenceMedication } from '@/shared/generated/grpc/go/pms/pkg/patient/medication/medication.pb';
import { Tooltip } from '@/shared/tempo/atom/Tooltip';

import {
  arrow,
  brandList,
  container,
  header,
  triggerContainer,
} from './BrandsTooltip.css';

type Props = {
  children: ReactNode;
  referenceMed?: ReferenceMedication;
  isPreview?: boolean;
};

export function BrandsTooltip({ children, isPreview, referenceMed }: Props) {
  const hasBrands = (referenceMed?.brandNames?.length ?? 0) > 0;

  // isPreview indicates we're rendering in a server context and we cannot
  // render this tooltip (it uses portals) in that context.
  if (isPreview) {
    return <>{children}</>;
  }

  return (
    <Tooltip
      isDisabled={!hasBrands}
      content={<TooltipContent referenceMed={referenceMed} />}
      placement="bottom-start"
      arrowClassName={arrow}
      classes={{ triggerContainer: hasBrands ? triggerContainer : undefined }}
    >
      {children}
    </Tooltip>
  );
}

function TooltipContent({ referenceMed }: Pick<Props, 'referenceMed'>) {
  return (
    <div className={container}>
      <div className={header}>
        <FormattedMessage defaultMessage="Possible brands" />
      </div>
      <span className={brandList}>
        {uniq(referenceMed?.brandNames)?.join(', ')}
      </span>
    </div>
  );
}
