import { format, parseISO } from 'date-fns';
import { FormattedMessage, type IntlShape, useIntl } from 'react-intl';

import type { NextAppointmentRecommendation } from '@/shared/generated/grpc/go/pms/pkg/scheduling/scheduling.pb';
import { useFlags } from '@/shared/hooks';
import { type Patient } from '@/shared/types/patient.types';

import {
  isRtePatientWithRecommendedStartDate,
  shouldBeScheduledAsap,
} from '../appointment.utils';
import { emphasis } from './RecommendationInfo.css';

type Props = {
  patient: Patient;
  recommendedAppt: NextAppointmentRecommendation;
};

export function RecommendationInfo({ patient, recommendedAppt }: Props) {
  const { smartSchedulingRte } = useFlags();
  const intl = useIntl();

  if (!recommendedAppt.appointmentTypeName) {
    return null;
  }

  const formattedDate = recommendedAppt.startDate
    ? format(parseISO(recommendedAppt.startDate), 'MMM d, yyyy')
    : intl.formatMessage({ defaultMessage: 'N/A' });

  if (isRtePatientWithRecommendedStartDate(patient, recommendedAppt)) {
    // When smart scheduling for RTE is enabled, we do not display the recommended appointmentCareProvider since SmartScheduler will determine best care provider
    if (smartSchedulingRte) {
      return (
        <FormattedMessage
          defaultMessage="Recommended next visit is {article} <emphasize>{appointmentTypeName}</emphasize> around <emphasize>{date}</emphasize>"
          values={{
            date: formattedDate,
            emphasize,
            article: getArticle(recommendedAppt.appointmentTypeName, intl),
            appointmentTypeName: recommendedAppt.appointmentTypeName,
          }}
        />
      );
    }
    // When smart scheduling for RTE is disabled, we display the recommended appointmentCareProvider
    if (!smartSchedulingRte && recommendedAppt.careProviderAcuityCalendarId) {
      return (
        <FormattedMessage
          defaultMessage="Recommended next visit is {article} <emphasize>{appointmentTypeName}</emphasize> with <emphasize>{appointmentCareProvider}</emphasize> around <emphasize>{date}</emphasize>"
          values={{
            date: formattedDate,
            emphasize,
            article: getArticle(recommendedAppt.appointmentTypeName, intl),
            appointmentTypeName: recommendedAppt.appointmentTypeName,
            appointmentCareProvider: `${recommendedAppt.careProviderFirstName} ${recommendedAppt.careProviderLastName}`,
          }}
        />
      );
    }
  }

  const isRelevantForImmediateScheduling = shouldBeScheduledAsap(
    patient,
    recommendedAppt,
  );

  if (isRelevantForImmediateScheduling) {
    return (
      <FormattedMessage
        defaultMessage="Recommended next visit is {article} <emphasize>{appointmentTypeName} at the next available appointment time</emphasize>"
        values={{
          emphasize,
          article: getArticle(recommendedAppt.appointmentTypeName, intl),
          appointmentTypeName: recommendedAppt.appointmentTypeName,
        }}
      />
    );
  }
  if (recommendedAppt.startDate) {
    return (
      <FormattedMessage
        defaultMessage="Recommended next visit is {article} <emphasize>{appointmentTypeName}</emphasize> around the week of <emphasize>{date}</emphasize>"
        values={{
          date: formattedDate,
          emphasize,
          article: getArticle(recommendedAppt.appointmentTypeName, intl),
          appointmentTypeName: recommendedAppt.appointmentTypeName,
        }}
      />
    );
  }
  return (
    <FormattedMessage
      defaultMessage="Recommended next visit is {article} <emphasize>{appointmentTypeName}</emphasize>"
      values={{
        emphasize,
        article: getArticle(recommendedAppt.appointmentTypeName, intl),
        appointmentTypeName: recommendedAppt.appointmentTypeName,
      }}
    />
  );
}

function emphasize(children: string) {
  return <span className={emphasis}>{children}</span>;
}

function getArticle(word: string, intl: IntlShape) {
  const vowels = ['a', 'e', 'i', 'o', 'u'];
  return vowels.includes(word[0].toLowerCase())
    ? intl.formatMessage({ defaultMessage: 'an' })
    : intl.formatMessage({ defaultMessage: 'a' });
}
