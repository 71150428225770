import type { IntlShape } from 'react-intl';

import { INIT_VISIT_SECTIONS } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/CNWizardForm/sections/initialVisit';
import type { EmergencyVisitsFormFields } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/CNWizardForm/sections/shared/emergencyVisits';
import type { SectionStepsState } from '@/shared/common/Wizard/state';
import { getWizardStepValues } from '@/shared/common/Wizard/state';

import { cnEmergencyVisits } from '../../shared/cnEmergencyVisits';
import { sectionHasData } from '../../util';

export function initialVisitEmergencyVisits(
  data: SectionStepsState,
  intl: IntlShape,
) {
  const edVisitData = getWizardStepValues(
    data,
    INIT_VISIT_SECTIONS,
    '/emergency-visits',
    '/index',
  )<EmergencyVisitsFormFields>();

  // we'll only have this if we've got a CHF patient. can skip altogether otherwise
  if (!sectionHasData(edVisitData)) {
    return '';
  }

  return cnEmergencyVisits({ intl, edVisitData });
}
