import { intlReducer } from 'react-intl-redux';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import authReducer from '@/pages/auth/reducer';

const reducers = combineReducers({
  intl: intlReducer,
  auth: authReducer,
  form: formReducer,
});

// eslint-disable-next-line import/no-default-export
export default reducers;
