/* eslint-disable */
// @ts-nocheck
/*
* This file is a generated Typescript file for GRPC Gateway, DO NOT MODIFY
*/

import * as CadenceModelsV1Models from "../../../../../cadence/models/models.pb"
import * as GoogleProtobufEmpty from "../../../../../google/protobuf/empty.pb"
import * as GoogleProtobufField_mask from "../../../../../google/protobuf/field_mask.pb"
import * as GoogleProtobufTimestamp from "../../../../../google/protobuf/timestamp.pb"
import * as GoogleProtobufWrappers from "../../../../../google/protobuf/wrappers.pb"
import * as GoPmsPkgNoteNote from "../../note/note.pb"
import * as GoPmsPkgPatientPms from "../pms.pb"
import * as fm from "@/shared/generated/grpc/fetch.pb"

export enum AsyncTitrationAsyncTitrationStatus {
  ASYNC_TITRATION_STATUS_UNSPECIFIED = "ASYNC_TITRATION_STATUS_UNSPECIFIED",
  NEW = "NEW",
  INITIALLY_REVIEWED = "INITIALLY_REVIEWED",
  PATIENT_CONSENTED = "PATIENT_CONSENTED",
  APPLIED = "APPLIED",
  REJECTED_ON_INITIAL_REVIEW = "REJECTED_ON_INITIAL_REVIEW",
  PATIENT_REJECTED = "PATIENT_REJECTED",
  REJECTED_ON_FINAL_REVIEW = "REJECTED_ON_FINAL_REVIEW",
}

export enum AsyncTitrationMedicationWasNotTitratedReason {
  MEDICATION_WAS_NOT_TITRATED_REASON_UNSPECIFIED = "MEDICATION_WAS_NOT_TITRATED_REASON_UNSPECIFIED",
  PROVIDER_PREFERENCE = "PROVIDER_PREFERENCE",
  PATIENT_PREFERENCES = "PATIENT_PREFERENCES",
  MEDICAL_REASON = "MEDICAL_REASON",
  FINANCIAL_REASON = "FINANCIAL_REASON",
  NEW_PRESCRIPTION_REQUIRED = "NEW_PRESCRIPTION_REQUIRED",
  MEDICATION_NOT_ACCURATE = "MEDICATION_NOT_ACCURATE",
  AGE_OR_CONDITION = "AGE_OR_CONDITION",
  CALCIUM_TOO_HIGH = "CALCIUM_TOO_HIGH",
  CALCIUM_TOO_LOW = "CALCIUM_TOO_LOW",
  HEART_RATE_TOO_LOW = "HEART_RATE_TOO_LOW",
  KIDNEY_FUNCTION_TOO_LOW = "KIDNEY_FUNCTION_TOO_LOW",
  MANAGED_BY_EXTERNAL_PROVIDER = "MANAGED_BY_EXTERNAL_PROVIDER",
  POTASSIUM_TOO_HIGH = "POTASSIUM_TOO_HIGH",
  POTASSIUM_TOO_LOW = "POTASSIUM_TOO_LOW",
  RECENT_MEDICATION_CHANGE_BY_EXTERNAL_PROVIDER = "RECENT_MEDICATION_CHANGE_BY_EXTERNAL_PROVIDER",
  SIDE_EFFECT_AT_HIGHER_DOSE = "SIDE_EFFECT_AT_HIGHER_DOSE",
  SODIUM_TOO_LOW = "SODIUM_TOO_LOW",
  ADJUSTED_ANOTHER_MEDICATION = "ADJUSTED_ANOTHER_MEDICATION",
  ELECTROLYTE_ABNORMALITY = "ELECTROLYTE_ABNORMALITY",
  NEED_LABS = "NEED_LABS",
  ACUTE_MEDICAL_ISSUE = "ACUTE_MEDICAL_ISSUE",
  PROVIDER_GOAL_DIFFERS = "PROVIDER_GOAL_DIFFERS",
  MEDICATION_VERIFICATION = "MEDICATION_VERIFICATION",
  HYPOGLYCEMIC_EPISODES = "HYPOGLYCEMIC_EPISODES",
  HYPOTENSION = "HYPOTENSION",
  LABILE_BLOOD_GLUCOSE = "LABILE_BLOOD_GLUCOSE",
  ABNORMAL_LABS = "ABNORMAL_LABS",
  DISENROLLED = "DISENROLLED",
  DUPLICATE_TASK = "DUPLICATE_TASK",
  INSUFFICIENT_READINGS = "INSUFFICIENT_READINGS",
  NEW_RX_REQUIRED_FOR_CURRENT_MEDS_MGMT_PATIENT = "NEW_RX_REQUIRED_FOR_CURRENT_MEDS_MGMT_PATIENT",
  NON_COMPLIANT = "NON_COMPLIANT",
  RECENT_PATIENT_REFUSAL = "RECENT_PATIENT_REFUSAL",
  RECOMMEND_ONLY_PATIENT = "RECOMMEND_ONLY_PATIENT",
}

export enum AsyncTitrationPatientRejectedTitrationReason {
  PATIENT_REJECTED_TITRATION_REASON_UNSPECIFIED = "PATIENT_REJECTED_TITRATION_REASON_UNSPECIFIED",
  RECENT_ADJUSTMENT_ON_THIS_MEDICATION = "RECENT_ADJUSTMENT_ON_THIS_MEDICATION",
  RECENT_ADJUSTMENT_ON_ANOTHER_MEDICATION = "RECENT_ADJUSTMENT_ON_ANOTHER_MEDICATION",
  REPORTED_SIDE_EFFECT_AT_HIGHER_DOSE = "REPORTED_SIDE_EFFECT_AT_HIGHER_DOSE",
  NOT_TAKING_MEDS_AS_PRESCRIBED = "NOT_TAKING_MEDS_AS_PRESCRIBED",
  WOULD_LIKE_TO_SPEAK_TO_PROVIDER_FIRST = "WOULD_LIKE_TO_SPEAK_TO_PROVIDER_FIRST",
  WOULD_LIKE_TO_WORK_ON_LIFESTYLE = "WOULD_LIKE_TO_WORK_ON_LIFESTYLE",
  DOES_NOT_WANT_TO_TAKE_MORE_MEDICATION = "DOES_NOT_WANT_TO_TAKE_MORE_MEDICATION",
  PROVIDER_SET_GOAL_HIGHER_THAN_130_OVER_80 = "PROVIDER_SET_GOAL_HIGHER_THAN_130_OVER_80",
  ACUTE_MEDICAL_CONDITION_AFFECTING_VITALS = "ACUTE_MEDICAL_CONDITION_AFFECTING_VITALS",
  CADENCE_MEDICATION_LIST_INACCURATE = "CADENCE_MEDICATION_LIST_INACCURATE",
  HIGH_MEDICATION_COST = "HIGH_MEDICATION_COST",
  DOESNT_WANT_INSULIN = "DOESNT_WANT_INSULIN",
}

export enum AsyncTitrationAsyncTitrationType {
  ASYNC_TITRATION_TYPE_UNSPECIFIED = "ASYNC_TITRATION_TYPE_UNSPECIFIED",
  UP_TITRATION = "UP_TITRATION",
  NEW_MED = "NEW_MED",
}

export enum AsyncTitrationAsyncTitrationCreationMethod {
  ASYNC_TITRATION_CREATION_METHOD_UNSPECIFIED = "ASYNC_TITRATION_CREATION_METHOD_UNSPECIFIED",
  AUTO = "AUTO",
  MANUAL = "MANUAL",
}

export enum MedicationChangeStatus {
  STATUS_UNSPECIFIED = "STATUS_UNSPECIFIED",
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  NEEDS_REVIEW = "NEEDS_REVIEW",
}

export enum MedicationChangeChangeType {
  CHANGE_TYPE_UNSPECIFIED = "CHANGE_TYPE_UNSPECIFIED",
  EMR_CAPTURE = "EMR_CAPTURE",
  RECONCILIATION = "RECONCILIATION",
  TITRATION = "TITRATION",
  START = "START",
  STOP = "STOP",
  REVIEW = "REVIEW",
}

export enum MedicationChangeFrequency {
  FREQUENCY_UNSPECIFIED = "FREQUENCY_UNSPECIFIED",
  DAILY = "DAILY",
  DAILY_AM = "DAILY_AM",
  DAILY_PM = "DAILY_PM",
  TWICE_A_DAY = "TWICE_A_DAY",
  THREE_TIMES_A_DAY = "THREE_TIMES_A_DAY",
  FOUR_TIMES_A_DAY = "FOUR_TIMES_A_DAY",
  EVERY_OTHER_DAY = "EVERY_OTHER_DAY",
  ONCE_A_WEEK = "ONCE_A_WEEK",
  TWICE_A_WEEK = "TWICE_A_WEEK",
  MONTHLY = "MONTHLY",
  AS_NEEDED = "AS_NEEDED",
  HOURLY = "HOURLY",
  EVERY_BEDTIME = "EVERY_BEDTIME",
  WITH_MEALS = "WITH_MEALS",
}

export enum ReferenceMedicationMedicationClass {
  MEDICATION_CLASS_UNSPECIFIED = "MEDICATION_CLASS_UNSPECIFIED",
  THIAZIDE_DIURETICS = "THIAZIDE_DIURETICS",
  ARB = "ARB",
  CCB_DHP = "CCB_DHP",
  ARNI = "ARNI",
  ACE_INHIBITOR = "ACE_INHIBITOR",
  CCB_NON_DHP = "CCB_NON_DHP",
  LOOP_DIURETICS = "LOOP_DIURETICS",
  ALDOSTERONE_ANTAGONISTS = "ALDOSTERONE_ANTAGONISTS",
  BETA_BLOCKERS = "BETA_BLOCKERS",
  ALPHA_ADRENERGIC_BLOCKERS = "ALPHA_ADRENERGIC_BLOCKERS",
  RENIN_INHIBITORS = "RENIN_INHIBITORS",
  CENTRAL_ALPHA_2_AGONIST = "CENTRAL_ALPHA_2_AGONIST",
  VASODILATOR = "VASODILATOR",
  SODIUM_GLUCOSE_CO_TRANSPORTER_2_INHIBITORS = "SODIUM_GLUCOSE_CO_TRANSPORTER_2_INHIBITORS",
  GLUCAGON_LIKE_PEPTIDE_1_RECEPTOR_AGONIST = "GLUCAGON_LIKE_PEPTIDE_1_RECEPTOR_AGONIST",
  DIPEPTIDYL_PEPTIDASE_IV_INHIBITORS = "DIPEPTIDYL_PEPTIDASE_IV_INHIBITORS",
  THIAZOLIDINEDIONES = "THIAZOLIDINEDIONES",
  GLUCOSE_DEPENDENT_INSULINOTROPIC_POLYPEPTIDE = "GLUCOSE_DEPENDENT_INSULINOTROPIC_POLYPEPTIDE",
  SULFONYLUREAS_2ND_GENERATION = "SULFONYLUREAS_2ND_GENERATION",
  BASAL_INSULIN = "BASAL_INSULIN",
  PRANDIAL_INSULIN = "PRANDIAL_INSULIN",
  BIGUANIDES = "BIGUANIDES",
  MEGLITINIDES = "MEGLITINIDES",
}

export enum RxNormMedicationUnit {
  MEDICATION_UNIT_UNSPECIFIED = "MEDICATION_UNIT_UNSPECIFIED",
  MG = "MG",
  MG_ML = "MG_ML",
  G = "G",
  IU = "IU",
  MEQ = "MEQ",
  MCG = "MCG",
  UNIT = "UNIT",
  U_ML = "U_ML",
  MG_24_HR = "MG_24_HR",
}

export enum RxNormDeliveryMechanism {
  DELIVERY_MECHANISM_UNSPECIFIED = "DELIVERY_MECHANISM_UNSPECIFIED",
  TABLET = "TABLET",
  CAPSULE = "CAPSULE",
  INJECTION = "INJECTION",
  TOPICAL_OINTMENT = "TOPICAL_OINTMENT",
  TRANSDERMAL_PATCH = "TRANSDERMAL_PATCH",
  SUBCUTANEOUS_SOLUTION = "SUBCUTANEOUS_SOLUTION",
  SUBCUTANEOUS_PEN_INJECTOR = "SUBCUTANEOUS_PEN_INJECTOR",
  PEN_INJECTOR = "PEN_INJECTOR",
  INTRAVENOUS_SOLUTION = "INTRAVENOUS_SOLUTION",
  ORAL_SOLUTION = "ORAL_SOLUTION",
  AUTO_INJECTOR = "AUTO_INJECTOR",
  CARTRIDGE = "CARTRIDGE",
}

export enum RxNormReleaseType {
  RELEASE_TYPE_UNSPECIFIED = "RELEASE_TYPE_UNSPECIFIED",
  EXTENDED = "EXTENDED",
  IMMEDIATE = "IMMEDIATE",
  EXTENDED_12_HR = "EXTENDED_12_HR",
}

export type GetPatientMedicationSummaryRequest = {
  name?: string
}

export type StopMedicationRequest = {
  patientId?: string
  rxnormId?: GoogleProtobufWrappers.Int64Value
  endDate?: GoogleProtobufTimestamp.Timestamp
  reason?: string
  noteId?: GoogleProtobufWrappers.Int32Value
}

export type NewMedicationChangeRequest = {
  patientId?: string
  rxnormId?: GoogleProtobufWrappers.Int64Value
  doseQuantities?: number[]
  frequencies?: MedicationChangeFrequency[]
  startDate?: GoogleProtobufTimestamp.Timestamp
  endDate?: GoogleProtobufTimestamp.Timestamp
  maxToleratedDoseAchieved?: GoogleProtobufWrappers.BoolValue
  reason?: GoogleProtobufWrappers.StringValue
  noteId?: GoogleProtobufWrappers.Int32Value
}

export type StructureMedicationRequest = {
  medicationChange?: string
  rxnormId?: GoogleProtobufWrappers.Int64Value
  doseQuantities?: number[]
  frequencies?: MedicationChangeFrequency[]
  startDate?: GoogleProtobufTimestamp.Timestamp
  endDate?: GoogleProtobufTimestamp.Timestamp
  status?: MedicationChangeStatus
  reason?: GoogleProtobufWrappers.StringValue
  noteId?: GoogleProtobufWrappers.Int32Value
  medicationName?: GoogleProtobufWrappers.StringValue
}

export type ReviewMedicationRequest = {
  patientId?: string
  medicationChangeId?: GoogleProtobufWrappers.Int64Value
  rxnormId?: GoogleProtobufWrappers.Int64Value
  doseQuantities?: number[]
  frequencies?: MedicationChangeFrequency[]
  startDate?: GoogleProtobufTimestamp.Timestamp
  endDate?: GoogleProtobufTimestamp.Timestamp
  status?: MedicationChangeStatus
  reason?: GoogleProtobufWrappers.StringValue
  noteId?: GoogleProtobufWrappers.Int32Value
  medicationName?: GoogleProtobufWrappers.StringValue
}

export type AcceptMedicationRequest = {
  medicationChange?: string
}

export type UndoMedicationChangeRequest = {
  medicationChange?: string
}

export type DenyMedicationRequest = {
  medicationChange?: string
  endDate?: GoogleProtobufTimestamp.Timestamp
  reason?: GoogleProtobufWrappers.StringValue
  noteId?: GoogleProtobufWrappers.Int32Value
}

export type DeleteMedicationChangeRequest = {
  medicationChange?: string
}

export type GetPatientEhrMedicationsRequest = {
  name?: string
}

export type GetPatientMedicationsRequest = {
  name?: string
}

export type PatientMedicationSummary = {
  latestMedicationChanges?: LatestMedicationChange[]
}

export type LatestMedicationChangePreviousChange = {
  doseQuantities?: number[]
  frequencies?: MedicationChangeFrequency[]
  rxnorm?: RxNorm
  createTime?: GoogleProtobufTimestamp.Timestamp
}

export type LatestMedicationChange = {
  referenceMedicationId?: string
  changeType?: MedicationChangeChangeType
  status?: MedicationChangeStatus
  doseQuantities?: number[]
  frequencies?: MedicationChangeFrequency[]
  rxnorm?: RxNorm
  createTime?: GoogleProtobufTimestamp.Timestamp
  previousChange?: LatestMedicationChangePreviousChange
}

export type PatientEhrMedications = {
  name?: string
  ehrMedications?: EhrMedication[]
}

export type EhrMedication = {
  name?: string
  active?: boolean
  medName?: GoogleProtobufWrappers.StringValue
  unstructuredsig?: GoogleProtobufWrappers.StringValue
  filledAt?: GoogleProtobufTimestamp.Timestamp
  orderedAt?: GoogleProtobufTimestamp.Timestamp
  startedAt?: GoogleProtobufTimestamp.Timestamp
}

export type PatientMedications = {
  name?: string
  referencedMedications?: Medication[]
  unreferencedMedications?: MedicationChange[]
  newMedSuggestions?: NewMedSuggestion[]
}

export type Medication = {
  referenceMedicationId?: GoogleProtobufWrappers.Int64Value
  medChanges?: MedicationChange[]
  referenceMedicationName?: GoogleProtobufWrappers.StringValue
  isDiseaseSpecific?: boolean
  referenceMedication?: ReferenceMedication
}

export type NewMedSuggestion = {
  referenceMedicationId?: GoogleProtobufWrappers.Int64Value
  asyncTitrations?: AsyncTitration[]
  referenceMedicationName?: GoogleProtobufWrappers.StringValue
  isEmpty?: boolean
  referenceMedication?: ReferenceMedication
}

export type AsyncTitration = {
  id?: string
  createdTaskId?: string
  inputPatientId?: string
  inputConditionId?: string
  inputLatestMedicationChangeId?: string
  inputProgramType?: CadenceModelsV1Models.ProgramType
  inputProgramStatus?: CadenceModelsV1Models.ProgramStatus
  inputLatestMedicationStartDate?: GoogleProtobufTimestamp.Timestamp
  inputSystolicAvg30D?: number
  inputDiastolicAvg30D?: number
  createdAt?: GoogleProtobufTimestamp.Timestamp
  inputLatestMedicationCreatedAt?: GoogleProtobufTimestamp.Timestamp
  inputTitratableMedicationChangeId?: string
  inputTitrationProtocolId?: string
  nextRxcui?: string
  nextDoseQuantity?: number
  nextFrequency?: MedicationChangeFrequency
  nextVitalsCriteria?: string
  nextLabsCriteria?: string
  nextSymptomsCriteria?: string
  status?: AsyncTitrationAsyncTitrationStatus
  inputProviderMedManagementDelegation?: CadenceModelsV1Models.MedManagementDelegation
  inputPatientMedManagementDelegation?: CadenceModelsV1Models.MedManagementDelegation
  inputAllowPatientLevelMedManagementDelegation?: boolean
  editedRxcui?: GoogleProtobufWrappers.Int64Value
  medicationWasNotTitratedReason?: AsyncTitrationMedicationWasNotTitratedReason
  editedNote?: GoogleProtobufWrappers.StringValue
  initialReviewNoteId?: GoogleProtobufWrappers.Int32Value
  consentRequestNoteId?: GoogleProtobufWrappers.Int32Value
  prescriptionChangeNoteId?: GoogleProtobufWrappers.Int32Value
  initialReviewNoteStatus?: GoPmsPkgNoteNote.NoteStatus
  consentRequestNoteStatus?: GoPmsPkgNoteNote.NoteStatus
  prescriptionChangeNoteStatus?: GoPmsPkgNoteNote.NoteStatus
  initialReviewCareProviderId?: GoogleProtobufWrappers.StringValue
  initialReviewAt?: GoogleProtobufTimestamp.Timestamp
  approvingCareProvider?: CareProviderDetails
  editedMaxToleratedDoseAchieved?: GoogleProtobufWrappers.BoolValue
  editedDoseQuantities?: number[]
  editedFrequencies?: MedicationChangeFrequency[]
  updatedAt?: GoogleProtobufTimestamp.Timestamp
  patientRejectedTitrationReason?: AsyncTitrationPatientRejectedTitrationReason
  type?: AsyncTitrationAsyncTitrationType
  creationMethod?: AsyncTitrationAsyncTitrationCreationMethod
}

export type CareProviderDetails = {
  givenName?: string
  familyName?: string
  role?: string
}

export type NextTitrationStep = {
  patientId?: string
  titratableMedicationChangeId?: string
  nextTitrationProtocolId?: string
  nextRxcui?: string
  nextDoseQuantity?: number
  nextFrequency?: MedicationChangeFrequency
  nextVitalsCriteria?: string
  nextLabsCriteria?: string
  nextSymptomsCriteria?: string
}

export type MedicationChange = {
  name?: string
  ehrMedication?: EhrMedication
  doseQuantities?: number[]
  frequencies?: MedicationChangeFrequency[]
  status?: MedicationChangeStatus
  startDate?: GoogleProtobufTimestamp.Timestamp
  endDate?: GoogleProtobufTimestamp.Timestamp
  changeType?: MedicationChangeChangeType
  reason?: GoogleProtobufWrappers.StringValue
  maxToleratedDoseAchieved?: GoogleProtobufWrappers.BoolValue
  rxnorm?: RxNorm
  medicationName?: GoogleProtobufWrappers.StringValue
  updatedDate?: GoogleProtobufTimestamp.Timestamp
  noteId?: GoogleProtobufWrappers.Int32Value
  asyncTitration?: AsyncTitration
  nextTitrationStep?: NextTitrationStep
}

export type ListReferenceMedicationsRequest = {
  pageSize?: number
  pageToken?: string
  filter?: string
  orderBy?: string
}

export type GetReferenceMedicationRequest = {
  referenceMedication?: string
}

export type AddReferenceMedicationRequest = {
  medicationName?: string
  conditions?: GoPmsPkgPatientPms.Condition[]
  medClasses?: ReferenceMedicationMedicationClass[]
  components?: string[]
  requiredLabs?: string
  sideEffects?: string
  mechanismOfAction?: string
}

export type UpdateReferenceMedicationRequest = {
  referenceMedication?: string
  medicationName?: string
  conditions?: GoPmsPkgPatientPms.Condition[]
  medClasses?: ReferenceMedicationMedicationClass[]
  components?: string[]
  requiredLabs?: string
  sideEffects?: string
  mechanismOfAction?: string
}

export type AddRxNormRequest = {
  rxcui?: string
  components?: string[]
  doseStrength?: number[]
  deliveryMechanism?: RxNormDeliveryMechanism
  releaseType?: RxNormReleaseType
  unit?: RxNormMedicationUnit
  referenceMedicationId?: number
  brand?: GoogleProtobufWrappers.StringValue
}

export type UpdateRxNormRequest = {
  rxnorm?: string
  rxcui?: string
  components?: string[]
  doseStrength?: number[]
  deliveryMechanism?: RxNormDeliveryMechanism
  releaseType?: RxNormReleaseType
  unit?: RxNormMedicationUnit
  referenceMedicationId?: number
  brand?: GoogleProtobufWrappers.StringValue
}

export type ReferenceMedications = {
  referenceMedications?: ReferenceMedication[]
  nextPageToken?: string
  totalSize?: number
}

export type ReferenceMedication = {
  id?: number
  name?: string
  conditions?: GoPmsPkgPatientPms.Condition[]
  medClasses?: ReferenceMedicationMedicationClass[]
  rxNorms?: RxNorm[]
  components?: string[]
  requiredLabs?: string
  sideEffects?: string
  mechanismOfAction?: string
  brandNames?: string[]
}

export type RxNorm = {
  id?: string
  rxcui?: string
  components?: RxNormComponent[]
  unit?: RxNormMedicationUnit
  deliveryMechanism?: RxNormDeliveryMechanism
  releaseType?: RxNormReleaseType
  brand?: GoogleProtobufWrappers.StringValue
}

export type RxNormComponent = {
  ingredient?: string
  strength?: number
}

export type UpdateAsyncTitrationStatusRequest = {
  name?: string
  status?: AsyncTitrationAsyncTitrationStatus
  initialReviewNoteId?: GoogleProtobufWrappers.Int32Value
  consentRequestNoteId?: GoogleProtobufWrappers.Int32Value
  prescriptionChangeNoteId?: GoogleProtobufWrappers.Int32Value
  editedRxcui?: GoogleProtobufWrappers.Int64Value
  medicationWasNotTitratedReason?: AsyncTitrationMedicationWasNotTitratedReason
  editedNote?: GoogleProtobufWrappers.StringValue
  updateMask?: GoogleProtobufField_mask.FieldMask
  editedStartDate?: GoogleProtobufTimestamp.Timestamp
  editedEndDate?: GoogleProtobufTimestamp.Timestamp
  editedMaxToleratedDoseAchieved?: GoogleProtobufWrappers.BoolValue
  editedDoseQuantities?: number[]
  editedFrequencies?: MedicationChangeFrequency[]
  patientRejectedTitrationReason?: AsyncTitrationPatientRejectedTitrationReason
}

export type PublishAsyncTitrationRequest = {
  noteId?: number
}

export type CreateManualAsyncTitrationRequest = {
  type?: AsyncTitrationAsyncTitrationType
  inputPatientId?: string
  inputTitratableMedicationChangeId?: GoogleProtobufWrappers.Int64Value
  initialReviewNoteId?: GoogleProtobufWrappers.Int32Value
  editedRxcui?: GoogleProtobufWrappers.Int64Value
  editedNote?: GoogleProtobufWrappers.StringValue
  editedStartDate?: GoogleProtobufTimestamp.Timestamp
  editedEndDate?: GoogleProtobufTimestamp.Timestamp
  editedMaxToleratedDoseAchieved?: GoogleProtobufWrappers.BoolValue
  editedDoseQuantities?: number[]
  editedFrequencies?: MedicationChangeFrequency[]
}

export type RevertAsyncTitrationsForNoteRequest = {
  noteId?: number
}

export class MedicationService {
  static GetPatientEhrMedications(req: GetPatientEhrMedicationsRequest, initReq?: fm.InitReq): Promise<PatientEhrMedications> {
    return fm.fetchReq<GetPatientEhrMedicationsRequest, PatientEhrMedications>(`/rpm/v1/${req["name"]}?${fm.renderURLSearchParams(req, ["name"])}`, {...initReq, method: "GET"})
  }
  static GetPatientMedications(req: GetPatientMedicationsRequest, initReq?: fm.InitReq): Promise<PatientMedications> {
    return fm.fetchReq<GetPatientMedicationsRequest, PatientMedications>(`/rpm/v1/${req["name"]}?${fm.renderURLSearchParams(req, ["name"])}`, {...initReq, method: "GET"})
  }
  static StructureMedication(req: StructureMedicationRequest, initReq?: fm.InitReq): Promise<GoogleProtobufEmpty.Empty> {
    return fm.fetchReq<StructureMedicationRequest, GoogleProtobufEmpty.Empty>(`/rpm/v1/${req["medicationChange"]}:structureMedication`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static ReviewMedication(req: ReviewMedicationRequest, initReq?: fm.InitReq): Promise<GoogleProtobufEmpty.Empty> {
    return fm.fetchReq<ReviewMedicationRequest, GoogleProtobufEmpty.Empty>(`/rpm/v1/medications/medicationChange:reviewMedication`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static AcceptMedication(req: AcceptMedicationRequest, initReq?: fm.InitReq): Promise<GoogleProtobufEmpty.Empty> {
    return fm.fetchReq<AcceptMedicationRequest, GoogleProtobufEmpty.Empty>(`/rpm/v1/${req["medicationChange"]}:acceptMedication`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static DenyMedication(req: DenyMedicationRequest, initReq?: fm.InitReq): Promise<GoogleProtobufEmpty.Empty> {
    return fm.fetchReq<DenyMedicationRequest, GoogleProtobufEmpty.Empty>(`/rpm/v1/${req["medicationChange"]}:denyMedication`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static UndoMedicationChange(req: UndoMedicationChangeRequest, initReq?: fm.InitReq): Promise<GoogleProtobufEmpty.Empty> {
    return fm.fetchReq<UndoMedicationChangeRequest, GoogleProtobufEmpty.Empty>(`/rpm/v1/${req["medicationChange"]}:undoMedicationChange`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static TitrateMedication(req: NewMedicationChangeRequest, initReq?: fm.InitReq): Promise<GoogleProtobufEmpty.Empty> {
    return fm.fetchReq<NewMedicationChangeRequest, GoogleProtobufEmpty.Empty>(`/rpm/v1/medications/medicationChange:titrateMedication`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static StopMedication(req: StopMedicationRequest, initReq?: fm.InitReq): Promise<GoogleProtobufEmpty.Empty> {
    return fm.fetchReq<StopMedicationRequest, GoogleProtobufEmpty.Empty>(`/rpm/v1/medications/medicationChange:stopMedication`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static StartMedication(req: NewMedicationChangeRequest, initReq?: fm.InitReq): Promise<GoogleProtobufEmpty.Empty> {
    return fm.fetchReq<NewMedicationChangeRequest, GoogleProtobufEmpty.Empty>(`/rpm/v1/medications/medicationChange:startMedication`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static ListReferenceMedications(req: ListReferenceMedicationsRequest, initReq?: fm.InitReq): Promise<ReferenceMedications> {
    return fm.fetchReq<ListReferenceMedicationsRequest, ReferenceMedications>(`/rpm/v1/medications/references?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static GetReferenceMedication(req: GetReferenceMedicationRequest, initReq?: fm.InitReq): Promise<ReferenceMedication> {
    return fm.fetchReq<GetReferenceMedicationRequest, ReferenceMedication>(`/rpm/v1/${req["referenceMedication"]}?${fm.renderURLSearchParams(req, ["referenceMedication"])}`, {...initReq, method: "GET"})
  }
  static AddReferenceMedication(req: AddReferenceMedicationRequest, initReq?: fm.InitReq): Promise<GoogleProtobufEmpty.Empty> {
    return fm.fetchReq<AddReferenceMedicationRequest, GoogleProtobufEmpty.Empty>(`/rpm/v1/medications/references:addReference`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static AddRxNorm(req: AddRxNormRequest, initReq?: fm.InitReq): Promise<GoogleProtobufEmpty.Empty> {
    return fm.fetchReq<AddRxNormRequest, GoogleProtobufEmpty.Empty>(`/rpm/v1/medications/references:addRxNorm`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static UpdateReferenceMedication(req: UpdateReferenceMedicationRequest, initReq?: fm.InitReq): Promise<GoogleProtobufEmpty.Empty> {
    return fm.fetchReq<UpdateReferenceMedicationRequest, GoogleProtobufEmpty.Empty>(`/rpm/v1/${req["referenceMedication"]}:updateReference`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static UpdateRxNorm(req: UpdateRxNormRequest, initReq?: fm.InitReq): Promise<GoogleProtobufEmpty.Empty> {
    return fm.fetchReq<UpdateRxNormRequest, GoogleProtobufEmpty.Empty>(`/rpm/v1/${req["rxnorm"]}:updateRxnorm`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static DeleteMedicationChange(req: DeleteMedicationChangeRequest, initReq?: fm.InitReq): Promise<GoogleProtobufEmpty.Empty> {
    return fm.fetchReq<DeleteMedicationChangeRequest, GoogleProtobufEmpty.Empty>(`/rpm/v1/${req["medicationChange"]}:deleteMedicationChange`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static GetPatientMedicationSummary(req: GetPatientMedicationSummaryRequest, initReq?: fm.InitReq): Promise<PatientMedicationSummary> {
    return fm.fetchReq<GetPatientMedicationSummaryRequest, PatientMedicationSummary>(`/rpm/v1/${req["name"]}?${fm.renderURLSearchParams(req, ["name"])}`, {...initReq, method: "GET"})
  }
  static UpdateAsyncTitrationStatus(req: UpdateAsyncTitrationStatusRequest, initReq?: fm.InitReq): Promise<GoogleProtobufEmpty.Empty> {
    return fm.fetchReq<UpdateAsyncTitrationStatusRequest, GoogleProtobufEmpty.Empty>(`/rpm/v1/${req["name"]}`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static PublishAsyncTitration(req: PublishAsyncTitrationRequest, initReq?: fm.InitReq): Promise<GoogleProtobufEmpty.Empty> {
    return fm.fetchReq<PublishAsyncTitrationRequest, GoogleProtobufEmpty.Empty>(`/rpm/v1/async_titration/publish`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static CreateManualAsyncTitration(req: CreateManualAsyncTitrationRequest, initReq?: fm.InitReq): Promise<GoogleProtobufEmpty.Empty> {
    return fm.fetchReq<CreateManualAsyncTitrationRequest, GoogleProtobufEmpty.Empty>(`/rpm/v1/async_titration/createManual`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static RevertAsyncTitrationsForNote(req: RevertAsyncTitrationsForNoteRequest, initReq?: fm.InitReq): Promise<GoogleProtobufEmpty.Empty> {
    return fm.fetchReq<RevertAsyncTitrationsForNoteRequest, GoogleProtobufEmpty.Empty>(`/rpm/v1/async_titration/revertForNote`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
}