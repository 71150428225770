import { Segment as BaseSegment } from './Segment';
import { SegmentIcon } from './SegmentIcon';

export { SegmentedButtonGroup } from './SegmentedButtonGroup';

export const Segment = Object.assign(BaseSegment, {
  Icon: SegmentIcon,
});

export type { Props as SegmentProps } from './Segment';
