import { FormattedMessage, type IntlShape, useIntl } from 'react-intl';

import type { AppointmentDetails } from '@/shared/generated/grpc/go/pms/pkg/scheduling/scheduling.pb';

import { emphasis } from './AppointmentInfo.css';

type Props = {
  appointment: AppointmentDetails;
  formatInPatientTimezone: (
    date: string | Date,
    fmt: string,
  ) => Nullable<string>;
};

export function AppointmentInfo({
  appointment,
  formatInPatientTimezone,
}: Props) {
  const intl = useIntl();

  if (!appointment.appointmentCanonicalName || !appointment.startTime) {
    return null;
  }

  const formattedDate = formatInPatientTimezone(
    appointment.startTime,
    'eeee, MMM d',
  );
  const formattedTime = formatInPatientTimezone(
    appointment.startTime,
    'h:mm a',
  );

  return (
    <FormattedMessage
      defaultMessage="Next visit is {article} <emphasize>{appointmentTypeName}</emphasize> with <emphasize>{appointmentCareProvider}</emphasize> on <emphasize>{date}</emphasize> at <emphasize>{time}</emphasize>"
      values={{
        date: formattedDate,
        emphasize,
        article: getArticle(appointment.appointmentCanonicalName, intl),
        appointmentTypeName: appointment.appointmentCanonicalName,
        appointmentCareProvider: `${appointment.careProviderFirstName} ${appointment.careProviderLastName}`,
        time: formattedTime,
      }}
    />
  );
}

function emphasize(children: string) {
  return <span className={emphasis}>{children}</span>;
}

function getArticle(word: string, intl: IntlShape) {
  const vowels = ['a', 'e', 'i', 'o', 'u'];
  return vowels.includes(word[0].toLowerCase())
    ? intl.formatMessage({ defaultMessage: 'an' })
    : intl.formatMessage({ defaultMessage: 'a' });
}
