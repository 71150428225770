import { format, parseISO } from 'date-fns';
import { useCallback } from 'react';

import {
  BGReading,
  BPReading,
  HRReading,
  WeightReading,
} from '@/pages/patients/PatientListDashboard/VitalReading';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@/shared/common/@deprecated/Table';
import { SkeletonTable } from '@/shared/common/SkeletonTable';
import type { ListPatientVitalsResponseVital } from '@/shared/generated/grpc/go/telemetry/pkg/telemetry/v1/telemetry.pb';
import { usePatientDetails } from '@/shared/hooks/queries';
import type { Patient } from '@/shared/types/patient.types';
import { ReadingDataType } from '@/shared/types/patientSummary.types';
import type {
  GroupedVitals,
  VitalsForDateType,
} from '@/shared/types/vitals.types';
import { VitalType } from '@/shared/types/vitals.types';

import { hasAlertForReading } from '../hasAlert.util';
import { VitalTimestamp } from './VitalTimestamp';
import { table, tableCell } from './VitalsTable.css';
import { VitalsTableHeader } from './VitalsTableHeader';
import { getVitalsByType, tempGetNumTableRows } from './tableUtils';

type VitalsTableProps = {
  patientId: string;
  vitalsByDateMap: GroupedVitals;
};

export const VitalsTable = ({
  patientId,
  vitalsByDateMap,
}: VitalsTableProps) => {
  const { data: patient, isLoading } = usePatientDetails(patientId, false);

  return (
    <Table className={table.standard}>
      <VitalsTableHeader />
      <TableBody>
        {isLoading ? (
          <SkeletonTable columns={5} rows={2} />
        ) : (
          Object.entries(vitalsByDateMap)
            .sort(([dateA], [dateB]) => dateB.localeCompare(dateA))
            .map(([date, vitals]) => (
              <VitalsForDate
                key={date}
                date={date}
                vitals={vitals}
                activeAlerts={patient?.active_vitals_alerts ?? []}
              />
            ))
        )}
      </TableBody>
    </Table>
  );
};

const VitalsForDate = ({
  date,
  vitals,
  activeAlerts,
}: {
  date: string;
  vitals: VitalsForDateType;
  activeAlerts: Patient['active_vitals_alerts'];
}) => {
  const hasAlert = useCallback(
    (vital: ListPatientVitalsResponseVital, readingDataType: ReadingDataType) =>
      hasAlertForReading(vital?.name || '', readingDataType, activeAlerts),
    [activeAlerts],
  );

  const bpVitals = getVitalsByType(vitals, VitalType.BloodPressure);
  const hrVitals = getVitalsByType(vitals, VitalType.HeartRate);
  const weightVitals = getVitalsByType(vitals, VitalType.Weight);
  const bgVitals = getVitalsByType(vitals, VitalType.BloodGlucose);

  const numRows = tempGetNumTableRows(vitals);
  const rows = [];
  for (let i = numRows - 1; i >= 0; i -= 1) {
    const showDateInThisRow = i === numRows - 1;
    const bpReading = bpVitals?.[i];
    const hrReading = hrVitals?.[i];
    const weightReading = weightVitals?.[i];
    const bgReading = bgVitals?.[i];

    rows.push(
      <TableRow
        key={`${date}-${i}`}
        data-testid={`vitals-for-date-row-${date}-${i}`}
      >
        <TableCell className={tableCell} data-testid="vital-reading-date">
          {showDateInThisRow ? format(parseISO(date), 'MM/dd/yy') : ''}
        </TableCell>
        <TableCell className={tableCell}>
          {bpReading ? (
            <>
              <BPReading
                hasAlert={hasAlert(bpReading, ReadingDataType.BloodPressure)}
                systolic={bpReading?.value || 0}
                diastolic={bpReading?.secondaryValue || 0}
                tags={bpReading.tags}
                isReadingSuppressed={!!bpReading.isSuppressed}
              />
              {bpVitals && (
                <VitalTimestamp>{bpReading?.timestamp || ''}</VitalTimestamp>
              )}
            </>
          ) : (
            '-'
          )}
        </TableCell>
        <TableCell className={tableCell}>
          {hrReading ? (
            <>
              <HRReading
                hasAlert={hasAlert(hrReading, ReadingDataType.HeartRate)}
                pulse={hrReading?.value || 0}
                tags={hrReading.tags}
                isReadingSuppressed={!!hrReading.isSuppressed}
              />
              {hrVitals && (
                <VitalTimestamp>{hrReading?.timestamp || ''}</VitalTimestamp>
              )}
            </>
          ) : (
            '-'
          )}
        </TableCell>
        <TableCell className={tableCell}>
          {weightReading ? (
            <>
              <WeightReading
                hasAlert={hasAlert(weightReading, ReadingDataType.Weight)}
                weight={weightReading?.value || 0}
                tags={weightReading.tags}
                isReadingSuppressed={!!weightReading.isSuppressed}
              />
              {weightVitals && (
                <VitalTimestamp>
                  {weightReading?.timestamp || ''}
                </VitalTimestamp>
              )}
            </>
          ) : (
            '-'
          )}
        </TableCell>
        <TableCell className={tableCell}>
          {bgReading ? (
            <>
              <BGReading
                hasAlert={hasAlert(bgReading, ReadingDataType.BloodGlucose)}
                glucoseLevel={bgReading?.value || 0}
                tags={bgReading.tags}
                isReadingSuppressed={!!bgReading.isSuppressed}
              />
              {bgVitals && (
                <VitalTimestamp>{bgReading?.timestamp || ''}</VitalTimestamp>
              )}
            </>
          ) : (
            '-'
          )}
        </TableCell>
      </TableRow>,
    );
  }

  return <>{rows}</>;
};
