import { formatISO } from 'date-fns';

import { usePatientProfileCtx } from '@/pages/patients/PatientProfile/context/PatientProfileCtx';

import { VitalsView } from '../patientDetails/ui/tabs/Vitals';

type Props = {
  patientId: string;
};

export function PatientListSidebarVitalsTable({ patientId }: Props) {
  const { vitalsStartDate, vitalsEndDate } = usePatientProfileCtx();

  const requestParams = {
    patientId,
    dateFrom: encodeURI(formatISO(vitalsStartDate, { representation: 'date' })),
    dateTo: encodeURI(formatISO(vitalsEndDate, { representation: 'date' })),
  };

  return (
    <VitalsView requestParams={requestParams} showLoadingSpinner={false} />
  );
}
