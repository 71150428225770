import type { NextAppointmentRecommendation } from '@/shared/generated/grpc/go/pms/pkg/scheduling/scheduling.pb';

import { EmbeddedAcuityView } from './EmbeddedAcuityView';
import { SchedulingInfo } from './SchedulingInfo';
import type { AcuityIframeUrlParams, ApptsFilter } from './types';

type Props = {
  patientId: string;
  filterType: ApptsFilter;
  iframeParams: AcuityIframeUrlParams;
  recommendedAppt: NextAppointmentRecommendation | undefined;
};

export function Scheduler({
  patientId,
  filterType,
  iframeParams,
  recommendedAppt,
}: Props) {
  return (
    <EmbeddedAcuityView
      key={`${filterType}-${JSON.stringify(iframeParams)}`}
      iframeParams={iframeParams}
    >
      <SchedulingInfo patientId={patientId} recommendedAppt={recommendedAppt} />
    </EmbeddedAcuityView>
  );
}
