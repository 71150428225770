import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useRouteMatch } from 'react-router-dom';

import TrashIcon from '@/shared/assets/svgs/trash.svg?react';
import { Form } from '@/shared/common/Form';
import { useFormFromConfig } from '@/shared/common/Form/FormContainer';
import { LoadingPlaceholder } from '@/shared/common/LoadingPlaceholder';
import { NoteStatus } from '@/shared/generated/grpc/go/pms/pkg/note/note.pb';
import { useFlags } from '@/shared/hooks';
import {
  usePatientBestThreeConditionsFromProblemList,
  usePatientDetails,
} from '@/shared/hooks/queries';
import {
  useCarePlanDraft,
  useCarePlanNoteInfo,
} from '@/shared/hooks/queries/carePlan.queries';
import { Button } from '@/shared/tempo/atom/Button';
import { Link } from '@/shared/tempo/atom/Link';
import { color } from '@/shared/tempo/theme';
import { grpcNameToId } from '@/shared/utils/grpc';
import { getCarePlanSuggestionsUrl } from '@/shared/utils/metabase';
import { parentPath } from '@/shared/utils/route-helpers';

import { CarePlanSuggestionBanner } from '../CarePlanSuggestionBanner';
import { ContextualGoalButton } from '../Goals/ContextualGoalButton';
import { GoalsGrid } from '../Goals/GoalsGrid';
import { GoalModalType } from '../Goals/goalModal.types';
import { GoalMeasure } from '../Goals/goals.types';
import { useGoalGoalMeasureI18n } from '../Goals/i18nMapping';
import {
  actions,
  autopopulatedSectionBanner,
  autopopulatedSectionContainer,
  formWrapper,
  gridContainer,
} from './CarePlanForm.css';
import {
  CarePlanFormModals,
  type ModalState,
  ModalType,
} from './CarePlanFormModals';
import { marshalCarePlanToFormData } from './dataTransformation';
import { getFormConfig } from './formConfig';
import { useAutosaveCarePlan, useOnSave } from './persistence';
import { CulturalFactors } from './sections/CulturalFactors';
import { EmergencyVisits } from './sections/EmergencyVisits';
import { GoalsAndInterventions } from './sections/GoalsAndInterventions';
import { MedicationAllergies } from './sections/MedicationAllergies';
import { Medications } from './sections/Medications';
import { PastSurgicalHistory } from './sections/PastSurgicalHistory';
import { PreventativeCare } from './sections/PreventativeCare';
import { PreventativeCareGaps } from './sections/PreventativeCareGaps';
import { ProblemList } from './sections/ProblemList';
import { PsychosocialAssessment } from './sections/PsychosocialAssessment';
import { Specialists } from './sections/Specialists';
import {
  goalSectionContainer,
  helpText,
  sectionTitle,
} from './sections/carePlanSections.css';

export function CarePlanForm() {
  const { showCarePlanSuggestions, carePlanVisitFlowImprovements } = useFlags();
  const intl = useIntl();
  const history = useHistory();
  const [modalState, setModalState] = useState<Nullable<ModalState>>(null);
  const { url, params } = useRouteMatch<{ patientId: string }>();
  const goalMeasureI18n = useGoalGoalMeasureI18n();

  function navigateUp() {
    history.push(parentPath(url));
  }

  const {
    data: existingDraft,
    isLoading: isLoadingDraft,
    isFetching: isFetchingDraft,
  } = useCarePlanDraft(params.patientId, {
    onSuccess(data) {
      if (!data.ccmCarePlans?.length) {
        // No draft so navigate up
        navigateUp();
      }
    },
  });
  const draftPlan = existingDraft?.carePlan;
  const hasDraftPlan = !!draftPlan;
  const noteId = hasDraftPlan ? (existingDraft.noteId as number) : undefined;

  const { data: patient, isLoading: isLoadingPatient } = usePatientDetails(
    params.patientId,
    true,
  );

  const { data: bestThreeConditionsResp, isLoading: isLoadingBestConditions } =
    usePatientBestThreeConditionsFromProblemList(params.patientId);

  const form = useFormFromConfig({
    ...getFormConfig(
      intl,
      hasDraftPlan
        ? marshalCarePlanToFormData(draftPlan, carePlanVisitFlowImprovements)
        : {},
      bestThreeConditionsResp?.conditions || [],
      carePlanVisitFlowImprovements,
    ),
    triggerReset:
      !isLoadingDraft &&
      hasDraftPlan &&
      !isLoadingPatient &&
      !!patient &&
      !isLoadingBestConditions,
  });

  const { onSave, isSaving } = useOnSave(params.patientId, noteId);
  const { manualAutoSave } = useAutosaveCarePlan(form, onSave, {
    enabled: !isFetchingDraft,
  });

  const draftCarePlanId = existingDraft?.name
    ? grpcNameToId(existingDraft.name)
    : undefined;
  const {
    data: noteInfo,
    isLoading: isLoadingNoteInfo,
    isFetching: isFetchingNoteInfo,
  } = useCarePlanNoteInfo(draftCarePlanId || '', {
    enabled: Boolean(draftCarePlanId),
  });

  const associatedNoteIsPublished =
    noteInfo?.noteStatus === NoteStatus.PUBLISHED;

  if (isLoadingDraft || !noteId) {
    return <LoadingPlaceholder isLoading />;
  }

  function createGoalWithMeasure(measure: GoalMeasure, description?: string) {
    setModalState({
      type: GoalModalType.CreateGoal,
      defaults: { measure, title: goalMeasureI18n[measure], description },
    });
  }

  return (
    <>
      <div className={gridContainer}>
        <GoalsGrid
          patientId={params.patientId}
          onEditGoal={(goal) =>
            setModalState({ type: GoalModalType.EditGoal, goal })
          }
          onCreateGoal={() => setModalState({ type: GoalModalType.CreateGoal })}
        />
      </div>
      {showCarePlanSuggestions && (
        <CarePlanSuggestionBanner
          title={
            <FormattedMessage defaultMessage="Smart Suggestions available" />
          }
        >
          <FormattedMessage
            defaultMessage="You can now view the {link} for insights on how to complete this Care Plan."
            values={{
              link: (
                <Link.Native
                  target="_blank"
                  href={getCarePlanSuggestionsUrl(params.patientId)}
                >
                  <FormattedMessage defaultMessage="Smart Care Plan Suggestions dashboard" />
                </Link.Native>
              ),
            }}
          />
        </CarePlanSuggestionBanner>
      )}
      <Form
        form={form}
        onSubmit={(data) => {
          onSave(data, {
            onSuccess: () => navigateUp(),
          });
        }}
      >
        {({ canSubmit }) => (
          <>
            <div className={formWrapper}>
              {!carePlanVisitFlowImprovements && (
                <>
                  <ProblemList />
                  <PastSurgicalHistory
                    form={form}
                    manualAutoSave={manualAutoSave}
                  />
                </>
              )}
              <Specialists form={form} manualAutoSave={manualAutoSave} />
              <EmergencyVisits
                form={form}
                manualAutoSave={manualAutoSave}
                createGoalWithMeasure={createGoalWithMeasure}
              />
              <Medications
                noteId={noteId}
                createGoalWithMeasure={createGoalWithMeasure}
              />
              {!carePlanVisitFlowImprovements && (
                <MedicationAllergies
                  form={form}
                  manualAutoSave={manualAutoSave}
                />
              )}
              <PreventativeCare
                form={form}
                createGoalWithMeasure={createGoalWithMeasure}
              />
              <Form.Section
                title={
                  <div className={goalSectionContainer}>
                    {intl.formatMessage({ defaultMessage: 'Social history' })}
                    <ContextualGoalButton
                      onPress={() => createGoalWithMeasure(GoalMeasure.Tobacco)}
                    />
                  </div>
                }
                classes={{ title: sectionTitle }}
              >
                <Form.Row>
                  <Form.TextField
                    size={11}
                    label={
                      <FormattedMessage defaultMessage="History of tobacco use?" />
                    }
                    name="tobaccoUse"
                  />
                </Form.Row>
              </Form.Section>
              <Form.Section
                title={
                  <div className={goalSectionContainer}>
                    {intl.formatMessage({ defaultMessage: 'Diet' })}
                    <ContextualGoalButton
                      onPress={() => createGoalWithMeasure(GoalMeasure.Diet)}
                    />
                  </div>
                }
                classes={{ title: sectionTitle }}
              >
                {carePlanVisitFlowImprovements ? (
                  <>
                    <Form.Row>
                      <Form.TextArea
                        classes={{ persistedHelpText: helpText }}
                        size={11}
                        label={
                          <FormattedMessage defaultMessage="Tell me about your diet. What does a typical day of eating look like?" />
                        }
                        name="dietNotes"
                        rows={3}
                        required
                        persistedHelp={
                          <FormattedMessage defaultMessage="Pay attention especially to fast food, snacks, sugar, salt, and water intake " />
                        }
                      />
                    </Form.Row>
                  </>
                ) : (
                  <>
                    <Form.Row>
                      <Form.TextField
                        size={11}
                        label={
                          <FormattedMessage defaultMessage="How many times per week do you eat fast food meals?" />
                        }
                        name="fastFood"
                      />
                    </Form.Row>
                    <Form.Row>
                      <Form.TextField
                        size={11}
                        label={
                          <FormattedMessage defaultMessage="Do you snack often?" />
                        }
                        name="snacks"
                      />
                    </Form.Row>
                    <Form.Row>
                      <Form.TextField
                        size={11}
                        label={
                          <FormattedMessage defaultMessage="How many times per week do you eat desserts or other sweets?" />
                        }
                        name="desserts"
                      />
                    </Form.Row>
                    <Form.Row>
                      <Form.TextField
                        size={11}
                        label={
                          <FormattedMessage defaultMessage="Do you drink regular soda?" />
                        }
                        name="regularSoda"
                      />
                    </Form.Row>
                    <Form.Row>
                      <Form.TextField
                        size={11}
                        label={
                          <FormattedMessage defaultMessage="Do you drink sweet tea?" />
                        }
                        name="sweetTea"
                      />
                    </Form.Row>
                    <Form.Row>
                      <Form.TextField
                        size={11}
                        label={
                          <FormattedMessage defaultMessage="How much water do you drink per day?" />
                        }
                        name="waterIntake"
                      />
                    </Form.Row>
                    <Form.Row>
                      <Form.TextField
                        size={11}
                        label={
                          <FormattedMessage defaultMessage="Do you add salt to meals regularly?" />
                        }
                        name="saltIntake"
                      />
                    </Form.Row>
                  </>
                )}
              </Form.Section>
              {!carePlanVisitFlowImprovements && (
                <Form.Section
                  title={
                    <div className={goalSectionContainer}>
                      {intl.formatMessage({ defaultMessage: 'Exercise' })}
                      <ContextualGoalButton
                        onPress={() =>
                          createGoalWithMeasure(GoalMeasure.Exercise)
                        }
                      />
                    </div>
                  }
                  classes={{ title: sectionTitle }}
                >
                  <Form.Row>
                    <Form.TextArea
                      size={11}
                      rows={3}
                      label={
                        <FormattedMessage defaultMessage="Do you exercise regularly? If so, what type of exercise? How long and how often?" />
                      }
                      name="exercise"
                    />
                  </Form.Row>
                </Form.Section>
              )}
              <PsychosocialAssessment
                form={form}
                createGoalWithMeasure={createGoalWithMeasure}
              />
              <CulturalFactors />
              <GoalsAndInterventions
                form={form}
                manualAutoSave={manualAutoSave}
                createGoalWithMeasure={createGoalWithMeasure}
              />
              <PreventativeCareGaps form={form} />
              <Form.Section
                title={intl.formatMessage({ defaultMessage: 'Follow up' })}
                classes={{ title: sectionTitle }}
              >
                <Form.Row>
                  <Form.TextField
                    size={11}
                    label={
                      <FormattedMessage defaultMessage="Date and time for next follow-up with my care manager" />
                    }
                    name="ccmAppointment"
                  />
                </Form.Row>
                <Form.Row>
                  <Form.TextField
                    size={11}
                    label={
                      <FormattedMessage defaultMessage="Date and time for next appointment with my doctor" />
                    }
                    name="providerAppointment"
                  />
                </Form.Row>
              </Form.Section>
              <Form.GridItem size={11} className={actions.container}>
                {!associatedNoteIsPublished ? (
                  <Button
                    variant="tertiary"
                    isDisabled={isLoadingNoteInfo || isFetchingNoteInfo}
                    className={actions.deleteButton}
                    onPress={() => {
                      if (existingDraft?.name) {
                        setModalState({
                          type: ModalType.Delete,
                          carePlanName: existingDraft.name,
                        });
                      } else {
                        // if we don't have a name we haven't saved yet so we can just navigate out
                        navigateUp();
                      }
                    }}
                  >
                    <Button.Icon>
                      <TrashIcon fill={color.Theme.Light.Danger} />
                    </Button.Icon>
                    <FormattedMessage defaultMessage="Delete" />
                  </Button>
                ) : (
                  // Empty Div to keep Save button on the right
                  <div />
                )}
                <Button
                  type="submit"
                  isDisabled={!canSubmit}
                  isProcessing={isSaving}
                >
                  <FormattedMessage defaultMessage="Save Draft" />
                </Button>
              </Form.GridItem>
            </div>
            {carePlanVisitFlowImprovements && (
              <div className={autopopulatedSectionContainer}>
                <CarePlanSuggestionBanner
                  className={autopopulatedSectionBanner}
                  title={
                    <FormattedMessage defaultMessage="Sections below auto-populated" />
                  }
                >
                  <FormattedMessage defaultMessage="We are autopopulating these sections with data from the EMR. You do not need to review them with the patient" />
                </CarePlanSuggestionBanner>
                <ProblemList />
                <PastSurgicalHistory
                  form={form}
                  manualAutoSave={manualAutoSave}
                />
                <MedicationAllergies
                  form={form}
                  manualAutoSave={manualAutoSave}
                />
              </div>
            )}
          </>
        )}
      </Form>

      <CarePlanFormModals
        modalState={modalState}
        setModalState={setModalState}
        existingDraft={existingDraft}
      />
    </>
  );
}
