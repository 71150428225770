import cx from 'classnames';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import type { TypeOfEncounter } from '@/pages/patients/patientDetails/ui/Notes/Notes.types';
import { LastSyncDate } from '@/shared/common/LastSyncDate';
import type { MedicationChange } from '@/shared/generated/grpc/go/pms/pkg/patient/medication/medication.pb';
import { EhrSyncTaskRequestScope } from '@/shared/generated/grpc/go/pms/pkg/patient/synchronization/synchronization.pb';
import { useFlags } from '@/shared/hooks';
import {
  usePatientDetails,
  usePatientMedicationAllergies,
} from '@/shared/hooks/queries';
import { scopeLastSyncTime } from '@/shared/patient/synchronizationUtils';
import {
  Segment,
  SegmentedButtonGroup,
} from '@/shared/tempo/@labs/molecule/SegmentedButtonGroup';
import { Label } from '@/shared/tempo/atom/Label';
import { NotificationBadge } from '@/shared/tempo/atom/NotificationBadge';

import type { OriginalMedChanges } from '../PatientMedicationsContext';
import { PatientMedicationsContextProvider } from '../PatientMedicationsContext';
import { usePatientMedications } from '../patientMedications.queries';
import { getMedGroups } from '../utils/sortMeds';
import { AllergiesSection } from './AllergiesSection';
import { MedsSection } from './MedsSection';
import {
  medsListContainer,
  medsListHeader,
  medsListTitleContent,
} from './styles.css';
import { MedPermissions } from './types';

type Props = {
  patientId: string;
  noteId: Maybe<number>;
  medPermissions: MedPermissions;
  showRequiredActions: boolean;
  hideOtherMeds?: boolean;
  typeOfEncounter?: TypeOfEncounter;
  classes?: {
    container?: string;
  };
};

type TabSelection = 'meds' | 'allergies';

export function PatientMedicationsList({
  noteId,
  patientId,
  medPermissions,
  showRequiredActions,
  hideOtherMeds = false,
  typeOfEncounter,
  classes,
}: Props) {
  const intl = useIntl();
  const { allergiesInMedsModule } = useFlags();
  const [activeTab, setActiveTab] = useState<TabSelection>('meds');
  const { data: patient } = usePatientDetails(patientId, true);
  const { data: patientMeds } = usePatientMedications(patientId);
  const { medAllergies } = usePatientMedicationAllergies(patientId);
  const lastSyncDate = scopeLastSyncTime(
    patient,
    activeTab === 'meds'
      ? EhrSyncTaskRequestScope.MEDICATIONS
      : EhrSyncTaskRequestScope.ALLERGIES,
  );

  const [originalUnrefdMedChanges, setOriginalUnrefdMedChanges] =
    useState<OriginalMedChanges>({});
  const [originalRefdMedChanges, setOriginalRefdMedChanges] =
    useState<OriginalMedChanges>({});

  function setOriginalMedChange(
    medChange: MedicationChange,
    referenceId: Maybe<string>,
  ) {
    if (referenceId) {
      if (!originalRefdMedChanges[referenceId]) {
        setOriginalRefdMedChanges((prev) => ({
          ...prev,
          [referenceId]: medChange,
        }));
      }
    } else {
      const medChangeId = medChange.name;
      if (medChangeId && !originalUnrefdMedChanges[medChangeId]) {
        setOriginalUnrefdMedChanges((prev) => ({
          ...prev,
          [medChangeId]: medChange,
        }));
      }
    }
  }

  const { diseaseSpecificMeds, otherMeds } = getMedGroups(
    patientMeds,
    originalUnrefdMedChanges,
    originalRefdMedChanges,
  );

  return (
    <PatientMedicationsContextProvider
      value={{
        noteId,
        patientId,
        showRequiredActions,
        setOriginalMedChange,
      }}
    >
      <div
        className={cx(
          {
            [medsListContainer]: medPermissions === MedPermissions.View,
          },
          classes?.container,
        )}
      >
        <div className={medsListHeader}>
          <div className={medsListTitleContent}>
            {allergiesInMedsModule ? (
              <SegmentedButtonGroup
                size="small"
                value={activeTab}
                onChange={(value) => setActiveTab(value as TabSelection)}
              >
                <Segment value="meds">
                  <FormattedMessage defaultMessage="Medications" />
                  <NotificationBadge
                    count={diseaseSpecificMeds.length + otherMeds.length}
                    variant="muted"
                  />
                </Segment>
                <Segment value="allergies">
                  <FormattedMessage defaultMessage="Allergies" />
                  <NotificationBadge
                    count={medAllergies?.length ?? 0}
                    variant="muted"
                  />
                </Segment>
              </SegmentedButtonGroup>
            ) : (
              <Label
                label={intl.formatMessage({
                  defaultMessage: 'Medications',
                })}
              />
            )}
          </div>
          <LastSyncDate syncDate={lastSyncDate} />
        </div>
        {activeTab === 'meds' ? (
          <MedsSection
            medPermissions={medPermissions}
            noteId={noteId}
            patientId={patientId}
            showRequiredActions={showRequiredActions}
            hideOtherMeds={hideOtherMeds}
            typeOfEncounter={typeOfEncounter}
            diseaseSpecificMeds={diseaseSpecificMeds}
            otherMeds={otherMeds}
            patientMeds={patientMeds}
          />
        ) : (
          <AllergiesSection allergies={medAllergies ?? []} />
        )}
      </div>
    </PatientMedicationsContextProvider>
  );
}
