import { subDays } from 'date-fns';
import noop from 'lodash/noop';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Box } from '@/deprecated/mui';
import { bigButton } from '@/pages/auth/ui/shared/auth.css';
import { PatientProfileProvider } from '@/pages/patients/PatientProfile/context/PatientProfileCtx';
import { ProfileAlertDetail } from '@/pages/patients/patientDetails/ui/ProfileAlertDetail';
import { BasicPatientInfoSummary } from '@/pages/patients/patientInfoSummaries';
import { SidePanel } from '@/shared/common/Page';
import { usePatientDetails } from '@/shared/hooks/queries';
import { flexSection } from '@/shared/jsStyle';
import { Button } from '@/shared/tempo/atom/Button';
import { Skeleton } from '@/shared/tempo/atom/Skeleton';
import { color } from '@/shared/tempo/theme';

import { useSidePanelPatientCtx } from '../PatientProfile/SidePanelPatientContext';
import {
  summaryContainer,
  vitalsContainer,
} from './PatientListSidebarPanel.css';
import { PatientListSidebarVitalsTable } from './PatientListSidebarVitalsTable';

export function PatientListSidebarPanel() {
  const { patientId } = useSidePanelPatientCtx();
  const { data: patientDetails, isLoading: isLoadingPatientDetails } =
    usePatientDetails(patientId, true);
  const handleClick = () => {
    window.open(`/patients/${patientId}`, '_blank');
  };

  const [vitalsStartDate, setVitalsStartDate] = useState(
    subDays(new Date(), 5),
  );
  const [vitalsEndDate, setVitalsEndDate] = useState(new Date());

  return (
    <SidePanel autoDismiss>
      <SidePanel.Header
        title={
          isLoadingPatientDetails ? (
            <Skeleton variant="text" width="150px" />
          ) : (
            `${patientDetails?.patient?.givenName} ${patientDetails?.patient?.familyName}`
          )
        }
      />
      {/* TODO: PLAT-2521 */}
      <SidePanel.Body>
        <PatientProfileProvider
          value={{
            vitalsStartDate,
            vitalsEndDate,
            setVitalsStartDate,
            setVitalsEndDate,
            // Unused on this page
            currentModal: null,
            setCurrentModal: noop,
          }}
        >
          <div className={summaryContainer}>
            <BasicPatientInfoSummary patientId={patientId} />
          </div>
          {patientDetails && <ProfileAlertDetail patientId={patientId} />}
          <div className={vitalsContainer}>
            <PatientListSidebarVitalsTable patientId={patientId} />
          </div>
        </PatientProfileProvider>
      </SidePanel.Body>
      <SidePanel.Footer>
        <Box
          sx={{
            ...flexSection('column', 'center', 'center'),
            width: '100%',
            height: '112px',
            borderTop: `1px solid ${color.Theme.Light['Base Form Disabled']}`,
            padding: '32px',
          }}
        >
          <Button className={bigButton} onPress={handleClick}>
            <FormattedMessage defaultMessage="Go to patient profile" />
          </Button>
        </Box>
      </SidePanel.Footer>
    </SidePanel>
  );
}
