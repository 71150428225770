import { FormattedMessage, useIntl } from 'react-intl';

import { EllipseIcon } from '@/shared/assets/icons/Ellipse';
import AlertIcon from '@/shared/assets/svgs/alertTriangle.svg?react';
import {
  type PatientDetails,
  PatientStatusEnum,
  ProgramType,
} from '@/shared/generated/grpc/go/pms/pkg/patient/pms.pb';
import {
  isDisenrolledFromAllPrograms,
  isParticipatingInProgramType,
} from '@/shared/patient/programs.utils';
import { Tag } from '@/shared/tempo/atom/Tag';
import { Tooltip } from '@/shared/tempo/atom/Tooltip';
import { color } from '@/shared/tempo/theme';

type Props = {
  patientDetails: PatientDetails;
};

export function PatientStatusTag({ patientDetails }: Props) {
  const intl = useIntl();
  const isRpmActivated = Boolean(patientDetails.patient?.activationDate);
  const status = patientDetails.patient?.status;

  if (
    status === PatientStatusEnum.DISENROLLED ||
    isDisenrolledFromAllPrograms(patientDetails)
  ) {
    return (
      <Tooltip
        size="small"
        content={intl.formatMessage({
          defaultMessage: 'Patient is disenrolled',
        })}
      >
        <Tag variant="danger">
          <Tag.Icon>
            <EllipseIcon fill={color.Palette.Danger[700]} />
          </Tag.Icon>
          <FormattedMessage defaultMessage="DISENROLLED" />
        </Tag>
      </Tooltip>
    );
  }

  const { isParticipating: isParticipatingRpm } = isParticipatingInProgramType(
    patientDetails,
    ProgramType.RPM,
  );

  if (
    status === PatientStatusEnum.ENROLLED &&
    isParticipatingRpm &&
    !isRpmActivated
  ) {
    return (
      <Tooltip
        size="small"
        content={intl.formatMessage({
          defaultMessage: 'Patient has not sent their first reading',
        })}
      >
        <Tag variant="warning">
          <Tag.Icon>
            <AlertIcon fill={color.Palette.Warning[700]} />
          </Tag.Icon>
          <FormattedMessage defaultMessage="Not activated" />
        </Tag>
      </Tooltip>
    );
  }

  // TODO: Handle disenrolled status

  return null;
}
