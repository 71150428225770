import type { IntlShape } from 'react-intl';

import { getTitrationRecommendation } from '@/components/AsyncTitration/hooks';
import {
  getDoseStr,
  getRxNormDose,
  stripInstructions,
} from '@/pages/patients/PatientMedications/utils/medInfoUtils';
import type {
  MedicationChange,
  ReferenceMedication,
} from '@/shared/generated/grpc/go/pms/pkg/patient/medication/medication.pb';

type MedicationStrings =
  | {
      medicationName: string;
      dosage: string;
      frequencyText: string;
      clinicianName: string;
      isValid: true;
    }
  | {
      medicationName?: never;
      dosage?: never;
      frequencyText?: never;
      clinicianName?: never;
      isValid: false;
    };

export function getMedicationStrings(
  intl: IntlShape,
  med: MedicationChange,
  referenceMed: ReferenceMedication,
): MedicationStrings {
  const { rxNorm, doseQuantities, frequencies, approvingCareProvider } =
    getTitrationRecommendation(med, referenceMed, true, false);
  if (
    !rxNorm ||
    !doseQuantities ||
    !frequencies ||
    !approvingCareProvider ||
    !referenceMed.name
  ) {
    return { isValid: false };
  }

  const quantitiesAndFrequencies = stripInstructions(
    getDoseStr(intl, doseQuantities, frequencies, rxNorm),
  )?.toLowerCase();
  if (!quantitiesAndFrequencies) {
    return { isValid: false };
  }

  return {
    isValid: true,
    medicationName: referenceMed.name.toUpperCase(),
    dosage: getRxNormDose(rxNorm, intl, '/') ?? '',
    frequencyText: quantitiesAndFrequencies,
    clinicianName: `${approvingCareProvider?.givenName} ${approvingCareProvider?.familyName}`,
  };
}
