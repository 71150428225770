import { FormattedMessage } from 'react-intl';

import type {
  AppointmentAvailability,
  AppointmentDetails,
} from '@/shared/generated/grpc/go/pms/pkg/scheduling/scheduling.pb';

import {
  appointmentLabelText,
  appointmentText,
  confirmationContainer,
  confirmationMessage,
  container,
} from './AppointmentConfirmation.css';
import { createFormatInTzFn } from './appointment.utils';

type Props = {
  currentAppointment: AppointmentDetails;
  newApptSlot: Maybe<AppointmentAvailability>;
  patientTimezone: string;
};

export function AppointmentConfirmation({
  currentAppointment,
  newApptSlot,
  patientTimezone,
}: Props) {
  if (!currentAppointment.startTime || !newApptSlot || !newApptSlot.datetime) {
    return null;
  }

  const formatInTz = createFormatInTzFn(patientTimezone);
  return (
    <div className={container}>
      <div className={confirmationContainer}>
        <div className={appointmentLabelText}>Old appointment:</div>
        <div className={appointmentText}>
          {formatInTz(currentAppointment.startTime, 'MMM d, yyyy h:mm a z')}
          <div>
            with: {currentAppointment.careProviderFirstName}{' '}
            {currentAppointment.careProviderLastName},{' '}
            {currentAppointment.careProviderRole}
          </div>
        </div>
      </div>
      <div className={confirmationContainer}>
        <div className={appointmentLabelText}>New appointment:</div>
        <div className={appointmentText}>
          {formatInTz(newApptSlot.datetime, 'MMM d, yyyy h:mm a z')}
          <div>
            with: {newApptSlot.careProvider?.careProviderFirstName}{' '}
            {newApptSlot.careProvider?.careProviderLastName},{' '}
            {newApptSlot.careProvider?.careProviderRole}
          </div>
        </div>
      </div>
      <div className={confirmationMessage}>
        <FormattedMessage defaultMessage="Are you sure you want to proceed with the above rescheduling?" />
      </div>
    </div>
  );
}
