import { isValid } from 'date-fns';

import { DatePicker } from '@/shared/common/DatePicker';

import { calendarSelect } from './styles.css';
import { useDateValidators } from './useDateValidators';

type Props = {
  value: Date;
  highlightedDates?: Date[];
  onMonthChange?: (date: Date | unknown) => void; // Type is unknown because the type of the date parameter is not specified in the MUI DatePicker component
  onChange: (date: Date) => void;
};

export function CalendarSelect({
  value,
  onChange,
  highlightedDates,
  onMonthChange,
}: Props) {
  const { today, isValidDateSelection } = useDateValidators();
  return (
    <DatePicker
      classes={{ input: calendarSelect.input }}
      onChange={(date) => {
        if (
          date instanceof Date &&
          date &&
          isValid(date) &&
          isValidDateSelection(date)
        ) {
          onChange(date);
        }
      }}
      onMonthChange={onMonthChange}
      highlightedDates={highlightedDates}
      minDate={today}
      value={value}
    />
  );
}
