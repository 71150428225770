import cx from 'classnames';
import { isDate, isSameDay, isValid } from 'date-fns';

import {
  type DatePickerProps,
  DatePicker as MUIDatePicker,
  PickersDay,
  TextField,
} from '@/deprecated/mui';
import CalendarIcon from '@/shared/assets/svgs/calendar.svg?react';
import {
  addInputClassname,
  addInputWrapperClassname,
} from '@/shared/common/Input';
import { color } from '@/shared/tempo/theme';

import {
  adornment,
  button,
  highlightedDay,
  pickerInput,
} from './DatePicker.css';

type Props = {
  classes?: { input?: string };
  isDisabled?: boolean;
  isReadOnly?: boolean;
  isError?: boolean;
  onBlur?: () => void;
  highlightedDates?: Date[];
} & Omit<DatePickerProps, 'disabled' | 'renderInput'>;

export function DatePicker({
  isDisabled,
  isReadOnly,
  isError,
  onBlur,
  classes,
  highlightedDates = [],
  ...props
}: Props) {
  return (
    <MUIDatePicker
      {...props}
      components={{
        OpenPickerIcon: ({ ...iconProps }) => (
          <CalendarIcon
            {...iconProps}
            fill={color.Theme.Light['Base Font']}
            width={18}
            height={18}
          />
        ),
      }}
      renderDay={(day, selectedDates, pickersDayProps) => {
        let isHighlighted = false;

        if (isDate(day) && isValid(day)) {
          // If day is valid, then check if it's highlighted
          const dayAsDate = day as Date; // day is a Date now
          isHighlighted = highlightedDates?.some((hDay) =>
            isSameDay(hDay, dayAsDate),
          );
        }

        return (
          <PickersDay
            {...pickersDayProps}
            className={cx(pickersDayProps.className, {
              [highlightedDay]: isHighlighted,
            })}
          />
        );
      }}
      InputAdornmentProps={{ className: adornment }}
      OpenPickerButtonProps={{
        disableRipple: true,
        className: button,
      }}
      renderInput={({ inputProps, ...params }) => (
        <TextField
          {...params}
          error={isError}
          onBlur={onBlur}
          label={props.label}
          className={cx(
            addInputWrapperClassname(isDisabled, isReadOnly, isError),
            classes?.input,
          )}
          inputProps={addInputClassname({
            ...inputProps,
            className: cx(inputProps?.className, pickerInput),
          })}
        />
      )}
      disabled={isDisabled}
    />
  );
}
