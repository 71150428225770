import { ProgramStatus } from '@/shared/generated/grpc/go/pms/pkg/patient/pms.pb';
import { makeI18nHookAndGetter } from '@/shared/hooks/makeI18nHook';

export const { hook: useProgramStatusI18n, getter: getProgramStatusI18n } =
  makeI18nHookAndGetter<Record<ProgramStatus, string>>((intl) => ({
    [ProgramStatus.ELIGIBLE]: intl.formatMessage({
      defaultMessage: 'Eligible',
    }),
    [ProgramStatus.ORDERED]: intl.formatMessage({
      defaultMessage: 'Ordered',
    }),
    [ProgramStatus.SELECTED]: intl.formatMessage({
      defaultMessage: 'Selected',
    }),
    [ProgramStatus.ENROLLED]: intl.formatMessage({
      defaultMessage: 'Enrolled',
    }),
    [ProgramStatus.DISENROLLED]: intl.formatMessage({
      defaultMessage: 'Disenrolled',
    }),
    [ProgramStatus.SUGGESTED]: intl.formatMessage({
      defaultMessage: 'Suggested',
    }),
    [ProgramStatus.PROGRAM_STATUS_UNSPECIFIED]: intl.formatMessage({
      defaultMessage: 'Unspecified',
    }),
  }));
