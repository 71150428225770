import type { AxiosError } from 'axios';
import type { UseInfiniteQueryOptions, UseQueryOptions } from 'react-query';
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';

import type {
  CareProvider,
  CreateMarketCareProviderRequest,
  ListMarketCareProvidersRequest,
} from '@/shared/generated/grpc/go/pms/pkg/care_provider/care_provider.pb';
import { CareProviderService } from '@/shared/generated/grpc/go/pms/pkg/care_provider/care_provider.pb';
import type {
  GetMarketRequest,
  ListMarketsRequest,
  ListMarketsResponse,
  Market,
} from '@/shared/generated/grpc/go/pms/pkg/market/market.pb';
import { MarketService } from '@/shared/generated/grpc/go/pms/pkg/market/market.pb';
import type { AipPaginatedData } from '@/shared/types/pagination.types';
import { idToGrpcName } from '@/shared/utils/grpc';

import {
  careProviderNameToId,
  useInvalidateProviderMarkets,
} from './providers.queries';

const MARKET_QUERY_KEY_BASE = ['rpm', 'v1', 'markets'] as const;

export type SortableMarketFields = 'name' | 'displayName' | 'healthSystemName';

type PaginatedMarkets = AipPaginatedData<Market, 'data'>;
type PaginatedCareProviders = AipPaginatedData<CareProvider, 'careProviders'>;

const marketKeys = {
  list: (params?: ListMarketsRequest) =>
    [...MARKET_QUERY_KEY_BASE, params] as const,
  infinite: (params?: ListMarketsRequest) =>
    [...MARKET_QUERY_KEY_BASE, params, 'infinite'] as const,
  detail: (name?: GetMarketRequest['name']) =>
    [...MARKET_QUERY_KEY_BASE, name] as const,
  providersInfinite: (params?: ListMarketCareProvidersRequest) =>
    [...MARKET_QUERY_KEY_BASE, 'careProviders', params, 'infinite'] as const,
};

export function useMarkets(
  req: ListMarketsRequest,
  config?: UseQueryOptions<ListMarketsResponse>,
) {
  return useQuery<ListMarketsResponse>(
    marketKeys.list(req),
    () => MarketService.ListMarkets(req),
    config,
  );
}

export function useMarketsInfinite(
  query: ListMarketsRequest,
  config?: UseInfiniteQueryOptions<PaginatedMarkets>,
) {
  return useInfiniteQuery<PaginatedMarkets>(marketKeys.infinite(query), {
    ...config,
    queryFn: async (ctx) => {
      const marketResponse = await MarketService.ListMarkets({
        ...query,
        pageToken: ctx.pageParam,
      });

      return {
        data: (marketResponse.markets as Market[]) ?? [],
        nextPageToken: marketResponse.nextPageToken ?? '',
      };
    },
  });
}

export function useMarketById(id: string, config?: UseQueryOptions<Market>) {
  const grpcId = idToGrpcName('markets', id);
  return useQuery<Market>(
    marketKeys.detail(grpcId),
    () => MarketService.GetMarket({ name: grpcId }),
    config,
  );
}

export function useMarketCareProvidersInfinite(
  req: ListMarketCareProvidersRequest,
  config?: UseInfiniteQueryOptions<PaginatedCareProviders>,
) {
  return useInfiniteQuery<PaginatedCareProviders>(
    marketKeys.providersInfinite(req),
    {
      ...config,
      queryFn: async (ctx) => {
        const providersResp = await CareProviderService.ListMarketCareProviders(
          {
            ...req,
            pageToken: ctx.pageParam,
          },
        );

        return {
          careProviders: providersResp.careProviders ?? [],
          nextPageToken: providersResp.nextPageToken ?? '',
        };
      },
    },
  );
}

type Callbacks<D = unknown, E = unknown> = {
  onSuccess?: (data?: D) => void;
  onError?: (err?: E) => void;
};

export function useUpdateMarket(
  id: string,
  callbacks?: Callbacks<Market, AxiosError<{ message?: string }>>,
) {
  const client = useQueryClient();

  return useMutation(
    (market: Partial<Market>) =>
      MarketService.UpdateMarket({
        market: {
          name: idToGrpcName('markets', id),
          ...market,
        },
      }),
    {
      onSuccess: async (data) => {
        await client.invalidateQueries(MARKET_QUERY_KEY_BASE);
        callbacks?.onSuccess?.(data);
      },
      onError: callbacks?.onError,
    },
  );
}

export function useCreateMarket(callbacks?: Callbacks<Market>) {
  const client = useQueryClient();

  return useMutation(
    (market: Market) => MarketService.CreateMarket({ market }),
    {
      onSuccess: async (data) => {
        await client.invalidateQueries(MARKET_QUERY_KEY_BASE);
        callbacks?.onSuccess?.(data);
      },
      onError: callbacks?.onError,
    },
  );
}

export function useAssignMarketToProvider(
  providerId: string,
  marketId: string,
) {
  const queryClient = useQueryClient();
  const invalidateProviderMarkets = useInvalidateProviderMarkets();

  return useMutation(
    () =>
      CareProviderService.CreateMarketCareProvider({
        // Typing is invalid, so need to force cast it
        market_care_provider: {
          marketId,
          careProviderId: providerId,
        },
      } as unknown as CreateMarketCareProviderRequest),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(MARKET_QUERY_KEY_BASE);
        await invalidateProviderMarkets(providerId);
      },
    },
  );
}

export function useUnassignMarketFromProvider(
  providerId: string,
  marketId: string,
) {
  const queryClient = useQueryClient();
  const invalidateProviderMarkets = useInvalidateProviderMarkets();

  return useMutation(
    () =>
      CareProviderService.DeleteMarketCareProvider({
        name: `careProvider/${providerId}/markets/${marketId}`,
      }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(MARKET_QUERY_KEY_BASE);
        await invalidateProviderMarkets(providerId);
      },
    },
  );
}

export function useBulkAssignProvidersToMarket(marketId: string) {
  const queryClient = useQueryClient();
  const invalidateProviderMarkets = useInvalidateProviderMarkets();
  return useMutation(
    (careProviderIds: string[]) =>
      Promise.all(
        careProviderIds.map((careProviderId) =>
          CareProviderService.CreateMarketCareProvider({
            // Typing is invalid, so need to force cast it
            market_care_provider: {
              marketId,
              careProviderId,
            },
          } as unknown as CreateMarketCareProviderRequest),
        ),
      ),
    {
      onSuccess: async (_, careProviderIds) => {
        await queryClient.invalidateQueries(MARKET_QUERY_KEY_BASE);
        await Promise.all(
          careProviderIds.map((careProviderId) =>
            invalidateProviderMarkets(careProviderId),
          ),
        );
      },
    },
  );
}

export function useBulkUnassignProvidersToMarket(marketId: string) {
  const queryClient = useQueryClient();
  const invalidateProviderMarkets = useInvalidateProviderMarkets();
  return useMutation(
    (careProviders: CareProvider[]) =>
      Promise.all(
        careProviders.map((careProvider) =>
          CareProviderService.DeleteMarketCareProvider({
            name: `${careProvider.name}/markets/${marketId}`,
          }),
        ),
      ),
    {
      onSuccess: async (_, careProviders) => {
        await queryClient.invalidateQueries(MARKET_QUERY_KEY_BASE);
        await Promise.all(
          careProviders.map((careProvider) =>
            invalidateProviderMarkets(careProviderNameToId(careProvider.name)),
          ),
        );
      },
    },
  );
}
