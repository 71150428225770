import { useIntl } from 'react-intl';

import { useRecentNoteFormData } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared/useRecentNoteFormData';
import { useWizardFormFromConfig } from '@/shared/common/Wizard/state';

import { REGULAR_VISIT_SECTIONS } from '../../metadata';
import type { DeviceUsageGoalsFormFields } from '../../shared/LowDeviceUsage';
import {
  SharedLowDeviceUsage,
  getDeviceUsageGoalsFormConfig,
} from '../../shared/LowDeviceUsage';
import { regVisitPath } from '../paths';
import { useDeviceUsageOnNext } from './useDeviceUsageOnNext';

export function LowDeviceUsage() {
  const intl = useIntl();
  const path = regVisitPath('/product-support', '/low-device-usage');
  const { isLoading, data } = useRecentNoteFormData<DeviceUsageGoalsFormFields>(
    [path, regVisitPath('/product-support', '/low-device-usage')],
  );
  const form = useWizardFormFromConfig<DeviceUsageGoalsFormFields>(path, {
    ...getDeviceUsageGoalsFormConfig(intl, data),
    triggerReset: !isLoading,
  });

  const [onNext, isLoadingNav] = useDeviceUsageOnNext();

  return (
    <SharedLowDeviceUsage
      form={form}
      sections={REGULAR_VISIT_SECTIONS}
      isLoading={isLoading || isLoadingNav}
      onNext={onNext}
    />
  );
}
