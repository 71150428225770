import { INIT_VISIT_SECTIONS } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/CNWizardForm/sections/initialVisit';
import type { DeviceTipsFormFields } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/CNWizardForm/sections/initialVisit/deviceTips/formConfig';
import type { SectionStepsState } from '@/shared/common/Wizard/state';
import { getWizardStepValues } from '@/shared/common/Wizard/state';

import { MarkdownBuilder } from '../../../engine/MarkdownBuilder';
import { sectionHasData } from '../../util';

export function initialVisitDeviceTips(data: SectionStepsState) {
  const deviceTipsData = getWizardStepValues(
    data,
    INIT_VISIT_SECTIONS,
    '/device-tips',
    '/index',
  )<DeviceTipsFormFields>();

  if (!sectionHasData(deviceTipsData)) {
    return '';
  }

  const md = new MarkdownBuilder();

  md.h3('Device Tips');
  md.field(
    'Patient is receiving SMS vital receipts',
    deviceTipsData?.smsReceived,
  );

  return md.toString();
}
