import cx from 'classnames';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { useSidePanelPatientCtx } from '@/pages/patients/PatientProfile/SidePanelPatientContext';
import { SearchResultsWrapper } from '@/shared/common/@deprecated/PatientSearch/SearchResultsWrapper';
import { CounterBox } from '@/shared/common/CounterBox';
import { useSidePanelCtx } from '@/shared/common/Page';
import {
  ResourceTableContainer,
  ResourceTableTitle,
} from '@/shared/common/ResourceTableContainer';
import { flexContainer } from '@/shared/jsStyle/flex.css';
import { gap } from '@/shared/jsStyle/gap.css';
import { Tabs } from '@/shared/tempo/@labs/atom/Tabs';
import type { Patient, PatientDetails } from '@/shared/types/patient.types';

import { usePatientsInfiniteQuery } from '../hooks/queries.hooks';
import { useFiltersQuery } from '../hooks/useFiltersQuery.hook';
import { tab, tabsContainer } from './PatientAlertsTabs.css';
import { PatientsWithActiveAlerts } from './PatientsWithActiveAlerts';
import { PatientsWithoutActiveAlerts } from './PatientsWithoutActiveAlerts';

enum AlertTabs {
  Priority,
  NonPriority,
}

export function PatientAlertsTabs() {
  const { state, handlers } = useSidePanelCtx();
  const { setIsVisible: setIsSidePanelVisible } = handlers;
  const { isVisible: isSidePanelVisible } = state;

  const { setPatientDetails } = useSidePanelPatientCtx();

  const handlePatientRowClick = useCallback(
    (patient: Patient | PatientDetails) => {
      // TODO: remove this cast once we've eradicated patient ctx
      setPatientDetails(patient as PatientDetails);
      if (!isSidePanelVisible) {
        setIsSidePanelVisible(true);
      }
    },
    [isSidePanelVisible, setIsSidePanelVisible, setPatientDetails],
  );

  const { searchFilter } = useFiltersQuery();

  const { totalPatients: totalPatientsWithoutAlerts } =
    usePatientsInfiniteQuery(false, {}, { refetchOnWindowFocus: true });

  const { totalPatients: totalPatientsWithAlerts } = usePatientsInfiniteQuery(
    true,
    {},
    { refetchOnWindowFocus: true },
  );

  if (searchFilter) {
    return (
      <ResourceTableContainer
        header={
          <ResourceTableTitle>
            <FormattedMessage defaultMessage="Search results" />
          </ResourceTableTitle>
        }
      >
        <SearchResultsWrapper
          searchTerm={searchFilter}
          onPatientSelected={handlePatientRowClick}
        />
      </ResourceTableContainer>
    );
  }

  return (
    <div className={tabsContainer}>
      <Tabs>
        <Tabs.Tab
          className={tab}
          key={AlertTabs.Priority}
          title={
            <div className={cx(flexContainer.row, gap.XS)}>
              <FormattedMessage defaultMessage="Priority" />
              <span>
                <CounterBox>{totalPatientsWithAlerts}</CounterBox>
              </span>
            </div>
          }
        >
          <PatientsWithActiveAlerts onClickPatient={handlePatientRowClick} />
        </Tabs.Tab>
        <Tabs.Tab
          className={tab}
          key={AlertTabs.NonPriority}
          title={
            <div className={cx(flexContainer.row, gap.XS)}>
              <FormattedMessage defaultMessage="Non-priority" />
              <span>
                <CounterBox>{totalPatientsWithoutAlerts}</CounterBox>
              </span>
            </div>
          }
        >
          <PatientsWithoutActiveAlerts onClickPatient={handlePatientRowClick} />
        </Tabs.Tab>
      </Tabs>
    </div>
  );
}
