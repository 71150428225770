import first from 'lodash/first';

import { usePatientMedications } from '@/pages/patients/PatientMedications/patientMedications.queries';
import { LoadingPlaceholder } from '@/shared/common/LoadingPlaceholder';
import { useGetWizardStepValues } from '@/shared/common/Wizard/state';
import {
  AsyncTitrationAsyncTitrationStatus,
  AsyncTitrationPatientRejectedTitrationReason,
} from '@/shared/generated/grpc/go/pms/pkg/patient/medication/medication.pb';
import { useUpdateAsyncTitration } from '@/shared/hooks/queries/medication.queries';

import { useCNFormContext } from '../../../CNFormContext';
import { TITRATION_OUTREACH_SECTIONS } from '../../metadata';
import { SharedTimeTracking } from '../../shared/TimeTracking';
import { usePendingTitrationList } from '../hooks/usePendingTitrationList';
import type { MedReviewFormFields } from '../medReview/formConfig';

export function TimeTracking() {
  const { patientId } = useCNFormContext();
  const medReviewValues = useGetWizardStepValues(
    TITRATION_OUTREACH_SECTIONS,
    '/med-review',
    '/index',
  )<MedReviewFormFields>();
  const { isLoading, data: patientMeds } = usePatientMedications(patientId);
  const titrations = usePendingTitrationList([
    ...(patientMeds?.referencedMedications ?? []),
    ...(patientMeds?.newMedSuggestions ?? []),
  ]);
  const titration = first(titrations);
  const updateTitration = useUpdateAsyncTitration(
    patientId,
    titration?.id ?? '',
  );

  // if the patient is not adhering to their current meds, we skip the titration
  // review. here, we'll mark it as patient rejected once the note has published.
  const onAfterPublish = (noteId: number) => {
    if (medReviewValues?.patientAdheringToMeds !== 'false') {
      // we don't care about this case here, only interested in rejecting
      // the pending titration if the patient is not adhering to meds.
      return;
    }

    if (!titration) {
      // should never happen, buuuuut might as well guard against it.
      return;
    }

    updateTitration.mutate({
      consentRequestNoteId: noteId,
      status: AsyncTitrationAsyncTitrationStatus.PATIENT_REJECTED,
      patientRejectedTitrationReason:
        AsyncTitrationPatientRejectedTitrationReason.NOT_TAKING_MEDS_AS_PRESCRIBED,
    });
  };

  return (
    <LoadingPlaceholder isLoading={isLoading}>
      <SharedTimeTracking
        sections={TITRATION_OUTREACH_SECTIONS}
        onAfterPublish={onAfterPublish}
      />
    </LoadingPlaceholder>
  );
}
