import isEmpty from 'lodash/isEmpty';
import { useCallback } from 'react';
import type { UseInfiniteQueryResult } from 'react-query';

import { Box, CircularProgress, Divider } from '@/deprecated/mui';
import { usePatientListCtx } from '@/pages/patients/PatientListDashboard/PatientListContext';
import { usePatientVitalsAlertsData } from '@/pages/patients/PatientProfile/shared';
import {
  getFilteredPatients,
  getUpdatedPatients,
  useUpdateOrBlockAlertRequest,
} from '@/pages/patients/patientDetails/utils/updateOrBlockAlertRequest.utils';
import { useFetchAllPages, useFlatPages } from '@/reactQuery';
import { useSidePanelCtx } from '@/shared/common/Page';
import type { ListPatientVitalsResponseVital } from '@/shared/generated/grpc/go/telemetry/pkg/telemetry/v1/telemetry.pb';
import {
  useListPatientVitals,
  usePatientClinicalProfile,
} from '@/shared/hooks/queries';
import { flexSection } from '@/shared/jsStyle';
import type { VitalsAlert } from '@/shared/types/alert.types';
import type { PaginatedData } from '@/shared/types/pagination.types';
import type { Patient } from '@/shared/types/patient.types';

import { AlertDescription, getAlertDateRange } from '../AlertDescription';
import { transformListVitalsToPatientVitals } from './PatientAlertDetail.utils';
import { AlertDescriptionFooter } from './ProfileAlertFooter';
import { AlertDescriptionHeader } from './ProfileAlertHeader';

export const ProfileAlertDetail = ({
  patientId,
  isProfilePage = false,
  onOpenNewAlertNote,
}: {
  patientId: string;
  isProfilePage?: boolean;
  onOpenNewAlertNote?: (alert: VitalsAlert) => void;
}) => {
  const { state: sidePanelState } = useSidePanelCtx();
  const vitalsAlerts = usePatientVitalsAlertsData(patientId, isProfilePage);
  const vitalsQuery = useVitals(patientId, vitalsAlerts);
  const isExhausted = !vitalsQuery.hasNextPage && vitalsQuery.isSuccess;
  const vitalsData = useFlatPages<ListPatientVitalsResponseVital, 'data'>(
    vitalsQuery,
  ) as ListPatientVitalsResponseVital[];
  const vitals = transformListVitalsToPatientVitals(vitalsData);

  const { data: patientProfile, isLoading: isLoadingClinicalProfile } =
    usePatientClinicalProfile(patientId);

  const showAlerts = !isEmpty(vitals);
  const onMarkAsReviewed = useOnMarkAsReviewed(isProfilePage, vitalsAlerts);
  return (
    <Box
      sx={{
        ...flexSection('column', 'center', 'center'),
        width: '100%',
      }}
    >
      {isLoadingClinicalProfile || !isExhausted ? (
        <CircularProgress />
      ) : (
        <>
          {showAlerts &&
            vitalsAlerts.map((alert) => (
              <Box
                key={alert.id}
                sx={{
                  width: sidePanelState.isFullscreen ? '960px' : '100%',
                }}
              >
                <Box sx={{ margin: '24px' }}>
                  <AlertDescriptionHeader
                    isProfilePage={isProfilePage}
                    alert={alert}
                    onMarkAsReviewed={onMarkAsReviewed}
                  />
                  <AlertDescription
                    isProfilePage={isProfilePage}
                    alert={alert}
                    vitals={vitals}
                    patientProfile={patientProfile}
                  />
                  <AlertDescriptionFooter
                    isProfilePage={isProfilePage}
                    alert={alert}
                    onMarkAsReviewed={onMarkAsReviewed}
                    onMarkAsResolved={onOpenNewAlertNote}
                  />
                </Box>
                {isProfilePage && <Divider light />}
              </Box>
            ))}
        </>
      )}
    </Box>
  );
};

function useVitals(patientId: string, vitalsAlerts: VitalsAlert[]) {
  const dateRange = getAlertDateRange(vitalsAlerts, true);
  return useFetchAllPages(
    useListPatientVitals({
      patientId,
      dateFrom: dateRange.startDate,
      dateTo: dateRange.endDate,
    }) as UseInfiniteQueryResult<
      PaginatedData<ListPatientVitalsResponseVital, 'data'>
    >,
  );
}

function useOnMarkAsReviewed(
  isProfilePage: boolean,
  vitalsAlerts: VitalsAlert[],
) {
  const { handlers: sidePanel } = useSidePanelCtx();
  const { setPatients, patients } = usePatientListCtx();
  const updateOrBlockAlertRequest = useUpdateOrBlockAlertRequest();

  const onShowWarningForPatientProfilePage = useCallback(
    (patientDetails: Patient) => {
      if (patients) {
        // update patient on patientProfile page
        setPatients?.(getUpdatedPatients(patients, patientDetails));
      }
    },
    [patients, setPatients],
  );

  return async (alert: VitalsAlert, closeDialog: () => void) => {
    const { error } = await updateOrBlockAlertRequest({
      alert,
      onShowWarningForPatientProfilePage,
    });

    closeDialog();
    if (!error) {
      // Update patient list in main section
      if (!isProfilePage && vitalsAlerts.length === 1) {
        setPatients(getFilteredPatients(patients, alert.patient_id));
        sidePanel.setIsVisible(false);
      }
    }
  };
}
