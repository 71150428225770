import cx from 'classnames';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useFlatPages } from '@/reactQuery';
import ScheduledIcon from '@/shared/assets/svgs/alarm.svg?react';
import CloseCircle from '@/shared/assets/svgs/closeCircle.svg?react';
import { Form } from '@/shared/common/Form';
import type { ConfiguredForm } from '@/shared/common/Form/FormContainer';
import {
  type TaskQueue,
  TaskTypeDefaultAssigneeType,
  type TaskType as TaskTypePb,
} from '@/shared/generated/grpc/go/pms/pkg/task/task.pb';
import { useFlags } from '@/shared/hooks';
import {
  useTaskQueuesInfinite,
  useTaskTypesInfinite,
} from '@/shared/hooks/queries/tasks.queries';
import { display } from '@/shared/jsStyle/utils.css';

import { Button } from '../../tempo/atom/Button/Button';
import { AssignmentAutocomplete } from '../AssignmentAutocomplete';
import { cardChoices } from '../TaskCard/Choice.css';
import { COMMENT_TEXTAREA_HEIGHT } from '../TaskCard/CommentInputControls/CommentInput';
import { scheduleIcon } from '../TaskCard/ScheduleDate/ScheduleDate.css';
import { smallestAcceptableDate } from '../utils';
import { AssignmentChoice } from './AssignmentChoice';
import {
  clearIcon,
  noteRow,
  scheduleTaskDate,
  scheduleTaskDiv,
  scheduleTaskRow,
  scheduleTaskRowFade,
  urgentToggle,
} from './TaskModal.css';
import { TeamQueue } from './TeamQueue';
import { type FormFields, MAX_NOTE_LENGTH } from './form.config';

type Props = {
  form: ConfiguredForm<FormFields>;
  onSubmit: (data: FormFields) => void;
};

export function TaskForm({ form, onSubmit }: Props) {
  const intl = useIntl();
  const { enableScheduledTasks } = useFlags();
  const [forIndividual, stateSetForIndividual] = useState(false);
  const [scheduledTaskDate, setScheduledTaskDate] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  // mui autocomplete doesn't have a way to programmatically clear the input value,
  // so we use `autocompleteResetHack` as the key to the `AssignmentAutocomplete` component
  // and increment it to trigger a re-render whenever we need to clear the value
  const [autocompleteResetHack, setAutocompleteResetHack] = useState(0);
  const taskTypes = useFlatPages<TaskTypePb, 'data'>(useTaskTypesInfinite({}));
  const taskQueues = useFlatPages<TaskQueue, 'data'>(useTaskQueuesInfinite({}));
  const selectedType = form.watch('taskTypeId');
  const selectedTaskType = taskTypes?.find(
    (taskType) => taskType.name === selectedType,
  );
  const selectedQueue = selectedTaskType
    ? taskQueues.find(
        (queue) => queue.name === selectedTaskType.taskQueue?.name,
      )
    : undefined;

  const validTaskTypes = taskTypes.filter(
    (type) => type.typeName !== 'Historical Task' && type.active,
  );

  const tasksGroupedByQueue = groupBy(
    validTaskTypes,
    (type) => type.taskQueue?.queueName,
  );

  const clearAssignee = () => {
    form.setValue('assignee', undefined);
    setAutocompleteResetHack((prev) => prev + 1);
  };

  const setForIndividual = (isForIndividual: boolean) => {
    stateSetForIndividual(isForIndividual);
    form.setValue('assigneeType', isForIndividual ? 'individual' : 'team');
    clearAssignee();
  };

  useEffect(() => {
    const timeout = setTimeout(() => setShowDatePicker(scheduledTaskDate), 300);
    return () => clearTimeout(timeout);
  }, [scheduledTaskDate]);

  return (
    <Form form={form} onSubmit={onSubmit}>
      {() => (
        <>
          <Form.Row rowSpacing={0.75}>
            <Form.DeprecatedSelect
              name="taskTypeId"
              size={7}
              label={<FormattedMessage defaultMessage="Task type" />}
              onChange={(id) => {
                const taskType = taskTypes.find(({ name }) => name === id);
                setForIndividual(
                  taskType?.defaultAssigneeType ===
                    TaskTypeDefaultAssigneeType.INDIVIDUAL,
                );
              }}
            >
              <option key="select" disabled selected value="">
                {intl.formatMessage({
                  defaultMessage: 'Select task type',
                })}
              </option>
              {Object.keys(tasksGroupedByQueue)
                .sort()
                .map((queueName) => (
                  <optgroup
                    key={queueName}
                    label={
                      queueName ||
                      intl.formatMessage({ defaultMessage: 'No queue' })
                    }
                  >
                    {sortBy(tasksGroupedByQueue[queueName], 'typeName').map(
                      (taskType) => (
                        <option key={taskType.name} value={taskType.name}>
                          {taskType.typeName}
                        </option>
                      ),
                    )}
                  </optgroup>
                ))}
            </Form.DeprecatedSelect>
            <Form.Toggle
              className={urgentToggle}
              name="is_urgent"
              size={5}
              label={<FormattedMessage defaultMessage="Urgent task" />}
            />
          </Form.Row>
          <Form.Row
            rowSpacing={0.75}
            className={cx({
              [display.none]: !selectedType,
            })}
          >
            <Form.GridItem className={cardChoices} rowSpacing={0.75}>
              <AssignmentChoice
                isSelected={Boolean(selectedQueue) && !forIndividual}
                onPress={() => setForIndividual(false)}
              />
              <AssignmentChoice
                onPress={() => setForIndividual(true)}
                isSelected={forIndividual}
                isIndividual
              />
            </Form.GridItem>
          </Form.Row>
          {selectedQueue && !forIndividual && (
            <Form.Row rowSpacing={0.75}>
              <Form.GridItem rowSpacing={0.75}>
                <TeamQueue queue={selectedQueue} />
              </Form.GridItem>
            </Form.Row>
          )}
          {selectedType && forIndividual && (
            <Form.Row rowSpacing={0.75}>
              <AssignmentAutocomplete
                name="assignee"
                view={{ type: 'designated' }}
                queue={selectedQueue}
                key={autocompleteResetHack}
                isRequired
              />
            </Form.Row>
          )}
          <Form.Row rowSpacing={0.75} className={noteRow}>
            <Form.TextArea
              size={12}
              expand="expanded"
              expandHeight={COMMENT_TEXTAREA_HEIGHT}
              name="note"
              maxLength={MAX_NOTE_LENGTH}
              placeholder={intl.formatMessage({
                defaultMessage: 'Enter a note',
              })}
              label={<FormattedMessage defaultMessage="Note" />}
            />
          </Form.Row>
          {enableScheduledTasks && !showDatePicker && (
            <Form.Row
              rowSpacing={0}
              className={cx(scheduleTaskRow, {
                [scheduleTaskRowFade.visible]: !scheduledTaskDate,
                [scheduleTaskRowFade.hidden]: scheduledTaskDate,
              })}
            >
              <Form.GridItem size={5}>
                <div className={scheduleTaskDiv}>
                  <Button
                    variant="tertiary"
                    className={scheduleTaskDate}
                    onPress={() => {
                      form.setValue('scheduled_time', smallestAcceptableDate());
                      setScheduledTaskDate(true);
                    }}
                  >
                    <ScheduledIcon className={scheduleIcon} />
                    <FormattedMessage defaultMessage="Schedule for Later" />
                  </Button>
                </div>
              </Form.GridItem>
            </Form.Row>
          )}
          {enableScheduledTasks && showDatePicker && (
            <Form.Row
              rowSpacing={0.75}
              className={cx(scheduleTaskRow, {
                [scheduleTaskRowFade.visible]: scheduledTaskDate,
                [scheduleTaskRowFade.hidden]: !scheduledTaskDate,
              })}
            >
              <Form.DatePicker
                required={false}
                minDate={smallestAcceptableDate()}
                size={4}
                label={intl.formatMessage({
                  defaultMessage: 'Schedule task date',
                })}
                name="scheduled_time"
                disablePortal
              />
              <CloseCircle
                className={clearIcon}
                onClick={() => {
                  setScheduledTaskDate(false);
                  form.setValue('scheduled_time', undefined);
                }}
              />
            </Form.Row>
          )}
        </>
      )}
    </Form>
  );
}
