import cx from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';

import { Script } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared';
import { formatProviderForScript } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared/script.utils';
import { useTimeEstimate } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared/useTimeEstimate';
import { Wizard } from '@/shared/common/Wizard';
import { useWizardStateContext } from '@/shared/common/Wizard/state';
import { usePatientContext } from '@/shared/hooks/queries';
import { flexContainer } from '@/shared/jsStyle/flex.css';
import { gap } from '@/shared/jsStyle/gap.css';

import { useCNFormContext } from '../../../../CNFormContext';
import { INIT_VISIT_SECTIONS } from '../../../metadata';
import { GoalAndStepsBlock } from '../../../shared/ReviewActionSteps/GoalAndStepsBlock';
import type { TActionStep } from '../../../shared/SetActionSteps';
import { initVisitPath } from '../../paths';
import { usePatientGoalText } from '../usePatientGoal';

export function ReviewActionSteps() {
  const intl = useIntl();
  const patientGoalText = usePatientGoalText();
  const {
    wizardFormData: { data: stepData },
  } = useWizardStateContext();
  const actionSteps = (stepData[
    initVisitPath('/action-plan', '/set-action-steps')
  ]?.values?.actionSteps || []) as TActionStep[];
  const { patientId } = useCNFormContext();
  const { data: context, isLoading: isLoadingContext } =
    usePatientContext(patientId);
  const provider = formatProviderForScript(
    context?.primaryPhysicianGivenName,
    context?.primaryPhysicianFamilyName,
    context?.primaryPhysicianRole,
  );

  return (
    <Wizard.Step
      isLoading={isLoadingContext}
      sections={INIT_VISIT_SECTIONS}
      title={intl.formatMessage({
        defaultMessage: 'Action steps',
      })}
      subtitle={useTimeEstimate(
        intl.formatMessage({ defaultMessage: '1-3 min' }),
      )}
    >
      <div className={cx(flexContainer.column, gap.M)}>
        <GoalAndStepsBlock
          actionSteps={actionSteps}
          patientGoalText={patientGoalText}
        />
        <Script>
          <FormattedMessage defaultMessage="We've made a great plan together. Cadence is your accountability partner. We're here to be sure that you achieve your goal! To help you track your progress, I would recommend using something like a notebook or phone app to track each time you do one of our action steps. When we speak next, we'll celebrate your wins and make adjustments as needed." />
        </Script>
        <Script>
          <FormattedMessage
            defaultMessage="As a reminder, {provider} also receives your readings directly through Cadence, as we are integrated with their records. This connection allows {provider} to see your progress in the program, make adjustments if needed, and celebrate your wins."
            values={{ provider }}
          />
        </Script>
      </div>
    </Wizard.Step>
  );
}
