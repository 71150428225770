import { format, isValid } from 'date-fns';
import { FormattedMessage, type IntlShape } from 'react-intl';

import type {
  PsychosocialAssessment,
  Specialist,
} from '@/shared/generated/grpc/go/pms/pkg/ccm_care_plan/ccm_care_plan.pb';
import { parseGrpcDate } from '@/shared/utils/grpc';

import { PSYCHOSOCIAL_ASSESSMENT_OPTIONS } from '../../CarePlanForm/sections/PsychosocialAssessment/psychoSocialAssessmentOptions';

export function formatGoogleDate(googleDate?: GoogleDate) {
  const NA = <FormattedMessage defaultMessage="N/A" />;

  if (!googleDate) {
    return NA;
  }

  const date = parseGrpcDate(googleDate);

  if (!isValid(date)) {
    return NA;
  }

  return format(date, 'MM/dd/yyyy');
}

export function formatSpecialist(specialist: Specialist, intl: IntlShape) {
  const { name, specialty, lastVisit, nextVisit } = specialist;
  const specialistStr = [name, specialty].filter(Boolean).join(', ');
  const lastVisitStr = lastVisit
    ? intl.formatMessage(
        { defaultMessage: 'Last visit: {lastVisit}' },
        { lastVisit },
      )
    : '';
  const nextVisitStr = nextVisit
    ? intl.formatMessage(
        { defaultMessage: 'Next visit: {nextVisit}' },
        { nextVisit },
      )
    : '';
  const visitsStr = [lastVisitStr, nextVisitStr].filter(Boolean).join(', ');
  return [specialistStr, visitsStr].join('. ');
}

export function formatDisabledPsychosocialAssessments(
  intl: IntlShape,
  psychosocialAssessments: PsychosocialAssessment[] | undefined,
) {
  const disabledAssessments =
    psychosocialAssessments?.filter(({ enabled }) => !enabled) || [];

  if (!disabledAssessments.length) {
    return intl.formatMessage({ defaultMessage: 'None at this time' });
  }

  return disabledAssessments.map(({ name }, idx) => (
    <>
      {PSYCHOSOCIAL_ASSESSMENT_OPTIONS.find((opt) => opt.name === name)?.label}
      {idx < disabledAssessments.length - 1 ? ', ' : ''}
    </>
  ));
}
