import { NoteStatus } from '@/shared/generated/grpc/go/pms/pkg/note/note.pb';
import type {
  PatientMedications,
  ReferenceMedication,
} from '@/shared/generated/grpc/go/pms/pkg/patient/medication/medication.pb';
import { AsyncTitrationAsyncTitrationStatus as AsyncTitrationStatus } from '@/shared/generated/grpc/go/pms/pkg/patient/medication/medication.pb';

import { TypeOfEncounter } from '../../../Notes.types';
import type { AsyncTitrationEncounterType, MedicationInfo } from './types';

// a med change is deemed relevant if we want to consider including information
// about it in the published note. generally speaking, this means that the
// async titration's status aligns with the statuses below AND the async titration
// was updated in the current note. one exception to this is for the
// TITRATION_OUTREACH encounter type, where we also want to include information
// on changes awaiting consent when a patient no-shows. we do not handle the
// no-show here, but include the med change with the pending consent for the
// note body generation to handle.
//
// changes are sorted such that all approved (i.e. initially reviewed
// and patient consented) titration recommendations come first, followed
// by rejected (i.e. rejected on initial review and patient rejected) titration
// recommendations. if we have multiple titration suggestions in the same
// state, they will be sorted alphabetically by reference medication name.
const RELEVANT_STATUSES = {
  [TypeOfEncounter.ASYNC_REVIEW]: [
    AsyncTitrationStatus.INITIALLY_REVIEWED,
    AsyncTitrationStatus.REJECTED_ON_INITIAL_REVIEW,
  ],
  [TypeOfEncounter.TITRATION_OUTREACH]: [
    AsyncTitrationStatus.PATIENT_CONSENTED,
    AsyncTitrationStatus.PATIENT_REJECTED,
    AsyncTitrationStatus.INITIALLY_REVIEWED,
  ],
  [TypeOfEncounter.CN_TITRATION_OUTREACH]: [
    AsyncTitrationStatus.PATIENT_CONSENTED,
    AsyncTitrationStatus.PATIENT_REJECTED,
    AsyncTitrationStatus.INITIALLY_REVIEWED,
  ],
};

export const EMPTY_NEW_MED = 'EMPTY_NEW_MED';

export function getSortedRelevantMedChanges(
  encounterType: AsyncTitrationEncounterType,
  noteId: number,
  patientMeds?: PatientMedications,
  referenceMeds?: ReferenceMedication[],
): MedicationInfo[] {
  if (
    !patientMeds ||
    (!patientMeds.referencedMedications && !patientMeds.newMedSuggestions)
  ) {
    return [];
  }

  const relevantStatuses = RELEVANT_STATUSES[encounterType];
  const referencedMedications = patientMeds.referencedMedications ?? [];
  const newMedSuggestions = (patientMeds.newMedSuggestions ?? []).map(
    (suggestion) => ({
      medChanges: suggestion.asyncTitrations?.map((asyncTitration) => ({
        asyncTitration,
      })),
      referenceMedicationId: suggestion.referenceMedicationId ?? EMPTY_NEW_MED,
    }),
  );

  const allMeds = [...newMedSuggestions, ...referencedMedications];

  return allMeds
    .flatMap((med) => {
      if (!med.medChanges) {
        return [];
      }

      let referenceMed = referenceMeds?.find(
        (refMed) => refMed.id?.toString() === med.referenceMedicationId,
      );
      if (!referenceMed && med.referenceMedicationId === EMPTY_NEW_MED) {
        referenceMed = { name: EMPTY_NEW_MED };
      }

      return med.medChanges.map((change) => [referenceMed, change] as const);
    })
    .filter((medInfo): medInfo is MedicationInfo => {
      const [referenceMed, med] = medInfo;

      if (!med || !referenceMed || !med.asyncTitration) {
        return false;
      }

      if (
        encounterType === TypeOfEncounter.ASYNC_REVIEW &&
        med.asyncTitration.initialReviewNoteId !== noteId
      ) {
        return false;
      }

      const consentCapturedInThisNote =
        med.asyncTitration.consentRequestNoteId === noteId;
      const isAwaitingConsent =
        med.asyncTitration.status === AsyncTitrationStatus.INITIALLY_REVIEWED &&
        med.asyncTitration.initialReviewNoteStatus === NoteStatus.PUBLISHED;

      if (
        [
          TypeOfEncounter.TITRATION_OUTREACH,
          TypeOfEncounter.CN_TITRATION_OUTREACH,
        ].includes(encounterType) &&
        !consentCapturedInThisNote &&
        !isAwaitingConsent
      ) {
        return false;
      }

      return relevantStatuses.includes(
        med.asyncTitration.status as AsyncTitrationStatus,
      );
    })
    .sort(([refMedA, changeA], [refMedB, changeB]) => {
      const statusA = changeA.asyncTitration?.status;
      const statusB = changeB.asyncTitration?.status;
      const nameA = refMedA.name;
      const nameB = refMedB.name;

      // if statuses are identical, sort by alpha med name. if we don't
      // have those for whatever reason, just leave them in their original
      // positions in the array
      if (statusA === statusB) {
        return nameA && nameB ? nameA.localeCompare(nameB) : 0;
      }

      return statusA === relevantStatuses[0] ? -1 : 1;
    });
}
