export enum HousingStabilityChoice {
  No = 'NO',
  Yes = 'YES',
}

export enum HousingStabilityDetail {
  FutureWorries = 'FUTURE_WORRIES',
  Unstable = 'UNSTABLE',
}

export enum HousingConcernsChoice {
  No = 'NO',
  Yes = 'YES',
}

export enum HousingConcern {
  Pests = 'PESTS',
  Mold = 'MOLD',
  Lead = 'LEAD',
  NoHeat = 'NO_HEAT',
  BrokenAppliances = 'BROKEN_APPLIANCES',
  MissingDetectors = 'MISSING_DETECTORS',
  WaterLeaks = 'WATER_LEAKS',
  None = 'NONE',
}

export type LivingSituationFields = {
  housingStabilityChoice?: HousingStabilityChoice;
  housingStabilityDetail?: HousingStabilityDetail;
  housingConcerns?: HousingConcern[];
};
