import type { ReportIssueRequestCategory } from '@/shared/generated/grpc/go/pms/pkg/issue_reporter/issue_reporter.pb';
import { useQueryParams } from '@/shared/hooks';

import { useSupportModalContext } from '../SupportModalContext';
import { ReportIssueForm } from './ReportIssueForm';

export function ReportIssue() {
  const { patientId } = useSupportModalContext();
  const params = useQueryParams();
  const category = params.get('category') as
    | ReportIssueRequestCategory
    | undefined;

  return <ReportIssueForm patientId={patientId} category={category} />;
}
