import type { ReactElement } from 'react';
import { useEffect } from 'react';
import type { UseFormReturn } from 'react-hook-form';
import { useController, useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { Form } from '@/shared/common/Form';
import { Tag } from '@/shared/tempo/atom/Tag';

import { GoalMeasure } from '../../../Goals/goals.types';
import { type FormFields, getAssessmentFieldNames } from '../../formConfig';
import { GapWidget } from './GapWidget';
import * as styles from './PhysicalActivity.css';
import type { DaysPerWeek, MinutesPerDay } from './physicalActivity.types';

const DAYS_PER_WEEK: DaysPerWeek[] = [0, 1, 2, 3, 4, 5, 6, 7];
const MINUTES_PER_DAY: MinutesPerDay[] = [
  0, 10, 20, 30, 40, 50, 60, 90, 120, 150,
];

type Props = {
  form: UseFormReturn<FormFields>;
  createGoalWithMeasure: (measure: GoalMeasure, description?: string) => void;
};

export function PhysicalActivity({
  form,
  createGoalWithMeasure,
}: Props): ReactElement {
  const intl = useIntl();
  const { control } = useFormContext();
  const exerciseDaysPerWeek = form.watch('exerciseDaysPerWeek');
  const exerciseMinutesPerDay = form.watch('exerciseMinutesPerDay');

  const showMinutesField =
    exerciseDaysPerWeek !== undefined && exerciseDaysPerWeek !== 0;

  const totalMinutesPerWeek =
    showMinutesField && exerciseMinutesPerDay
      ? exerciseDaysPerWeek * exerciseMinutesPerDay
      : 0;

  const insufficientExercise = totalMinutesPerWeek < 150;

  const { description: descriptionFieldName, checked: checkedFieldName } =
    getAssessmentFieldNames('physicalActivity');

  const { field } = useController({ name: checkedFieldName, control });

  useEffect(() => {
    field.onChange(insufficientExercise);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insufficientExercise]);

  return (
    <>
      <div className={styles.leftColumn}>
        <Form.RadioGroup
          name="exerciseDaysPerWeek"
          label={
            <>
              <Tag variant="default" className={styles.sectionTag}>
                <FormattedMessage defaultMessage="Physical Activity" />
              </Tag>{' '}
              <FormattedMessage defaultMessage="In the last 30 days, on average how many days per week did you engage in moderate exercise (like walking fast, jogging, dancing, swimming, or biking)?" />
            </>
          }
          orientation="horizontal"
          size={11}
          required
          valueMap={Object.fromEntries(
            DAYS_PER_WEEK.map((value) => [value.toString(), value]),
          )}
        >
          {DAYS_PER_WEEK.map((value) => (
            <Form.Radio key={value} value={value.toString()}>
              {value}
            </Form.Radio>
          ))}
        </Form.RadioGroup>

        {showMinutesField && (
          <div className={styles.detailContainer}>
            <Form.RadioGroup
              name="exerciseMinutesPerDay"
              label={
                <FormattedMessage defaultMessage="On average, how many minutes did you usually spend exercising at this level on one of those days?" />
              }
              orientation="horizontal"
              size={11}
              required
              valueMap={Object.fromEntries(
                MINUTES_PER_DAY.map((value) => [value.toString(), value]),
              )}
            >
              {MINUTES_PER_DAY.map((value) => (
                <Form.Radio key={value} value={value.toString()}>
                  {value === 150 ? (
                    <FormattedMessage defaultMessage="150+" />
                  ) : (
                    value
                  )}
                </Form.Radio>
              ))}
            </Form.RadioGroup>
          </div>
        )}

        {insufficientExercise && showMinutesField && exerciseMinutesPerDay && (
          <div className={styles.gapMessage}>
            <FormattedMessage defaultMessage="Gap detected: patient exercises less than 150 minutes a week" />
          </div>
        )}
      </div>

      {exerciseDaysPerWeek !== undefined && (
        <div className={styles.rightColumn}>
          <GapWidget
            fieldName={descriptionFieldName}
            placeholder={intl.formatMessage({
              defaultMessage: 'Enter notes about physical activity',
            })}
            onCreateGoal={createGoalWithMeasure}
            measure={GoalMeasure.PhysicalActivity}
            form={form}
          />
        </div>
      )}
    </>
  );
}
