import { type AppointmentAvailability } from '@/shared/generated/grpc/go/pms/pkg/scheduling/scheduling.pb';

import { type CustomIntakeFormIds } from './acuityIds.utils';

type CategoryId = string;
type AppointmentId = string;

type AcuityIframeCustomIntakeFormParams = Partial<
  Record<`field:${CustomIntakeFormIds}`, string>
>;

type AcuityIframeDefaultFormParams = {
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  // TODO: Acuity used to support passing in a timezone to autoselect the timezone in the
  // iframe. However, they removed it in an update and haven't added it back yet.
  // Context: https://cadence-tyz9473.slack.com/archives/C02BC7KSZ24/p1683563434049459
  // Keeping this around in the case they add this functionality back.
  timezone: string | undefined;
};

type AcuityIframeFilterParams = Partial<{
  calendarID: string;
  datetime: string; // Parsed by strtotime
  location: string;
  appointmentType: `category:${CategoryId}` | AppointmentId;
  'appointmentType[]':
    | `category:${CategoryId}`[]
    | AppointmentId[]
    | (`category:${CategoryId}` | AppointmentId)[];
}>;

/**
 * These embedded iframe URL parameters are documented here:
 * https://developers.acuityscheduling.com/docs/embedding
 */
export type AcuityIframeUrlParams = AcuityIframeCustomIntakeFormParams &
  AcuityIframeDefaultFormParams &
  AcuityIframeFilterParams;

export enum ApptsFilter {
  SHOW_ALL = 'show_all',
  SHOW_SUGGESTED = 'show_suggested',
}

export enum RescheduleMode {
  MANUAL = 'MANUAL',
  SAME_SLOT = 'SAME_SLOT',
}

export type SameSlotReschedulingFormFields = {
  selectedAvailability: string;
};

export type RescheduleData = {
  slot: AppointmentAvailability;
  type: RescheduleMode;
  currentUser: string;
};
