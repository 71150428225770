import { useEffect } from 'react';
import { useQueryClient } from 'react-query';

import { useFlags } from './useFlags';

export function useMonitorForNetworkErrors() {
  const { clearReactQueryCacheOnNetworkError } = useFlags();
  const queryClient = useQueryClient();

  useEffect(() => {
    const cache = queryClient.getQueryCache();
    const unsubscribe = cache.subscribe((event) => {
      if (event?.type !== 'queryUpdated') {
        return;
      }

      if (
        event.action.type !== 'error' ||
        event.action.error.code !== 'ERR_NETWORK'
      ) {
        return;
      }

      queryClient.invalidateQueries({
        queryKey: event.query.queryKey,
        refetchActive: true,
        refetchInactive: true,
      });
    });

    return unsubscribe;
  }, [queryClient, clearReactQueryCacheOnNetworkError]);
}
