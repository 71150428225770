import { TypeOfEncounter } from '@/pages/patients/patientDetails/ui/Notes/Notes.types';
import type { SectionMeta } from '@/shared/common/Wizard/path.utils';
import { getIndex } from '@/shared/common/Wizard/state/sectionUtils';

import { isInitialCnVisit } from '../shared/note.utils';
import type { CNVisitType } from './types';

// Returns true if should move the Action Plan between the Product Support and Med Review sections. This is done here so it can be conditionally (if the feature flag is on and the change wouldn't cause the user to skip the Action Plan section). Once this is permanently turned on and there are no existing notes made before the flag was turned on, can change to modify INIT_VISIT_SECTIONS / REGULAR_VISIT_SECTIONS directly
export function useShouldMoveUpActionPlan() {
  // Now moving up the Action Plan will be released together with the Vitals Education changes, but keeping this as a separate hook to keep intent clear
  return useShowVitalsEducation();
}

export function useShowVitalsEducation() {
  return true;
}

// Returns sections with feature-flagged changes
export function useDisplayedSections(
  sections: readonly SectionMeta[],
  cnVisitType: CNVisitType,
) {
  const showVitalsEducation = useShowVitalsEducation();
  const unmodifiedEncounterTypes = [
    TypeOfEncounter.PATIENT_FOLLOW_UP,
    TypeOfEncounter.INITIAL_CN_VISIT,
    TypeOfEncounter.CN_TITRATION_OUTREACH,
    TypeOfEncounter.INITIAL_CN_VISIT,
    TypeOfEncounter.INITIAL_CN_CHF_VISIT,
  ];

  if (unmodifiedEncounterTypes.includes(cnVisitType)) {
    return sections;
  }

  const newSections = [...sections];

  if (!showVitalsEducation) {
    // Remove Vital Progress section if new Vitals Education is not enabled
    const vitalProgressIndex = getIndex('/vital-progress', newSections);
    if (vitalProgressIndex >= 0) {
      newSections.splice(vitalProgressIndex, 1);
    }

    // Remove Feedback screen if new Vitals Education is not enabled, unless it already existed
    const productSupportIndex = getIndex('/product-support', newSections);
    if (productSupportIndex >= 0 && !isInitialCnVisit(cnVisitType)) {
      newSections[productSupportIndex].steps = newSections[
        productSupportIndex
      ].steps.filter((s) => s.path !== '/feedback');
    }

    return newSections;
  }

  // Change Product Support section to a single feedback page
  const productSupportSection = sections.find(
    (section) => section.basePath === '/product-support',
  );
  if (productSupportSection) {
    newSections.splice(getIndex('/product-support', newSections), 1, {
      ...productSupportSection,
      steps: [{ path: '/feedback' }],
    });
  }

  // Move Action Plan section before Med Review
  const oldIndex = getIndex('/action-plan', newSections);
  if (oldIndex > 0) {
    // Remove Action Plan section from existing location
    const actionPlanSection = newSections[oldIndex];
    newSections.splice(oldIndex, 1);

    // Insert Action Plan section before Med Review
    const newActionPlanIndex = getIndex('/med-review', newSections);
    newSections.splice(newActionPlanIndex, 0, actionPlanSection);
  }

  return newSections;
}
