import type { AxiosError } from 'axios';
import { differenceInDays, format, isSameDay, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as yup from 'yup';

import { logger } from '@/logger';
import {
  useCheckPatientAppointmentAvailability,
  useCheckSameSlotAppointmentAvailability,
} from '@/pages/patients/PatientProfile/PatientScheduling/appointments.queries';
import AlertTriangleIcon from '@/shared/assets/svgs/alertTriangle.svg?react';
import InfoIcon from '@/shared/assets/svgs/info-circle.svg?react';
import { useFormFromConfig } from '@/shared/common/Form/FormContainer';
import { validators } from '@/shared/common/Form/validations';
import type {
  AppointmentAvailability,
  AppointmentDetails,
} from '@/shared/generated/grpc/go/pms/pkg/scheduling/scheduling.pb';
import { useFlags } from '@/shared/hooks';
import { useCurrentUser } from '@/shared/hooks/useCurrentUser';
import { useOnMount } from '@/shared/hooks/useOnMount';
import { Button } from '@/shared/tempo/atom/Button';
import { Skeleton } from '@/shared/tempo/atom/Skeleton';
import { useToaster } from '@/shared/tempo/molecule/Toast';
import { color } from '@/shared/tempo/theme';
import type { Patient } from '@/shared/types/patient.types';

import { SameSlotRescheduler } from './SameSlotRescheduler';
import { AvailabilitySelector } from './SmartScheduler/AvailabilitySelector';
import {
  availability,
  info,
  infoContainer,
  subheading,
  swapClinicianButton,
  warningContainer,
  warningIcon,
} from './SmartScheduler/SmartScheduler.css';
import { CalendarSelect } from './SmartScheduler/dateSelectors/CalendarSelect';
import { getPatientStateInfo } from './SmartScheduler/patient.utils';
import {
  createFormatInTzFn,
  getUniformUniqueAvailabilities,
} from './appointment.utils';
import {
  type RescheduleData,
  RescheduleMode,
  type SameSlotReschedulingFormFields,
} from './types';

type Props = {
  patient: Patient;
  currentAppointment: AppointmentDetails;
  onSelect: (data: RescheduleData) => void;
};

const ANY_CALENDAR_THRESHOLD_DAYS = 5;

export function AppointmentRescheduler({
  patient,
  currentAppointment,
  onSelect,
}: Props) {
  const intl = useIntl();
  const { toaster } = useToaster();
  const { smartReassignment } = useFlags();
  const { currentUserId } = useCurrentUser();
  const { id: patientId } = patient;
  const apptType = currentAppointment.appointmentTypeAcuityId;
  const currentApptDate = currentAppointment.startTime
    ? parseISO(currentAppointment.startTime)
    : new Date();
  const [rescheduleMode, setRescheduleMode] = useState<RescheduleMode>(
    RescheduleMode.MANUAL,
  );
  const config = useSameSlotReschedulingFormConfig();
  const form = useFormFromConfig<SameSlotReschedulingFormFields>(config);
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [searchDate, _setSearchDate] = useState(currentApptDate);
  // Check initial availability
  useOnMount(() => {
    if (apptType) {
      checkAvailability({
        acuityAppointmentTypeId: apptType,
        apptDate: searchDate,
        recommendedApptDate: currentApptDate,
        appointmentId: currentAppointment.name,
        restrictCalendars:
          Math.abs(differenceInDays(searchDate, currentApptDate)) <=
          ANY_CALENDAR_THRESHOLD_DAYS,
      });
    }
    checkSameSlotAvailability({
      name: currentAppointment.name,
    });
  });
  // Changing the search date should also check for availability on that date
  const setSearchDate = (date: Date) => {
    _setSearchDate(date);
    if (apptType) {
      checkAvailability({
        acuityAppointmentTypeId: apptType,
        apptDate: date,
        recommendedApptDate: currentApptDate,
        appointmentId: currentAppointment.name,
        restrictCalendars:
          Math.abs(differenceInDays(date, currentApptDate)) <=
          ANY_CALENDAR_THRESHOLD_DAYS,
      });
    }
  };
  const formattedSearchDate = format(searchDate, 'MMM d, yyyy');
  const patientState = getPatientStateInfo(patient);

  const {
    mutate: checkAvailability,
    isLoading: checkingAvailabilities,
    data: appointmentAvailabilities,
    error,
  } = useCheckPatientAppointmentAvailability(patientId, {
    onError: () => {
      toaster.error(
        intl.formatMessage(
          {
            defaultMessage:
              'Failed to retrieve appointment availabilities for date: {date}',
          },
          { date: formattedSearchDate },
        ),
      );
    },
  });

  const {
    mutate: checkSameSlotAvailability,
    isLoading: checkingSameSlotAvailabilities,
    data: sameSlotAvailabilities,
  } = useCheckSameSlotAppointmentAvailability({
    onError: () => {
      toaster.error(
        intl.formatMessage(
          {
            defaultMessage:
              'Failed to retrieve available clinicians for same slot appointment on: {date}',
          },
          { date: formattedSearchDate },
        ),
      );
    },
  });

  const timezone = patient.timezone || '';
  const formatInTz = createFormatInTzFn(timezone);
  const availabilities = useMemo(
    () =>
      getUniformUniqueAvailabilities(
        appointmentAvailabilities?.availabilities || [],
      ),
    [appointmentAvailabilities?.availabilities],
  );
  const assignedCareProvider = appointmentAvailabilities?.careProvider;
  // Filter out the times that aren't in the patient's day
  const availabilitiesInPatientDay = useMemo(
    () =>
      availabilities.filter(({ datetime }) => {
        const date = datetime ? utcToZonedTime(datetime, timezone) : null;
        return date && isSameDay(date, searchDate);
      }),
    [availabilities, searchDate, timezone],
  );

  return (
    <>
      {rescheduleMode === RescheduleMode.MANUAL && (
        <>
          <div className={availability.container}>
            <div className={availability.heading}>
              <Skeleton isLoading={checkingAvailabilities}>
                <FormattedMessage
                  defaultMessage="{timeSlots, plural, =0 {No} one {{timeSlots}} other {{timeSlots}}} available {timeSlots, plural, =0 {times} one {time} other {times}}{state, select, NOT_FOUND {} other { for {state}}} on {date}"
                  values={{
                    timeSlots: availabilitiesInPatientDay.length,
                    state: patientState?.name || 'NOT_FOUND',
                    date: formattedSearchDate,
                  }}
                />
                <div className={subheading}>
                  {!assignedCareProvider ||
                  assignedCareProvider?.careProviderId === '' ? (
                    <div className={warningContainer}>
                      <AlertTriangleIcon className={warningIcon} />
                      <FormattedMessage defaultMessage="Currently searching across all available clinician calendars" />
                    </div>
                  ) : (
                    <FormattedMessage
                      defaultMessage="Searching assigned clinician calendar: {careProviderFirstName} {careProviderLastName}"
                      values={{
                        careProviderFirstName:
                          assignedCareProvider?.careProviderFirstName,
                        careProviderLastName:
                          assignedCareProvider?.careProviderLastName,
                      }}
                    />
                  )}
                </div>
              </Skeleton>
            </div>
            <CalendarSelect value={searchDate} onChange={setSearchDate} />
          </div>
          <AvailabilitySelector
            // HACK: Attempting to fix issue where search date and selector date are out of sync
            key={searchDate.toDateString()}
            isLoading={checkingAvailabilities}
            fetchError={error as AxiosError}
            searchDate={searchDate}
            setSearchDate={setSearchDate}
            availabilities={availabilitiesInPatientDay}
            onSelect={(slot) => {
              logger.debug('SmartScheduler availability time slot selected', {
                searchDate,
                formattedSearchDate,
                selectedTimeInPatientTz: formatInTz(
                  slot.datetime,
                  'eeee, MMM d h:mm a',
                ),
                selectedAvailabilitySlot: slot,
              });
              onSelect({
                slot,
                type: RescheduleMode.MANUAL,
                currentUser: currentUserId,
              });
            }}
            formatInPatientTimezone={formatInTz}
          />
        </>
      )}
      {rescheduleMode === RescheduleMode.SAME_SLOT && (
        <SameSlotRescheduler
          form={form}
          sameSlotAvailabilities={sameSlotAvailabilities}
          formatInPatientTimezone={formatInTz}
          onSelect={(formData) => {
            const parsedKey = parseFormKey(formData.selectedAvailability);
            if (
              !parsedKey ||
              !parsedKey.datetime ||
              !parsedKey.acuityCalendarId
            )
              return;
            const selectedSlot = findAvailability(
              sameSlotAvailabilities?.availabilities || [],
              parsedKey.datetime,
              parsedKey.acuityCalendarId,
            );
            if (!selectedSlot) return;
            onSelect({
              slot: selectedSlot,
              type: RescheduleMode.SAME_SLOT,
              currentUser: currentUserId,
            });
          }}
        />
      )}
      {patientState && (
        <div className={infoContainer}>
          <InfoIcon stroke={color.Theme.Light.Info} />
          <div className={info}>
            <FormattedMessage
              defaultMessage="Make sure the patient will be in {state} at the time of their appointment"
              values={{ state: patientState.name }}
            />
          </div>
        </div>
      )}
      {smartReassignment && (
        <Button
          variant="tertiary"
          size="small"
          onPress={() =>
            setRescheduleMode(
              rescheduleMode === RescheduleMode.MANUAL
                ? RescheduleMode.SAME_SLOT
                : RescheduleMode.MANUAL,
            )
          }
          isProcessing={checkingSameSlotAvailabilities}
          className={swapClinicianButton}
        >
          {rescheduleMode === RescheduleMode.SAME_SLOT ? (
            <FormattedMessage defaultMessage="Adjust date and/or time of this appointment" />
          ) : (
            <FormattedMessage defaultMessage="Adjust appointment to new clinician at the same time" />
          )}
        </Button>
      )}
    </>
  );
}

function useSameSlotReschedulingFormConfig() {
  const intl = useIntl();
  const { required } = validators(intl);

  return {
    fields: {
      selectedAvailability: {
        validation: required(yup.string()),
      },
    },
  };
}

const parseFormKey = (
  availabilityString?: string,
): AppointmentAvailability | null => {
  if (!availabilityString) return null;

  const [calendarIdStr, datetime] = availabilityString.split('+');
  if (!calendarIdStr || !datetime) return null;

  const acuityCalendarId = parseInt(calendarIdStr, 10);
  if (Number.isNaN(acuityCalendarId)) return null;

  return {
    datetime,
    acuityCalendarId,
  };
};

const findAvailability = (
  availabilities: AppointmentAvailability[],
  datetime: string,
  acuityCalendarId: number,
): AppointmentAvailability | undefined =>
  availabilities.find(
    (a) => a.datetime === datetime && a.acuityCalendarId === acuityCalendarId,
  );
