import { FormattedMessage } from 'react-intl';

import MedicalHistory from '@/shared/assets/svgs/medicalHistory.svg?react';
import { Button } from '@/shared/tempo/atom/Button';
import { IconButton } from '@/shared/tempo/atom/IconButton';
import { color } from '@/shared/tempo/theme';

import { container } from './ContextualGoalButton.css';

type Props = {
  className?: string;
  type?: 'default' | 'form';
  onPress: () => void;
  goalName?: string;
};

export function ContextualGoalButton({
  onPress,
  type = 'default',
  className,
  goalName,
}: Props) {
  if (type === 'form') {
    return (
      <div className={container.form}>
        <IconButton
          variant="tertiary"
          onPress={onPress}
          size="small"
          className={className}
        >
          <MedicalHistory
            fill={color.Theme.Light['Base Font']}
            width="16"
            height="16"
          />
        </IconButton>
      </div>
    );
  }

  return (
    <div className={container.default}>
      <Button
        variant="tertiary"
        size="small"
        onPress={onPress}
        className={className}
      >
        <Button.Icon>
          <MedicalHistory width="16" height="16" />
        </Button.Icon>
        <FormattedMessage
          defaultMessage="Create {goalName} Goal"
          values={{
            goalName: goalName || '',
          }}
        />
      </Button>
    </div>
  );
}
