import type { ConditionProgram } from './condition.types';
import type { EHR } from './ehr.types';
import type { Hospital as THospital } from './hospital.types';
import type { Contact, MedManagementDelegation } from './shared.types';

export enum ProviderType {
  Cadence = 'CADENCE_PROVIDER',
  Hospital = 'HOSPITAL_PROVIDER',
}
export enum ProviderSpecialty {
  PrimaryCare = 'PRIMARY_CARE',
  Cardiology = 'CARDIOLOGY',
  Resident = 'RESIDENT',
  Other = 'OTHER',
}

export enum ProviderTitle {
  MD = 'MD',
  DO = 'DO',
  NP = 'NP',
  RN = 'RN',
  MA = 'MA',
  PA = 'PA',
  LPN = 'LPN',
  Other = 'OTHER',
}

export enum ProviderTeamRole {
  NP = 'NURSE_PRACTITIONER',
  CN = 'CLINICAL_NAVIGATOR',
  RN = 'REGISTERED_NURSE',
  PS = 'PATIENT_SUCCESS_ADVOCATE',
  ONBOARDER = 'ONBOARDER',
  ENROLLER = 'ENROLLER',
  ADMIN = 'ADMINISTRATIVE_STAFF',
  GROWTH_OPS = 'GROWTH_OPS',
  CCM_RN = 'CCM_REGISTERED_NURSE',
  CCM_COORDINATOR = 'CCM_COORDINATOR',
  Other = 'OTHER',
  TITRATION_REGISTERED_NURSE = 'TITRATION_REGISTERED_NURSE',
  TITRATION_CLINICAL_NAVIGATOR = 'TITRATION_CLINICAL_NAVIGATOR',
  PATIENT_COORDINATOR = 'PATIENT_COORDINATOR',
}

export enum PatientAcuityTier {
  TIER_1 = 'TIER_1',
  TIER_2 = 'TIER_2',
  TIER_3 = 'TIER_3',
  TIER_4 = 'TIER_4',
}

export interface Provider {
  contact: Contact;
  created_at: string;
  created_by: string;
  email: string;
  first_name: string;
  id: string;
  last_name: string;
  npi_id: number;
  provider_type: ProviderType;
  provider_specialty: ProviderSpecialty;
  role: ProviderTitle;
  team_role: ProviderTeamRole;
  updated_at: string;
  updated_by: string;
  hospitals?: THospital[];
  preferences?: ProviderPreferences;
  status: ProviderStatus;
  ehr_information?: ProviderEHRInformation[];
  latest_summary_create_time: string | null;
}

export type ProviderEHRInformation = {
  ehr: EHR;
  epic_provider_id?: string;
  epic_fhir_id?: string;
  epic_external_id?: string;
  athena_provider_id?: string;
  hospital_provider_id?: string;
  hospital_id: string;
};

export interface ProviderPreferences {
  ordering_routes: ProviderOrderingRoutes[];
  eligible_patient_list_frequency?: ProviderEligiblePatientListFrequency;
  eligible_patient_list_max_size?: number;
  med_management_delegation: MedManagementDelegation;
  med_management_delegation_note?: string;
  allow_patient_level_med_management_delegation: boolean;
  notify_on_medication_change: boolean;
  acuity_option?: ProviderAcuityOptions;
  patient_acuity_tier?: PatientAcuityTier;
  should_add_appt_note_for_enrolled_patients?: boolean;
  email_opt_in: boolean;
  eligible_patient_list_conditions?: ConditionProgram[];
  ap_acuity_tier?: PatientAcuityTier;
  epl_acuity_tier?: PatientAcuityTier;
}

export enum ProviderOrderingRoutes {
  APPOINTMENT_BASED = 'APPOINTMENT_BASED',
  ELIGIBLE_PATIENT_LIST = 'ELIGIBLE_PATIENT_LIST',
  DIRECT_TO_PEND_ASSESSMENT_AND_PLAN = 'DIRECT_TO_PEND_ASSESSMENT_AND_PLAN',
}

export enum ProviderEligiblePatientListFrequency {
  DAILY = 'DAILY',
  BIWEEKLY = 'BIWEEKLY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
}

export enum ProviderAcuityOptions {
  ALL_MEETING_DIAGNOSIS = 'ALL_MEETING_DIAGNOSIS',
  MOST_ACUTE_ONLY = 'MOST_ACUTE_ONLY',
}

export enum ProviderStatus {
  PROSPECT = 'PROSPECT',
  ONBOARDED = 'ONBOARDED',
  DEACTIVATED = 'DEACTIVATED',
  UNKNOWN = 'UNKNOWN',
  DISENGAGED = 'DISENGAGED',
}
