import type { UseMutationOptions, UseQueryOptions } from 'react-query';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { medicationsKeys } from '@/pages/patients/PatientMedications/patientMedications.queries';
import type {
  CreateManualAsyncTitrationRequest,
  PatientEhrMedications,
  RevertAsyncTitrationsForNoteRequest,
  UpdateAsyncTitrationStatusRequest,
} from '@/shared/generated/grpc/go/pms/pkg/patient/medication/medication.pb';
import { MedicationService } from '@/shared/generated/grpc/go/pms/pkg/patient/medication/medication.pb';
import type { Empty } from '@/shared/generated/grpcWeb/google/protobuf/empty_pb';
import { idToGrpcName } from '@/shared/utils/grpc';

import { TASKS_QUERY_KEY_BASE } from './tasks.queries';

const PATIENT_EHR_MEDICATION_QUERY_KEY_BASE = [
  'rpm',
  'v1',
  'patients',
  'ehrMedications',
] as const;

const patientMedicationKeys = {
  patientEhrMedications: (patientId: string) =>
    [...PATIENT_EHR_MEDICATION_QUERY_KEY_BASE, patientId] as const,
};

export function usePatientEhrMedications(
  patientId: string,
  options?: UseQueryOptions<PatientEhrMedications>,
) {
  return useQuery({
    queryKey: patientMedicationKeys.patientEhrMedications(patientId),
    queryFn: () =>
      MedicationService.GetPatientEhrMedications({
        name: idToGrpcName('patients', patientId, 'ehrMedications'),
      }),
    ...options,
    enabled: Boolean(patientId) && (options?.enabled ?? true),
  });
}

type UpdateAsyncTitrationParams = Omit<
  UpdateAsyncTitrationStatusRequest,
  'name'
>;

export function useUpdateAsyncTitration(
  patientId: string,
  titrationId: string,
  options?: UseMutationOptions<Empty, unknown, UpdateAsyncTitrationParams>,
) {
  const queryClient = useQueryClient();

  return useMutation(
    (payload: UpdateAsyncTitrationParams) =>
      MedicationService.UpdateAsyncTitrationStatus({
        ...payload,
        name: idToGrpcName('async_titration', titrationId),
        updateMask: Object.keys(payload).join(','),
      }),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryClient.invalidateQueries(
          medicationsKeys.patientMedications(patientId),
        );
        await queryClient.invalidateQueries(TASKS_QUERY_KEY_BASE);
        options?.onSuccess?.(data, variables, context);
      },
    },
  );
}

export function useCreateManualAsyncTitration(
  patientId: string,
  options?: UseMutationOptions<
    Empty,
    unknown,
    CreateManualAsyncTitrationRequest
  >,
) {
  const queryClient = useQueryClient();

  return useMutation(
    (payload: CreateManualAsyncTitrationRequest) =>
      MedicationService.CreateManualAsyncTitration(payload),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryClient.invalidateQueries(
          medicationsKeys.patientMedications(patientId),
        );
        await queryClient.invalidateQueries(TASKS_QUERY_KEY_BASE);
        options?.onSuccess?.(data, variables, context);
      },
    },
  );
}

export function useRevertAsyncTitrationForNote(
  patientId: string,
  options?: UseMutationOptions<
    Empty,
    unknown,
    RevertAsyncTitrationsForNoteRequest
  >,
) {
  const queryClient = useQueryClient();

  return useMutation(
    (payload: RevertAsyncTitrationsForNoteRequest) =>
      MedicationService.RevertAsyncTitrationsForNote(payload),
    {
      ...options,
      onSuccess: async () => {
        await queryClient.invalidateQueries(
          medicationsKeys.patientMedications(patientId),
        );
      },
    },
  );
}
