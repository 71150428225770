import { type IntlShape, useIntl } from 'react-intl';

import {
  type PatientDetails,
  PatientStatusEnum,
  ProgramType,
} from '@/shared/generated/grpc/go/pms/pkg/patient/pms.pb';
import { getConditionAbbreviationI18nMap } from '@/shared/patient/conditions.utils';
import {
  grpcPatientStatusToEnum,
  usePatientStatusMap,
} from '@/shared/patient/patientStatus';
import {
  getProgramStatusRecord,
  isDisenrolledFromAllPrograms,
  isParticipatingInProgramType,
} from '@/shared/patient/programs.utils';
import type { TagVariant } from '@/shared/tempo/atom/Tag';
import type {
  ApcmCondition,
  CcmCondition,
  RpmCondition,
} from '@/shared/types/clinicalprofile.types';

export type ProgramTagVariant = Extract<
  TagVariant,
  'success' | 'warning' | 'danger' | 'default'
>;

export function isSupportedProspectiveStatus(status: Maybe<PatientStatusEnum>) {
  return [
    PatientStatusEnum.ELIGIBLE,
    PatientStatusEnum.READY_TO_ENROLL,
    PatientStatusEnum.PATIENT_DECLINED,
    PatientStatusEnum.PROVIDER_DECLINED,
    PatientStatusEnum.NOT_ELIGIBLE,
    PatientStatusEnum.NEEDS_REVIEW,
    PatientStatusEnum.WAITING_FOR_PROVIDER,
    PatientStatusEnum.APPROVED,
    PatientStatusEnum.ORDER_PENDED,
  ].includes(status as PatientStatusEnum);
}

export function getVariant(
  program: ProgramType,
  patientDetails: PatientDetails,
  isAdminView: boolean,
): ProgramTagVariant {
  const programStatus = getProgramStatusRecord(patientDetails, program)?.status;

  // Admin view should also show prospective statueses
  const prospectiveStatusVariant = getProspectiveStatusVariant(programStatus);
  if (isAdminView && prospectiveStatusVariant) {
    return prospectiveStatusVariant;
  }

  const { isDisenrolled } = isParticipatingInProgramType(
    patientDetails,
    program,
  );

  if (isDisenrolled || isDisenrolledFromAllPrograms(patientDetails)) {
    return 'danger';
  }

  if (
    !isAdminView &&
    program === ProgramType.RPM &&
    isEnrolledNotActivated(patientDetails)
  ) {
    return 'warning';
  }

  return 'success';
}

function getProspectiveStatusVariant(
  status: Maybe<PatientStatusEnum>,
): Nullable<ProgramTagVariant> {
  if (!status) {
    return null;
  }
  switch (status) {
    case PatientStatusEnum.ELIGIBLE:
    case PatientStatusEnum.READY_TO_ENROLL:
      return 'warning';
    case PatientStatusEnum.PATIENT_DECLINED:
    case PatientStatusEnum.PROVIDER_DECLINED:
      return 'danger';
    case PatientStatusEnum.NOT_ELIGIBLE:
    case PatientStatusEnum.NEEDS_REVIEW:
    case PatientStatusEnum.WAITING_FOR_PROVIDER:
    case PatientStatusEnum.APPROVED:
    case PatientStatusEnum.ORDER_PENDED:
      return 'default';
    default:
      return null;
  }
}

export function useTooltipContent(
  program: ProgramType,
  patientDetails: PatientDetails,
  isAdminView: boolean,
) {
  const intl = useIntl();
  const patientStatusI18n = usePatientStatusMap();

  if (isAdminView) {
    const status = getProgramStatusRecord(patientDetails, program)?.status;
    const statusEnum = grpcPatientStatusToEnum(status);
    return statusEnum ? patientStatusI18n[statusEnum] : undefined;
  }

  if (isDisenrolledFromAllPrograms(patientDetails)) {
    return intl.formatMessage({
      defaultMessage: 'Patient is disenrolled',
    });
  }

  if (program === ProgramType.RPM && isEnrolledNotActivated(patientDetails)) {
    return intl.formatMessage({
      defaultMessage: 'Patient has not sent their first reading',
    });
  }

  if (program === ProgramType.CCM) {
    const { conditions } = isParticipatingInProgramType(
      patientDetails,
      program,
    );
    return conditions.join(', ');
  }
  return undefined;
}

export function getConditionBadges(
  intl: IntlShape,
  program: ProgramType,
  conditions: RpmCondition[] | CcmCondition[] | ApcmCondition[],
) {
  if (program === ProgramType.CCM && conditions.length) {
    return [conditions.length.toString()];
  }
  if (program === ProgramType.RPM) {
    const conditionAbbreviations = getConditionAbbreviationI18nMap(intl);
    return conditions.map(
      (condition) => conditionAbbreviations[condition as RpmCondition],
    );
  }
  return [];
}

function isEnrolledNotActivated(patientDetails: PatientDetails) {
  const status = patientDetails.patient?.status;
  return (
    status === PatientStatusEnum.ENROLLED &&
    !patientDetails.patient?.activationDate
  );
}
