import { FormattedMessage } from 'react-intl';

import type { PatientAllergy } from '@/shared/generated/grpc/go/pms/pkg/patient/pms.pb';

import {
  allergen,
  allergiesDefaultText,
  allergiesTitle,
  allergyList,
} from './styles.css';

type Props = {
  allergies: PatientAllergy[];
};

export function AllergiesSection({ allergies }: Props) {
  return (
    <>
      <h2 className={allergiesTitle}>
        <FormattedMessage defaultMessage="Medication allergies" />
      </h2>
      {allergies.length ? (
        <ul className={allergyList.ul}>
          {allergies.map((allergy) => (
            <li key={allergy.name} className={allergyList.li}>
              <FormattedMessage
                defaultMessage="{allergen}{reactionCount, plural, =0 {} other {: }}{reactions}"
                values={{
                  allergen: (
                    <span className={allergen}>{allergy.allergen}</span>
                  ),
                  reactions: (
                    <span className={allergiesDefaultText}>
                      {allergy.reactions?.join(', ')}
                    </span>
                  ),
                  reactionCount: allergy.reactions?.length ?? 0,
                }}
              />
            </li>
          ))}
        </ul>
      ) : (
        <span className={allergiesDefaultText}>
          <FormattedMessage defaultMessage="No allergies on file." />
        </span>
      )}
    </>
  );
}
