import { parseISO, startOfDay } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { type IntlShape } from 'react-intl';
import * as yup from 'yup';

import type { FormConfig } from '@/shared/common/Form';
import { validators } from '@/shared/common/Form/validations';
import {
  type PatientDetails,
  ProgramType,
} from '@/shared/generated/grpc/go/pms/pkg/patient/pms.pb';
import { isParticipatingInProgramType } from '@/shared/patient/programs.utils';
import { getUserTimezone } from '@/shared/utils/time-helpers';

export type FormFields = {
  approvalDate?: Date;
};

export function getFormConfig(
  intl: IntlShape,
  patient: PatientDetails | undefined,
): FormConfig {
  const { required } = validators(intl);
  const approvalDateSchema = getApprovalDateValidator(intl, patient);

  return {
    fields: {
      approvalDate: {
        defaultValue: null,
        validation: required(approvalDateSchema),
      },
      approvalTime: {
        defaultValue: null,
        validation: required(yup.string()),
      },
    },
  };
}

function getApprovalDateValidator(
  intl: IntlShape,
  patient: PatientDetails | undefined,
) {
  const { date } = validators(intl);
  const getMinDate = (d: Date | undefined) =>
    d ? startOfDay(utcToZonedTime(d, getUserTimezone())) : undefined;

  // CCM-Participating
  if (
    isParticipatingInProgramType(patient, ProgramType.CCM, {
      checkConsent: true,
    }).isParticipating
  ) {
    const ccmConsentDate = patient?.patient?.ccmConsentDate
      ? parseISO(patient.patient.ccmConsentDate)
      : undefined;

    return date({
      minDate: getMinDate(ccmConsentDate),
      minDateErrorMessage: intl.formatMessage({
        defaultMessage: 'Precedes CCM Consent Date',
      }),
    });
  }

  // APCM-Participating
  if (
    isParticipatingInProgramType(patient, ProgramType.APCM, {
      checkConsent: true,
    }).isParticipating
  ) {
    const apcmProgramStatus = patient?.programStatuses?.find(
      (status) => status.programType === ProgramType.APCM,
    );
    const apcmConsentDate = apcmProgramStatus?.consentDate
      ? parseISO(apcmProgramStatus.consentDate)
      : undefined;

    return date({
      minDate: getMinDate(apcmConsentDate),
      minDateErrorMessage: intl.formatMessage({
        defaultMessage: 'Precedes APCM Consent Date',
      }),
    });
  }

  return date({});
}
