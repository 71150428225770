import type { IntlShape } from 'react-intl';
import { useIntl } from 'react-intl';

import UndoIcon from '@/shared/assets/svgs/undo.svg?react';
import type { MedicationChange } from '@/shared/generated/grpc/go/pms/pkg/patient/medication/medication.pb';
import {
  MedicationChangeChangeType,
  MedicationChangeStatus,
} from '@/shared/generated/grpc/go/pms/pkg/patient/medication/medication.pb';
import { IconButton } from '@/shared/tempo/atom/IconButton';
import { useToaster } from '@/shared/tempo/molecule/Toast';
import { getGrpcErrorMessage } from '@/shared/utils/helpers';

import { usePatientMedicationsContext } from '../PatientMedicationsContext';
import {
  useDeleteMedicationChange,
  useUndoMarkInactive,
} from '../patientMedications.queries';
import { isTitrationChange } from '../utils/medChangeUtils';
import { removeIcon } from './styles.css';

type Props = {
  medChangeId: string;
  medChange: MedicationChange;
  onSuccess: () => void;
};
export function UndoActions({ medChangeId, medChange, onSuccess }: Props) {
  const { noteId: currNoteId } = usePatientMedicationsContext();
  const { status, changeType } = medChange;

  if (!changeType) {
    return null;
  }

  // Never show undo action if latest med change was from a previous note
  if (medChange.noteId !== currNoteId) {
    return null;
  }

  if (
    status === MedicationChangeStatus.INACTIVE &&
    changeType !== MedicationChangeChangeType.STOP
  ) {
    return (
      <UndoMarkInactiveButton medChangeId={medChangeId} onSuccess={onSuccess} />
    );
  }

  if (isTitrationChange(changeType)) {
    return (
      <UndoTitrationButton
        changeType={changeType}
        medChangeId={medChangeId}
        onSuccess={onSuccess}
      />
    );
  }

  return null;
}

function UndoMarkInactiveButton({
  medChangeId,
  onSuccess,
}: {
  medChangeId: string;
  onSuccess: () => void;
}) {
  const intl = useIntl();
  const { toaster } = useToaster();
  const undoMedication = useUndoMarkInactive(medChangeId, onSuccess);

  function handleUndo() {
    undoMedication.mutate(undefined, {
      onError: (err) => {
        toaster.error(
          intl.formatMessage(
            {
              defaultMessage: 'Failed to undo removing medication: {message}',
            },
            { message: getGrpcErrorMessage(err) },
          ),
        );
      },
    });
  }
  return (
    <IconButton.Tooltip
      content={intl.formatMessage({
        defaultMessage: 'Undo removing med',
      })}
    >
      <IconButton variant="secondary" size="small" onPress={handleUndo}>
        <UndoIcon className={removeIcon} />
      </IconButton>
    </IconButton.Tooltip>
  );
}

function UndoTitrationButton({
  changeType,
  medChangeId,
  onSuccess,
}: {
  changeType: MedicationChangeChangeType;
  medChangeId: string;
  onSuccess: () => void;
}) {
  const intl = useIntl();
  const { toaster } = useToaster();
  const deleteMedicationChange = useDeleteMedicationChange(
    medChangeId,
    onSuccess,
  );
  const titrationChangeLabel = getTitrationChangeLabel(intl, changeType);

  function handleUndo() {
    deleteMedicationChange.mutate(undefined, {
      onError: (err) => {
        toaster.error(
          intl.formatMessage(
            {
              defaultMessage:
                'Failed to revert {titrationChangeLabel}: {message}',
            },
            { message: getGrpcErrorMessage(err), titrationChangeLabel },
          ),
        );
      },
    });
  }

  return (
    <IconButton.Tooltip
      content={intl.formatMessage(
        {
          defaultMessage: 'Undo {titrationChangeLabel}',
        },
        { titrationChangeLabel },
      )}
    >
      <IconButton variant="secondary" size="small" onPress={handleUndo}>
        <UndoIcon className={removeIcon} />
      </IconButton>
    </IconButton.Tooltip>
  );
}

function getTitrationChangeLabel(
  intl: IntlShape,
  changeType: MedicationChangeChangeType,
) {
  switch (changeType) {
    case MedicationChangeChangeType.START:
      return intl.formatMessage({ defaultMessage: 'start titration' });
    case MedicationChangeChangeType.STOP:
      return intl.formatMessage({ defaultMessage: 'stop titration' });
    case MedicationChangeChangeType.TITRATION:
      return intl.formatMessage({ defaultMessage: 'titration change' });
    default:
      return '';
  }
}
