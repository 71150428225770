import uniq from 'lodash/uniq';
import { useMemo } from 'react';

import { getProblemList } from '@/pages/adminPanel/patient-enrollment-tracker/ui/shared/icd10CodeUtils';
import { ProgramType } from '@/shared/generated/grpc/go/pms/pkg/patient/pms.pb';
import { useFlags } from '@/shared/hooks';
import {
  usePatientDetails,
  usePatientMedicationAllergies,
} from '@/shared/hooks/queries';
import { useAssignedDevices } from '@/shared/hooks/queries/devices.queries';
import { usePatientContext } from '@/shared/hooks/queries/patientContext.queries';
import { usePatientPrograms } from '@/shared/hooks/queries/program-condition.queries';
import { usePatientVitalsContext } from '@/shared/hooks/queries/vitalsContext.queries';
import { isParticipatingInProgramType } from '@/shared/patient/programs.utils';

import type { TemplateContext } from '../types';
import { usePatientLatestLabsContext } from './usePatientLatestLabsContext';
import { usePatientEhrMedicationsContext } from './usePatientMedicationsContext';

export function useTemplateContext(patientId: string) {
  const flags = useFlags();
  const { data: context, isLoading: isContextLoading } = usePatientContext(
    patientId,
    { enabled: !!patientId },
  );
  const { data: patientVitalsContext, isLoading: isContextVitalsLoading } =
    usePatientVitalsContext(patientId);

  const { data: devices, isLoading: isDevicesLoading } = useAssignedDevices(
    patientId,
    {},
  );
  const { data: latestLabsContext, isLoading: isLabsLoading } =
    usePatientLatestLabsContext(patientId);
  const { data: patientDetailsContext, isLoading: isPatientDetailsLoading } =
    usePatientDetails(patientId, false);
  const {
    data: patientDetailsGrpcContext,
    isLoading: isPatientDetailsGrpcLoading,
  } = usePatientDetails(patientId, true);
  const { data: ehrMedicationsContext, isLoading: isMedicationsLoading } =
    usePatientEhrMedicationsContext(patientId);
  const { medAllergies, isLoading: isLoadingMedAllergies } =
    usePatientMedicationAllergies(patientId);
  const deviceTypes = useMemo(
    () => uniq(devices?.data?.map((d) => d.device_type) || []),
    [devices?.data],
  );
  const contextVitals = patientVitalsContext?.contextVitals;

  const { data: programs, isLoading: isLoadingPatientPrograms } =
    usePatientPrograms(patientId || '', Boolean(patientId));
  const programTypes = uniq(
    programs?.programs
      ?.filter(
        (p) =>
          p.programStatus === patientDetailsContext?.status && !!p.programType,
      )
      .map((p) => p.programType) || [],
  ).sort() as ProgramType[];

  const { conditions: rpmConditions } = isParticipatingInProgramType(
    patientDetailsGrpcContext,
    ProgramType.RPM,
  );
  const { conditions: ccmConditions } = isParticipatingInProgramType(
    patientDetailsGrpcContext,
    ProgramType.CCM,
  );

  const templateContext: TemplateContext = {
    ...(patientDetailsContext && {
      problemList: getProblemList(patientDetailsContext?.diagnosis_codes),
    }),
    ...(patientDetailsGrpcContext && {
      rpmConditions,
      ccmConditions,
      ccmConsentDate: patientDetailsGrpcContext.patient?.ccmConsentDate,
      programTypes,
    }),
    ...context,
    ...contextVitals,
    latestLabs: latestLabsContext,
    ehrMedications: ehrMedicationsContext,
    flags,
    deviceTypes,
    medAllergies,
  };
  const isLoading = [
    isContextLoading,
    isContextVitalsLoading,
    isDevicesLoading,
    isPatientDetailsLoading,
    isPatientDetailsGrpcLoading,
    isLabsLoading,
    isMedicationsLoading,
    isLoadingPatientPrograms,
    isLoadingMedAllergies,
  ].some(Boolean);

  return {
    isLoading,
    context: templateContext,
  };
}
