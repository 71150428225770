import {
  type AsyncTitration,
  AsyncTitrationAsyncTitrationType as AsyncTitrationType,
} from '@/shared/generated/grpc/go/pms/pkg/patient/medication/medication.pb';

import type { TitrationRecommendation } from './useTitrationRecommendation';

export function useIsNewMed(
  titration: Maybe<AsyncTitration | TitrationRecommendation>,
) {
  return titration?.type === AsyncTitrationType.NEW_MED;
}
