import { FormattedMessage, useIntl } from 'react-intl';

import { queriesAreLoading } from '@/reactQuery';
import { CollapsibleCardSection } from '@/shared/common/Form/CardSection';
import { Modal } from '@/shared/common/Modal';
import { EHR } from '@/shared/generated/grpc/cadence/models/models.pb';
import {
  useInstance,
  usePatientDetails,
  usePatientHospitals,
} from '@/shared/hooks/queries';
import { useHealthSystemById } from '@/shared/hooks/queries/health-system.queries';
import { useCopyToClipboard } from '@/shared/hooks/useCopyToClipboard';
import { grpcNameToId } from '@/shared/utils/grpc';

type Props = {
  open: boolean;
  patientId: string;
  onClose: () => void;
};

export function DebugModal({ open, onClose, patientId }: Props) {
  const intl = useIntl();

  const patientDetailsQuery = usePatientDetails(patientId, true);
  const patient = patientDetailsQuery.data;

  const hospitalDetailsQuery = usePatientHospitals({ patientId });
  const hospital = hospitalDetailsQuery?.data?.hospitals?.[0];

  const healthSystemQuery = useHealthSystemById(
    hospital?.health_system_id || '',
    { enabled: Boolean(hospital?.health_system_id) },
  );
  const healthSystem = healthSystemQuery?.data;

  const instanceQuery = useInstance(
    hospital?.ehr_information?.instance_id || '',
    { enabled: Boolean(hospital?.ehr_information?.instance_id) },
  );
  const instance = instanceQuery?.data;

  return (
    <Modal
      open={open}
      onClose={onClose}
      size="xlarge"
      isLoading={queriesAreLoading([
        patientDetailsQuery,
        hospitalDetailsQuery,
        healthSystemQuery,
        instanceQuery,
      ])}
    >
      <Modal.Header
        title={intl.formatMessage({
          defaultMessage: 'Debug Info',
        })}
      />
      <Modal.Body>
        <CollapsibleCardSection
          title={intl.formatMessage({ defaultMessage: 'IDs' })}
          defaultOpen
        >
          <p>
            <FormattedMessage defaultMessage="Patient ID: " />
            <Copyable text={grpcNameToId(patient?.name || '')} />
          </p>
          <p>
            <FormattedMessage defaultMessage="Hospital ID: " />
            <Copyable text={hospital?.id} /> ({hospital?.name})
          </p>
          <p>
            <FormattedMessage defaultMessage="Health System ID: " />
            <Copyable text={String(healthSystem?.id)} /> ({healthSystem?.name})
          </p>
          <p>
            <FormattedMessage defaultMessage="Market ID: " />
            <Copyable text={String(hospital?.market?.id)} /> (
            {hospital?.market?.name})
          </p>
        </CollapsibleCardSection>
        <CollapsibleCardSection
          title={intl.formatMessage({ defaultMessage: 'EHR' })}
          defaultOpen
        >
          <p>
            <FormattedMessage defaultMessage="MRN: " />
            <Copyable text={patient?.ehrInformation?.mrn} /> -{' '}
            {patient?.ehrInformation?.ehr}
          </p>
          {instance && (
            <p>
              <FormattedMessage defaultMessage="Instance: " />
              <Copyable text={String(instance?.instanceId)} /> (
              {instance?.displayName})
            </p>
          )}
          {patient?.ehrInformation?.ehr === EHR.EPIC && (
            <>
              <p>
                <FormattedMessage defaultMessage="Epic FHIR ID: " />
                <Copyable text={patient?.ehrInformation?.fhirId} />
              </p>
              <p>
                <FormattedMessage defaultMessage="Epic Department ID: " />
                <Copyable
                  text={hospital?.ehr_information?.epic_department_id}
                />
              </p>
            </>
          )}
          {patient?.ehrInformation?.ehr === EHR.CERNER && (
            <>
              <p>
                <FormattedMessage defaultMessage="Cerner FHIR ID: " />
                <Copyable text={patient?.ehrInformation?.fhirId} />
              </p>
              <p>
                <FormattedMessage defaultMessage="Cerner Department ID" />
                <Copyable
                  text={hospital?.ehr_information?.cerner_department_id}
                />
              </p>
            </>
          )}
          {patient?.ehrInformation?.ehr === EHR.ATHENA && (
            <>
              <p>
                <FormattedMessage defaultMessage="Athena Practice ID: " />
                <Copyable
                  text={hospital?.ehr_information?.athena_practice_id}
                />
              </p>
              <p>
                <FormattedMessage defaultMessage="Athena Department ID: " />
                <Copyable
                  text={hospital?.ehr_information?.athena_department_id}
                />{' '}
                ({hospital?.ehr_information?.athena_department_name})
              </p>
            </>
          )}
        </CollapsibleCardSection>
      </Modal.Body>
    </Modal>
  );
}

type CopyableProps = {
  text?: string;
};

function Copyable({ text }: CopyableProps) {
  const copyToClipboard = useCopyToClipboard();

  return <span onClick={() => copyToClipboard(text ?? '')}>{text}</span>; // eslint-disable-line
}
