import { createCtx } from '@/shared/hooks/createCtx';
import type { PatientDetails } from '@/shared/types/patient.types';

export type SidePanelPatientCtx = {
  patientId: string;
  /**
   * @deprecated Use patientId instead and load the patient details via usePatientDetails gRPC
   * TODO: Remove once all usages of PatientDetails are removed
   */
  patientDetails: PatientDetails;
  setPatientDetails: (details: PatientDetails) => void;
};

const [useCtx, Provider] = createCtx<SidePanelPatientCtx>(
  'SidePanelPatientCtx',
);

export const useSidePanelPatientCtx = useCtx;
export const SidePanelPatientProvider = Provider;
