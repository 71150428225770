/* eslint-disable */
// @ts-nocheck
/*
* This file is a generated Typescript file for GRPC Gateway, DO NOT MODIFY
*/

import * as GoogleProtobufField_mask from "../../../../../google/protobuf/field_mask.pb"
import * as GoogleProtobufWrappers from "../../../../../google/protobuf/wrappers.pb"
import * as GoogleTypeDate from "../../../../../google/type/date.pb"
import * as fm from "@/shared/generated/grpc/fetch.pb"

export enum LabGroup {
  LAB_GROUP_UNSPECIFIED = "LAB_GROUP_UNSPECIFIED",
  ECHO = "ECHO",
  A1C = "A1C",
  CMP = "CMP",
  LIPIDS = "LIPIDS",
  BNP = "BNP",
  TROPONIN = "TROPONIN",
  UNKNOWN = "UNKNOWN",
}

export enum LabType {
  LAB_TYPE_UNSPECIFIED = "LAB_TYPE_UNSPECIFIED",
  LAB = "LAB",
  GROUP = "GROUP",
}

export enum LabValueStatus {
  LAB_VALUE_STATUS_UNSPECIFIED = "LAB_VALUE_STATUS_UNSPECIFIED",
  NORMAL = "NORMAL",
  HIGH = "HIGH",
  LOW = "LOW",
}

export type GetPatientLabsRequest = {
  name?: string
}

export type GetPatientLabsAndGroupsRequest = {
  name?: string
}

export type ListReferenceLabsRequest = {
  pageSize?: number
  pageToken?: string
  readMask?: GoogleProtobufField_mask.FieldMask
  filter?: string
  orderBy?: string
}

export type ListReferenceLabsResponse = {
  referenceLabs?: ReferenceLab[]
  nextPageToken?: string
  totalSize?: number
}

export type ReferenceLab = {
  name?: string
  labName?: string
  initial?: string
  unit?: string
  labGroup?: LabGroup
  minValue?: number
  maxValue?: number
  clinicalMaxValue?: number
  clinicalMinValue?: number
  loinc?: string
  loincCodes?: string[]
  validatedNames?: string[]
}

export type PatientLabs = {
  name?: string
  labs?: Lab[]
}

export type Lab = {
  name?: string
  value?: string
  observationDate?: GoogleTypeDate.Date
  referenceLabId?: string
  s3Uri?: string
}

export type LabsAndGroups = {
  name?: string
  value?: GoogleProtobufWrappers.StringValue
  observationDate?: GoogleTypeDate.Date
  s3Uri?: string
  valueStatus?: LabValueStatus
}

export type PatientLabsAndGroups = {
  name?: string
  labGroups?: LabGroupWithReferenceLabs[]
}

export type LabGroupWithReferenceLabs = {
  labGroup?: LabGroup
  referenceLabs?: ReferenceLabWithLabs[]
}

export type ReferenceLabWithLabs = {
  referenceLab?: ReferenceLab
  labs?: LabsAndGroups[]
}

export class LabsService {
  static GetPatientLabs(req: GetPatientLabsRequest, initReq?: fm.InitReq): Promise<PatientLabs> {
    return fm.fetchReq<GetPatientLabsRequest, PatientLabs>(`/rpm/v1/${req["name"]}?${fm.renderURLSearchParams(req, ["name"])}`, {...initReq, method: "GET"})
  }
  static ListReferenceLabs(req: ListReferenceLabsRequest, initReq?: fm.InitReq): Promise<ListReferenceLabsResponse> {
    return fm.fetchReq<ListReferenceLabsRequest, ListReferenceLabsResponse>(`/rpm/v1/labs/reference?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static GetPatientLabsAndGroups(req: GetPatientLabsAndGroupsRequest, initReq?: fm.InitReq): Promise<PatientLabsAndGroups> {
    return fm.fetchReq<GetPatientLabsAndGroupsRequest, PatientLabsAndGroups>(`/rpm/v1/${req["name"]}?${fm.renderURLSearchParams(req, ["name"])}`, {...initReq, method: "GET"})
  }
}