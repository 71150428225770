import { useCallback } from 'react';

import type { NoteResponse } from '@/shared/types/note.types';

import { useNoteEditorContext } from '../NoteEditorContext';
import { InitialEncounterTypeInstance } from './encounterTypeUtils';

export function useSetNoteEditorContentFromNote() {
  const { setNoteEditorContent, setEncounterModuleInstances } =
    useNoteEditorContext();

  return useCallback(
    (note: NoteResponse) => {
      setNoteEditorContent({
        body: note.rtf_body,
        bodyHtml: note.body_html,
        title: note.title,
        labels: note.labels.map((label) => label.id),
        externalProviderId: note.external_provider_id,
        escalationMessage: note.escalation_message,
        actionRequired: note.action_required,
        urgent: note.urgent,
        timeTracking: note.time_tracking,
        zendeskTicket: note.zendesk_ticket_id,
        appointmentId: note.appointment_id,
        noShowAppointmentId: note.no_show_appointment_id,
        endEncounter: {
          endType: note.end_encounter_type,
          endReason: note.end_encounter_reason,
          endNote: note.end_encounter_note,
        },
        timeElapsed: note.time_elapsed,
      });

      const draftInstances = note.encounter_instances || [
        InitialEncounterTypeInstance,
      ];
      setEncounterModuleInstances(draftInstances);
    },
    [setEncounterModuleInstances, setNoteEditorContent],
  );
}
