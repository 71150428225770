import jwt_decode from 'jwt-decode';
import { useMutation } from 'react-query';

import { settings } from '@/config';
import { getRumSessionId, getRumSessionUrl } from '@/monitoring.utils';
import {
  IssueReporterService,
  ReportIssueRequestCategory,
} from '@/shared/generated/grpc/go/pms/pkg/issue_reporter/issue_reporter.pb';
import type { CognitoIdToken } from '@/shared/types/cognito.types';
import { store } from '@/store';

type CreateIssueReportParams = {
  description: string;
  patientId: string;
  category?: ReportIssueRequestCategory;
};

export function useDebugMetadata() {
  const userInfo = getUserInfo();
  const { VITE_VERSION, VITE_ENVIRONMENT } = settings;
  return {
    currentPageUri: window.location.href,
    rumSessionId: getRumSessionId(),
    userDisplayName: `${userInfo?.first} ${userInfo?.last}`,
    environment: VITE_ENVIRONMENT,
    version: VITE_VERSION,
    rumSessionUrl: getRumSessionUrl(),
    sentryUrl: `https://sentry.io/organizations/cadence/releases/${VITE_ENVIRONMENT}%40${VITE_VERSION}/?environment=${VITE_ENVIRONMENT}&project=5889850&issuesType=all`,
  };
}

export function useReportIssueToSlack() {
  const {
    currentPageUri,
    environment,
    rumSessionId,
    userDisplayName,
    version,
  } = useDebugMetadata();

  return useMutation(
    ({ description, patientId, category }: CreateIssueReportParams) =>
      IssueReporterService.ReportIssue({
        patientId,
        description,
        category: category ?? ReportIssueRequestCategory.CATEGORY_UNSPECIFIED,
        currentPageUri,
        environment,
        rumSessionId,
        userDisplayName,
        version,
      }),
  );
}

function getUserInfo(): { first: string; last: string } | null {
  // Catch errors here as `jwt_decode` could throw if the JWT is null / invalid
  try {
    const currentUserInfo = jwt_decode<CognitoIdToken>(
      store.getState().auth.token.id_token,
    );

    return {
      first: currentUserInfo.given_name,
      last: currentUserInfo.family_name,
    };
  } catch (_) {
    return null;
  }
}
